import { IPerson, Person } from './person';
import { Company, ICompany } from './company';
import { BcmOrderAttachment, BcmOrderAttachmentDto } from './bcm-order-attachment';
import { BcmOrderBoatWeighing, BcmOrderBoatWeighingDto } from './bcm-order-boat-weighing';
import { BcmOrderInvoicePosition, BcmOrderInvoicePositionDto } from './bcm-order-invoice-position';
import { BcmOrderStatus, BcmOrderStatusDto, BcmOrderStatusType, bcmOrderStatusTypeDE } from './bcm-order-status';
import { BcmOrderTodo, BcmOrderTodoDto } from './bcm-order-todo';
import { BcmNote, BcmNoteDto } from './bcm-note';
import { Boat, IBoat } from './boat';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { addDays, isSameDay } from '@core/date.facade';
import { BcmReceipt, BcmReceiptDto } from '@shared/models/bcm-receipt';
import { User } from '@core/services/api/u2b-user.api-service';

export enum BcmOrderWorkPackageType {
    Crane = 'crane',
    Slip = 'slip',
    None = 'none'
}

export enum BcmOrderExportType {
    Invoice = 'invoice',
    CashReceipt = 'cash-receipt'
}

export interface BcmOrderDto {
    id: number;
    orderTitle: string;
    orderType: BcmOrderWorkPackageType;
    startTime: Date | string | null;
    endTime: Date | string | null;
    startedOn: Date | string | null;
    endedOn: Date | string | null;
    exportType: BcmOrderExportType;
    sendMailOnStatusChange: boolean; // future functionality
    lastStatus: BcmOrderStatusType;
    insertedOn: string;
    insertedBy: string;
    assignedUser: string;
    assignedUserObject: User;
    lastUpdateOn: string;
    lastUpdateBy: string;
    tasks: string;

    client: IPerson;
    clientCompany: ICompany;
    boats: IBoat[];
    boatWeighings: BcmOrderBoatWeighingDto[];
    notes: BcmNoteDto[];
    note: string | null;
    status: BcmOrderStatusDto[];
    todos: BcmOrderTodoDto[];
    orderAttachments: BcmOrderAttachmentDto[];
    orderInvoicePositions: BcmOrderInvoicePositionDto[];

    invoiceNumber?: string;
    receipts: BcmReceiptDto[];
}

export class BcmOrder {
    id;
    orderNumber: string;
    orderTitle;
    orderType: BcmOrderWorkPackageType = BcmOrderWorkPackageType.None;
    startTime: Date;
    endTime;
    startedOn;
    endedOn;
    exportType: BcmOrderExportType = BcmOrderExportType.Invoice;
    sendMailOnStatusChange;
    lastStatus: BcmOrderStatusType;
    lastStatusName: string;
    tasks: string;
    assignedUser: string;
    assignedUserName: string;
    assignedUserObject: User;
    readonly insertedOn;
    readonly insertedBy;
    readonly lastUpdateOn;
    readonly lastUpdateBy;

    client?: Person | null = null;
    clientCompany?: Company | null = null;
    activeClient: Person | Company | null;
    boats: Boat[] = [];
    boatWeighings: BcmOrderBoatWeighing[] = [];
    note: string | null;
    notes: BcmNote[] = [];
    statusHistory: BcmOrderStatus[] = [];
    todos: BcmOrderTodo[] = [];
    orderAttachments: BcmOrderAttachment[] = [];
    orderInvoicePositions: BcmOrderInvoicePosition[] = [];

    // helpers
    isSameDayStartAndEnd: boolean;
    isToday: boolean;
    isTomorrow: boolean;
    clientName: string;
    userName: string;

    doneState: number;

    invoiceNumber?: string;
    receipts?: BcmReceipt[];

    constructor(bcmOrderDto: BcmOrderDto = {} as BcmOrderDto) {
        this.id = bcmOrderDto.id;
        this.orderNumber = bcmOrderDto.id ? ('#' + String(bcmOrderDto.id).padStart(4, '0')) : '';
        this.orderTitle = bcmOrderDto.orderTitle;
        this.orderType = bcmOrderDto.orderType;
        this.startTime = tryParseDate(bcmOrderDto.startTime);
        this.endTime = tryParseDate(bcmOrderDto.endTime);
        this.startedOn = tryParseDate(bcmOrderDto.startedOn);
        this.endedOn = tryParseDate(bcmOrderDto.endedOn);
        this.exportType = bcmOrderDto.exportType;
        this.sendMailOnStatusChange = bcmOrderDto.sendMailOnStatusChange;
        this.lastStatus = bcmOrderDto.lastStatus;
        this.lastStatusName = bcmOrderStatusTypeDE[bcmOrderDto.lastStatus];
        this.insertedOn = tryParseDate(bcmOrderDto.insertedOn);
        this.insertedBy = bcmOrderDto.insertedBy;
        this.assignedUser = bcmOrderDto.assignedUser;

        this.lastUpdateOn = tryParseDate(bcmOrderDto.lastUpdateOn);
        this.lastUpdateBy = bcmOrderDto.lastUpdateBy;
        this.tasks = bcmOrderDto.tasks;

        this.isSameDayStartAndEnd = !!this.startTime && !!this.endTime && isSameDay(this.startTime, this.endTime);
        this.isToday = !!this.startTime && isSameDay(this.startTime, new Date());
        this.isTomorrow = !!this.startTime && isSameDay(this.startTime, addDays(new Date(), 1));

        this.client = bcmOrderDto.client ? new Person(bcmOrderDto.client) : null;
        this.clientCompany = bcmOrderDto.clientCompany ? new Company(bcmOrderDto.clientCompany) : null;
        this.boats = (bcmOrderDto.boats || []).map(boat => new Boat(boat));
        this.boatWeighings = (bcmOrderDto.boatWeighings || []).map(weighing => new BcmOrderBoatWeighing(weighing));
        this.notes = (bcmOrderDto.notes || []).map(note => new BcmNote(note));
        this.note = bcmOrderDto.note;
        this.statusHistory = (bcmOrderDto.status || []).map(status => new BcmOrderStatus(status));
        this.todos = (bcmOrderDto.todos || []).map(todo => new BcmOrderTodo(todo));
        this.orderAttachments = (bcmOrderDto.orderAttachments || []).map(orderAttachment => new BcmOrderAttachment(orderAttachment));
        this.orderInvoicePositions = (bcmOrderDto.orderInvoicePositions || []).map(orderInvoicePosition => new BcmOrderInvoicePosition(orderInvoicePosition));

        this.doneState = this.todos.filter(todo => todo.done).length / this.todos.length;

        this.activeClient = this.client || this.clientCompany || null;
        this.clientName = (this.activeClient)?.fullName;

        this.invoiceNumber = bcmOrderDto.invoiceNumber;
        this.receipts = (bcmOrderDto.receipts || []).map(r => new BcmReceipt(r));

        this.assignedUserObject = bcmOrderDto.assignedUserObject;
        this.assignedUserName = bcmOrderDto.assignedUserObject
            ? [
                bcmOrderDto.assignedUserObject.lastName?.trim(),
                ', ',
                bcmOrderDto.assignedUserObject.firstName?.trim(),
            ].join('')
            : '';

    }
}
