import { isDate, isValid } from '@core/date.facade';
import { parseISOWithFallback } from '@core/functions/parse-iso-with-fallback';

export function tryParseDate(date: any, defaultIfNotParsable = null): Date | null {

    if (date === '1970-01-01T00:00:00.000Z') {
        return null;
    }

    if (isDate(date) && isValid(date)) {
        return date;
    }

    const parsedDate = parseISOWithFallback(date, null);

    if (isDate(parsedDate) && isValid(parsedDate)) {
        return parsedDate;
    }

    return defaultIfNotParsable;
}
