<mat-expansion-panel class="container" [class.mat-panel-padding]="!expanded" [(expanded)]="expanded">

    <mat-expansion-panel-header [class.expanded]="expanded">
        <mat-panel-description>
            <ng-container *ngIf="!expanded">
                <div>
                    {{ travelerFormGroup.get('lastName')?.value }}, {{ travelerFormGroup.get('firstName')?.value }}
                    <ng-container *ngIf="travelerFormGroup.get('birthDate')?.value">
                        <br><small>({{ travelerFormGroup.get('birthDate')?.value | date: 'dd.MM.yyyy' }})</small>
                    </ng-container>
                </div>

                <div class="mat-panel-description-contact-data">
                    <ng-container *ngIf="travelerFormGroup.get('phone')?.value">
                        <div class="contact-data">
                            {{ travelerFormGroup.get('phone')?.value }}
                            <mat-icon>phone</mat-icon>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="travelerFormGroup.get('email')?.value">
                        <div class="contact-data">
                            {{ travelerFormGroup.get('email')?.value }}
                            <mat-icon>email</mat-icon>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="travelerFormGroup" fxLayout="column">

        <ng-container *ngIf="!bookingDialogService.readonlyView">
            <button mat-stroked-button color="accent" (click)="showSelectPersonDialog()">
                Personen durchsuchen ...
            </button>

            <mat-divider fxFlex="100" class="special-divider"></mat-divider>
        </ng-container>

        <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field appearance="outline" fxFlex="50" fxFlex.gt-xs="50">
                <mat-label>Vorname</mat-label>
                <input matInput [readonly]="bookingDialogService.readonlyView" data-test="FI-Name" type="text"
                       formControlName="firstName">
                <mat-error>{{ travelerFormGroup.get('firstName').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50" fxFlex.gt-xs="50">
                <mat-label>Nachname</mat-label>
                <input matInput [readonly]="bookingDialogService.readonlyView" data-test="FI-Name" type="text"
                       formControlName="lastName">
                <mat-error>{{ travelerFormGroup.get('lastName').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="8px">
            <mat-form-field appearance="outline" fxFlex="50" fxFlex.gt-xs="50">
                <mat-label>Telefonnummer</mat-label>
                <input matInput [readonly]="bookingDialogService.readonlyView" data-test="FI-Phone" type="text"
                       formControlName="phone">
                <mat-error>{{ travelerFormGroup.get('phone').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50" fxFlex.gt-xs="50">
                <mat-label>E-Mail</mat-label>
                <input matInput [readonly]="bookingDialogService.readonlyView" data-test="FI-Email" type="email"
                       formControlName="email">
                <mat-error>{{ travelerFormGroup.get('email').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="8px">
            <div fxFlex="50" fxFlex.gt-xs="50">
                <app-input-date [readonly]="bookingDialogService.readonlyView" formControlName="birthDate">
                    <label>Geburtsdatum</label>
                </app-input-date>
            </div>
        </div>

    </form>

    <ng-container *ngIf="!bookingDialogService.readonlyView">
        <mat-action-row>
            <button mat-stroked-button color="warn" class="remove-btn"
                    (click)="removeTraveler.emit()">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-action-row>
    </ng-container>

</mat-expansion-panel>
