import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance } from '@angular/material/legacy-form-field';
import { compareObjectsById } from '@shared/functions/compare-objects-by-id';
import { Company } from '@shared/models/company';
import { CompaniesFacade } from '@modules/bcm/@core/state-management/companies/companies.facade';

@Component({
    selector: 'app-input-select-company',
    templateUrl: './input-select-company.component.html',
    styleUrls: ['./input-select-company.component.scss']
})
export class InputSelectCompanyComponent implements OnInit, OnDestroy {

    @Input() appearance: MatLegacyFormFieldAppearance = 'outline';

    @Input() multiSelect = false;

    @Input() set control(formControl: AbstractControl) {
        this._formControl = formControl as UntypedFormControl;
        this._addFormControlListeners(this._formControl);
    }

    get control(): UntypedFormControl {
        return this._formControl;
    }

    private _formControl: UntypedFormControl;

    private _unsubscribeAll = new Subject();

    private _companies: Company[] = [];

    filteredCompanies$: Observable<Company[]>;

    compareObjectsById = compareObjectsById;

    constructor(private companiesFacade: CompaniesFacade) {
    }

    ngOnInit(): void {
        this._loadCompanies();

        if (this.multiSelect) {
            this._formControl.setValue(this._formControl.value);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _addFormControlListeners(formControl: UntypedFormControl): void {
        if (!formControl) {
            return;
        }
        this.filteredCompanies$ = combineLatest([
            formControl.valueChanges
                .pipe(
                    startWith((this.multiSelect) ? [] : ''),
                    debounceTime(DEFAULT_DEBOUNCE_TIME)
                ),
            this.companiesFacade.list$.pipe(map(companies => this._companies = companies))
        ])
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((value) => isString(value[0]) && !this.multiSelect ? this._filter(value[0]) : this._companies)
            );

    }

    private _loadCompanies(): void {
        this._companies = [];
        this.companiesFacade.loadList().subscribe();
    }

    private _filter(search = ''): Company[] {
        return this._companies.filter(company =>
            company.fullName?.toLowerCase().includes(search.toLowerCase())
            || company.identNumber?.toLowerCase().includes(search.toLowerCase())
            || company.mail?.toLowerCase().includes(search.toLowerCase())
        );
    }

    displayWith(company: Company): string {
        return company ? company.fullName : '';
    }
}
