import { IPhoneNumber } from './phone-number';
import { PersonRolesArray } from './person-role';
import { Salutation } from './salutation';
import { IMail } from './mail';
import { Boat, IBoat } from './boat';
import { IFile } from '@shared/interfaces/file';
import { isArray } from '@shared/functions/is-array';
import { TenantRelationAssignment, TenantRelationAssignmentDto } from '@shared/models/tenant-relation-assignment';
import { parseISO } from '@core/date.facade';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { ProductSubscription, ProductSubscriptionDto } from '@shared/models/product-subscription';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { formatDistanceToNow, getYear, isToday, isTomorrow, setYear, startOfToday, subDays } from '@core/date.facade';
import { KeyAssignment, KeyAssignmentRaw } from '@shared/models/key';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { CompanyContact, ICompanyContact } from '@shared/models/company-contact';
import { BcmDocument, BcmDocumentDto } from '@shared/models/bcm-document';
import { de } from 'date-fns/locale';
import { toDateStringDE } from '@core/functions/to-date-string';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BcmVoucher, BcmVoucherDto } from '@shared/models/bcm-voucher';
import { Country } from '@shared/models/country';
import { BcmBooking, IBcmBooking } from '@shared/models/bcm-booking';

export enum SEPAMandatType {
    FirstDirectDebit = 0,
    RecurringDebit = 1,
}

export enum BcmPaymentType {
    Cash = 'cash',
    CreditCard = 'creditCard',
    EcCard = 'ecCard',
    PaymentProvider = 'paymentProvider',
    Invoice = 'invoice',
    DirectDebit = 'direct-debit',
}

export const BcmPaymentTypeFromString = {
    'cash': BcmPaymentType.Cash,
    'creditCard': BcmPaymentType.CreditCard,
    'ecCard': BcmPaymentType.EcCard,
    'paymentProvider': BcmPaymentType.PaymentProvider,
    'invoice': BcmPaymentType.Invoice,
    'direct-debit': BcmPaymentType.DirectDebit
};

export const paymentTypeTranslationDe = {
    [BcmPaymentType.Cash]: 'Bar',
    [BcmPaymentType.CreditCard]: 'Kreditkarte',
    [BcmPaymentType.EcCard]: 'EC-Karte',
    [BcmPaymentType.PaymentProvider]: 'Zahlungsanbieter',
    [BcmPaymentType.Invoice]: 'Überweisung',
    [BcmPaymentType.DirectDebit]: 'SEPA-Lastschrift',
};

export interface IPerson {
    id: number;
    alias: string;
    identNumber: string;
    // address: IAddress;
    birthDate: string;
    country_old: string;
    country_code: string;
    creditInstitution: any;
    debitAuthorization: any;
    firstName: string;
    fullName: string;
    fullAddress: string;
    tenantId: number;
    image: IFile;
    imageView: string;
    insertByUserId: any;
    insertTimestamp: any;
    lastName: any;
    lastUpdateByUserId: any;
    lastUpdateTimestamp: string;
    note: string;
    postCode: string;
    city: any;
    street: string;
    title: string;
    salutation: Salutation;
    salutationMail: string;
    kinshipDegreePrefix?: string;
    kinshipDegree?: string; // added and used when listing / handling family members
    fullKinshipDegree?: string;
    tenantRelationAssignments: TenantRelationAssignmentDto[];
    keys: KeyAssignmentRaw[];
    phone?: string;
    mail?: string;
    phones: IPhoneNumber[];
    mails: IMail[];
    rolesOrigin: PersonRolesArray;
    roles: PersonRolesArray;
    // familyMembersOrigin: Person[];
    // familyMembers: Person[];
    boatsOrigin: IBoat[];
    boats: IBoat[];
    companyContactsOrigin: ICompanyContact[];
    companyContacts: ICompanyContact[];
    positions: InvoicePositionDto[];
    subscriptions: ProductSubscriptionDto[];
    invoices: IInvoice[]; // todo: add type or interface
    documentsOrigin: BcmDocumentDto[];
    documents: BcmDocumentDto[];
    rolesAsString: string; // Only until I found out, why concat_group does not work in left outer join query
    bcm_files_id: number;

    // hack until I have a class / Interface for InvoiceRecipient
    totalGrossPrice: number;
    totalNetPrice: number;

    qrCodeData: string;

    insertedOn: string | Date;
    lastUpdatedOn: string | Date;

    accountHolder: string;
    IBAN: string;
    BIC: string;
    BLZ: string;
    KtoNr: string;
    Kreditinstitut: boolean;

    SEPAMandat: boolean;
    SEPAMandatId: string;
    SEPAMandatFile: IFile;
    SEPAMandatSignDate: string;
    SEPAMandatType: SEPAMandatType;
    SEPAMandatLastDirectDebitOn: string | Date;

    taxNumber: string;

    invoicePaymentType: BcmPaymentType;

    invoicesByMail: boolean;
    invoicesByPost: boolean;
    job: string;

    entryDate: string | null;
    exitDate: string | null;

    isOwnerSince: string | null;
    isOwnerUntil: string | null;

    sortIndex?: number;

    vouchers: BcmVoucherDto[];

    bookings: IBcmBooking[];

    country: Country;

    uuid: string;
    uuidConnectedSince: string;
}

export class Person {

    private tenantRelationAssignments: TenantRelationAssignment[] = [];

    id: number;
    alias: string;
    identNumber: string;
    // address: IAddress;
    birthDate: Date;
    nextBirthDate: Date;
    nextBirthDateString: string;
    nextBirthDateDistance: string;
    countryOld: string;
    countryCode: string;
    creditInstitution: any;
    debitAuthorization: any;
    firstName: string;
    fullName: string;
    fullNameBackward: string;
    fullAddress: string;
    tenantId: number;
    image: IFile;
    imageView: string;
    insertByUserId: any;
    insertTimestamp: any;
    lastName: any;
    lastUpdateByUserId: any;
    lastUpdateTimestamp: string;
    note: string;
    postCode: string;
    city: any;
    street: string;
    title: string;
    salutation: Salutation;
    salutationMail: string;
    kinshipDegreePrefix?: string;
    kinshipDegree?: string;
    fullKinshipDegree?: string;
    keys: KeyAssignment[];
    phone: string;
    mail: string;
    phones: IPhoneNumber[] = [];
    mails: IMail[] = [];
    rolesOrigin = new PersonRolesArray();
    roles = new PersonRolesArray();
    // familyMembersOrigin: Person[] = [];
    // familyMembers: Person[] = [];
    boatsOrigin: Boat[] = [];
    boats: Boat[] = [];
    companyContactsOrigin: CompanyContact[];
    companyContacts: CompanyContact[];
    positions: InvoicePosition[] = [];
    subscriptions: ProductSubscription[];
    invoices: Invoice[]; // todo: add type or interface

    documentsOrigin: BcmDocument[];
    documents: BcmDocument[];

    rolesAsString: string;
    // tslint:disable-next-line:variable-name
    bcm_files_id: number;

    // hack until I have a class / Interface for InvoiceRecipient
    totalGrossPrice: number;
    totalNetPrice: number;

    qrCodeData: string;

    insertedOn: Date;
    lastUpdatedOn: Date;

    accountHolder: string;
    IBAN: string;
    BIC: string;
    BLZ: string;
    KtoNr: string;
    Kreditinstitut: boolean;

    SEPAMandat: boolean;
    SEPAMandatId: string;
    SEPAMandatFile: IFile;
    SEPAMandatSignDate: Date;
    SEPAMandatType: SEPAMandatType;
    SEPAMandatLastDirectDebitOn: string | Date;

    taxNumber: string;

    invoicesByMail: boolean;
    invoicesByPost: boolean;
    invoicePaymentType: BcmPaymentType;

    job: string;

    entryDate: Date | null;
    exitDate: Date | null;

    isOwnerSince: Date | null;
    isOwnerUntil: Date | null;

    sortIndex?: number;

    imagePlaceholder: string;

    // search and filter helpers
    searchActiveTenantRelations: string;

    bookings: BcmBooking[];
    berthAssignments?: BerthBoatAssignment[];

    vouchers: BcmVoucher[];

    country: Country;

    uuid: string;

    uuidConnectedSince: Date;

    uuidConnectedInfo: string;

    public get allTenantRelations(): TenantRelationAssignment[] {
        return this.tenantRelationAssignments;
    }

    public get activeTenantRelationAssignments(): TenantRelationAssignment[] {
        const today = Date.now();

        return this.tenantRelationAssignments
            .filter(tenantRelationAssignment => (tenantRelationAssignment.toDate == null || +tenantRelationAssignment.toDate >= today)
                && +tenantRelationAssignment.fromDate <= today);
    }

    public get activeTenantRelationAssignmentNames(): string {
        return this.activeTenantRelationAssignments
            .map(tenantRelationAssignment => tenantRelationAssignment.tenantRelation.name)
            .join(', ');
    }

    public get nextTenantRelationAssignments(): TenantRelationAssignment[] {
        const today = Date.now();

        return this.tenantRelationAssignments
            .filter(tenantRelationAssignment => +tenantRelationAssignment.fromDate > today);
    }

    public get activeAndNextTenantRelationAssignments(): TenantRelationAssignment[] {
        return [
            ...this.activeTenantRelationAssignments,
            ...this.nextTenantRelationAssignments
        ];
    }

    public get activeAndNextTenantRelationAssignmentNames(): string {
        return [
            ...this.activeTenantRelationAssignments,
            ...this.nextTenantRelationAssignments
        ]
            .map(tenantRelationAssignment => tenantRelationAssignment.tenantRelation.name)
            .join(', ');
    }

    // public get previousTenantRelations(): TenantRelationAssignment[] {
    //     const today = Date.now();
    //
    //     return this.tenantRelations
    //         .filter(tr => tr.toDate != null && +tr.toDate < today && +tr.fromDate < today);
    // }

    public get firstActiveTenantRelationAssignment(): TenantRelationAssignment {
        return this.activeTenantRelationAssignments[0];
    }

    constructor(person = {} as IPerson, skipBoats = false) {
        this.id = person.id || null;
        this.identNumber = person.identNumber || null;
        this.firstName = person.firstName || '';
        this.lastName = person.lastName || '';
        this.alias = person.alias || '';
        this.fullName = [
            person.title,
            person.firstName,
            person.lastName
        ].filter(_ => !!_).join(' ');
        this.fullNameBackward = [person.lastName, person.firstName].filter(_ => !!_).join(', ');
        this.street = person.street || '';
        this.postCode = person.postCode || '';
        this.city = person.city || '';
        this.country = person.country || undefined;
        this.countryOld = person.country_old || '';
        this.countryCode = person.country_code || '';
        this.salutation = person.salutation != null ? person.salutation : null;
        this.salutationMail = person.salutationMail || null;
        this.title = person.title || '';
        this.mails = person.mails || [];
        this.phones = person.phones || [];
        this.image = (person.image || {}) as IFile;
        this.birthDate = tryParseDate(person.birthDate);
        this.nextBirthDate = this.birthDate ? setYear(this.birthDate, getYear(startOfToday())) : null;
        this.nextBirthDateString = toDateStringDE(this.nextBirthDate);
        this.nextBirthDateDistance = this.nextBirthDate ? this.formatDistanceDay(this.nextBirthDate) : null;
        this.tenantRelationAssignments = (person.tenantRelationAssignments || []).map(tr => new TenantRelationAssignment(tr));
        this.note = person.note;
        this.kinshipDegreePrefix = person.kinshipDegreePrefix;
        this.kinshipDegree = person.kinshipDegree;
        this.positions = (person.positions || []).map(pos => new InvoicePosition(pos));
        this.subscriptions = (person.subscriptions || []).map(subscription => new ProductSubscription(subscription));
        this.invoices = (person.invoices || []).map(invoice => new Invoice(invoice));
        this.keys = (person.keys || []).map(keyAssignment => new KeyAssignment(keyAssignment));

        this.fullAddress = this.getAddressString();
        this.fullKinshipDegree = this.getKinshipDegreeString();
        this.rolesAsString = person.rolesAsString || '-';

        this._setPrimaryMail(person.mails);
        this._setPrimaryPhone(person.phones);

        this.roles = new PersonRolesArray(...(person.roles || []));
        this.rolesOrigin = this.roles.slice();

        if (!skipBoats) {
            this.boats = (person.boats || []).map(boat => new Boat(boat));
            this.boatsOrigin = this.boats.slice();
        }

        this.companyContacts = (person.companyContacts || []).map(companyContact => new CompanyContact(companyContact));
        this.companyContactsOrigin = this.companyContacts.slice();

        // this.familyMembers = (person.familyMembers || []).map(familyMember => new Person(familyMember));
        // this.familyMembersOrigin = this.familyMembers.slice();
        this.bcm_files_id = person.bcm_files_id;
        this.totalGrossPrice = person.totalGrossPrice;
        this.totalNetPrice = person.totalNetPrice;
        this.qrCodeData = JSON.stringify({
            category: 'bcm_person',
            personId: person.id
        });

        this.insertedOn = parseISO(person.insertedOn as string);
        this.lastUpdatedOn = parseISO(person.lastUpdatedOn as string);

        this.accountHolder = person.accountHolder;
        this.IBAN = person.IBAN;
        this.BIC = person.BIC;
        this.BLZ = person.BLZ;
        this.KtoNr = person.KtoNr;
        this.Kreditinstitut = person.Kreditinstitut;

        this.SEPAMandat = person.SEPAMandat;
        this.SEPAMandatId = person.SEPAMandatId;
        this.SEPAMandatFile = person.SEPAMandatFile;
        this.SEPAMandatSignDate = tryParseDate(person.SEPAMandatSignDate);
        this.SEPAMandatType = person.SEPAMandatType;
        this.SEPAMandatLastDirectDebitOn = person.SEPAMandatLastDirectDebitOn;

        this.taxNumber = person.taxNumber;

        this.invoicesByMail = person.invoicesByMail;
        this.invoicesByPost = person.invoicesByPost;
        this.invoicePaymentType = person.invoicePaymentType;

        this.documents = person.documents?.map(document => new BcmDocument(document)) || [];
        this.job = person.job;

        this.entryDate = tryParseDate(person.entryDate);
        this.exitDate = tryParseDate(person.exitDate);

        this.isOwnerSince = tryParseDate(person.isOwnerSince);
        this.isOwnerUntil = tryParseDate(person.isOwnerUntil);

        this.sortIndex = person?.sortIndex;

        this.imagePlaceholder = person?.salutation === Salutation.Male
            ? 'assets/images/placeholder/male.jpg'
            : (
                person?.salutation === Salutation.Female
                    ? 'assets/images/placeholder/female.jpg'
                    : 'assets/images/placeholder/male-female.jpg'
            );

        // search and filter helpers
        this.searchActiveTenantRelations = this.activeTenantRelationAssignmentNames;

        this.bookings = (person?.bookings || []).map(b => new BcmBooking(b));
        this.berthAssignments = this.bookings.reduce((acc, booking) => {
            return [...acc, ...(booking.berthAssignments || [])];
        }, []);

        this.vouchers = (person?.vouchers || []).map(v => new BcmVoucher(v));

        this.uuid = person.uuid;
        this.uuidConnectedSince = tryParseDate(person.uuidConnectedSince);

        if (this.uuid) {
            this.uuidConnectedInfo = [
                'Über die Up2Boat-App verbunden',
                (this.uuidConnectedSince ? ` seit ${toDateStringDE(this.uuidConnectedSince)}` : ''),
                '.'
            ].join('');
        }

    }

    static filterPersons(persons: Person[], filter: string) {
        return persons.filter(person =>
            [
                person.fullNameBackward,
                person.fullName,
                person.identNumber,
                person.mail,
                person.phone,
            ].some(field =>
                (field || '').toLowerCase().includes((filter || '').toLowerCase())
            )
        );
    }

    get streetWithoutNumber(): string {
        try {
            const streetParts = this.street.split(' ');
            return streetParts.splice(streetParts.length - 1, 1).join(' ');
        } catch (e) {
            return this.street;
        }
    }

    get streetNumber(): string {
        try {
            const streetParts = this.street.split(' ');
            return streetParts[streetParts.length - 1];
        } catch (e) {
            return '';
        }
    }

    // show as fullName?
    toString(backwards = true): string {
        return backwards
            ? [this.lastName, this.firstName].filter(_ => _ != null).join(', ').trim()
            : `${this.firstName} ${this.lastName}`.trim();
    }

    toDropdownString(backwards = true): string {
        return this.toString() + (this.identNumber != null ? ` (${this.identNumber})` : '');
    }

    valueOf(): number {
        return this.id;
    }

    addTenantRelationAssignment(tenantRelationAssignment: TenantRelationAssignment): void {
        this.tenantRelationAssignments.unshift(tenantRelationAssignment);
        this.tenantRelationAssignments = this.tenantRelationAssignments.slice();
    }

    removeTenantRelationAssignment(tenantRelationAssignment: TenantRelationAssignment): void {
        this.tenantRelationAssignments = this.tenantRelationAssignments.filter((t) => t.id !== tenantRelationAssignment.id);
        this.tenantRelationAssignments = this.tenantRelationAssignments.slice();
    }

    public getAddressString(separator = ', ', includeName = false, includeCountry = false): string {
        const address = [];
        let helper = '';

        if (includeName) {

            if (this.fullName) {
                helper = this.fullName;
            } else {
                if (this.firstName) {
                    helper = this.firstName;
                }

                if (this.lastName) {
                    helper = `${helper || ''} ${this.lastName || ''}`.trim();
                }
            }

            address.push(helper);
            helper = '';
        }

        if (this.street) {
            address.push(this.street);
        }

        if (separator === '\n') {
            address[address.length - 1] += separator;
        }

        if (this.postCode) {
            helper = this.postCode;
        }

        if (this.city) {
            helper += ' ' + this.city;
        }

        if (helper) {
            address.push(helper);
        }

        if (includeCountry && this.country?.de) {
            address.push(this.country?.de);
        }

        return address.join(separator) || '';
    }

    private getKinshipDegreeString(separator = ''): string {
        const kinshipDegree = [];

        if (this.kinshipDegreePrefix) {
            kinshipDegree.push(this.kinshipDegreePrefix.replace('-', ''));
            kinshipDegree.push(this.kinshipDegree.toLowerCase());
        } else {
            kinshipDegree.push(this.kinshipDegree);
        }

        return kinshipDegree.join(separator);
    }

    private _setPrimaryPhone(phones: IPhoneNumber[] = []): void {
        phones = isArray(phones) ? phones : [];
        if (!!phones && phones.length) {
            this.phone = this.phone = (phones.find((phone) => phone.isPrimary) || phones[0]).number;
            return;
        }

        this.phone = this.phone = '';
    }

    private _setPrimaryMail(mails: IMail[] = []): void {
        mails = isArray(mails) ? mails : [];
        if (!!mails && mails.length) {
            this.mail = this.mail = (mails.find((mail) => mail.isPrimary) || mails[0]).mail;
            return;
        }

        this.mail = this.mail = '';
    }

    private formatDistanceDay(date: Date): string {
        if (isToday(date)) {
            return 'heute';
        } else if (isTomorrow(date)) {
            return 'morgen';
        } else if (isTomorrow(subDays(date, 1))) {
            return 'übermorgen';
        }

        return `in ${formatDistanceToNow(date, {locale: de})}n`;
    }
}
