<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Beziehung(en) erfassen</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <ng-container *ngIf="loading; else content">
            <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
        </ng-container>

        <ng-template #content>
            <div>
                <div [formGroup]="tenantRelationDatesForm">
                    <form-widget-tenant-relation [parentFormGroup]="tenantRelationDatesForm"
                                                 [prefilledTenantRelationId]="tenantRelationAssignment?.tenantRelation?.id"
                                                 [disableConditionCallback]="disableConditionCallback"
                                                 [slimmedView]="true"
                                                 [multipleSelectable]="multipleSelectable"></form-widget-tenant-relation>

                    <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
                        <h2>Laufzeit der Beziehung</h2>
                        <div>"Ende" leer lassen, wenn Ende der Beziehung offen.</div>
                    </div>

                    <app-input-date-time formControlName="fromDate">
                        <label>Start</label>
                    </app-input-date-time>

                    <app-input-date-time formControlName="toDate">
                        <label>Ende</label>
                    </app-input-date-time>
                </div>

                <ng-container *ngIf="products?.length">

                    <mat-divider class="my-24"></mat-divider>

                    <u2b-message type="info">
                        <header>Produkte gefunden</header>
                        <p>
                            Es wurden Produkte gefunden, die den ausgewählten Beziehungen angehängt wurden. Bitte gib
                            an, ob du eine regelmäßige Zahlung anlegen möchtest.
                        </p>
                        <ng-container
                                *ngFor="let tenantRelation of selectedTenantRelations">
                            <div>
                                <strong>
                                    {{ tenantRelation.name }}
                                </strong>
                            </div>
                            <ng-container
                                    *ngFor="let product of tenantRelation.products">
                                <div>
                                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                        <div class="pr-16">{{ product.name }}</div>
                                        <div>{{ product.price | bcmDynamicCurrency: '1.2-2' }}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </u2b-message>

                    <mat-divider class="my-24"></mat-divider>

                    <div [formGroup]="subscriptionForm">

                        <ng-container *hasTenantPermission="BcmTenantPermission.COST_CENTERS">

                            <mat-divider class="my-24"></mat-divider>

                            <h2>Kostenstellen</h2>

                            <ng-container *ngFor="let product of products; let index = index">
                                <div>
                                    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                                        <div class="pr-16">{{ product.name }}</div>

                                        <app-input-cost-centers
                                                [control]="productsCostCenterFormControls[index] | formControl"></app-input-cost-centers>
                                    </div>
                                </div>
                            </ng-container>

                            <mat-divider class="my-24"></mat-divider>
                        </ng-container>

                        <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
                            <br>
                            <strong>
                                Regelmäßige Zahlung
                                <ng-container *ngIf="payableOption?.name">
                                <span style="text-transform: lowercase;">
                                    - {{ payableOption?.name }}
                                </span>
                                </ng-container>
                            </strong>
                        </div>

                        <h3>Wann soll die <strong>erste</strong> Position erstellt werden?</h3>
                        <app-input-date-time formControlName="periodFromDate">
                            <label>Erste Position am</label>
                        </app-input-date-time>

                        <h3>
                            Wann soll die <strong>letzte</strong> Position erzeugt werden?<br>
                            <small>Leer lassen, wenn Laufzeitende offen.</small>
                        </h3>
                        <app-input-date-time formControlName="periodToDate">
                            <label>Letzte Position am</label>
                        </app-input-date-time>

                        <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
                            <h2>Erster Leistungszeitraum</h2>
                            <p>
                                Definiere den 1. Leistungszeitraum, für den eine Position erstellt werden soll.
                                Nachfolgende Positionen werden
                                <ng-container *ngIf="payableOption?.name; else other">
                                    <strong style="text-transform: lowercase;">{{ payableOption?.name }}</strong>
                                </ng-container>
                                <ng-template #other>im ausgewählten Zahlungsintervall</ng-template>
                                erneut erzeugt.
                            </p>
                        </div>

                        <ng-container
                                *ngIf="payableOption?.id === PayableOptionId.Yearly && seasons?.length">
                            <div fxLayout="row" fxLayoutAlign="start start">
                                <mat-form-field appearance="outline" floatLabel="always" fxFlex>
                                    <mat-label>Wähle aus periodischen Saisons</mat-label>
                                    <mat-select data-test="FS-Saisons"
                                                [formControl]="seasonFormControl"
                                                [compareWith]="compareSeasons">
                                        <mat-option [value]="null">- Keine Saison -</mat-option>
                                        <mat-option *ngFor="let season of seasons" [value]="season">
                                            {{ season.name }} / {{ season.startDate | dfnsFormat : 'dd.MM.yyyy' }} -
                                            {{ season.endDate | dfnsFormat : 'dd.MM.yyyy' }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <app-input-date-time formControlName="vestingPeriodFrom">
                            <label>Leistungszeitraum Start</label>
                        </app-input-date-time>

                        <app-input-date-time formControlName="vestingPeriodUntil">
                            <label>Leistungszeitraum Ende</label>
                        </app-input-date-time>

                        <ng-container
                                *ngIf="[PayableOptionId.Monthly, PayableOptionId.HalfYearly, PayableOptionId.QuarterYearly].includes(payableOption?.id)">
                            <div fxLayout="row" fxLayoutAlign="start start">
                                <mat-checkbox formControlName="lastDayOfMonth">
                                    Leistungszeitraum Ende automatisch auf den letzten Tag des Monats setzen
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                class="save-button mat-primary"
                aria-label="Abbrechen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>

        <button data-test="B-Speichern" mat-flat-button
                (click)="save()"
                class="save-button mat-accent"
                aria-label="Speichern">
            <mat-icon>save</mat-icon>
            Speichern
        </button>

    </div>
</div>
