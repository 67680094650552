import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPerson, Person } from '@shared/models/person';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmBaseFacade } from '../bcm-base.facade';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BcmExportExcelApiService } from '@bcmApiServices/bcm-export-excel.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { personsTableData } from '@modules/bcm/@core/state-management/persons/persons-table-data';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { ThemePalette } from '@angular/material/core';
import { ImageCropperDialogService } from '@sharedComponents/dialogs/image-cropper-dialog/image-cropper-dialog.service';
import { EMPTY, forkJoin, of, switchMap } from 'rxjs';
import { FilesApiService, PersonApiService } from '@modules/bcm/@shared/services';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import {
    GetTenantRelationDataDialogComponent
} from '@sharedComponents/dialogs/get-tenant-relation-data/get-tenant-relation-data-dialog.component';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { tap } from 'rxjs/operators';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmInvoicePositionsService } from '@bcmServices/invoice-positions.service';
import {
    GetCountryDialogComponent
} from '@modules/bcm/@shared/components/dialogs/get-country-dialog/get-country-dialog.component';
import { Country } from '@shared/models/country';
import { BcmBaseBulkChanges } from '@modules/bcm/@core/state-management/bcm-base-bulk-changes';

@Injectable({providedIn: 'root'})
export class PersonsFacade extends BcmBaseFacade<Person, IPerson> {

    readonly resourcePath = 'persons';

    readonly resourceNameSingular = 'Person';

    readonly resourceNamePlural = 'Personen';

    readonly resourceIconPlural = 'person';

    readonly resourceIconSingular = 'person';

    readonly deletePermission = BcmUserPermission.PERSONS_DELETE;

    readonly readPermission = BcmUserPermission.PERSONS_READ;

    readonly writePermission = BcmUserPermission.PERSONS_WRITE;

    readonly headerActions = [
        {
            title: 'Excel Export',
            onClick: () => this._bcmExportExcelApiService.exportPersons(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        },
        {
            title: 'Excel Export - Beziehungen',
            onClick: () => this._bcmExportExcelApiService.exportPersonTenantRelations(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        }
    ];

    readonly rowActions = [
        {
            title: 'Datensatz anzeigen',
            icon: 'assignment',
            iconColor: 'accent' as ThemePalette,
            onClick: (person: Person) => this._bcmNavigationService.navigate('persons/' + person.id),
        },
        {
            title: 'Datensatz löschen',
            icon: 'delete',
            iconColor: 'warn' as ThemePalette,
            onClick: (person: Person) => super.remove(person, person.fullName).subscribe(),
        }
    ];

    readonly bulkChanges = new BcmBaseBulkChanges<Person>()
        .setButtons(
            {
                title: 'Beziehung',
                icon: 'add',
                iconColor: 'accent' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (persons: Person[]) => this.addTenantRelation(...persons),
            },
            {
                title: 'Position',
                icon: 'add',
                iconColor: 'accent' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (persons: Person[]) => this.addInvoicePosition(...persons),
            },
            {
                title: 'Land',
                icon: 'edit',
                iconColor: 'accent' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (persons: Person[]) => this.updateCountry(...persons),
            },
            {
                title: 'Löschen',
                icon: 'delete',
                iconColor: 'warn' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (persons: Person[]) => super.removeMultiple(persons),
            },
        );

    constructor(appNotificationService: AppNotificationService,
                confirmDialogService: ConfirmDialogService,
                bcmUserSettingsFacade: BcmUserSettingsFacade,
                httpClient: HttpClient,
                bcmService: BcmService,
                filesApiService: FilesApiService,
                bcmNavigationService: BcmNavigationService,
                private _bcmExportExcelApiService: BcmExportExcelApiService,
                private _bcmNavigationService: BcmNavigationService,
                private _imageCropperDialogService: ImageCropperDialogService,
                private _personApiService: PersonApiService,
                private _bcmInvoicePositionsService: BcmInvoicePositionsService,
                private _matDialog: MatLegacyDialog,
    ) {
        super(
            Person,
            personsTableData,
            bcmNavigationService,
            appNotificationService,
            confirmDialogService,
            bcmUserSettingsFacade,
            httpClient,
            bcmService,
            filesApiService
        );
    }

    public changeImageFrom(person: Person) {
        this._imageCropperDialogService
            .getCroppedImage(person.image)
            .pipe(switchMap(newImage => {
                if (newImage) {
                    return this.addOrUpdateFile(newImage);
                } else {
                    return EMPTY;
                }
            }))
            .subscribe(fileFromBackEnd => {
                if (fileFromBackEnd) {
                    person.image = fileFromBackEnd;
                    this.updateStateFor(person, true);
                }
            });
    }

    public deleteImageFrom(person: Person) {
        super.deleteFile(person.image)
            .subscribe(deleted => {
                if (deleted) {
                    person.image = undefined;
                    this.updateStateFor(person, true);
                }
            });
    }

    addTenantRelation(...persons: Person[]) {
        const dialogRef = this._matDialog.open(GetTenantRelationDataDialogComponent);

        return dialogRef
            .afterClosed()
            .pipe(
                switchMap((tenantRelationAssignment: TenantRelationAssignment) => {

                    if (!tenantRelationAssignment) {
                        return of(undefined);
                    }

                    return this._personApiService
                        .multipleNewTenantRelationAssignment(tenantRelationAssignment, ...persons)
                        .pipe(tap((tenantRelationAssignments) => {
                            persons.forEach(person => person.addTenantRelationAssignment(
                                tenantRelationAssignments.find(assignment => assignment.person?.id === person.id)
                            ));
                            this.appNotificationService.showSuccess(`Beziehungen erfolgreich angelegt`);
                        }));
                })
            );
    }

    addInvoicePosition(...persons: Person[]) {
        return this._bcmInvoicePositionsService
            .addPositionsForMultiplePersons(persons)
            .pipe(tap((positions: InvoicePosition[]) => {
                if (positions.length > 0) {
                    this.appNotificationService.showSuccess('Neue Position(en) hinzugefügt.');
                }
            }));
    }

    updateCountry(...persons: Person[]) {
        const dialogRef = this._matDialog.open(GetCountryDialogComponent);

        return dialogRef
            .afterClosed()
            .pipe(
                switchMap((country: Country) => {
                    if (country) {
                        persons.forEach(person => person.country = country);
                        return forkJoin(
                            persons.map(person => super.update(person, {country} as Partial<Person>))
                        );
                    }
                    return EMPTY;
                })
            );
    }
}
