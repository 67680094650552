import { Component, Input, ViewChild } from '@angular/core';
import { AbstractControlOptions, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { BcmSettingsSectionName, DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';
import { MatAccordion } from '@angular/material/expansion';
import { v4 as uuidv4 } from 'uuid';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BaseArrayTabComponent } from '@sharedComponents/dialogs/booking-dialog/tabs/abstract/base-array-tab.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { U2bValidators } from '@shared/validators/validators';
import { addDays } from 'date-fns';
import { removeErrorFromControls } from '@core/functions/remove-error-from-controls';

@UntilDestroy()
@Component({
    selector: 'booking-berth-assignments-tab',
    templateUrl: './berth-assignments-tab.component.html',
    styleUrls: ['./berth-assignments-tab.component.scss'],
})
export class BerthAssignmentsTabComponent extends BaseArrayTabComponent<IBerthBoatAssignment, BerthBoatAssignment> {

    @ViewChild('accordion') accordion: MatAccordion;

    @Input() scrollOnCreate = false;

    defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit;

    constructor(
        private _bcmSettingsFacade: BcmSettingsFacade,
        bookingDialogService: BookingDialogService,
        formBuilder: FormBuilder,
    ) {
        super(
            bookingDialogService,
            formBuilder,
            BookingAttribute.BERTH_ASSIGNMENTS,
            BerthBoatAssignment
        );

        this.defaultBerthReservationTimeUnit = this._bcmSettingsFacade.settings()[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];
    }

    formTemplate(value: BerthBoatAssignment): {
        controls: { [key: string]: any },
        options?: AbstractControlOptions | null
    } {
        return {
            controls: {
                id: [value?.id || null],
                from: [value?.from || new Date(), U2bValidators.required('Das Anreisedatum ist erforderlich')],
                to: [value?.to || null],
                berth: [value?.berth || null, U2bValidators.required('Der Liegeplatz ist erforderlich')],
                note: [value?.note || null],
                durationOfStay: [[]],
                electricMeterAssignments: [value?.electricMeterAssignments || []],
                uuid: [value?.uuid || uuidv4()],
                deleted: [value?.deleted || false]
            },
            options: {
                validators: [
                    (formGroup: UntypedFormGroup) => {

                        const fromDate = formGroup.get('from').value;
                        const toDate = formGroup.get('to').value;

                        if (!fromDate || !toDate) {
                            return null;
                        }

                        if (fromDate > toDate) {
                            formGroup.get('from').setErrors({
                                wrongDateRange: {
                                    message: 'Das "von" Datum darf nicht hinter dem "bis" Datum liegen'
                                }
                            });
                            formGroup.get('to').setErrors({
                                wrongDateRange: {
                                    message: 'Das "bis" Datum darf nicht vor dem "von" Datum liegen'
                                }
                            });
                        } else {
                            removeErrorFromControls('wrongDateRange', [
                                formGroup.get('from'),
                                formGroup.get('to'),
                            ]);
                        }
                    }
                ]
            }
        };
    }

    addBerthAssignment(): void {

        const currentTo = this.bookingDialogService.booking.to;
        const currentToTimeInMilliseconds = currentTo?.getTime();

        const from = currentTo || new Date();
        from.setHours(this.defaultBerthReservationTimeUnit.overnightGuestFromHours, this.defaultBerthReservationTimeUnit.overnightGuestFromMinutes);

        if (from.getTime() < currentToTimeInMilliseconds) {
            from.setTime(currentToTimeInMilliseconds);
        }

        const to = addDays(from, 1);
        to.setHours(this.defaultBerthReservationTimeUnit.overnightGuestToHours, this.defaultBerthReservationTimeUnit.overnightGuestToMinutes);

        const newAssignment = new BerthBoatAssignment({
            id: null,
            from,
            to,
            berth: null,
            boat: null,
            note: null,
            electricMeterAssignments: [],
            uuid: uuidv4(),
            deleted: false
        });

        this.addFormGroup(newAssignment);
    }

}
