import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { IPerson, Person } from '@shared/models/person';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { BcmBookingTraveler } from '@shared/models/bcm-booking';

export class BookingDialogPerson extends BookingDialogBaseEntity<Person, Person> {

    constructor(bookingDialogService: BookingDialogService) {
        super(bookingDialogService, BookingAttribute.PERSON);
    }

    protected afterValueSet(): void {

        if (this.value?.id) {

            this.bookingDialogService.general.value.isBoatOwner = this.value?.id === this.bookingDialogService.boat.value?.owner?.id;
            this.bookingDialogService.company.value = null;

            if (!this.bookingDialogService.booking?.id) {

                this.bookingDialogService.travelers.add(new BcmBookingTraveler({
                    person: this.value as unknown as IPerson,
                    firstName: this.value.firstName,
                    lastName: this.value.lastName,
                    email: this.value.mail,
                    phone: this.value.phone,
                    birthDate: this.value.birthDate,
                }));

                this.bookingDialogService.travelers.removeDuplicateTravelers();
                this.bookingDialogService.travelers.sendUpdate();

            }
        }
    }

}
