import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Berth } from '@shared/models/berth';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';

@Component({
    selector: 'bcm-berth-link',
    templateUrl: './berth-link.component.html'
})
export class BerthLinkComponent implements OnChanges {

    @Input()
    public berthAssignments?: BerthBoatAssignment[];

    @Input()
    public berth!: Berth;

    @Input()
    public dataTest: string;

    @Input()
    public additionalInfo: string;

    @Input()
    public disableLink: boolean;

    public viewData: string;

    @Output()
    public clicked = new EventEmitter<void>();

    @Input()
    public berthHandleOnly = false;

    @Input()
    public pierHandleOnly = false;

    future: boolean;

    ngOnChanges(): void {

        if (!(this.berth instanceof Berth)) {
            this.berth = null;
        }

        if (this.berthAssignments?.length > 0) {

            const activeAssignment = this.getActiveAssignment();
            const futureAssignments = this.getFutureAssignments();

            if (this.getActiveAssignment()) {
                this.berth = activeAssignment.berth;
                this.future = false;
            } else if ((futureAssignments || [])?.length > 0) {
                this.berth = futureAssignments[0].berth;
                this.future = true;
            }
        }

        const berth = this.berth;

        if (this.berthHandleOnly) {
            this.viewData = berth.handle || '-';
        } else if (this.pierHandleOnly) {
            this.viewData = berth.pier?.handle || '-';
        } else {

            this.viewData = [
                (berth?.handle || null),
                (berth?.pier?.handle || null),
                this.additionalInfo,
            ].filter(item => item != null)
                .join(' - ');
        }
    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }

    // TODO shared method
    private getActiveAssignment(): BerthBoatAssignment {
        const today = new Date();
        return this.berthAssignments
            .find(
                assignment => assignment.from <= today && (
                    assignment.to === null || assignment.to >= today
                )
            );
    }

    // TODO shared method
    private getFutureAssignments(): BerthBoatAssignment[] {
        return this.berthAssignments
            .filter(assignment => assignment.from > new Date())
            .sort((a, b) => a.from.getTime() - b.from.getTime());
    }

}
