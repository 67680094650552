<ng-container *ngIf="berth?.id">
    <ng-container *ngIf="disableLink; else withLink">
        <span [attr.data-test]="dataTest"
              [style.color]="future ? '#2b2c7c' : '#ff9d00'">
            <ng-container *ngIf="viewData; else noIdent">
                      <ng-container *ngIf="future">&#9733;</ng-container>
                {{ viewData }}
            </ng-container>
            <ng-template #noIdent>
                <abbr matTooltip="Liegeplatz kann nicht identifiziert werden!">
                    <em>Keine Bezeichnung</em>
                </abbr>
            </ng-template>
        </span>
    </ng-container>
    <ng-template #withLink>
        <a (click)="click($event)"
           [style.color]="future ? '#2b2c7c' : '#ff9d00'"
           [attr.data-test]="dataTest"
           [bcmRouterLink]="['berths', berth.id]">
            <ng-container *ngIf="viewData; else noIdent">
                <ng-container *ngIf="future">&#9733;</ng-container>
                {{ viewData }}
            </ng-container>
            <ng-template #noIdent>
                <abbr matTooltip="Liegeplatz kann nicht identifiziert werden!">
                    <em>Keine Bezeichnung</em>
                </abbr>
            </ng-template>
        </a>
    </ng-template>
</ng-container>
