import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance } from '@angular/material/legacy-form-field';
import { compareObjectsById } from '@shared/functions/compare-objects-by-id';
import { Boat } from '@shared/models/boat';
import { BoatsFacade } from '@modules/bcm/@core/state-management/boats/boats.facade';

@Component({
    selector: 'app-input-select-boat',
    templateUrl: './input-select-boat.component.html',
    styleUrls: ['./input-select-boat.component.scss']
})
export class InputSelectBoatComponent implements OnInit, OnDestroy {

    @Input() appearance: MatLegacyFormFieldAppearance = 'outline';

    @Input() multiSelect = false;

    @Input() set control(formControl: AbstractControl) {
        this._formControl = formControl as UntypedFormControl;
        this._addFormControlListeners(this._formControl);
    }

    get control(): UntypedFormControl {
        return this._formControl;
    }

    private _formControl: UntypedFormControl;

    private _unsubscribeAll = new Subject();

    private _boats: Boat[] = [];

    filteredBoats$: Observable<Boat[]>;

    compareObjectsById = compareObjectsById;

    constructor(private boatsFacade: BoatsFacade) {
    }

    ngOnInit(): void {
        this._loadBoats();

        if (this.multiSelect) {
            this._formControl.setValue(this._formControl.value);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _addFormControlListeners(formControl: UntypedFormControl): void {
        if (!formControl) {
            return;
        }
        this.filteredBoats$ = combineLatest([
            formControl.valueChanges
                .pipe(
                    startWith((this.multiSelect) ? [] : ''),
                    debounceTime(DEFAULT_DEBOUNCE_TIME)
                ),
            this.boatsFacade.list$.pipe(map(boats => this._boats = boats))
        ])
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((value) => isString(value[0]) && !this.multiSelect ? this._filter(value[0]) : this._boats)
            );

    }

    private _loadBoats(): void {
        this._boats = [];
        this.boatsFacade.loadList().subscribe();
    }

    private _filter(search = ''): Boat[] {
        return this._boats.filter(boat =>
            boat.fullName.toLowerCase().includes(search.toLowerCase())
            || boat.manufacturer.toLowerCase().includes(search.toLowerCase())
            || boat.type.toLowerCase().includes(search.toLowerCase())
        );
    }

    displayWith(boat: Boat): string {
        return boat ? boat.fullName : '';
    }
}
