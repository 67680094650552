import { ChangeDetectorRef, Component, Inject, OnDestroy, Optional, ViewChild } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { U2bValidators } from '@shared/validators/validators';
import { ProductsApiService } from '@modules/bcm/@shared/services';
import { AppNotificationService } from '@core/services/app-notification.service';
import { PayableOption } from '@shared/models/payable-option';
import { PayableOptionId, ProductPayableOptionApiService } from '@bcmApiServices/product-payable-option.api-service';
import { removeErrorFromControls } from '@core/functions/remove-error-from-controls';
import { hours, minutes } from '@shared/constants/date';
import { Season } from '@shared/models/seasons';
import { SeasonApiService } from '@bcmApiServices/seasons.api-service';
import {
    addDays,
    addHours,
    addMonths,
    addWeeks,
    addYears,
    endOfMonth,
    isFirstDayOfMonth,
    startOfToday,
    startOfYear,
    subMinutes,
} from '@core/date.facade';
import { isValidDate } from '@core/functions/is-valid-date';
import { U2bDateValidators } from '@shared/validators/date/date-validators';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { BcmService } from '@modules/bcm/bcm.service';
import { ProductSubscription } from '@shared/models/product-subscription';

@Component({
    selector: 'get-product-subscription-dates-dialog',
    templateUrl: './get-product-subscription-dates-dialog.component.html',
    styleUrls: ['./get-product-subscription-dates-dialog.component.scss']
})
export class GetProductSubscriptionDatesDialogComponent implements OnDestroy {
    private _unsubAll = new Subject();
    formGroup: UntypedFormGroup;
    payableOptions: PayableOption[];
    PayableOptionId = PayableOptionId;
    seasons: Season[];
    seasonFormControl = new FormControl<Season>(null);
    hours = hours;
    minutes = minutes;
    isSaving: boolean;
    categories: string[];

    @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger})
    matAutocompleteTrigger: MatAutocompleteTrigger;

    constructor(
        public dialogRef: MatDialogRef<GetProductSubscriptionDatesDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            initialFromDate: Date,
            initialPayableOption: PayableOption,
            subscription?: ProductSubscription
        },
        private _formBuilder: UntypedFormBuilder,
        private _productApiService: ProductsApiService,
        private _productPayableOptionApiService: ProductPayableOptionApiService,
        private _seasonApiService: SeasonApiService,
        private _appNotificationService: AppNotificationService,
        private _bcmService: BcmService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
        this.dialogRef.addPanelClass('default-dialog');
        this.formGroup = this._createForm(
            data.initialFromDate,
            data.initialPayableOption,
            data.subscription
        );

        this._loadPayableOptions();
        this._loadSeasons();

        this.seasonFormControl.valueChanges
            .pipe(takeUntil(this._unsubAll))
            .subscribe(season => {
                this.formGroup.patchValue({
                    vestingPeriodFrom: season?.startDate || null,
                    vestingPeriodUntil: season?.endDate || null
                });
            });

    }

    ngOnDestroy(): void {
        this._unsubAll.next(undefined);
        this._unsubAll.complete();
    }

    saveAndContinue(): void {

        let invalid = false;

        for (const key in this.formGroup.controls) {
            if (key !== 'dynamicPriceFormGroup') {
                if (this.formGroup.controls[key].invalid) {
                    this.formGroup.controls[key].markAsTouched();
                    invalid = true;
                }
            }
        }

        if (invalid) {
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        const productSubscription = this.formGroup.getRawValue();

        this.isSaving = true;
        this.dialogRef.close(productSubscription);
    }

    comparePayableOptions(payableOption1: PayableOption, payableOption2: PayableOption): boolean {
        return payableOption1?.id === payableOption2?.id;
    }

    compareSeasons(season1: Season, season2: Season): boolean {
        return season1?.id === season2?.id;
    }

    private _createForm(initialFromDate: Date, initialPayableOption: PayableOption, subscription: ProductSubscription): UntypedFormGroup {
        const form = this._formBuilder.group({
            payableOption: [subscription?.payableOption || initialPayableOption || null, [U2bValidators.required('Bitte Zahlungsintervall auswählen')]],
            fromDate: [
                subscription?.fromDate ||
                initialFromDate || null,
                [U2bValidators.required('Bitte angeben, zu welchem Datum das Abo starten soll.')]
            ],
            toDate: [
                subscription?.toDate || null],
            vestingPeriodFrom: [
                subscription?.vestingPeriodFrom ||
                null,
                [U2bValidators.required('Bitte gib den Leistungszeitraum an.')]
            ],
            vestingPeriodUntil: [
                subscription?.vestingPeriodUntil ||
                {value: null, disabled: false},
                [U2bValidators.required('Bitte gib den Leistungszeitraum an.')]
            ],
            lastDayOfMonth: [
                subscription?.lastDayOfMonth || false],
        });

        const payableOptionField = form.get('payableOption');
        const vestingPeriodFromField = form.get('vestingPeriodFrom');
        const vestingPeriodUntilField = form.get('vestingPeriodUntil');
        const lastDayOfMonthField = form.get('lastDayOfMonth');

        form.valueChanges
            .pipe(takeUntil(this._unsubAll))
            .subscribe(value => {
                if (!value) {
                    return;
                }

                const {
                    fromDate,
                    toDate,
                    vestingPeriodFrom,
                    vestingPeriodUntil,
                } = value;

                if (fromDate && toDate) {
                    if (fromDate > toDate) {
                        form.get('fromDate').setErrors({
                            wrongDateRange: {
                                message: 'Das "von" Datum darf nicht hinter dem "bis" Datum liegen'
                            }
                        });
                        form.get('toDate').setErrors({
                            wrongDateRange: {
                                message: 'Das "bis" Datum darf nicht vor dem "von" Datum liegen'
                            }
                        });
                    } else {
                        removeErrorFromControls('wrongDateRange', [
                            form.get('fromDate'),
                            form.get('toDate'),
                        ]);
                    }
                }

                if (vestingPeriodFrom && vestingPeriodUntil) {
                    if (vestingPeriodFrom > vestingPeriodUntil) {
                        vestingPeriodFromField.setErrors({
                            wrongDateRange: {
                                message: 'Das "Laufzeit Start" Datum darf nicht hinter dem "Laufzeit Ende" Datum liegen'
                            }
                        });
                        vestingPeriodUntilField.setErrors({
                            wrongDateRange: {
                                message: 'Das "Laufzeit Ende" Datum darf nicht vor dem "Laufzeit Start" Datum liegen'
                            }
                        });
                    } else {
                        removeErrorFromControls('wrongDateRange', [
                            vestingPeriodFromField,
                            vestingPeriodUntilField,
                        ]);
                    }
                }

            });

        vestingPeriodFromField
            .valueChanges
            .pipe(takeUntil(this._unsubAll))
            .subscribe(value => {
                if (isValidDate(value) && payableOptionField.value && vestingPeriodUntilField.value == null) {
                    let newDate: Date;
                    let setUntilDateToLastDayOfMonth = false;
                    const firstDayOfMonth = isFirstDayOfMonth(value);

                    switch (payableOptionField.value.id) {
                        case PayableOptionId.Yearly:
                            newDate = addYears(value, 1);
                            break;
                        case PayableOptionId.HalfYearly:
                            newDate = addMonths(value, 6);
                            setUntilDateToLastDayOfMonth = true;
                            break;
                        case PayableOptionId.QuarterYearly:
                            newDate = addMonths(value, 3);
                            setUntilDateToLastDayOfMonth = true;
                            break;
                        case PayableOptionId.Monthly:
                            newDate = addMonths(value, 1);
                            setUntilDateToLastDayOfMonth = true;
                            break;
                        case PayableOptionId.Weekly:
                            newDate = addWeeks(value, 1);
                            break;
                        case PayableOptionId.Daily:
                            newDate = addDays(value, 1);
                            break;
                        case PayableOptionId.Hourly:
                            newDate = addHours(value, 1);
                            break;
                    }

                    vestingPeriodUntilField.patchValue(subMinutes(newDate, 1));

                    if (firstDayOfMonth && setUntilDateToLastDayOfMonth) {
                        lastDayOfMonthField.setValue(true);
                    }
                }
            });

        lastDayOfMonthField
            .valueChanges
            .pipe(takeUntil(this._unsubAll))
            .subscribe(value => {
                if (value) {
                    form.get('vestingPeriodUntil').disable();
                    form.get('vestingPeriodUntil').patchValue(endOfMonth(form.get('vestingPeriodUntil').value || form.get('vestingPeriodFrom').value));
                } else {
                    form.get('vestingPeriodUntil').enable();
                }
            });

        if (subscription && subscription.lastDayOfMonth) {
            form.get('vestingPeriodUntil').disable();
        }

        return form;
    }

    private _loadPayableOptions(): void {
        this._productPayableOptionApiService
            .getAll()
            .pipe(takeUntil(this._unsubAll))
            .subscribe((payableOptions: PayableOption[]) => {
                this.payableOptions = payableOptions.filter((po => po.id < 100));
            });
    }

    private _loadSeasons(): void {
        this._seasonApiService
            .getAllSeasons()
            .pipe(takeUntil(this._unsubAll))
            .subscribe((seasons: Season[]) => {
                this.seasons = seasons;
            });
    }
}
