import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { TenantRelation, TenantRelationWithDisable } from '@shared/models/tenant-relation';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { TenantRelationsApiService } from '@modules/bcm/@shared/services';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { AppNotificationService } from '@core/services/app-notification.service';
import { hours, minutes } from '@shared/constants/date';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { U2bDateValidators } from '@shared/validators/date/date-validators';
import { Season } from '@shared/models/seasons';
import { SeasonApiService } from '@bcmApiServices/seasons.api-service';
import { PayableOptionId } from '@bcmApiServices/product-payable-option.api-service';
import { isValidDate } from '@core/functions/is-valid-date';
import { addDays, addHours, addMonths, addWeeks, addYears, endOfMonth, subMinutes, } from '@core/date.facade';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { Product } from '@shared/models/product';
import { PayableOption } from '@shared/models/payable-option';

@Component({
    selector: 'dialog-tenant-relation',
    templateUrl: './get-tenant-relation-data-dialog.component.html',
})
export class GetTenantRelationDataDialogComponent implements OnInit, OnDestroy {

    private _unsubscribeAll = new Subject();

    @Input()
    tenantRelationAssignment: TenantRelationAssignment;

    protected readonly BcmTenantPermission = BcmTenantPermission;

    tenantRelations: TenantRelation[] = [];

    seasons: Season[];

    tenantRelationDatesForm: UntypedFormGroup;

    subscriptionForm: UntypedFormGroup;

    seasonFormControl = new FormControl<Season>(null);

    @Input()
    parentFormGroup: UntypedFormGroup;

    formGroup: UntypedFormGroup;

    productsCostCenterFormControls: FormControl[] = [];

    loading: boolean;

    hours = hours;

    minutes = minutes;

    PayableOptionId = PayableOptionId;

    products: Product[] = [];

    selectedTenantRelations: TenantRelation[] = [];

    payableOption?: PayableOption;

    multipleSelectable = false;

    disableConditionCallback = (selectedItems: TenantRelationWithDisable[], allItems: TenantRelationWithDisable[]) => {
        allItems.forEach(currentItem => {
            const foundElement = selectedItems.find(selectedElement => selectedElement.payableOption?.id);
            const isItemSelected = selectedItems.some(item => item.id === currentItem.id);
            const hasDifferentPayableOption = (foundElement?.payableOption?.id && currentItem.payableOption?.id !== foundElement.payableOption?.id);

            currentItem.disabled = isItemSelected || hasDifferentPayableOption;

            if (isItemSelected) {
                currentItem.disabledReason = 'Bereits selektiert.';
            } else if (hasDifferentPayableOption) {
                currentItem.disabledReason = `Nur noch Beziehungen mit Zahlungsintervall "${foundElement?.payableOption?.name}" möglich. Diese Beziehung hat Zahlungsintervall "${currentItem.payableOption?.name}" hinterlegt.`;
            }
        });
    }

    constructor(
        public dialogRef: MatDialogRef<GetTenantRelationDataDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            tenantRelationAssignment: TenantRelationAssignment,
            multipleSelectable: boolean
        },
        private _formBuilder: UntypedFormBuilder,
        private _tenantRelationsApiService: TenantRelationsApiService,
        private _seasonApiService: SeasonApiService,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogRef.addPanelClass('default-dialog');

        this.tenantRelationAssignment = this.data?.tenantRelationAssignment;
        this.multipleSelectable = this.data?.multipleSelectable || false;

        this.createMandatoryForm();
        this.createOptionalForm();
    }

    ngOnInit(): void {
        this.loading = true;

        if (this.parentFormGroup) {
            this.parentFormGroup.addControl('tenantRelationAssignmentForm', this.formGroup);
        }

        this._tenantRelationsApiService
            .getAll()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((tenantRelations) => {
                this.tenantRelations = tenantRelations;
                this.loading = false;
            });

        this._seasonApiService
            .getAllSeasons()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((seasons) => {
                this.seasons = seasons;
            });

        this.seasonFormControl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(season => {
                this.subscriptionForm.patchValue({
                    vestingPeriodFrom: season?.startDate || null,
                    vestingPeriodUntil: season?.endDate || null
                });
            });
    }

    ngOnDestroy(): void {
        if (this.parentFormGroup) {
            this.parentFormGroup.removeControl('tenantRelationAssignmentForm');
        }
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    compareSeasons(season1: Season, season2: Season): boolean {
        return season1?.id === season2?.id;
    }

    save(): void {

        if (this.tenantRelationDatesForm.invalid || (this.products?.length && this.subscriptionForm.invalid)) {
            this.tenantRelationDatesForm.markAllAsTouched();
            this.subscriptionForm.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        let formValueRaw = {
            ...this.tenantRelationDatesForm.getRawValue(),
        };

        if (this.products?.length) {
            formValueRaw = {
                ...formValueRaw,
                ...this.subscriptionForm.getRawValue()
            };
        }

        const costCenters = this.productsCostCenterFormControls.map(control => control.value);

        this.dialogRef.close({
            ...formValueRaw,
            ...(formValueRaw.tenantRelationForm || {}),
            costCenters,
        });
    }

    private createMandatoryForm() {
        this.tenantRelationDatesForm = this._formBuilder.group(
            {
                fromDate: [
                    this.tenantRelationAssignment?.fromDate,
                    [
                        U2bValidators.required('Bitte angeben, zu welchem Datum das Abo starten soll.'),
                    ]
                ],
                toDate: [this.tenantRelationAssignment?.toDate],
            },
            {
                validators: [
                    U2bDateValidators.dateBeforeOtherDate('fromDate', 'toDate', 'Start', 'Ende'),
                ]
            }
        );

        this.tenantRelationDatesForm.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                map(value => {
                    return value?.tenantRelationForm?.tenantRelation
                        ? [value?.tenantRelationForm?.tenantRelation]
                        : (value?.tenantRelationForm?.tenantRelations || []);
                })
            )
            .subscribe((tenantRelations: TenantRelation[]) => {
                if (!tenantRelations?.length) {
                    this.selectedTenantRelations = [];
                    this.products = [];
                    this.productsCostCenterFormControls = [];
                    this.payableOption = undefined;
                }

                this.selectedTenantRelations = tenantRelations;
                this.payableOption = tenantRelations.find(relation => !!relation.payableOption?.id)?.payableOption;

                const products = this.selectedTenantRelations.flatMap(relation => relation.products);

                if (products?.length) {
                    this.products = products;
                    this.productsCostCenterFormControls = products.map(product => {
                        const costCenter = product.costCenters?.length === 1
                            ? product.costCenters[0]
                            : undefined;
                        return new FormControl(costCenter);
                    });
                } else {
                    this.products = [];
                    this.productsCostCenterFormControls = [];
                }
            });
    }

    private createOptionalForm() {
        this.subscriptionForm = this._formBuilder.group(
            {
                periodFromDate: [
                    this.tenantRelationAssignment?.periodFromDate,
                    [U2bValidators.required('Bitte angeben, zu welchem Datum das Abo für die Beziehung starten soll.')]
                ],
                periodToDate: [this.tenantRelationAssignment?.periodToDate],
                vestingPeriodFrom: [
                    this.tenantRelationAssignment?.vestingPeriodFrom,
                    [U2bValidators.required('Bitte gib den Leistungszeitraum an.')]
                ],
                vestingPeriodUntil: [
                    this.tenantRelationAssignment?.vestingPeriodUntil || null,
                    [U2bValidators.required('Bitte gib den Leistungszeitraum an.')]
                ],
                lastDayOfMonth: [this.tenantRelationAssignment?.lastDayOfMonth || false],
            },
            {
                validators: [
                    U2bDateValidators.dateBeforeOtherDate('periodFromDate', 'periodToDate', 'Abo Start', 'Abo Ende'),
                    U2bDateValidators.dateBeforeOtherDate('vestingPeriodFrom', 'vestingPeriodUntil', 'Leistungszeitraum Start', 'Leistungszeitraum Ende'),
                ]
            }
        );

        const subscriptionForm = this.subscriptionForm;
        const vestingPeriodFromField = subscriptionForm.get('vestingPeriodFrom');
        const vestingPeriodUntilField = subscriptionForm.get('vestingPeriodUntil');
        const lastDayOfMonthField = subscriptionForm.get('lastDayOfMonth');

        vestingPeriodFromField
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                if (isValidDate(value) && this.payableOption?.id && vestingPeriodUntilField.value == null) {
                    let newDate: Date;
                    switch (this.payableOption.id) {
                        case PayableOptionId.Yearly:
                            newDate = addYears(value, 1);
                            break;
                        case PayableOptionId.HalfYearly:
                            newDate = addMonths(value, 6);
                            break;
                        case PayableOptionId.QuarterYearly:
                            newDate = addMonths(value, 3);
                            break;
                        case PayableOptionId.Monthly:
                            newDate = addMonths(value, 1);
                            break;
                        case PayableOptionId.Weekly:
                            newDate = addWeeks(value, 1);
                            break;
                        case PayableOptionId.Daily:
                            newDate = addDays(value, 1);
                            break;
                        case PayableOptionId.Hourly:
                            newDate = addHours(value, 1);
                            break;
                    }
                    vestingPeriodUntilField.patchValue(subMinutes(newDate, 1));
                }
            });

        lastDayOfMonthField
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                if (value) {
                    subscriptionForm.get('vestingPeriodUntil').disable();
                    subscriptionForm.get('vestingPeriodUntil').patchValue(
                        endOfMonth(subscriptionForm.get('vestingPeriodUntil').value || subscriptionForm.get('vestingPeriodFrom').value), {emitEvent: false}
                    );
                } else {
                    subscriptionForm.get('vestingPeriodUntil').enable();
                }
            });
    }
}
