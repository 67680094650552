<mat-form-field fxFlex="100" style="width: 100%" [appearance]="appearance" floatLabel="always">
    <mat-label>
        <ng-container *ngIf="multiSelect">Organisationen</ng-container>
        <ng-container *ngIf="!multiSelect">Organisation</ng-container>
    </mat-label>

    <ng-container *ngIf="multiSelect; else singleSelect">
        <mat-select data-test="" [formControl]="control" multiple
                    [compareWith]="compareObjectsById">
            <mat-option *ngFor="let company of (filteredCompanies$ | async) | orderBy : 'fullName'"
                        [value]="company">
                {{company.fullName}}
            </mat-option>
        </mat-select>
    </ng-container>

    <ng-template #singleSelect>
        <input type="text" matInput [formControl]="control" [matAutocomplete]="companySelector">

        <mat-autocomplete #companySelector="matAutocomplete" [displayWith]="displayWith">
            <ng-container
                    *ngFor="let company of (filteredCompanies$ | async) | orderBy : 'fullName'">
                <mat-option [value]="company">
                    <span>{{ company.fullName }}</span><br>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </ng-template>

    <button type="button" matSuffix mat-icon-button
            matTooltip="Auswahl entfernen"
            [matTooltipDisabled]="!control.value || control.disabled"
            [disabled]="!control.value || control.disabled"
            (click)="control.setValue(null)">
        <mat-icon>clear</mat-icon>
    </button>

    <mat-error>{{control.errors | firstErrorMessage}}</mat-error>
</mat-form-field>
