import { BcmBooking } from '@shared/models/bcm-booking';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

/*
* T is the type of the value that is added or removed.
* U is the type of the value that is stored in the value$ observable - it can be an array of T or a single T.
* If U is an array, it must have a property 'uuid' of type string to be able to remove an item from the array by uuid (we dont have an id for the unsaved items).
* */
export abstract class BookingDialogBaseEntity<T extends U extends Array<any> ? { uuid: string } : unknown, U> {

    loading = false;

    protected _value$: BehaviorSubject<U>;

    get value$(): BehaviorSubject<U> {
        return this._value$;
    }

    get value(): U {
        return this._value$.value;
    }

    // Do not use this setter from TabsComponent. Value will be set by formGroup.valueChanges in BaseTabComponent and BaseArrayTabComponent.
    set value(value: U) {
        this._value$.next(value);

        this.afterValueSet();

        // only set to true if the booking was initialized
        // otherwise the changes from initialization would be handled as changes to the booking
        this.bookingDialogService.unsavedChanges = this.bookingDialogService.initialized;
    }

    private _update$: ReplaySubject<void> = new ReplaySubject<void>(1);

    get update$(): ReplaySubject<void> {
        return this._update$;
    }

    private _formGroup: FormGroup;
    private _formArray: FormArray;

    set formGroup(formGroup: FormGroup) {
        this._formGroup = formGroup;
    }

    set formArray(formArray: FormArray) {
        this._formArray = formArray;
    }

    protected constructor(protected bookingDialogService: BookingDialogService, private _attribute: BookingAttribute) {
        this._value$ = new BehaviorSubject<U>(this.getEmptyValue());
    }

    protected afterValueSet(): void {
        // to be overridden by subclasses (if needed)
    }

    protected isDuplicate(value: T): boolean {
        // to be overridden by subclasses (if needed)
        return false;
    }

    disable(): void{
        this._formGroup?.disable();
        this.formGroup?.disable();
    }

    private _findInvalid(form: AbstractControl): void {
        if (form instanceof FormGroup || form instanceof FormArray) {
            Object.keys((form as FormGroup).controls || form.controls).forEach(key => {
                const control = form.get(key);
                if (control && control.invalid) {
                    console.error(`${key} is invalid`);
                }
                if (control instanceof FormGroup || control instanceof FormArray) {
                    this._findInvalid(control);
                }
            });
        }
    }

    findInvalid(): void {
        if (this._formGroup) {
            this._findInvalid(this._formGroup);
        } else if (this._formArray) {
            this._findInvalid(this._formArray);
        }
    }

    isFormValid(): boolean {
        if (this._formGroup) {
            if (this._formGroup.invalid) {
                this._formGroup.markAllAsTouched();
            }
            return this._formGroup.valid;
        } else if (this._formArray) {
            if (this._formArray.invalid) {
                this._formArray.markAllAsTouched();
            }
            return this._formArray.valid;
        }
        return true;
    }

    sendUpdate(emitValue = false) {
        if (emitValue) {
            // normally we dont need this, except for the case when we modify the value directly without using the add/remove or value = ... methods
            this._value$.next(this.value);
        }

        // needed to trigger the valueChanges in the formGroup
        this._update$.next();
    }

    setBooking(booking: BcmBooking) {
        this.value = booking[this._attribute.valueOf()];
        this.sendUpdate();
    }

    add(value: T) {
        if (this.isDuplicate(value)) {
            return;
        }

        this.value = this.isArray()
            ? ([...(this.value as unknown as T[]), value] as unknown as U)
            : (value as unknown as U);
    }

    remove(value: T) {
        this.value = this.isArray()
            ? (this.value as unknown as T[]).filter(t => t['uuid'] !== value['uuid']) as unknown as U
            : (null as unknown as U);
    }

    protected isArray(): boolean {
        return Array.isArray([] as unknown as U);
    }

    private getEmptyValue(): U {
        if (this.isArray()) {
            return [] as unknown as U;
        }
        return null as unknown as U;
    }

}
