import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ConfirmDialogService } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.service';
import { U2bValidators } from '@shared/validators/validators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { Boat } from '@shared/models/boat';
import { BcmUser } from '@modules/bcm/bcm-user';
import { BcmOrderWorkPackageInvoicePosition, } from '@shared/models/bcm-order-work-package-invoice-position';
import { BcmOrder } from '@shared/models/bcm-order';
import { BcmOrderStatusType, bcmOrderStatusTypeDE } from '@shared/models/bcm-order-status';
import { Person } from '@shared/models/person';
import { IProduct, Product } from '@shared/models/product';
import { Company } from '@shared/models/company';
import { ChooseBoatDialogComponent } from '@shared/components/dialogs/choose-boat-dialog/choose-boat-dialog.component';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { toDateTimeStringDE } from '@core/functions/to-date-string';
import { OrderWorkPackagesApiService } from '@bcmServices/api-services/order-work-packages.api-service';
import { BcmOrderWorkPackage } from '@shared/models/bcm-order-work-package';
import { BcmOrderTodo } from '@shared/models/bcm-order-todo';
import { BaseState } from '@bcmServices/base.state';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { isString } from '@shared/functions/is-string';
import { InvoicePositionApiService, ProductsApiService } from '@bcmServices/index';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmOrderInvoicePosition, BcmOrderInvoicePositionDto } from '@shared/models/bcm-order-invoice-position';
import { BcmOrdersApi } from '@bcmServices/api-services/order.api-service';
import { suppressErrorForStatus } from '@shared/functions/suppress-error-for-status';
import { OrdersApi } from '@modules/bcm/docksite-order/orders-api.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'docksite-order',
    templateUrl: './docksite-order.component.html',
    styleUrls: ['./docksite-order.component.scss']
})
export class DocksiteOrderComponent implements OnDestroy, OnInit, AfterViewInit {

    private _unsubscribeAll = new Subject<any>();

    BcmUserPermission = BcmUserPermission;

    formGroup: UntypedFormGroup;
    formGroupPrepare: UntypedFormGroup;
    invoiceFormGroup: UntypedFormGroup;

    themePalette: ThemePalette = 'accent';

    boat: Boat;
    order: BcmOrder;

    orderState: BcmOrderStatusType;
    products: Product[];

    filteredProducts$: Observable<Product[]>;
    givenBoats: Boat[];
    orderWorkPackages: BcmOrderWorkPackage[] = [];
    selectedBoat: Boat;
    selectedStartTime: string;
    selectedEndTime: string;
    selectedWorkPackage: BcmOrderWorkPackage;
    selectedWorkPackages: BcmOrderWorkPackage[] = [];
    users: BcmUser[];

    selectedTodos: BcmOrderTodo[] = [];
    selectedInvoicePositions: BcmOrderWorkPackageInvoicePosition[] = [];
    selectedPerson: Person;
    selectedCompany: Company;

    boatString: string;
    personString: string;
    assignBoatBoolean = false;

    isSaving: boolean;

    dialogRef: any;

    orderTypes = [];

    step = 0;

    person$ = new EventEmitter<Person>();
    company$ = new EventEmitter<Company>();
    boat$ = new EventEmitter<Boat>();

    constructor(private _dialogRef: MatDialogRef<DocksiteOrderComponent>,
                private _appNotificationService: AppNotificationService,
                private _matDialog: MatDialog,
                private _confirmDialogService: ConfirmDialogService,
                private _ordersApi: OrdersApi,
                private _invoicePositionApiService: InvoicePositionApiService,
                private _formBuilder: UntypedFormBuilder,
                private productsApiService: ProductsApiService,
                private api: BcmOrdersApi,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: {
                    order: BcmOrder
                },
                private orderWorkPackagesApiService: OrderWorkPackagesApiService,
    ) {
        this._dialogRef.addPanelClass('default-dialog');
        this._dialogRef.disableClose = true;

        this.formGroupPrepare = this._formBuilder.group({
            type: [null]
        });

        this.formGroup = this._formBuilder.group({
            orderTitle: [data?.order?.orderTitle, [
                U2bValidators.required('Bitte einen Auftrags Titel eingeben.'),
                U2bValidators.maxLength(255)]
            ],
            note: [data?.order?.note, [U2bValidators.maxLength(1024)]],
            status: [bcmOrderStatusTypeDE[data?.order?.statusHistory[0].orderStatus] || 'offen', [U2bValidators.required('Bitte einen Auftrag Status auswählen.')]],
            orderWorkPackagesForm: [null, null],
            todos: this._createOrderWorkPackageTodoFormArray(data?.order?.todos),
            invoicePositions: this._createOrderWorkPackageInvoicePositionFormArray(data?.order?.orderInvoicePositions),
            user: [data?.order?.assignedUser, null]
        });


        if (data?.order?.activeClient) {
            if (data?.order?.client) {
                this.formGroupPrepare.get('type').setValue('person');
                this.selectedPerson = data?.order?.client;


            } else if (data?.order?.clientCompany) {
                this.formGroupPrepare.get('type').setValue('organisation');
                this.company$.emit(data?.order?.clientCompany);
            }
        }

        if (data?.order?.boats[0]) {
            this.selectedBoat = data?.order?.boats[0];
        }

        this.formGroup.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
            )
            .subscribe(value => {
                const person = value?.personForm?.person;
                const company = value?.companyForm?.company;
                const boat = value?.boatForm?.boat;
                boat?.id ? this.selectedBoat = boat : this.selectedBoat = null;

                if (this.formGroup.controls.personForm?.valid) {
                    person?.id ? this.personString = `${person?.firstName} ${person.lastName}` : this.personString = `${this.formGroup.get('personForm').value['firstName']} ${this.formGroup.get('personForm').value['lastName']}`;
                }

                if (this.formGroup.controls.companyForm?.valid) {
                    company?.id ? this.personString = `${company.name}` : this.personString = `${this.formGroup.get('companyForm').value['name']}`;
                }

                if (this.formGroup.controls.boatForm?.valid) {
                    boat?.id ? this.boatString = boat.name : this.boatString = this.formGroup.get('boatForm').value['name'] || 'Unbenanntes Boot';
                }

                if (person?.id !== this.selectedPerson?.id || company?.id !== this.selectedCompany?.id) {
                    if (person === null) {
                        this.selectedPerson = null;
                        this.personString = null;
                    }

                    if (company === null) {
                        this.selectedCompany = null;
                        this.personString = null;
                    }

                    person ? this.selectedPerson = person : this.selectedCompany = company;

                    if ((person?.id && person?.boats?.length) || (company?.id && company?.boats?.length)) {
                        person ? this.givenBoats = person.boats : this.givenBoats = company.boats;

                        switch (this.givenBoats?.length) {
                            case 1:
                                try {
                                    this.formGroup.get('boatForm').patchValue({
                                        boat: this.givenBoats[0],
                                    });
                                    this.selectedBoat = this.givenBoats[0];

                                } catch (error) {
                                    this._appNotificationService.showError(`Boot konnte nicht übernommen werden: ${error}`);
                                }
                                this._appNotificationService.showSuccess(`Das Boot ${this.givenBoats[0].name} wurde übernommen.`);
                                this.nextStep();
                                break;
                            default:
                                const boats = this.givenBoats;
                                this.dialogRef = this._matDialog.open(ChooseBoatDialogComponent, {data: {boats: boats}});

                                this.dialogRef.afterClosed().subscribe((selectedBoat: Boat) => {
                                    this.formGroup.get('boatForm').patchValue({
                                        boat: selectedBoat,
                                    });
                                    this.selectedBoat = selectedBoat;
                                    this._appNotificationService.showSuccess(`Das Boot ${selectedBoat.name || 'unbenanntes Boot'} wurde übernommen`);
                                });
                                this.nextStep();
                                break;

                        }
                    } else if ((company?.id || person?.id) && !(company?.boats?.length || person?.boats?.length)) {
                        this._confirmDialogService
                            .useWarnTheme()
                            .setTitle('Der Auftraggeber hat kein Boot')
                            .setBody('Möchten Sie ein neues Boot erfassen?')
                            .setYesButton({
                                text: 'Ja'
                            })
                            .setNoButton({
                                text: 'Nein'
                            })
                            .openWithCallback(() => this.setStep(2),
                                () => this.nextStep());
                    } else {
                        this.givenBoats = undefined;
                    }
                }

                if (this.formGroup.get('dateRangeForm').valid) {
                    const startTime = this.formGroup.get('dateRangeForm').get('from').value;
                    const endTime = this.formGroup.get('dateRangeForm').get('to').value;
                    this.selectedStartTime = `startet: ${toDateTimeStringDE(startTime).replace(' ', ', um ')}`;
                    if (endTime !== null) {
                        this.selectedEndTime = `endet: ${toDateTimeStringDE(endTime).replace(' ', ', um ')}`;
                    }
                }
            });
    }

    ngOnInit(): void {

        // StatusType Field
        for (const statusTyp in bcmOrderStatusTypeDE) {
            if (bcmOrderStatusTypeDE.hasOwnProperty(statusTyp)) {
                this.orderTypes.push(bcmOrderStatusTypeDE[statusTyp]?.toString());
            }
        }
        this.orderTypes.shift();

        // Load orderWorkPackages
        this.orderWorkPackagesApiService
            .getAll()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(orderWorkPackages => {
                this.orderWorkPackages = orderWorkPackages;
            });

        this.productsApiService
            .getAll(new HttpParams().set('filterByTags', ['DockSite'].join(',')))
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(products => {
                this.products = products;
            });

        this.api.getAllUsers()
            .pipe(suppressErrorForStatus<BcmOrder[]>(403))
            .subscribe(users => {
                this.users = users || [];
            });

    }

    ngAfterViewInit(): void {
        if (this.data?.order?.activeClient) {
            if (this.data?.order?.client) {
                this.formGroupPrepare.get('type').setValue('person');
                this.person$.emit(this.data?.order?.client);

            } else if (this.data?.order?.clientCompany) {
                this.formGroupPrepare.get('type').setValue('organisation');
                this.company$.emit(this.data?.order?.clientCompany);
            }
        }

        if (this.data?.order?.boats[0]) {
            this.delayFunction();
        }
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickClose(): void {
        if (this.formGroupPrepare.dirty || this.formGroup.dirty) {
            return this._confirmDialogService
                .useWarnTheme()
                .setTitle('Achtung')
                .setBody('Alle bislang eingegebenen Daten werden nicht gespeichert. Trotzdem beenden?')
                .setYesButton({
                    text: 'Ja, beenden'
                })
                .setNoButton({
                    text: 'Abbrechen'
                })
                .openWithCallback(() => this._dialogRef.close());
        }

        this._dialogRef.close();
    }

    onClickSaveAndClose(): void {

        if (this.formGroup.invalid || this.formGroupPrepare.invalid) {
            this.formGroup.markAllAsTouched();
            this.formGroupPrepare.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
        } else {

            this.isSaving = true;

            const {
                orderTitle,
                dateRangeForm,
                personForm,
                companyForm,
                boatForm,
                status,
                invoicePositions,
                note,
                user
            } = this.formGroup.value;

            const body: any = {
                orderTitle: orderTitle,
                startTime: dateRangeForm.from,
                endTime: dateRangeForm.to,
                note: note,
                assignedUser: user?.user_id
            };

            if (status) {
                this.orderState = Object.keys(bcmOrderStatusTypeDE).find(key => bcmOrderStatusTypeDE[key] === status) as BcmOrderStatusType;
            }

            if (personForm) {
                let client: any = {};

                if (personForm.person && personForm.person.id) {
                    client.id = personForm.person.id;
                } else {
                    delete personForm.person;
                    client = {...personForm};
                }
                body['client'] = client;
            }

            if (companyForm) {
                let clientCompany: any = {};

                if (companyForm.company && companyForm.company.id) {
                    clientCompany.id = companyForm.company.id;
                } else {
                    delete companyForm.company;
                    clientCompany = {...companyForm};
                }

                body['clientCompany'] = clientCompany;
            }

            if (this.assignBoatBoolean === true) {
                body['assignBoat'] = true;
            }

            if (this.formGroup.get('todos')['value'].length) {
                body['todos'] = this.formGroup.get('todos')['value'];
            }

            if (boatForm) {
                let boat: any = {};

                if (boatForm.boat && boatForm.boat.id) {
                    boat.id = boatForm.boat.id;
                } else {
                    delete boatForm.boat;
                    boat = {...boatForm};
                }

                body['boat'] = boat;
            }


            this._ordersApi.create(body, false)
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    map((addedOrder: BcmOrder) => {
                        if (this.orderState.length) {
                            this._ordersApi.update(addedOrder['order'], false).subscribe(() => {
                                if (invoicePositions.length) {
                                    for (const invoicePosition of invoicePositions) {
                                        const position: InvoicePosition = {
                                            // why was this parameter added?
                                            // bcm_invoice_positions_id: invoicePosition.id,
                                            product: invoicePosition.product,
                                            quantity: invoicePosition.quantity,
                                            account: '',
                                            title: invoicePosition.product.name,
                                            unit: invoicePosition.product.unit,
                                            price: invoicePosition.product.price,
                                            taxRate: invoicePosition.product.taxRate,
                                        } as InvoicePosition;

                                        if (personForm) {
                                            return this._invoicePositionApiService.addPositionForPerson(
                                                position,
                                                personForm
                                            ).pipe(
                                                switchMap((addedInvoicePosition: InvoicePosition) => {
                                                    return this._ordersApi.addOrderInvoicePosition({
                                                        bcm_orders_id: addedOrder.id,
                                                        bcm_invoice_positions_id: addedInvoicePosition.id,

                                                    });
                                                }),
                                                catchError((error: HttpErrorResponse) => {
                                                    this._appNotificationService.showError(`Die Leistung konnte nicht hinzugefügt werden.`);
                                                    return throwError(() => error);
                                                })
                                            ).subscribe();
                                        }

                                        if (companyForm) {
                                            return this._invoicePositionApiService.addPositionForPerson(
                                                position,
                                                companyForm
                                            ).pipe(
                                                switchMap((addedInvoicePosition: InvoicePosition) => {
                                                    return this._ordersApi.addOrderInvoicePosition({
                                                        bcm_orders_id: addedOrder.id,
                                                        bcm_invoice_positions_id: addedInvoicePosition.id,

                                                    });
                                                }),
                                                catchError((error: HttpErrorResponse) => {
                                                    this._appNotificationService.showError(`Die Leistung konnte nicht hinzugefügt werden.`);
                                                    return throwError(() => error);
                                                })
                                            ).subscribe();
                                        }
                                    }
                                }
                            });
                        }
                        return addedOrder['order'];
                    })
                )
                .subscribe(() => {
                    this._appNotificationService.showSuccess('Die Daten wurden erfolgreich gespeichert.');

                }).add(() => {
                this.isSaving = false;
                this._dialogRef.close();
            });


        }
    }

    onClickAddWorkPackage(): void {
        this.selectedWorkPackage = this.formGroup.get('orderWorkPackagesForm').value;

        this.selectedWorkPackage?.todos?.forEach((todo, index) => {
            const orderTodo = new BcmOrderTodo();
            orderTodo.todo = todo.todo;
            orderTodo.position = index;
            (this.formGroup.get('todos') as UntypedFormArray).push(this._createOrderWorkPackageTodoForm(orderTodo));
        });

        this.selectedWorkPackage?.invoicePositions?.forEach(invoicePosition => {
            const orderInvoice = new BcmOrderInvoicePosition({} as BcmOrderInvoicePositionDto);
            orderInvoice.invoicePosition = new InvoicePosition();
            orderInvoice.invoicePosition.product = invoicePosition.product;
            orderInvoice.invoicePosition.quantity = invoicePosition.quantity;
            (this.formGroup.get('invoicePositions') as UntypedFormArray).push(this._createOrderWorkPackageInvoicePositionForm(orderInvoice));
        });

        this.selectedWorkPackages.push(this.selectedWorkPackage);

        this.formGroup.get('orderWorkPackagesForm').setValue(null);

        this._appNotificationService.showSuccess(`Das Arbeitspaket "${this.selectedWorkPackage.shortDescription}" wurde hinzugefügt.`);
    }

    removeOrderWorkPackage(orderWorkPackage: BcmOrderWorkPackage): void {
        this._confirmDialogService
            .useWarnTheme()
            .setTitle('Arbeitspaket entfernen')
            .setBody('Möchtest Du das Arbeitspaket wirklich löschen?')
            .openWithCallback(() => {
                const index = this.selectedWorkPackages.findIndex(p => p.id === orderWorkPackage.id);
                this.selectedWorkPackages.splice(index, 1);
                this.selectedWorkPackages = this.selectedWorkPackages.slice();
                this._appNotificationService.showSuccess(`Das Arbeitspaket "${orderWorkPackage.shortDescription}" wurde entfernt.`);
            });
    }

    addTodoToOrderWorkPackage(event): void {
        event.preventDefault();
        event.stopPropagation();
        (this.formGroup.get('todos') as UntypedFormArray)
            .push(this._createOrderWorkPackageTodoForm(new BcmOrderTodo()));
    }

    addInvoicePositionToOrderWorkPackage(event): void {
        event.preventDefault();
        event.stopPropagation();
        (this.formGroup.get('invoicePositions') as UntypedFormArray)
            .push(this._createOrderWorkPackageInvoicePositionForm(new BcmOrderInvoicePosition({} as BcmOrderInvoicePositionDto)));
    }


    private _createOrderWorkPackageTodoForm(bcmOrderTodo: BcmOrderTodo): any {
        return new UntypedFormGroup({
            id: new UntypedFormControl(bcmOrderTodo.id),
            todo: new UntypedFormControl(
                bcmOrderTodo.todo,
                [U2bValidators.required('Bitte gib ein Todo an')]
            ),
        });
    }

    removeTodoFromOrderWorkPackage(event, indexToDo): void {
        event.preventDefault();
        event.stopPropagation();

        this._confirmDialogService
            .setTitle('Todo entfernen')
            .setBody('Möchtest Du das Todo wirklich löschen?')
            .useWarnTheme()
            .openWithCallback(() => {
                this.formGroup.get('todos')['controls'].splice(indexToDo, 1);
                this.formGroup.get('todos')['value'].splice(indexToDo, 1);
                this.formGroup.get('todos').updateValueAndValidity();

            });
    }

    removeInvoicePositionFromOrderWorkPackage(event, indexToDo): void {
        event.preventDefault();
        event.stopPropagation();

        this._confirmDialogService
            .setTitle('Leistung entfernen')
            .setBody('Möchtest Du die Leistung wirklich löschen?')
            .useWarnTheme()
            .openWithCallback(() => {
                this.formGroup.get('invoicePositions')['controls'].splice(indexToDo, 1);
                this.formGroup.get('invoicePositions')['value'].splice(indexToDo, 1);
                this.formGroup.get('invoicePositions').updateValueAndValidity();
            });
    }

    private _createOrderWorkPackageTodoFormArray(todos: BcmOrderTodo[], minFormGroups = 0): UntypedFormArray {

        if (!todos) {
            todos = [];
        }

        const formArray = new UntypedFormArray([], []);

        for (const todo of todos) {
            const todoFormGroup = this._createOrderWorkPackageTodoForm(todo);
            formArray.push(todoFormGroup);
        }

        if (minFormGroups && formArray.length < minFormGroups) {
            for (let i = formArray.length; i < minFormGroups; i++) {
                formArray.push(this._createOrderWorkPackageTodoForm({} as BcmOrderTodo));
            }
        }

        return formArray;
    }

    private _createOrderWorkPackageInvoicePositionFormArray(invoicePositions: BcmOrderInvoicePosition[], minFormGroups = 0): UntypedFormArray {

        if (!invoicePositions) {
            invoicePositions = [];
        }
        const formArray = new UntypedFormArray([], []);

        for (const invoicePosition of invoicePositions) {
            const invoiceFormGroup = this._createOrderWorkPackageInvoicePositionForm(invoicePosition);
            formArray.push(invoiceFormGroup);
        }

        if (minFormGroups && formArray.length < minFormGroups) {
            for (let i = formArray.length; i < minFormGroups; i++) {
                formArray.push(this._createOrderWorkPackageInvoicePositionForm({} as BcmOrderInvoicePosition));
            }
        }

        return formArray;
    }

    private _createOrderWorkPackageInvoicePositionForm(bcmOrderInvoicePosition: BcmOrderInvoicePosition): any {
        const formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(bcmOrderInvoicePosition.id),
            product: new UntypedFormControl(
                bcmOrderInvoicePosition?.invoicePosition?.product,
                [U2bValidators.required('Bitte gib ein Produkt an')]
            ),
            quantity: new UntypedFormControl(
                bcmOrderInvoicePosition?.invoicePosition?.quantity,
                [U2bValidators.required('Bitte gib eine Menge an an')]
            )
        });

        this.filteredProducts$ = formGroup.get('product').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(this.products),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((value) => isString(value) ? this._filterProducts(value as string) : of(this.products))
            );
        return formGroup;
    }

    private _filterProducts(filter: string): Observable<Product[]> {
        return of(this.products)
            .pipe(map((products: Product[]) => Product.filterProducts(products, filter)));
    }

    public displayProductWith(product: IProduct): string {
        return product ? product.name : '';
    }

    assignBoat(): void {
        this.assignBoatBoolean = !this.assignBoatBoolean;
    }

    setStep(index: number): void {
        this.step = index;
    }

    nextStep(): void {
        this.step++;
    }

    delayFunction(): void {
        setTimeout(() => {
            this.selectedBoat = this.data?.order?.boats[0];
            this.givenBoats = this.data?.order?.boats;
            this.formGroup.get('boatForm').patchValue({
                boat: this.givenBoats[0],
            });
        }, 1000);
    }


    drop(event: CdkDragDrop<string[]>, isTodo: boolean): void {
        const todoFormArray = this.formGroup.get('todos') as UntypedFormArray;
        const invoiceFormArray = this.formGroup.get('invoicePositions') as UntypedFormArray;

        const from = event.previousIndex;
        const to = event.currentIndex;

        if (isTodo) {
            this.moveItemInFormArray(todoFormArray, from, to);
        } else {
            this.moveItemInFormArray(invoiceFormArray, from, to);
        }
    }

    moveItemInFormArray(formArray: FormArray, fromIndex: number, toIndex: number): void {
        const from = this.clamp(fromIndex, formArray.length - 1);
        const to = this.clamp(toIndex, formArray.length - 1);

        if (from === to) {
            return;
        }

        const previous = formArray.at(from);
        const current = formArray.at(to);
        formArray.setControl(to, previous);
        formArray.setControl(from, current);
    }

    clamp(value: number, max: number): number {
        return Math.max(0, Math.min(max, value));
    }
}
