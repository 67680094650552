import { Injectable } from '@angular/core';
import { BcmService } from '@modules/bcm/bcm.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { filter } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';

@Injectable({providedIn: 'root'})
export class BcmSockets {

    private _settings$ = new BehaviorSubject<{ user: { email: string, name: string } }>(null);

    get settings$(): Observable<{ user: { email: string, name: string } }> {
        return this._settings$.asObservable();
    }

    private _selectedTenantSocket = new BehaviorSubject<Socket>(null);

    get selectedTenantSocket(): Observable<Socket> {
        return this._selectedTenantSocket.asObservable()
            .pipe(filter(item => item != null));
    }

    constructor(bcmService: BcmService) {
        bcmService
            .selectedTenant$
            .subscribe((tenant) => {
                this.resetSelectedTenantSocket();

                if (tenant?.id) {
                    const selectedTenantSocket = new Socket({
                        url: `${environment.endpoints.webSocket}/tenant-${tenant.id}`,
                        options: {
                            reconnection: true,
                            reconnectionAttempts: 5,
                            reconnectionDelay: 1000,
                            transports: ['websocket', 'polling', 'ws', 'wss']
                        }
                    });
                    this._selectedTenantSocket.next(selectedTenantSocket);
                    selectedTenantSocket
                        .fromEvent<{ user: { email: string, name: string } }>(`settings`)
                        .subscribe(data => this._settings$.next(data));
                }
            });
    }

    private resetSelectedTenantSocket(): void {
        if (this._selectedTenantSocket?.getValue()) {
            this._selectedTenantSocket.getValue().removeAllListeners();
            this._selectedTenantSocket.getValue().disconnect();
            this._selectedTenantSocket = new BehaviorSubject<Socket>(null);
        }
    }
}
