import { tryParseDate } from '@shared/functions/try-parse-date';
import { TenantRelation, TenantRelationRaw } from '@shared/models/tenant-relation';

export interface BerthProductDto {
    id: number;
    bcm_berths_id: number;
    bcm_products_id: number;
    quantity: number;
    sortIndex: number;
    ignoreFreeOfChargePosition: boolean;
    insertedOn: string;
    insertedBy: string;
    lastUpdateOn: string;
    lastUpdateBy: string;

    tenantRelations: TenantRelationRaw[];
}

export class BerthProduct {
    id: number;
    bcm_berths_id: number;
    bcm_products_id: number;
    quantity: number;
    sortIndex: number;
    ignoreFreeOfChargePosition: boolean;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;

    tenantRelations: TenantRelation[];

    constructor(berthProduct: BerthProductDto) {
        if (berthProduct) {
            this.id = berthProduct.id;
            this.bcm_berths_id = berthProduct.bcm_berths_id;
            this.bcm_products_id = berthProduct.bcm_products_id;
            this.quantity = berthProduct.quantity;
            this.sortIndex = berthProduct.sortIndex;
            this.ignoreFreeOfChargePosition = berthProduct.ignoreFreeOfChargePosition;
            this.insertedOn = tryParseDate(berthProduct.insertedOn);
            this.insertedBy = berthProduct.insertedBy;
            this.lastUpdateOn = tryParseDate(berthProduct.lastUpdateOn);
            this.lastUpdateBy = berthProduct.lastUpdateBy;

            this.tenantRelations = (berthProduct.tenantRelations || [])
                .map(tenantRelation => new TenantRelation(tenantRelation));
        }
    }
}
