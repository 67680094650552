<div fxFlex="100" fxLayout="column" class="travelers-tab">

    <div [class.mb-16]="(formGroups$ | async)?.length > 0" class="mt-16">
        <mat-accordion [multi]="false">
            <ng-container
                    *ngFor="let formGroup of (formGroups$ | async); let index = index">
                <booking-traveler
                        [travelerFormGroup]="formGroup"
                        (removeTraveler)="removeTraveler(index)"></booking-traveler>
            </ng-container>
        </mat-accordion>
    </div>

    <ng-container *ngIf="!bookingDialogService.readonlyView">
        <button class="add-btn" mat-stroked-button color="accent"
                (click)="addFormGroup()">
            <mat-icon>add</mat-icon>
            <span>Reisenden hinzufügen</span>
        </button>
    </ng-container>

</div>
