import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { AbstractControl, FormControl, UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance } from '@angular/material/legacy-form-field';
import { compareObjectsById } from '@shared/functions/compare-objects-by-id';
import { BcmCostCenter } from '@shared/models/bcm-cost-center';
import { CostCentersFacade } from '@modules/bcm/@core/state-management/cost-centers/cost-centers.facade';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-input-cost-centers',
    templateUrl: './cost-center-input.component.html',
    styleUrls: ['./cost-center-input.component.scss']
})
export class CostCenterInputComponent implements OnInit, OnDestroy {

    @Input() appearance: MatLegacyFormFieldAppearance = 'outline';

    @Input() multiSelect = false;

    @Input() set control(formControl: AbstractControl) {
        this._formControl = formControl as UntypedFormControl;
        this._addFormControlListeners(this._formControl);
    }

    get formControl(): UntypedFormControl {
        return this._formControl;
    }

    private _formControl: UntypedFormControl;

    private _unsubscribeAll = new Subject();

    private _bcmCostCenters: BcmCostCenter[] = [];

    filteredCostCenters$: Observable<BcmCostCenter[]>;

    compareObjectsById = compareObjectsById;

    constructor(private costCentersFacade: CostCentersFacade) {
    }

    ngOnInit(): void {
        this._loadBcmCostCenters();

        if (this.multiSelect) {
            this._formControl.setValue(this._formControl.value);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _addFormControlListeners(formControl: UntypedFormControl): void {
        if (!formControl) {
            return;
        }
        this.filteredCostCenters$ = combineLatest([
            formControl.valueChanges
                .pipe(
                    startWith((this.multiSelect) ? [] : ''),
                    debounceTime(DEFAULT_DEBOUNCE_TIME)
                ),
            this.costCentersFacade
                .page$
                .pipe(
                    filter(val => !!val),
                    map(page => {
                        this._bcmCostCenters = page.results;
                    })
                )
        ])
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((value) => isString(value[0]) && !this.multiSelect ? this._filter(value[0]) : this._bcmCostCenters)
            );

    }

    private _loadBcmCostCenters(): void {
        this._bcmCostCenters = [];
        const httpParams = new HttpParams().set('size', 1000);
        this.costCentersFacade.loadPage(httpParams).subscribe();
    }

    private _filter(search = ''): BcmCostCenter[] {
        return this._bcmCostCenters.filter(constCenter => constCenter.name.toLowerCase().includes(search.toLowerCase()));
    }

    displayWith(constCenter: BcmCostCenter): string {
        return constCenter?.name || '';
    }

}
