import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { IProduct, Product } from '@shared/models/product';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { ProductsApiService } from '@modules/bcm/@shared/services';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { BcmService } from '@modules/bcm/bcm.service';

@Component({
    selector: 'form-widget-building-cost-subsidy',
    templateUrl: './form-widget-building-cost-subsidy.component.html'
})
export class FormWidgetBuildingCostSubsidyComponent implements OnInit, OnDestroy {

    // Baukostenzuschuss

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    knownShortCodeList: string[] = [];

    contractBuildingCostSubsidyForm: UntypedFormGroup;

    captureBuildingCostSubsidy: boolean;

    products$: Observable<Product[]>;

    filteredProducts$: Observable<Product[]>;

    productControl = new UntypedFormControl();

    constructor(private _formBuilder: UntypedFormBuilder,
                private _matDialog: MatDialog,
                private _productsApiService: ProductsApiService,
                private _bcmService: BcmService) {
        this.contractBuildingCostSubsidyForm = this._formBuilder.group({
            BKZ_BETRAG_NETTO: [],
            BKZ_MWST: [],
            BKZ_MWST_BETRAG: [],
            BKZ_BETRAG_GESAMT_NETTO: [],
            BKZ_BETRAG_GESAMT_BRUTTO: [],
            BKZ_BETRAG_FAELLIG_ZUM: [],

            BKZ_TZ_1_BETRAG: [],
            BKZ_TZ_2_BETRAG: [],
            BKZ_TZ_1_FAELLIG_ZUM: [],
            BKZ_TZ_2_FAELLIG_ZUM: [],
        });

        this.productControl.valueChanges.subscribe((product: Product) => {
            if (!isString(product)) {
                this.contractBuildingCostSubsidyForm.patchValue({
                    BKZ_BETRAG_NETTO: product.price,
                    BKZ_MWST: product.taxRate.value,
                    BKZ_MWST_BETRAG: 0, // todo: !!! product.grossPrice - product.netPrice,
                    BKZ_BETRAG_GESAMT_NETTO: product.price,
                    BKZ_BETRAG_GESAMT_BRUTTO: product.price,
                });
            }
        });

        this.filteredProducts$ = this.productControl.valueChanges
            .pipe(
                startWith(this.products$),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((value) => isString(value) ? this._filterProducts(value) : this.products$)
            );

        this._loadProducts();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('contractBuildingCostSubsidyForm', this.contractBuildingCostSubsidyForm);
        });
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('contractBuildingCostSubsidyForm');
    }

    public displayProductWith(product: IProduct): string {
        return product ? product.name : '';
    }

    private _filterProducts(name = ''): Observable<Product[]> {
        return this.products$
            .pipe(map((products: Product[]) => Product.filterProducts(products, name)));
    }

    private _loadProducts(): void {
        this.products$ = this._productsApiService.getAll();
    }
}
