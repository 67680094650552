import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ParamMap, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { IProduct, Product } from '@shared/models/product';
import { Person } from '@shared/models/person';
import { ICategory } from '@shared/models/category';
import { PayableOption } from '@shared/models/payable-option';
import { IUnit } from '@shared/models/unit';
import { BcmApiService } from '@bcmApiServices/bcm-api.service';
import { map } from 'rxjs/operators';
import { ProductCategoryApiService } from '@bcmApiServices/product-category.api-service';
import { ITaxRate } from '@shared/models/tax-rate';
import BigNumber from 'bignumber.js';
import { BcmDynamicPrice } from '@shared/models/bcm-dynamic-price';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ProductService implements Resolve<any> {
    paramMap: ParamMap;
    product: Product;
    categories: ICategory[];
    units: IUnit[];
    payableOptions: PayableOption[];
    taxRates: ITaxRate[];

    onProductChanged: BehaviorSubject<Product> = new BehaviorSubject(null);
    onCategoriesChanged: BehaviorSubject<ICategory[]> = new BehaviorSubject(null);
    onUnitsChanged: BehaviorSubject<IUnit[]> = new BehaviorSubject(null);
    onPayableOptionsChanged: BehaviorSubject<PayableOption[]> = new BehaviorSubject(null);
    onTaxRatesChanged: BehaviorSubject<ITaxRate[]> = new BehaviorSubject(null);

    constructor(
        private _apiService: BcmApiService,
        private _productCategoryApiService: ProductCategoryApiService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        this.paramMap = route.paramMap;

        return combineLatest([
            this.getProduct(),
            this.getProductCategories(),
            this.getUnits(),
            this.getPayableOptions(),
            this.getTaxRates(),
        ]);
    }

    getProduct(): Observable<any> {
        if (this.paramMap.get('id') === 'new') {
            this.onProductChanged.next(null);
            return of(null);
        }

        return this._apiService.get<IProduct>(`products/${this.paramMap.get('id')}`)
            .pipe(
                map((product: IProduct) => {
                    this.product = new Product(product);
                    this.onProductChanged.next(this.product);
                    return this.product;
                })
            );
    }

    getProductCategories(): Observable<ICategory[]> {
        return this._productCategoryApiService.getAll()
            .pipe(
                map((categories: ICategory[]) => {
                    this.categories = categories;
                    this.onCategoriesChanged.next(categories);
                    return this.categories;
                })
            );
    }

    getUnits(): Observable<IUnit[]> {
        return this._apiService.get<IUnit[]>(`products/units`)
            .pipe(
                map((units: IUnit[]) => {
                    this.units = units;
                    this.onUnitsChanged.next(units);
                    return this.units;
                })
            );
    }

    getPayableOptions(): Observable<PayableOption[]> {
        return this._apiService.get<PayableOption[]>(`products/payable-options`)
            .pipe(
                map((payableOptions: PayableOption[]) => {
                    this.payableOptions = payableOptions;
                    this.onPayableOptionsChanged.next(payableOptions);
                    return this.payableOptions;
                })
            );
    }

    getTaxRates(): Observable<ITaxRate[]> {
        return this._apiService.get<ITaxRate[]>(`products/tax-rates`)
            .pipe(
                map((taxRates: ITaxRate[]) => {
                    this.taxRates = taxRates;
                    this.onTaxRatesChanged.next(taxRates);
                    return this.taxRates;
                })
            );
    }

    updateProduct(product: Product): Observable<any> {
        return this._apiService.put<any>(`products/${product.id}`, product);
    }

    addProduct(product: Product): Observable<any> {
        return this._apiService.post<any>(`products`, product);
    }

    deleteProduct(product: Product): Observable<any> {
        return this._apiService.delete<Person>(`products/${product.id}`);
    }

    getNetPrice(grossPrice: number, taxRate: number, decimalPlaces = 2): number {
        // taxRate >= 0 <= 100
        // = grossPrice / ((taxRate / 100) + 1)
        return new BigNumber(grossPrice)
            .dividedBy(new BigNumber(taxRate).dividedBy(100).plus(1))
            .decimalPlaces(decimalPlaces)
            .toNumber();
    }

    getGrossPrice(netPrice: number, taxRate: number, decimalPlaces = 2): number {
        // taxRate >= 0 <= 100
        // = net * ((taxRate / 100) + 1)
        return new BigNumber(netPrice)
            .multipliedBy(new BigNumber(taxRate).dividedBy(100).plus(1))
            .decimalPlaces(decimalPlaces)
            .toNumber();
    }

    updateOpenInvoicePositions(product: Product, prevProduct: Product): Observable<{
        countPersons: number,
        countCompanies: number
    }> {
        return this._apiService.post(`products/${product.id}/update-invoice-positions`, prevProduct);
    }

    updateDynamicPriceOpenInvoicePositions(product: Product): Observable<{
        countPersons: number,
        countCompanies: number
    }> {
        return this._apiService.post(`products/${product.id}/dynamic-price/update-invoice-positions`);
    }

    evaluatePriceRule(product: Product,
                      parameters: any,
                      quantity: number,
                      boatDimensions?: { width: number, length: number }): Observable<BcmDynamicPrice> {

        let params = new HttpParams();
        for (const key in parameters) {
            if (parameters[key]?.id) {
                params = params.append(`${key}Id`, parameters[key].id);
            } else {
                params = params.append(key, parameters[key]);
            }
        }
        params = quantity ? params.append('quantity', quantity) : params;

        if (boatDimensions) {
            params = params.append('boatLength', boatDimensions.length);
            params = params.append('boatWidth', boatDimensions.width);
        }

        return this._apiService.get(`products/${product.id}/dynamic-price/evaluate-price-rules`, params);
    }
}
