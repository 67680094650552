import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslationService } from '@core/translation/translation.service';
import { Boat } from '@shared/models/boat';
import { TenantRelation } from '@shared/models/tenant-relation';
import { BcmCostCenter } from '@shared/models/bcm-cost-center';
import { roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { ProduktQuantityButton } from '@shared/models/product-quantity-button';
import { BcmInvoicePositionsService } from '@bcmServices/invoice-positions.service';
import { Product } from '@shared/models/product';
import { Company } from '@shared/models/company';
import { Person } from '@shared/models/person';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InvoiceFormComponent } from '@modules/bcm/accounting/invoices/invoice-form/invoice-form.component';
import { ProductTaxRateApiService, ProductUnitApiService } from '@modules/bcm/@shared/services';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { ProductsFacade } from '@modules/bcm/@core/state-management/products/products.facade';
import { ITaxRate } from '@shared/models/tax-rate';
import { IUnit } from '@shared/models/unit';
import { BcmSettings } from '@shared/models/bcm-settings';
import { BcmBookingTraveler } from '@shared/models/bcm-booking';
import { GetProductSubscriptionDatesDialogComponent } from '@sharedComponents/dialogs/get-product-subscription-dates-dialog/get-product-subscription-dates-dialog.component';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { ProductSubscription } from '@shared/models/product-subscription';

@Component({
    selector: 'form-widget-positions',
    templateUrl: './form-widget-positions.component.html',
    styleUrls: ['./form-widget-positions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormWidgetPositionsComponent {

    @Input()
    headline = TranslationService.translate('positions');

    @Input()
    readonly = false;

    @Input()
    showAddBerthPositionButton = false;

    @Input()
    fromDate?: Date;

    @Input()
    toDate?: Date;

    @Input()
    givenCostCenter?: BcmCostCenter;

    @Input()
    boat: Boat;

    @Input()
    person: Person;

    @Input()
    company: Company;

    @Input()
    tenantRelation: TenantRelation;

    @Input()
    travelers: BcmBookingTraveler[];

    @Input()
    subscriptions: ProductSubscription[];

    @Output()
    subscriptionsChanged: EventEmitter<ProductSubscription[]> = new EventEmitter<ProductSubscription[]>();

    @Input()
    set berthAssignments(value: BerthBoatAssignment[]) {
        this._berthAssignments = value;

        this.fromDate = new Date(Math.min(...this._berthAssignments.map(a => a.from?.getTime())));

        if (this._berthAssignments.some(a => !a.to)) {
            this.toDate = null;
        } else {
            this.toDate = new Date(Math.max(...this._berthAssignments.map(a => a.to?.getTime())));
        }

    }

    get berthAssignments(): BerthBoatAssignment[] {
        return this._berthAssignments;
    }

    private _berthAssignments: BerthBoatAssignment[];

    @Input()
    set positions(value: InvoicePosition[]) {
        this._positions = value.map(position => {
            let totalPrice = position.price * position.quantity || 0;
            totalPrice = totalPrice - (totalPrice / 100 * (position.discountPercentage || 0));

            return {
                ...position,
                berthAssignmentIndex: position.fromTenantRelation ? null : this._getBerthAssignmentIndex(position.berthAssignmentUuid),
                totalPrice
            };
        });

        this._initialPositions = this._positions
            .map(position => new InvoicePosition({...position} as unknown as InvoicePositionDto));

        this.updateSelected();
    }

    get positions(): InvoicePosition[] {
        return this._positions;
    }

    private _initialPositions: InvoicePosition[];

    @Output()
    positionsChanged: EventEmitter<InvoicePosition[]> = new EventEmitter<InvoicePosition[]>();

    @Output()
    invoiceCreated: EventEmitter<void> = new EventEmitter<void>();

    colspan = 8;

    positionsSum: number;

    anyPositionSelected = false;
    allPositionsSelected = false;
    noPositionsAvailable = false;
    allPositionsPaid = false;
    unsavedPositions = false;

    payPositionTooltip: string;

    settings: BcmSettings;
    products: Product[] = [];
    units: IUnit[] = [];
    taxRates: ITaxRate[] = [];

    private _positions: InvoicePosition[] = [];

    constructor(
        private _bcmInvoicePositionsService: BcmInvoicePositionsService,
        private _dialog: MatDialog,
        private _confirmDialogService: ConfirmDialogService,
        private _settingsFacade: BcmSettingsFacade,
        private _productsFacade: ProductsFacade,
        private _productUnitApiService: ProductUnitApiService,
        private _productTaxRateApiService: ProductTaxRateApiService,
        private _cdRef: ChangeDetectorRef
    ) {
        this._settingsFacade.settings$
            .pipe(take(1))
            .subscribe(settings => this.settings = settings);

        this._productsFacade.loadList()
            .pipe(take(1))
            .subscribe(products => this.products = products);

        this._productUnitApiService.getAll()
            .pipe(take(1))
            .subscribe(units => this.units = units);

        this._productTaxRateApiService.getAll()
            .pipe(take(1))
            .subscribe(taxRates => this.taxRates = taxRates);

    }

    addBerthInvoicePosition(): void {

        const quantityButtons: ProduktQuantityButton[] = [];

        for (let i = 0; i < (this.berthAssignments || []).length; i++) {

            const berthAssignment = this.berthAssignments[i];
            const berth = berthAssignment.berth;

            if (berth?.length && berth?.width) {
                const quantity = roundNumber(berth.length * berth.width, RoundNumberFactor.TwoDecimals);
                quantityButtons.push({
                    label: `Belegung #${i + 1} - Fläche Liegeplatz: ${quantity} m²`,
                    quantity
                });
            }
        }

        if (this.boat) {
            let quantity = 0;

            if (this.boat?.sqm) {
                quantity = this.boat?.sqm;
            } else if (this.boat.length && this.boat.width) {
                quantity = this.boat.length * this.boat.width;
            }

            quantity = roundNumber(quantity, RoundNumberFactor.TwoDecimals);

            if (quantity) {
                quantityButtons.push({
                    label: `Fläche Boot: ${quantity} m²`,
                    quantity
                });
            }
        }

        this._bcmInvoicePositionsService
            .getInvoicePosition(
                {
                    person: this.person,
                    company: this.company,
                    boat: this.boat,
                    preselection: {
                        'boat': this.boat,
                        'tenantRelation': this.tenantRelation
                    },
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    travelers: this.travelers?.length,
                },
                (product: Product) => {
                    return product.category?.uniqueId === 'Liegeplatzgebühr' || product.category?.parentCategory?.uniqueId === 'Liegeplatzgebühr';
                },
                quantityButtons,
                this.fromDate,
                this.toDate,
                this.berthAssignments,
                this.givenCostCenter
            )
            .pipe(take(1))
            .subscribe(invoicePositions => {
                if (invoicePositions) {
                    this._positions.push(...invoicePositions);
                    this.positionsChanged.emit(this._positions);
                    this.updateSum();
                }
            });
    }

    showSubscriptionDialog(position: InvoicePosition): void {

        const dialogRef = this._dialog.open(
            GetProductSubscriptionDatesDialogComponent,
            {
                data: {
                    initialFromDate: position.vestingPeriodFromDate,
                }
            }
        );

        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe((result) => {
                if (result) {
                    const productSubscription: ProductSubscription = new ProductSubscription({
                        product: position.product,
                        quantity: position.quantity,
                        dynamicPrice: position.dynamicPrice,
                        ...result
                    });

                    this.subscriptions.push(productSubscription);
                    this.subscriptionsChanged.emit(this.subscriptions);
                }
            });

    }

    addInvoicePosition(): void {
        this._bcmInvoicePositionsService
            .getInvoicePosition(
                {
                    person: this.person,
                    company: this.company,
                    boat: this.boat,
                    preselection: {
                        'boat': this.boat,
                        'tenantRelation': this.tenantRelation
                    },
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    travelers: this.travelers?.length
                },
                this.showAddBerthPositionButton
                    ? (product: Product) => {
                        return product.category?.uniqueId !== 'Liegeplatzgebühr' && product.category?.parentCategory?.uniqueId !== 'Liegeplatzgebühr';
                    }
                    : undefined,
                [],
                this.fromDate,
                this.toDate,
                this.berthAssignments,
                this.givenCostCenter
            )
            .subscribe(invoicePositions => {
                if (invoicePositions?.length > 0) {

                    let positionAdded = false;

                    function _addPosition(invoicePosition: InvoicePosition, positions: InvoicePosition[]): void {
                        positions.push(invoicePosition);
                        positionAdded = true;
                    }

                    for (const invoicePosition of invoicePositions) {
                        if (!invoicePosition.vestingPeriodUntilDate) {
                            this._confirmDialogService.setTitle('Abo erstellen')
                                .setBody(`Die Position "${invoicePosition.product.name}" hat kein Leistungszeitraum-Ende. Möchten Sie ein Abo erstellen?`)
                                .setYesButton({
                                    text: 'Ja',
                                })
                                .setNoButton({
                                    text: 'Nein',
                                })
                                .openWithCallback(
                                    () => this.showSubscriptionDialog(invoicePosition),
                                    () => _addPosition(invoicePosition, this._positions),
                                    () => _addPosition(invoicePosition, this._positions)
                                );
                        } else {
                            _addPosition(invoicePosition, this._positions);
                        }
                    }

                    if (positionAdded) {
                        this.positionsChanged.emit(this._positions);
                        this.updateSum();
                    }
                }
            });
    }

    editInvoicePosition(invoicePosition: InvoicePosition, index: number): void {
        const berthAssignments =
            (invoicePosition.fromTenantRelation || invoicePosition.tenantRelationAssignment)
                ? []
                : this.berthAssignments;

        this._bcmInvoicePositionsService
            .editInvoicePosition(invoicePosition, berthAssignments)
            .subscribe(positions => {
                if (positions.length > 0) {
                    this._positions[index] = positions[positions.findIndex(p => p.positionId !== null)];
                    this._positions.push(...positions.filter(p => p.positionId === null));
                    this.positionsChanged.emit(this._positions);
                    this.updateSum();
                }
            });
    }

    removeInvoicePosition(index: number): void {
        // https://trello.com/c/85UpeSaY/4389-feature-gr-liegeplan-produkte-l%C3%B6schen-weitere-meldung-l%C3%B6schen-herausnehmen-4389
        this.positions[index].deleted = true;
        this.positionsChanged.emit(this.positions);
        this.updateSum();
    }

    positionQuantityChanged(): void {
        const positionsChanged = this.positions.some((position, index) => {
            if (this._initialPositions[index] === undefined) {
                return true;
            }
            const initialPosition = this._initialPositions[index];
            return position.quantity !== initialPosition.quantity;
        });

        if (positionsChanged) {
            this.positionsChanged.emit(this.positions);
        }
    }

    onDrop(event: CdkDragDrop<InvoicePosition[]>): void {
        moveItemInArray(this.positions, event.previousIndex, event.currentIndex);
        // this.positions.forEach((invoicePosition, idx) => {
        //     invoicePosition.order = idx + 1; // todo: add order to DB for each invoice position
        // });
    }

    updateSelected(): void {
        this.anyPositionSelected = this.positions.some(p => p.selected);

        this.noPositionsAvailable = this.positions.every(p => p.isPaid || p.deleted || !p.id);

        this.allPositionsSelected = this.positions
            .filter(position => !position.deleted && !position.isPaid && position.id)
            .every(p => p.selected);

        this.allPositionsPaid = this.positions.every(p => p.isPaid || p.deleted);

        this.unsavedPositions = this.positions.some(p => !p.id);

        if (this.noPositionsAvailable) {
            this.payPositionTooltip = TranslationService.translate('noPositionsAvailable');
            if (this.unsavedPositions) {
                this.payPositionTooltip += ' - ' + TranslationService.translate('unsavedPositions');
            }
        } else if (this.unsavedPositions) {
            this.payPositionTooltip = TranslationService.translate('unsavedPositions');
        } else if (this.allPositionsPaid) {
            this.payPositionTooltip = TranslationService.translate('allPositionsPaid');
        } else if (!this.anyPositionSelected) {
            this.payPositionTooltip = TranslationService.translate('noPositionSelected');
        } else {
            this.payPositionTooltip = null;
        }
    }

    openInvoiceForm(): void {

        const dialog = this._dialog.open(InvoiceFormComponent, {
            data: {
                positions: this.positions.filter(p => p.selected),
                person: this.person,
                company: this.company,
                invoiceFormData: {
                    settings: this.settings,
                    products: this.products,
                    units: this.units,
                    taxRates: this.taxRates
                }
            },
            disableClose: true,
            panelClass: ['invoice-form-dialog'],
        });

        dialog.afterClosed()
            .pipe(take(1))
            .subscribe((response) => {
                if (response) {
                    this.invoiceCreated.emit();
                }
            });

    }

    updateSum(onlySum = false): void {
        this.positionsSum = (this.positions || [])
            .filter(position => !position.deleted)
            .reduce((accumulator: number, invoicePosition) => {
                return accumulator + invoicePosition.totalPrice;
            }, 0);

        this._cdRef.detectChanges();
    }

    updateSelectAllPositions(): void {

        if (this.readonly) {
            return;
        }

        this.allPositionsSelected = !this.allPositionsSelected;

        if (!this.allPositionsSelected) {
            this.positions.forEach(position => position.selected = false);
        } else {
            this.positions
                .filter(position => !position.deleted && !position.isPaid && position.id)
                .forEach(position => position.selected = true);
        }

        this.updateSelected();
    }

    private _getBerthAssignmentIndex(berthAssignmentUuid: string): number {
        return (this.berthAssignments || [])
            .filter(assignment => !assignment.deleted)
            .findIndex(assignment => assignment.uuid === berthAssignmentUuid);
    }

}
