<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ dialogTitle }}</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <div fxLayout="row" fxLayoutAlign="start start">
                <app-input-select-person [control]="formGroup.get('person')"></app-input-select-person>
            </div>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button data-test="B-Übernehmen" mat-flat-button
                (click)="save()"
                class="save-button mat-accent"
                [disabled]="isSaving || !formGroup.dirty"
                aria-label="Speichern">
            Übernehmen
        </button>

    </div>
</div>
