import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { RealBaseStateService } from '@bcmServices/real-base-state.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BcmDocument } from '@shared/models/bcm-document';
import { CompanyApiService } from '@modules/bcm/@shared/services';
import { Company } from '@shared/models/company';
import { PageRequest } from '@shared/models/pagination';

@Injectable({providedIn: 'root'})
export class BcmCompaniesFacade extends CompanyApiService {

    private readonly state = new RealBaseStateService<Company>(Company);

    get latestPageRequest(): PageRequest<Company> {
        return this.state.latestPageRequest;
    }

    set latestPageRequest(value: PageRequest<Company>) {
        this.state.latestPageRequest = value;
    }

    get companies$(): Observable<Company[]> {
        return this.state.list$;
    }

    get selectedCompany$(): Observable<Company> {
        return this.state.selected$;
    }

    get loadingAll$(): Observable<boolean> {
        return this.state.isUpdatingAll$;
    }

    get loading$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    constructor(_matDialog: MatDialog,
                _httpClient: HttpClient,
                _bcmService: BcmService) {
        super(_matDialog, _httpClient, _bcmService);
    }

    private _loadAll(httpParams?: HttpParams): Observable<Company[]> {
        this.state.startUpdatingAll();
        return this.getAll(httpParams);
    }

    loadAll(httpParams?: HttpParams): Subscription {
        this.state.startUpdatingAll();
        return this._loadAll(httpParams)
            .pipe(
                tap(companies => {
                    this.state.setList(companies);
                    this.state.stopUpdatingAll();
                }),
            ).subscribe();
    }

    loadOne(id: number): Observable<Company> {
        this.state.startUpdating();
        return this.getOne(id)
            .pipe(
                tap(company => {
                        this.state.setSelected(company);
                        this.state.stopUpdating();
                    }
                ),
                switchMap(() => this.selectedCompany$)
            );
    }

    addCompany(tmpCompany: Company): Observable<Company> {

        tmpCompany.id = -1;

        this.state.startUpdating();
        this.state.addListItem(tmpCompany);

        return this.add(tmpCompany)
            .pipe(
                map(id => {
                    return {
                        ...tmpCompany,
                        id: id
                    } as Company;
                }),
                tap((company) => {
                    this.state.replaceListItem(tmpCompany, company);
                    this.state.stopUpdating();
                }),
                catchError((error: HttpErrorResponse) => {
                    this.state.removeListItem(tmpCompany);
                    return throwError(error);
                })
            );

        // return throwError('Bitte überprüfe Deine Angaben.');
    }

    updateCompany(company: Company, companyData: Partial<Company>): Observable<any> {
        if (company?.id) {

            this.state.startUpdating();

            const updatedCompany = {
                ...company,
                ...companyData
            } as Company;

            this.state.replaceListItem(company, updatedCompany); // optimistic change

            return this.update(company.id, companyData)
                .pipe(
                    tap(() => this.state.stopUpdating()),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(updatedCompany, company); // revert
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    removeCompany(company: Company): Observable<any> {

        if (company?.id) {

            this.state.removeListItem(company);

            return this.remove(company.id)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.state.addListItem(company);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    addCompanyDocument(company: Company, tmpDocument: BcmDocument): Observable<BcmDocument> {

        if (company?.id && tmpDocument?.title?.length) {

            tmpDocument.id = -1;

            this.state.startUpdating();

            const tmpCompany = {...company, documents: [...company.documents, tmpDocument]} as Company;

            this.state.replaceListItem(company, tmpCompany);

            return this.addDocument(company.id, tmpDocument)
                .pipe(
                    tap((document) => {
                        const newCompany = {...company, documents: [...company.documents, document]} as Company;
                        this.state.replaceListItem(tmpCompany, newCompany);
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(tmpCompany, company);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    updateCompanyDocument(company: Company, tmpDocument: BcmDocument, documentData: Partial<BcmDocument>): Observable<any> {
        if (company?.id && tmpDocument?.title?.length) {

            this.state.startUpdating();

            const updatedDocument = {
                ...tmpDocument,
                ...documentData
            } as BcmDocument;

            this.state.startUpdating();

            const index = company.documents.findIndex(doc => doc.id === tmpDocument.id);

            if (index === -1) {
                return throwError('Bitte überprüfe Deine Angaben.');
            }

            const tmpDocuments = [...company.documents];
            tmpDocuments.splice(index, 1, updatedDocument);

            const tmpCompany = {...company, documents: tmpDocuments} as Company;

            this.state.replaceListItem(company, tmpCompany);

            return this.updateDocument(company.id, tmpDocument, documentData)
                .pipe(
                    tap((document) => {
                        company.documents.splice(index, 1, document);
                        this.state.replaceListItem(tmpCompany, company);
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(tmpCompany, company);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    removeCompanyDocument(company: Company, document: BcmDocument): Observable<any> {

        if (company?.id && document?.id) {

            const newCompany = {
                ...company,
                documents: company.documents.filter(doc => doc.id !== document.id)
            } as Company;

            this.state.replaceListItem(company, newCompany);

            return this.deleteDocument(company.id, document.id)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(newCompany, company);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

}
