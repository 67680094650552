import { Component, Inject, OnDestroy, Optional, ViewEncapsulation } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ConfirmDialogService } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.service';
import { BcmWorkflowApiService } from '@bcmApiServices/bcm-workflow-api.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Contract, ContractFileType } from '@shared/models/contract';
import { Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { Boat } from '@shared/models/boat';
import { isString } from '@shared/functions/is-string';
import { U2bValidators } from '@shared/validators/validators';
import { BcmContractsStoreService } from '@bcmServices/contracts/bcm-contracts-store.service';
import { contractShortCodeEntries } from '@modules/bcm/@shared/enums/contract-short-codes';
import { findKnownShortcodes, findUnknownShortcodes } from '@shared/functions/find-short-codes';
import { missingContractFieldsPerson } from '@bcm-work-flows/contract-creator/missing-contract-fields-person';
import { BcmService } from '@modules/bcm/bcm.service';
import { missingContractFieldsCompany } from '@bcm-work-flows/contract-creator/missing-contract-fields-company';
import { Key } from '@shared/models/key';
import { Berth } from '@shared/models/berth';
import { faFileWord } from '@fortawesome/free-solid-svg-icons';
import { BcmBooking } from '@shared/models/bcm-booking';

@Component({
    selector: 'contract-creator',
    templateUrl: './contract-creator.component.html',
    styleUrls: ['./contract-creator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContractCreatorComponent implements OnDestroy {

    faFileWord = faFileWord;

    private _unsubscribeAll = new Subject<any>();

    public ContractFileType: typeof ContractFileType = ContractFileType;

    formGroup: UntypedFormGroup;

    availableContracts: (Contract & {group: string})[];

    loadingContracts = false;

    availableBoats: Boat[];

    availableBerths: Berth[];

    selectedContract: Contract;

    person: Person;

    company: Company;

    foundShortCodes = {};

    knownShortCodeList: string[] = [];

    unknownShortCodeList: string[] = [];

    unknownShortCodeForm: UntypedFormGroup;

    hasCoOwner: boolean;

    hasBkz: boolean;

    hasProducts: boolean;

    hasBoat: boolean;

    hasBerth: boolean;

    hasKey: boolean;

    contractTemplateMissing: boolean;

    isSaving: boolean;

    missingFields: {
        tenant: string[],
        person?: string[],
        company?: string[],
        boat: string[],
        berth: string[],
    } = {
        tenant: [],
        person: [],
        company: [],
        boat: [],
        berth: [],
    };

    constructor(private _dialogRef: MatDialogRef<ContractCreatorComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: {
                    booking?: BcmBooking,
                    person?: Person,
                    company?: Company,
                    boat?: Boat,
                },
                private _appNotificationService: AppNotificationService,
                private _confirmDialogService: ConfirmDialogService,
                private _bcmWorkflowApiService: BcmWorkflowApiService,
                private _bcmContractsStoreService: BcmContractsStoreService,
                private _bcmService: BcmService,
                private _formBuilder: UntypedFormBuilder) {
        this._bcmContractsStoreService.fetchAll();
        this._dialogRef.addPanelClass('default-dialog');
        this._dialogRef.disableClose = true;

        this.formGroup = this._formBuilder.group({
            contract: [null, [U2bValidators.required('Bitte wähle eine Vertragsvorlage aus')]],
            person: [],
            company: [],
            boat: [],
            berth: [],
            contractCoOwner: [],
            sendMail: [false],
            downloadPdf: [true],
            contractFileType: [ContractFileType.MAIL],
            key: [],
        });

        let {person, company, boat} = data;

        if (data.booking) {
            person = data.booking.person;
            company = data.booking.company;
            boat = data.booking.boat;
        }

        let tenantRelationIds: number[] = [];
        let boats: Boat[];

        if (person) {

            this.formGroup.patchValue({person, company: null}, {emitEvent: false});

            ({boats} = person);
            tenantRelationIds = person.activeAndNextTenantRelationAssignments.map(tr => tr.tenantRelation.id);
        }

        if (company) {
            this.formGroup.patchValue({company, person: null}, {emitEvent: false});

            ({boats} = company);
            tenantRelationIds = company.activeAndNextTenantRelationAssignments.map(tr => tr.tenantRelation.id);
        }

        if (boat) {
            // needs to be done after person and company
            boats = [boat];
        }

        if (boats?.length) {
            this.availableBoats = boats;

            if (boats?.length === 1) {
                const singleBoat: Boat = boats[0];

                this.formGroup.patchValue({
                    boat: singleBoat,
                });

                this.availableBerths = singleBoat.berthAssignments?.map(ba => ba.berth);

                if (this.availableBerths?.length === 1) {
                    this.formGroup.patchValue({
                        berth: this.availableBerths[0]
                    });
                }
            }
        }

        this.person = person;
        this.company = company;

        this.formGroup.get('boat').valueChanges.subscribe(selectedBoat => {
            this.availableBerths = selectedBoat.berthAssignments?.map(ba => ba.berth);

            if (this.availableBerths?.length === 1) {
                this.formGroup.patchValue({
                    berth: this.availableBerths[0]
                });
            }

            this.missingFields = data?.person
                ? missingContractFieldsPerson(
                    this.knownShortCodeList,
                    this._bcmService.tenant,
                    this.person,
                    selectedBoat || (this.availableBoats || {})[0],
                    this.formGroup.get('berth').value || (this.availableBerths || {})[0],
                )
                : missingContractFieldsCompany(
                    this.knownShortCodeList,
                    this._bcmService.tenant,
                    this.company,
                    selectedBoat || (this.availableBoats || {})[0],
                    this.formGroup.get('berth').value || (this.availableBerths || {})[0],
                );
        });

        this.formGroup.get('berth').valueChanges.subscribe((berth) => {
            this.missingFields = data?.person
                ? missingContractFieldsPerson(
                    this.knownShortCodeList,
                    this._bcmService.tenant,
                    this.person,
                    this.formGroup.get('boat').value || (this.availableBoats || {})[0],
                    berth || (this.availableBerths || {})[0],
                )
                : missingContractFieldsCompany(
                    this.knownShortCodeList,
                    this._bcmService.tenant,
                    this.company,
                    this.formGroup.get('boat').value || (this.availableBoats || {})[0],
                    berth || (this.availableBerths || {})[0],
                );
        });

        this._bcmContractsStoreService.contracts$.subscribe(contracts => {
            this.availableContracts = contracts
                .map(contract => {

                    let group = 'Weitere Verträge';

                    if ((contract.tenantRelations || []).find(tr => tenantRelationIds.includes(tr.id))) {
                        group = 'Passend zu gew. Beziehung(en)';
                    }

                    return {...contract, group};
                });
        });

        this.formGroup.get('contract').valueChanges.subscribe(contract => {
            this.selectedContract = contract;
            this.contractTemplateMissing = contract?.isWordTemplate && !contract?.templateMailFile && !contract?.templatePrintFile;

            this.foundShortCodes = {};

            contractShortCodeEntries.forEach(entry => this.foundShortCodes[entry[1]] = !!contract.content.includes(entry[1]));

            this.unknownShortCodeList = findUnknownShortcodes(contract?.content);
            this.unknownShortCodeForm = new UntypedFormGroup({});

            for (const item of this.unknownShortCodeList) {
                this.unknownShortCodeForm.addControl(item, new UntypedFormControl());
            }

            // todo: find all shortcodes which are not possible to fill because of missing data
            this.knownShortCodeList = findKnownShortcodes(contract?.content);
            this.hasCoOwner = !!this.knownShortCodeList.find(shortCode => !!shortCode.includes('VMI_'));
            this.hasBkz = !!this.knownShortCodeList.find(shortCode => !!shortCode.includes('BKZ_'));
            this.hasProducts = !!this.knownShortCodeList.find(shortCode => !!shortCode.includes('PRODUKT'));
            this.hasBoat = !!this.knownShortCodeList.find(shortCode => !!shortCode.includes('BOOT_'));
            this.hasBerth = !!this.knownShortCodeList.find(shortCode => !!shortCode.includes('LP_'));
            this.hasKey = !!this.knownShortCodeList.find(shortCode => !!shortCode.includes('SCHLUESSEL_NUMMER'));

            this.missingFields = data?.person
                ? missingContractFieldsPerson(
                    this.knownShortCodeList,
                    this._bcmService.tenant,
                    this.person,
                    this.formGroup.get('boat').value || (this.availableBoats || {})[0],
                    this.formGroup.get('berth').value || (this.availableBerths || {})[0]
                )
                : missingContractFieldsCompany(
                    this.knownShortCodeList,
                    this._bcmService.tenant,
                    this.company,
                    this.formGroup.get('boat').value || (this.availableBoats || {})[0],
                    this.formGroup.get('berth').value || (this.availableBerths || {})[0]
                );
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickClose(): void {

        if (this.formGroup.dirty) {
            return this._confirmDialogService
                .useWarnTheme()
                .setTitle('Achtung')
                .setBody('Alle bislang eingegebenen Daten werden nicht gespeichert. Trotzdem beenden?')
                .setYesButton({color: 'warn', text: 'Ja, beenden'})
                .setNoButton({color: 'accent', text: 'Abbrechen'})
                .openWithCallback(() => this._dialogRef.close());
        }

        this._dialogRef.close(false);
    }

    onClickSaveAndClose(preview = false): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
        } else {

            this.isSaving = true;

            const {
                person,
                company,
                boat,
                berth,
                contract,
                sendMail,
                downloadPdf,
                contractFileType,
                dateRangeForm,
                boatForm,
                signatureForm,
                contractCoOwnerForm,
                contractBuildingCostSubsidyForm,
                productsForm,
            } = this.formGroup.getRawValue();

            const unknownShortCodes = this.unknownShortCodeForm.getRawValue();

            const body: any = {
                preview,
                bcm_bookings_id: this.data?.booking?.id,
                person,
                company,
                boat,
                berth,
                contract,
                sendMail,
                downloadPdf,
                contractFileType: ContractFileType[contractFileType],
                fromDate: dateRangeForm.from,
                toDate: dateRangeForm.to,
                contractCoOwnerForm,
                contractBuildingCostSubsidyForm,
                productsForm,
                unknownShortCodes
            };

            if (!boat && boatForm) {
                let theBoat: any = {};

                if (boatForm.boat && boatForm.boat.id) {
                    theBoat.id = boatForm.boat.id;
                } else {
                    delete boatForm.boat;
                    theBoat = {...boatForm};
                }

                body['boat'] = theBoat;
            }

            if (signatureForm?.sign === true) {
                body['sign'] = signatureForm;
            }

            this._bcmWorkflowApiService
                .contractCreation(body, this._appNotificationService)
                .add(() => {
                    this.isSaving = false;

                    if (!preview) {
                        this._dialogRef.close(true);
                    }
                });
        }
    }

    compareBoats(b1: Boat, b2: Boat): boolean {
        return b1?.id === b2?.id;
    }

    compareBerth(b1: Berth, b2: Berth): boolean {
        return b1?.id === b2?.id;
    }

    compareKeys(k1: Key, k2: Key): boolean {
        return k1?.id === k2?.id;
    }
}
