import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { naturalSortMatTable } from '@shared/functions/natural-sort';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '../../constants';
import { mimeToFaIcon } from '@core/functions/mime-to-fa-icon';
import { BcmDocument } from '@shared/models/bcm-document';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { BcmFile } from '@shared/models/bcm-file';
import { FilesApiService } from '@modules/bcm/@shared/services';

@Component({
    selector: 'bcm-documents-table',
    templateUrl: './documents-table.component.html',
    styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit, OnChanges, OnDestroy {

    private _unsubscribeAll = new Subject();

    dataSource: MatTableDataSource<BcmDocument>;

    displayedColumns = ['icon', 'categoryName', 'title', 'documentIdent', 'dateOfExpiry', 'note', 'insertedOn', 'buttons'];

    permissionNames = BcmUserPermission;

    @Input()
    public documents: BcmDocument[];

    @Input()
    public showSearch = true;

    @Input() readonly = false;

    @Output()
    public deleteDocument = new EventEmitter<BcmDocument>();

    @Output()
    public downloadDocument = new EventEmitter<BcmDocument>();

    @Output()
    public editDocument = new EventEmitter<BcmDocument>();

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    @ViewChild('filter', {static: true})
    filter: ElementRef;

    mimeToFaIcon = mimeToFaIcon;

    faExclamationTriangle = faExclamationTriangle;

    constructor(private _filesApiService: FilesApiService) {
    }

    ngOnInit(): void {
        this._updateTable();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'documents': {
                        this._updateTable();
                    }
                }
            }
        }
    }

    showFile(file: BcmFile): void {
        if (file?.id) {
            this._filesApiService.getById(file.id).pipe(take(1)).subscribe(blob => {
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL, '_blank');
            });
        }
    }

    private _updateTable(): void {
        this.dataSource = new MatTableDataSource<BcmDocument>(this.documents);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortData = naturalSortMatTable;

        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }

                this.dataSource.filter = this.filter.nativeElement.value;
            });
    }

}
