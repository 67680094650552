import { differenceInMilliseconds, format } from '@core/date.facade';
import { ONE_DAY_IN_MS } from '@shared/constants/data';

export function getReadableDateRange(firstDate: Date, lastDate: Date): string {
        if (!firstDate) {
            return null;
        }

        if (!lastDate) {
            return format(firstDate, 'dd.MM.yyyy HH:mm').replace(' 00:00', '');
        }

        const hourly = differenceInMilliseconds(firstDate, lastDate) < ONE_DAY_IN_MS;
        const fromYear = firstDate.getFullYear();
        const fromMonth = format(firstDate, 'MM');
        const fromDay = format(firstDate, 'dd');
        const fromTime = (hourly) ? format(firstDate, 'HH:mm') : '';

        const untilYear = lastDate.getFullYear();
        const untilMonth = format(lastDate, 'MM');
        const untilDay = format(lastDate, 'dd');
        const untilTime = (hourly) ? format(lastDate, 'HH:mm') : '';

        const time = (hourly) ? ` - ${fromTime} bis ${untilTime} Uhr` : '';

        if (fromYear === untilYear) {

            if (fromMonth === untilMonth) {

                if (fromDay === untilDay) {

                    if (fromTime === untilTime) {
                        // time = time -> 01.01.1970 - 00:00 Uhr
                        return `${untilDay}.${untilMonth}.${untilYear}`;
                    }

                    // day = day -> 01.01.1970 - 00:00 bis 00:00 Uhr
                    return `${untilDay}.${untilMonth}.${untilYear}${time}`;
                }

                // month = month -> 01. bis 01.01.1970 - 00:00 bis 00:00 Uhr
                return `${fromDay}. bis ${untilDay}.${untilMonth}.${untilYear}${time}`;
            }

            // year = year -> 01.01. bis 01.02.1970 - 00:00 bis 00:00 Uhr
            return `${fromDay}.${fromMonth}. bis ${untilDay}.${untilMonth}.${untilYear}${time}`;
        }

        // year != year -> 01.01.1970 bis 01.02.1971 - 00:00 bis 00:00 Uhr
        return `${fromDay}.${fromMonth}.${fromYear} bis ${untilDay}.${untilMonth}.${untilYear}${time}`;

}
