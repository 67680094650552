import { InsertedLastUpdate } from '@shared/interfaces/inserted-updated';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { Pier, PierRaw } from '@shared/models/pier';
import { isWithinInterval } from 'date-fns';
import { IProduct, Product } from '@shared/models/product';
import { BcmCostCenter, BcmCostCenterDto } from '@shared/models/bcm-cost-center';
import { BcmLock, BcmLockDto } from '@shared/models/bcm-lock';

export enum BerthStatus {
    Free = 'free',
    FreeDueToAbsence = 'free-absence',
    Reserved = 'reserved',
    ReservedPartially = 'reserved-partially',
    Occupied = 'occupied',
    OccupiedPartially = 'occupied-partially',
    Locked = 'locked',
    LockedPartially = 'locked-partially',
}

export const berthStatusTranslationsDe = {
    [BerthStatus.Free]: 'frei',
    [BerthStatus.FreeDueToAbsence]: 'frei aufgrund Abwesenheit',
    [BerthStatus.Reserved]: 'reserviert',
    [BerthStatus.Occupied]: 'belegt',
    [BerthStatus.Locked]: 'gesperrt',
    [BerthStatus.Locked]: 'teilweise gesperrt',
};

export interface IBerth extends InsertedLastUpdate {
    id: number;
    handle: string;
    // pierHandle: string;
    length: number;
    width: number;
    maxDraft: number;
    boatType: string;
    status: BerthStatus;
    noMatch: boolean;
    noMatchLength: boolean;
    noMatchWidth: boolean;
    noMatchMaxDraft: boolean;
    noMatchBoatType: boolean;
    note?: string;
    assignments: IBerthBoatAssignment[];
    pier: PierRaw;
    isDoubleBox: boolean;
    isForeign: boolean;
    isLiftStation: boolean;
    isJetSki: boolean;
    isPier: boolean;
    isBuoy: boolean;
    forGuest: boolean;
    forLongTermResidents: boolean;
    multipleOverlappingBookingsAllowed: boolean;
    lat: number;
    long: number;
    maxCarryingCapacity: number;
    draftMeasuringStationUuid: string;
    draftMeasurementDifference: number;
    interimLockedFrom: string;
    interimLocked: string;
    interimLockedReason: string;
    isMostFree: boolean;
    matchFilter?: boolean;
    freeScore?: number;

    products: IProduct[];
    costCenter: BcmCostCenterDto;

    reservationEndsToday: boolean;
    reservationEndsWithinNext7Days: boolean;

    locks: BcmLock[];
}

export class Berth {
    id: number;
    handle: string;
    length: number;
    width: number;
    maxDraft: number;
    boatType: string;
    status: BerthStatus;
    statusIndex: number;
    statusText: string;
    noMatch: boolean;
    noMatchLength: boolean;
    noMatchWidth: boolean;
    noMatchMaxDraft: boolean;
    noMatchBoatType: boolean;
    note?: string;
    assignments: BerthBoatAssignment[] = [];
    activeAssignments: BerthBoatAssignment[] = [];
    futureAssignments: BerthBoatAssignment[] = [];
    pier: Pier;
    isDoubleBox: boolean;
    isForeign: boolean;
    isLiftStation: boolean;
    isJetSki: boolean;
    isPier: boolean;
    isBuoy: boolean;
    forGuest: boolean;
    forLongTermResidents: boolean;
    multipleOverlappingBookingsAllowed: boolean;
    lat: number;
    long: number;
    maxCarryingCapacity: number;
    _interimLocked: Date | null;
    get interimLocked(): Date | null {
        if (this._interimLocked && this._interimLocked > new Date()) {
            return this._interimLocked;
        }
        return null;
    }

    _interimLockedFrom: Date | null;
    get interimLockedFrom(): Date | null {
        if (this._interimLockedFrom) {
            return this._interimLockedFrom;
        }
        return null;
    }

     interimLockedReason: string;

    draftMeasuringStationUuid?: string;
    draftMeasurementDifference?: number;
    measuredRelativeDraft?: number;
    isMostFree: boolean;
    matchFilter?: boolean;
    freeScore?: number;

    // helpers
    pierHandle: string;
    boatName = '';
    boatLicensePlate = '';
    boatManufacturer = '';
    boatOwner = '';
    type = '';
    squareMeters: number;

    products: Product[];
    costCenter?: BcmCostCenter;
    reservationEndsToday?: boolean;
    reservationEndsWithinNext7Days?: boolean;

    uuid: string;

    locks: BcmLock[] = [];
    activeLocks: BcmLock[] = [];
    futureLocks: BcmLock[] = [];

    constructor(berth: IBerth = {} as IBerth) {
        this.id = berth.id || null;
        this.handle = berth.handle || null;
        this.pierHandle = berth.pier?.handle || null;
        this.length = berth.length || null;
        this.width = berth.width || null;
        this.maxDraft = berth.maxDraft || null;
        this.boatType = berth.boatType || null;
        this.note = berth.note || null;
        this.assignments = (berth.assignments || []).map(assignment => new BerthBoatAssignment(assignment));
        this.activeAssignments = this.getActiveAssignments();
        this.futureAssignments = this.getFutureAssignments();
        this.pier = berth.pier ? new Pier(berth.pier) : null;
        this.isDoubleBox = !!berth.isDoubleBox;
        this.isForeign = !!berth.isForeign;
        this.isLiftStation = !!berth.isLiftStation;
        this.isJetSki = !!berth.isJetSki;
        this.isPier = !!berth.isPier;
        this.isBuoy = !!berth.isBuoy;
        this.forGuest = !!berth.forGuest;
        this.forLongTermResidents = !!berth.forLongTermResidents;
        this.multipleOverlappingBookingsAllowed = !!berth.multipleOverlappingBookingsAllowed;
        this.lat = berth.lat;
        this.long = berth.long;
        this.maxCarryingCapacity = berth.maxCarryingCapacity;
        this._interimLocked = berth.interimLocked ? tryParseDate(berth.interimLocked) : null;
        this._interimLockedFrom = berth.interimLockedFrom ? tryParseDate(berth.interimLockedFrom) : null;
        this.interimLockedReason = berth.interimLockedReason;
        this.maxCarryingCapacity = berth.maxCarryingCapacity;
        this.type = this.getType();
        this.draftMeasuringStationUuid = berth.draftMeasuringStationUuid;
        this.draftMeasurementDifference = berth.draftMeasurementDifference;
        this.status = berth.status; // calculated in back end now

        this.locks =  (berth.locks || []).map(lock => new BcmLock(lock));
        this.activeLocks = this.getActiveLocks();
        this.futureLocks = this.getFutureLocks();

        this.squareMeters = this.length * this.width;

        // helper for sorting and labeling
        switch (this.status as BerthStatus) {
            case BerthStatus.Free:
                this.statusIndex = 0;
                break;
            case BerthStatus.FreeDueToAbsence:
                this.statusIndex = 1;
                break;
            case BerthStatus.Reserved:
                this.statusIndex = 2;
                break;
            case BerthStatus.Occupied:
                this.statusIndex = 3;
                break;
            case BerthStatus.Locked:
                this.statusIndex = 4;
                break;
        }

        this.statusText = berthStatusTranslationsDe[this.status];

        this.noMatch = !!berth.noMatch;
        this.noMatchLength = !!berth.noMatchLength;
        this.noMatchWidth = !!berth.noMatchWidth;
        this.noMatchMaxDraft = !!berth.noMatchMaxDraft;
        this.noMatchBoatType = !!berth.noMatchBoatType;
        this.isMostFree = !!berth.isMostFree;
        this.matchFilter = !!berth.matchFilter;
        if (this.matchFilter) {
            this.freeScore = berth.freeScore;
        }

        /* table list search helper */
        this.activeAssignments.forEach(assignment => {
            this.boatName += assignment.boat?.name || '';
            this.boatLicensePlate += assignment.boat?.licensePlate || '';
            this.boatManufacturer += assignment.boat?.manufacturer || '';
            this.boatOwner += (assignment.boat?.owner?.fullName || assignment.boat?.ownerCompany?.fullName) || '';

            this.reservationEndsToday = assignment.reservationEndsToday || null;
            this.reservationEndsWithinNext7Days = assignment.reservationEndsWithinNext7Days || null;
        });

        this.boatName = this.boatName || 'ZZ';
        this.boatLicensePlate = this.boatLicensePlate || 'ZZ';
        this.boatManufacturer = this.boatManufacturer || 'ZZ';
        this.boatOwner = this.boatOwner || 'ZZ';
        this.products = (berth.products || [])
            .map(p => new Product(p))
            .sort((a, b) => a.sortIndex - b.sortIndex);

        this.costCenter = berth.costCenter ? new BcmCostCenter(berth.costCenter) : undefined;
    }

    toString(): string {
        return [this.handle, this.pier?.handle].join(' - ');
    }

    public isCurrentStartDateBetweenDates(currentBookingStart: Date, start: Date, end: Date): boolean {
        return isWithinInterval(currentBookingStart, {start, end});
    }

    public isCurrentEndDateBetweenDates(currentBookingEnd: Date, start: Date, end: Date): boolean {
        return isWithinInterval(currentBookingEnd, {start, end});
    }

    public isLockedUntil(date: Date): boolean {
        return this._interimLocked && this._interimLocked > date;
    }

    public getActiveAssignments(): BerthBoatAssignment[] {
        return this.assignments.filter((assignment) => assignment.active);
    }

    public getFutureAssignments(): BerthBoatAssignment[] {
        return this.assignments.filter((assignment) => assignment.future);
    }

    public getActiveLocks(): BcmLock[] {
        return this.locks.filter((lock) => lock.active);
    }

     public getFutureLocks(): BcmLock[] {
        return this.locks.filter((lock) => lock.future);
    }

    public hasActiveAssignments(): boolean {
        return !!this.activeAssignments?.length;
    }

    public get area(): number {
        return this.length * this.width;
    }

    private getType(): string {
        const type: string[] = [];
        if (this.isDoubleBox) {
            type.push('Doppelbox');
        }
        if (this.isForeign) {
            type.push('Fremd');
        }
        if (this.isLiftStation) {
            type.push('Hebeanlage');
        }
        if (this.isJetSki) {
            type.push('Jetski Ponton');
        }
        if (this.isPier) {
            type.push('Pier');
        }
        if (this.isBuoy) {
            type.push('Boje');
        }
        if (this.forGuest) {
            type.push('Gastliegeplatz');
        }
        if (this.forGuest) {
            type.push('Dauergastliegeplatz');
        }
        return type.join(', ');
    }
}
