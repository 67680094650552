import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { deepCopyArray } from '@core/functions/deep-copy';
import { Page, PageRequest } from '@shared/models/pagination';
import { StateResetService } from '@bcmServices/state-reset.service';

export interface BaseEntity {
    id: number;

    [key: string]: any;
}

export interface BaseState<T> {
    list: T[];
    selected: T;
}

export class RealBaseStateService<Type extends BaseEntity> {

    private readonly _pages: Map<string, BehaviorSubject<Page<Type>>>;
    private readonly _latestPageRequests: Map<string, PageRequest<Type>>;

    get page$(): Observable<Page<Type>> {
        return this.getCustomPage$('');
    }

    get page(): Page<Type> {
        return this.getCustomPage('');
    }

    set page(value: Page<Type>) {
        this.setCustomPage('', value);
    }

    get latestPageRequest(): PageRequest<Type> {
        return this.getLatestRequestForCustomPage('');
    }

    set latestPageRequest(value: PageRequest<Type>) {
        this.setLatestRequestForCustomPage('', value);
    }

    private readonly _state$ = new BehaviorSubject<BaseState<Type>>({list: [], selected: undefined});

    private get state(): BaseState<Type> {
        return this._state$.getValue();
    }

    public readonly _searchTermChanged$ = new Subject<string | undefined>();

    public get searchTermChanged$(): Observable<string | undefined> {
        return this._searchTermChanged$.pipe(filter(value => value != null));
    }

    public set searchTerm(searchTerm: string | undefined) {
        this._searchTermChanged$.next(searchTerm);
    }

    private _updatingAll$ = new BehaviorSubject<boolean>(false);

    public get isUpdatingAll$(): Observable<boolean> {
        return this._updatingAll$.asObservable();
    }

    private _updating$ = new BehaviorSubject<boolean>(false);

    public get isUpdating$(): Observable<boolean> {
        return this._updating$.asObservable();
    }

    get list$(): Observable<Type[]> {
        return this.select(state => state.list);
        // todo: filter List here? Dos not make sense, does it?
        // return this
        //     .searchTermChanged$
        //     .pipe(switchMap(searchTerm => {
        //         if (searchTerm) {
        //             return this.select(state => state.list)
        //                 .pipe(map(list => FuseUtils.filterArrayByString(list, searchTerm)));
        //         }
        //         return this.select(state => state.list);
        //     }));
    }

    get list(): Type[] {
        return this.state.list;
    }

    get selected$(): Observable<Type> {
        return this.select((state: BaseState<Type>) => state.selected);
    }

    get selected(): Type {
        return this.state.selected;
    }

    constructor(private EntityClass?: new (...attrs: unknown[]) => Type) {
        this._pages = new Map<string, BehaviorSubject<Page<Type>>>();
        this._pages.set('', new BehaviorSubject<Page<Type>>(null));

        this._latestPageRequests = new Map<string, PageRequest>();

        StateResetService.onReset.subscribe(() => this.resetState());
    }

    private resetState(): void {
        this._pages.clear();
        this._pages.set('', new BehaviorSubject<Page<Type>>(null));
        this._state$.next({list: [], selected: undefined});
        this._latestPageRequests.clear();
        this._searchTermChanged$.next(undefined);
        this._updatingAll$.next(false);
        this._updating$.next(false);
    }

    setCustomPage(name: string, value?: Page<Type>): void {
        if (!this._pages.has(name)) {
            this._pages.set(name, new BehaviorSubject<Page<Type>>(value));
        }
        this._pages.get(name).next(value);
    }

    getCustomPage(name: string): Page<Type> {
        return this._pages.get(name).getValue();
    }

    getCustomPage$(name: string): Observable<Page<Type>> {
        if (!this._pages.get(name)) {
            this._pages.set(name, new BehaviorSubject<Page<Type>>({page: 0, total: 0, results: [], size: 0}));
        }
        return this._pages.get(name)?.asObservable() || of({page: 0, total: 0, results: [], size: 0});
    }

    getLatestRequestForCustomPage(name: string): PageRequest<Type> {
        return this._latestPageRequests.get(name);
    }

    setLatestRequestForCustomPage(name: string, pageRequest: PageRequest<Type>): void {
        if (pageRequest) {
            this._latestPageRequests.set(name, pageRequest);
        }
    }

    public startUpdatingAll(): void {
        this._updatingAll$.next(true);
    }

    public stopUpdatingAll(): void {
        this._updatingAll$.next(false);
    }

    public startUpdating(): void {
        this._updating$.next(true);
    }

    public stopUpdating(): void {
        this._updating$.next(false);
    }

    public setList(list: Type[]): void {
        this.setState({list});
    }

    public setSelected(selected: Type): void {
        this.setState({selected});
    }

    public addListItem(item: Type): void {
        this.setState({list: [...deepCopyArray<Type>(this.state.list, this.EntityClass), item]});
    }

    public updateItemById(itemId: number, data: Partial<Type>): void {

        if (this.selected?.id === itemId) {
            this.setSelected(this.toEntityInstance({...this.selected, ...data}));
        }

        const listItem = this.state.list.find(mm => mm.id === itemId);

        if (listItem) {
            this.replaceListItem(listItem, this.toEntityInstance({...listItem, ...data}));
        }

        for (const [customPageName, page$] of this._pages) {
            const pageItem = page$?.getValue()?.results?.find(mm => mm.id === itemId);

            if (pageItem) {
                this.replacePageItem(pageItem, this.toEntityInstance({...pageItem, ...data}), customPageName);
            }
        }
    }

    public replaceListItem(oldItem: Type, newItem: Type): void {

        const index = this.state.list.findIndex(mm => mm.id === oldItem.id);

        if (index > -1) {
            const list = deepCopyArray<Type>(this.state.list, this.EntityClass);

            list.splice(index, 1, newItem);

            this.setState({list});
        }
    }

    public removeListItem(item: Type): void {
        const index = this.state.list.findIndex(mm => mm.id === item.id);

        if (index > -1) {
            const list = deepCopyArray<Type>(this.state.list, this.EntityClass);

            list.splice(index, 1);

            this.setState({list});
        }
    }

    public replacePageItem(oldItem: Type, newItem: Type, customPageName = ''): void {
        // TODO replace item in all pages, not only page with customPageName (same for remove)

        const tmpPage = this.getCustomPage(customPageName);

        if (!tmpPage) {
            return;
        }

        const index = tmpPage.results.findIndex(mm => mm.id === oldItem.id);
        const list = deepCopyArray<Type>(tmpPage.results, this.EntityClass);

        list.splice(index, 1, newItem);

        const page: Page<Type> = {...tmpPage, results: list};

        this.setCustomPage(customPageName, page);
    }

    public removePageItem(item: Type, customPageName = ''): void {
        const tmpPage = this.getCustomPage(customPageName);

        if (!tmpPage) {
            return;
        }

        const index = tmpPage.results.findIndex(mm => mm.id === item.id);
        const list = deepCopyArray<Type>(tmpPage.results, this.EntityClass);

        list.splice(index, 1);

        const page: Page<Type> = {
            page: tmpPage.page,
            total: tmpPage.total - 1,
            size: tmpPage.size - 1,
            results: list
        };

        this.setCustomPage(customPageName, page);
    }

    private select<T>(mapFn: (state: Partial<BaseState<Type>>) => T): Observable<T> {
        return this._state$.asObservable().pipe(
            map((state: Partial<BaseState<Type>>) => mapFn(state)),
            distinctUntilChanged()
        );
    }

    private setState(newState: Partial<BaseState<Type>>): void {
        // ToDo: use deepCopyObject here?!?
        // this.state$.next(deepCopyObject({
        //     ...this.state,
        //     ...newState,
        // }));
        this._state$.next({
            ...this.state,
            ...newState,
        });
    }

    private toEntityInstance(item: Type) {
        return this.EntityClass
            ? new this.EntityClass(item)
            : item;
    }
}
