<div fxLayout="row wrap" fxLayout.gt-xs="row" fxLayoutAlign="start start">
    <ng-container *ngIf="!readonly; else showReadOnly">
        <mat-form-field [appearance]="appearance"
                        fxFlex.xs="100"
                        fxFlex.gt-xs="100%"
                        fxFlex="1 1 0"
                        class="date-field"
                        floatLabel="always">
            <mat-label>
                <ng-content select="label"></ng-content>
            </mat-label>
            <input matInput
                   appMaskDate
                   placeholder="TT.MM.JJJJ"
                   [min]="minDate"
                   [max]="maxDate"
                   [ngModel]="value"
                   [disabled]="disabled"
                   [errorStateMatcher]="errorStateMatcher"
                   [matDatepicker]="fromDatePicker"
                   [selectionStart]="selectionStartDate"
                   (dateInput)="inputChange($event)">
            <button type="button" matSuffix mat-icon-button matTooltip="Felder Leeren"
                    [disabled]="!value || disabled"
                    (click)="resetControl()">
                <mat-icon>clear</mat-icon>
            </button>
            <div matSuffix class="custom-suffix">
                <ng-content select="[mat-icon-button]"></ng-content>
            </div>
            <mat-datepicker-toggle data-test="FIT-von" matSuffix
                                   [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
            <mat-hint>
                <ng-content select=".hint"></ng-content>
            </mat-hint>
        </mat-form-field>
    </ng-container>
    <ng-template #showReadOnly>
        <mat-form-field [appearance]="appearance"
                        fxFlex.xs="100"
                        fxFlex.gt-xs="100%"
                        fxFlex="1 1 0"
                        class="date-field"
                        floatLabel="always">
            <mat-label>
                <ng-content select="label"></ng-content>
            </mat-label>
            <input matInput
                   readonly="true"
                   [ngModel]="value | date:'dd.MM.yyyy'"
                   [disabled]="disabled">
        </mat-form-field>
    </ng-template>

    <div fxHide fxShow.gt-xs fxFlex="10px"></div>

    <mat-form-field [appearance]="appearance"
                    floatLabel="always"
                    fxFlex="48"
                    fxFlex.gt-xs="48px">
        <mat-label>Std</mat-label>
        <input type="text"
               matInput
               appMaskTimeHours
               #hoursInput="matInput"
               [readonly]="readonly"
               [errorStateMatcher]="errorStateMatcher"
               [matAutocomplete]="hourSelector"
               [disabled]="disabled"
               (change)="setHours($event)">
        <mat-autocomplete #hourSelector="matAutocomplete" (optionSelected)="setHours(null, $event)">
            <mat-option *ngFor="let hour of hours" [value]="hour">
                <span>{{ hour }}</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <div fxFlex="4" fxFlex.gt-xs="10px" fxFlexAlign="center" style="text-align: center">:</div>

    <mat-form-field [appearance]="appearance"
                    floatLabel="always"
                    fxFlex="48"
                    fxFlex.gt-xs="48px">
        <mat-label>Min</mat-label>
        <input type="text"
               matInput
               appMaskTimeMinutes
               #minutesInput="matInput"
               [readonly]="readonly"
               [errorStateMatcher]="errorStateMatcher"
               [matAutocomplete]="minuteSelector"
               [max]="59"
               [disabled]="disabled"
               (change)="setMinutes($event)">
        <mat-autocomplete #minuteSelector="matAutocomplete" (optionSelected)="setMinutes(null, $event)">
            <mat-option *ngFor="let minute of minutes" [value]="minute">
                <span>{{ minute }}</span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
<div class="error">{{ ngControl?.errors | firstErrorMessage }}</div>
