import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { BcmVersions } from '@modules/bcm/bcm-versions';
import { U2bDateValidators } from '@shared/validators/date/date-validators';
import { addDays, endOfToday } from '@core/date.facade';

@Component({
    selector: 'form-widget-tenant',
    templateUrl: './form-widget-tenant.component.html'
})
export class FormWidgetTenantComponent implements OnInit, OnDestroy {

    @Input()
    parentFormGroup: UntypedFormGroup;

    formGroup: UntypedFormGroup;

    BcmVersions = BcmVersions;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _matDialog: MatDialog) {
        this.formGroup = this._formBuilder.group({
            name: [null, [U2bValidators.required('Bitte gib einen Namen ein')]],
            numberOfBerths: [null, [U2bValidators.required('Bitte gib die Anzahl der Liegeplätze ein'), U2bNumericValidators.integerFormat()]],
            version: [null, [U2bValidators.required('Bitte gib die Version an')]],
            isDemo: [true],
            addDemoData: [true],
            isDemoUntil: [addDays(endOfToday(), 30), [U2bDateValidators.minDate(new Date())]],
            street: [null, [U2bValidators.required('Bitte gib eine Straße + Hausnummer ein')]],
            postcode: [null, [U2bValidators.required('Bitte PLZ angeben')]],
            city: [null, [U2bValidators.required('Bitte gib eine Stadt ein')]],
            country: ['Deutschland', [U2bValidators.required('Bitte wähle ein Land aus')]],
            mail: [null/*, [U2bValidators.required('Bitte gib eine E-Mail-Adresse ein')]*/],
            phone: [null/*, [U2bValidators.required('Bitte gib eine Telefonnummer ein')]*/],
            website: [null],
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('tenant', this.formGroup);
        });
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('tenant');
    }
}
