import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmBaseFacade } from '../bcm-base.facade';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BcmExportExcelApiService } from '@bcmApiServices/bcm-export-excel.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { Berth, IBerth } from '@shared/models/berth';
import { berthsTableData } from '@modules/bcm/@core/state-management/berths/berths-table-data';
import { ThemePalette } from '@angular/material/core';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { BcmBaseBulkChanges } from '@modules/bcm/@core/state-management/bcm-base-bulk-changes';
import { BcmFinancialRecord } from '@shared/models/bcm-financial-record';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class BerthsFacade extends BcmBaseFacade<Berth, IBerth> {

    readonly resourcePath = 'berths';

    readonly resourceNameSingular = 'Liegeplatz';

    readonly resourceNamePlural = 'Liegeplätze';

    readonly resourceIconPlural = 'directions_boat';

    readonly resourceIconSingular = 'directions_boat';

    readonly deletePermission = BcmUserPermission.BERTHS_DELETE;

    readonly readPermission = BcmUserPermission.BERTHS_READ;

    readonly writePermission = BcmUserPermission.BERTHS_WRITE;

    readonly headerActions = [
        {
            title: 'Grafischer Liegeplan',
            onClick: () =>  this._bcmNavigationService.navigate(['berths', 'map']),
            icon: 'compare_arrows',
            showDividerBelow: true
        },
        {
            title: 'Excel Export',
            onClick: () => this._bcmExportExcelApiService.exportBerths(),
        },
        {
            title: ' Excel Export Liegeplatzbelegung',
            onClick: () => this._bcmExportExcelApiService.exportBerthsAssignments(),
        }
    ];

    readonly rowActions = [
        {
            title: 'Datensatz anzeigen',
            icon: 'assignment',
            iconColor: 'accent' as ThemePalette,
            onClick: (berth: Berth) => this._bcmNavigationService.navigate('berths/list/' + berth.id)
        },
        {
            title: 'Datensatz löschen',
            icon: 'delete',
            iconColor: 'warn' as ThemePalette,
            onClick: (berth: Berth) =>  super.remove(berth, berth.handle, 'berths/list').subscribe()
        }
    ];

    readonly bulkChanges = new BcmBaseBulkChanges<Berth>()
        .setButtons(
            {
                title: 'Löschen',
                icon: 'delete',
                iconColor: 'warn' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (berths: Berth[]) => super.removeMultiple(berths),
            }
        );

    constructor(appNotificationService: AppNotificationService,
                confirmDialogService: ConfirmDialogService,
                bcmUserSettingsFacade: BcmUserSettingsFacade,
                httpClient: HttpClient,
                bcmService: BcmService,
                bcmNavigationService: BcmNavigationService,
                private _bcmExportExcelApiService: BcmExportExcelApiService,
                private _bcmNavigationService: BcmNavigationService,
    ) {
        super(
            Berth,
            berthsTableData,
            bcmNavigationService,
            appNotificationService,
            confirmDialogService,
            bcmUserSettingsFacade,
            httpClient,
            bcmService
        );

        this.rowClickHandler = (berth: Berth) => {
            this._bcmNavigationService.navigate('/berths/' + berth.id);
        };
    }
}
