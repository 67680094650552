import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { Person } from '@shared/models/person';

@Component({
    selector: 'bcm-person-link',
    templateUrl: './person-link.component.html'
})
export class PersonLinkComponent implements OnInit {

    @Input()
    public person: Person;

    @Input()
    public prefix: string;

    @Input()
    public suffix: string;

    @Input()
    public dataTest: string;

    @Input()
    public disableLink: boolean;

    @Input()
    public showTenantRelations: boolean;

    @Input()
    public showTenantRelationWithLineBreak: boolean;

    @Output()
    public clicked = new EventEmitter<void>();

    public insideMatOption = false;

    constructor(private hostElement: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.insideMatOption = this.isWithinMatOption();
    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }

    private isWithinMatOption(): boolean {
        let parent = this.renderer.parentNode(this.hostElement.nativeElement);

        while (parent) {
            if (parent.tagName?.toLowerCase() === 'mat-option') {
                return true;
            }
            parent = this.renderer.parentNode(parent);
        }
        return false;
    }
}
