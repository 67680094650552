import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance } from '@angular/material/legacy-form-field';
import { compareObjectsById } from '@shared/functions/compare-objects-by-id';
import { PersonsFacade } from '@modules/bcm/@core/state-management/persons/persons.facade';
import { Person } from '@shared/models/person';

@Component({
    selector: 'app-input-select-person',
    templateUrl: './input-select-person.component.html',
    styleUrls: ['./input-select-person.component.scss']
})
export class InputSelectPersonComponent implements OnInit, OnDestroy {

    @Input() appearance: MatLegacyFormFieldAppearance = 'outline';

    @Input() multiSelect = false;

    @Input() set control(formControl: AbstractControl) {
        this._formControl = formControl as UntypedFormControl;
        this._addFormControlListeners(this._formControl);
    }

    get control(): UntypedFormControl {
        return this._formControl;
    }

    private _formControl: UntypedFormControl;

    private _unsubscribeAll = new Subject();

    private _persons: Person[] = [];

    filteredPersons$: Observable<Person[]>;

    compareObjectsById = compareObjectsById;

    loadingPersons = true;

    constructor(private personsFacade: PersonsFacade) {
    }

    ngOnInit(): void {
        this._loadPersons();

        if (this.multiSelect) {
            this._formControl.setValue(this._formControl.value);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _addFormControlListeners(formControl: UntypedFormControl): void {
        if (!formControl) {
            return;
        }
        this.filteredPersons$ = combineLatest([
            formControl.valueChanges
                .pipe(
                    startWith((this.multiSelect) ? [] : ''),
                    debounceTime(DEFAULT_DEBOUNCE_TIME)
                ),
            this.personsFacade.list$.pipe(map(persons => this._persons = persons))
        ])
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((value) => isString(value[0]) && !this.multiSelect ? Person.filterPersons(this._persons, value[0]) : this._persons)
            );

    }

    private _loadPersons(): void {
        this.loadingPersons = true;
        this._persons = [];
        this.personsFacade.loadList().subscribe().add(() => this.loadingPersons = false);
    }

    displayWith(person: Person): string {
        return person ? person.fullNameBackward : '';
    }
}
