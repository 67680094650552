<form [formGroup]="formGroup">

    <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
        <mat-label>Buchung / Reservierung</mat-label>
        <mat-select data-test="FS-Typ"
                    formControlName="bookingType">
            <mat-option [value]="BookingType.Reservation">
                Reservierung
            </mat-option>
            <mat-option [value]="BookingType.Booking">
                Buchung
            </mat-option>
        </mat-select>
        <mat-error>{{ formGroup.get('bookingType').errors | firstErrorMessage }}</mat-error>
    </mat-form-field>

    <ng-container *ngIf="formGroup.get('bookingType').value === BookingType.Reservation; else isBooking">

        <app-input-date-time formControlName="reservedUntil">
            <label>Reserviert bis (optional)</label>
        </app-input-date-time>

        <div fxFlex="100" fxLayoutGap="8px">
            <mat-form-field appearance="outline" fxFlex="50" floatLabel="always">
                <mat-label>Anmerkung zur Reservierung</mat-label>
                <textarea #reservationText
                          matInput
                          [readonly]="bookingDialogService.readonlyView"
                          [attr.data-test]="'FT-Anmerkung zur Reservierung'"
                          formControlName="reservationText"
                          autocomplete="new-password"
                          [cdkTextareaAutosize]="true"
                          [cdkAutosizeMinRows]="3"
                          [cdkAutosizeMaxRows]="5"
                          [maxLength]="1024"></textarea>
                <mat-error>{{ formGroup.get('reservationText').errors | firstErrorMessage }}</mat-error>
                <mat-hint align="end">
                    {{ noteText.value?.length || 0 }} von max. 1024 Zeichen benutzt.
                </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50" floatLabel="always">
                <mat-label>Notizen</mat-label>
                <textarea #noteText
                          matInput
                          [readonly]="bookingDialogService.readonlyView"
                          [attr.data-test]="'FT-Bemerkung'"
                          formControlName="note"
                          autocomplete="new-password"
                          [cdkTextareaAutosize]="true"
                          [cdkAutosizeMinRows]="3"
                          [cdkAutosizeMaxRows]="5"
                          [maxLength]="1024"></textarea>
                <mat-error>{{ formGroup.get('note').errors | firstErrorMessage }}</mat-error>
                <mat-hint align="end">
                    {{ noteText.value?.length || 0 }} von max. 1024 Zeichen benutzt.
                </mat-hint>
            </mat-form-field>
        </div>

    </ng-container>

    <ng-template #isBooking>
        <mat-form-field appearance="outline" fxFlex="100" floatLabel="always">
            <mat-label>Notizen</mat-label>
            <textarea #noteText
                      matInput
                      [readonly]="bookingDialogService.readonlyView"
                      [attr.data-test]="'FT-Bemerkung'"
                      formControlName="note"
                      autocomplete="new-password"
                      [cdkTextareaAutosize]="true"
                      [cdkAutosizeMinRows]="3"
                      [cdkAutosizeMaxRows]="5"
                      [maxLength]="1024"></textarea>
            <mat-error>{{ formGroup.get('note').errors | firstErrorMessage }}</mat-error>
            <mat-hint align="end">
                {{ noteText.value?.length || 0 }} von max. 1024 Zeichen benutzt.
            </mat-hint>
        </mat-form-field>
    </ng-template>

</form>

<mat-divider class="special-divider"></mat-divider>

<ng-container
        *ngIf="bookingDialogFirstChoice === BookingDialogFirstChoice.PERSON_OR_COMPANY; else firstChoiceBoat">
    <ng-container *ngTemplateOutlet="personOrCompanySelection"></ng-container>
    <mat-divider class="special-divider"></mat-divider>
    <ng-container *ngTemplateOutlet="boatSelection"></ng-container>
</ng-container>

<ng-template #firstChoiceBoat>
    <ng-container *ngTemplateOutlet="boatSelection"></ng-container>
    <mat-divider class="special-divider"></mat-divider>
    <ng-container *ngTemplateOutlet="personOrCompanySelection"></ng-container>
</ng-template>

<ng-template #boatSelection>
    <ng-container [formGroup]="boatFormGroup">
        <form-widget-boat appearance="outline"
                          (boatChanged)="bookingDialogService.boat.value = $event"
                          [optionalFields]="true"
                          [editable]="true"
                          [predefinedValues]="bookingDialogService.boat.predefinedValues"
                          [canSave]="true"
                          [showMetaData]="true"
                          [general$]="bookingDialogService.general.value$"
                          [person$]="bookingDialogService.person.value$"
                          [company$]="bookingDialogService.company.value$"
                          [boat$]="bookingDialogService.boat.value$"
                          [boatRequired]="true"
                          [readonly]="bookingDialogService.readonlyView">
        </form-widget-boat>
    </ng-container>
</ng-template>

<ng-template #personOrCompanySelection>
    <ng-container [formGroup]="formGroup">
        <ng-container
                *ngIf="bookingDialogService.booking?.id; else showSelection">
            <u2b-message type="info" class="mb-24">
                Die Person / Organisation kann nicht mehr geändert werden, nachdem die Buchung angelegt wurde.<br>
                Die Daten der Person / Organisation können jedoch weiterhin bearbeitet werden.
            </u2b-message>

            <ng-container
                    *ngIf="(bookingDialogService.general.value$ | async)?.personOrCompany === ParticipantType.Person; else showCompany">

                <form-widget-person
                        (personChanged)="bookingDialogService.person.value = $event"
                        [person$]="bookingDialogService.person.value$"
                        [optionalFields]="true"
                        [editable]="true"
                        [disabled]="!!bookingDialogService.booking?.id"
                        headline=""
                        appearance="outline"></form-widget-person>

                <ng-container *ngIf="bookingDialogService.boat.value?.id">

                    <ng-container
                            *ngIf="bookingDialogService.boat.value?.owner?.id === bookingDialogService.person.value?.id; else setOwner">
                        <mat-checkbox [disabled]="true" [checked]="true" class="ml-4 mt-8 mb-8">
                    <span [innerHTML]="'nameIsOwnerOfThisBoat' | translate : {
                    boatOwnerName:  bookingDialogService.person.value?.fullName,
                    boatName: bookingDialogService.boat.value?.fullName}"></span>
                        </mat-checkbox>
                    </ng-container>

                    <ng-template #setOwner>
                        <mat-checkbox formControlName="isBoatOwner" class="ml-4 mt-8 mb-8">
                                    <span [innerHTML]="'setOwnerOfThisBoat' | translate : {
                    boatOwnerName:  bookingDialogService.person.value?.fullName,
                    boatName: bookingDialogService.boat.value?.fullName}"></span>
                        </mat-checkbox>
                    </ng-template>

                </ng-container>

            </ng-container>

            <ng-template #showCompany>

                <form-widget-company
                        (companyChanged)="bookingDialogService.company.value = $event"
                        [company$]="bookingDialogService.company.value$"
                        [optionalFields]="true"
                        [editable]="true"
                        [disabled]="!!bookingDialogService.booking?.id"
                        headline=""
                        appearance="outline"></form-widget-company>

                <ng-container *ngIf="bookingDialogService.boat.value?.id">

                    <ng-container
                            *ngIf="bookingDialogService.boat.value?.ownerCompany?.id === bookingDialogService.company.value?.id; else setOwnerCompany">
                        <mat-checkbox [disabled]="true" [checked]="true" class="ml-4 mt-8 mb-8">
                    <span [innerHTML]="'nameIsOwnerOfThisBoat' | translate : {
                    boatOwnerName:  bookingDialogService.company.value?.fullName,
                    boatName: bookingDialogService.boat.value?.fullName}"></span>
                        </mat-checkbox>
                    </ng-container>

                    <ng-template #setOwnerCompany>
                        <mat-checkbox formControlName="isBoatOwner" class="ml-4 mt-8 mb-8">
                                    <span [innerHTML]="'setOwnerOfThisBoat' | translate : {
                    boatOwnerName:  bookingDialogService.company.value?.fullName,
                    boatName: bookingDialogService.boat.value?.fullName}"></span>
                        </mat-checkbox>
                    </ng-template>

                </ng-container>

            </ng-template>

        </ng-container>

        <ng-template #showSelection>

            <mat-radio-group class="radio-group-inline" formControlName="personOrCompany">
                <mat-radio-button [value]="'person'">Person</mat-radio-button>
                <mat-radio-button [value]="'company'">Organisation</mat-radio-button>
            </mat-radio-group>

            <ng-container
                    *ngIf="(bookingDialogService.general.value$ | async).personOrCompany === ParticipantType.Person">
                <form-widget-person
                        (personChanged)="bookingDialogService.person.value = $event"
                        [person$]="bookingDialogService.person.value$"
                        [optionalFields]="true"
                        [editable]="true"
                        [disabled]="!!bookingDialogService.booking?.id"
                        appearance="outline"></form-widget-person>

                <ng-container
                        *ngIf="bookingDialogService.person.value?.id && bookingDialogService.boat.value?.id">

                    <ng-container
                            *ngIf="bookingDialogService.boat.value?.owner?.id === bookingDialogService.person.value?.id; else setOwner">
                        <mat-checkbox [disabled]="true" [checked]="true" class="ml-4 mt-16 mb-16">
                    <span [innerHTML]="'nameIsOwnerOfThisBoat' | translate : {
                    boatOwnerName:  bookingDialogService.person.value.fullName,
                    boatName: bookingDialogService.boat.value?.fullName}"></span>
                        </mat-checkbox>
                    </ng-container>

                    <ng-template #setOwner>
                        <mat-checkbox formControlName="isBoatOwner" class="mt-8">
                                    <span [innerHTML]="'setOwnerOfThisBoat' | translate : {
                    boatOwnerName:  bookingDialogService.person.value.fullName,
                    boatName: bookingDialogService.boat.value?.fullName}"></span>
                        </mat-checkbox>
                    </ng-template>

                </ng-container>

            </ng-container>

            <ng-container
                    *ngIf="(bookingDialogService.general.value$ | async).personOrCompany === ParticipantType.Company">
                <form-widget-company
                        (companyChanged)="bookingDialogService.company.value = $event"
                        [company$]="bookingDialogService.company.value$"
                        [optionalFields]="true"
                        [editable]="true"
                        [disabled]="!!bookingDialogService.booking?.id"
                        appearance="outline"></form-widget-company>

                <ng-container
                        *ngIf="bookingDialogService.company.value?.id && bookingDialogService.boat.value?.id">

                    <ng-container
                            *ngIf="bookingDialogService.boat.value?.owner?.id === bookingDialogService.company.value?.id; else setCompanyOwner">
                        <mat-checkbox [disabled]="true" [checked]="true" class="ml-4 mt-16 mb-16">
                    <span [innerHTML]="'nameIsOwnerOfThisBoat' | translate : {
                    boatOwnerName:  bookingDialogService.company.value.fullName,
                    boatName: bookingDialogService.boat.value?.fullName}"></span>
                        </mat-checkbox>
                    </ng-container>

                    <ng-template #setCompanyOwner>
                        <mat-checkbox formControlName="isBoatOwner" class="mt-8">
                                    <span [innerHTML]="'setOwnerOfThisBoat' | translate : {
                    boatOwnerName:  bookingDialogService.company.value.fullName,
                    boatName: bookingDialogService.boat.value?.fullName}"></span>
                        </mat-checkbox>
                    </ng-template>

                </ng-container>

            </ng-container>

        </ng-template>
    </ng-container>
</ng-template>
