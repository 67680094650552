import { ChangeDetectorRef, Component, Inject, Optional, ViewChild } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmDynamicPrice, BcmDynamicPriceContext } from '@shared/models/bcm-dynamic-price';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProductsApiService, ProductTaxRateApiService, ProductUnitApiService } from '@modules/bcm/@shared/services';
import { AppNotificationService } from '@core/services/app-notification.service';
import { U2bValidators } from '@shared/validators/validators';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { removeErrorFromControls } from '@core/functions/remove-error-from-controls';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Product } from '@shared/models/product';
import { combineLatest, forkJoin, merge, Observable, of } from 'rxjs';
import { IUnit, UnitUniqueName } from '@shared/models/unit';
import { ITaxRate } from '@shared/models/tax-rate';
import {
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger
} from '@angular/material/legacy-autocomplete';
import { BcmVoucherPosition } from '@shared/models/bcm-voucher';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { isFunction } from '@shared/functions/is-function';
import { stopEvent } from '@shared/functions/stop-event';
import { ProduktQuantityButton } from '@shared/models/product-quantity-button';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { HttpParams } from '@angular/common/http';
import { InvoicePositionDialogService } from '@sharedComponents/dialogs/invoice-position-dialog/invoice-position-dialog.service';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { BcmUserInvoicePositionsFormSettings, BcmUserSettingKey } from '@shared/models/bcm-settings-user';
import { BcmCostCenter } from '@shared/models/bcm-cost-center';
import { BcmSettingsSectionName, DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { DurationUnit, getDurationFromDates } from '@shared/functions/get-duration-from-dates';
import { isSameDay, startOfDay } from 'date-fns';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';

@UntilDestroy()
@Component({
    selector: 'invoice-position-dialog',
    templateUrl: './invoice-position-dialog.component.html',
    styleUrls: ['./invoice-position-dialog.component.scss'],
})
export class InvoicePositionDialogComponent {

    @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger})
    matAutocompleteTrigger: MatAutocompleteTrigger;

    formGroup: UntypedFormGroup;
    formGroupValid: boolean;

    hasSeenDynPrice = false;
    hasSeenVouchers = false;

    currentTabIndex = 0;
    hasNextTab = false;

    dynamicPriceContext?: BcmDynamicPriceContext;

    products: Product[];
    filteredProducts$: Observable<Product[]>;

    units: IUnit[];
    taxRates: ITaxRate[];
    showTaxRateColumn = true;

    isSaving: boolean;
    noProductsMessage = 'Es konnten keine Produkte gefunden werden.';

    showQuantityButtons = false;

    // do not set to false initially, otherwise if condition would not work first time in:
    // - dynamicPriceChanged
    // - useDynamicPriceChanged
    // - setVoucherUsage
    // I added the if conditions to have something similar to distinctUntilChanged() pipe
    hasDynamicPrice: boolean;
    vouchersAvailable: boolean;
    voucherPositions: BcmVoucherPosition[] = [];
    voucherUsage: boolean;

    dynamicPrice: BcmDynamicPrice;
    dynamicPriceHasError: boolean;
    useDynamicPrice: boolean;

    price: number;
    totalPrice: number;
    totalDiscount: number;

    protected readonly BcmTenantPermission = BcmTenantPermission;

    showAllProductFields = false;

    defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit;

    constructor(
        public dialogRef: MatDialogRef<InvoicePositionDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            productsFilterFn?: (product: Product) => boolean,
            quantityButtons?: ProduktQuantityButton[],
            position?: InvoicePosition,
            dynamicPriceContext?: BcmDynamicPriceContext,
            startDate?: Date,
            endDate?: Date,
            berthAssignments?: BerthBoatAssignment[],
            costCenter?: BcmCostCenter,
            disableVoucherAndDynPrice?: boolean,
        },
        private _formBuilder: UntypedFormBuilder,
        private _productApiService: ProductsApiService,
        private _productTaxRateApiService: ProductTaxRateApiService,
        private _productUnitApiService: ProductUnitApiService,
        private _appNotificationService: AppNotificationService,
        private _invoicePositionDialogService: InvoicePositionDialogService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _confirmDialogService: ConfirmDialogService,
        private _bcmUserSettingsFacade: BcmUserSettingsFacade,
        private _bcmSettingsFacade: BcmSettingsFacade,
    ) {
        this.dynamicPriceContext = data?.dynamicPriceContext;
        this.hasDynamicPrice = data?.position?.product?.hasDynamicPrice;
        this.useDynamicPrice = (data?.position?.dynamicPrice?.id > 0)
            || data?.position?.dynamicPrice?.ruleCalculations?.calculations?.length > 0
            || data?.position?.dynamicPrice?.ruleEvaluations?.evaluations?.length > 0;

        this._createForm().then(() => this._initRest());

        this.defaultBerthReservationTimeUnit = this._bcmSettingsFacade.settings()[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];
    }

    setToToday(formControlName: string) {
        this.formGroup.get(formControlName)?.setValue(new Date());

        this._confirmDialogService
            .setTitle('Datum übernehmen')
            .setBody('Soll das Datum künftig immer auf den aktuellen Tag gesetzt werden?')
            .appendInputToBody({
                name: 'vestingPeriodFrom',
                type: 'checkbox',
                label: 'Leistungszeitraum Start',
            })
            .appendInputToBody({
                name: 'vestingPeriodUntil',
                type: 'checkbox',
                label: 'Leistungszeitraum Ende',
            })
            .openAndReturnResult<{ vestingPeriodFrom: boolean, vestingPeriodUntil: boolean }>()
            .subscribe((result) => {
                if (result) {
                    const newSettings: BcmUserInvoicePositionsFormSettings = {
                        vestingPeriodFromDefaultsToToday: result.vestingPeriodFrom,
                        vestingPeriodUntilDefaultsToToday: result.vestingPeriodUntil,
                    };
                    this._bcmUserSettingsFacade.updateSingleSetting(BcmUserSettingKey.InvoicePositionsForm, newSettings);
                }
            });
    }

    dynamicPriceChanged(dynPrice: BcmDynamicPrice): void {
        if (this.dynamicPrice === dynPrice) {
            return;
        }

        this.dynamicPrice = dynPrice;

        if (this.dynamicPrice?.ruleIndex !== null && this.dynamicPrice?.ruleIndex >= 0 || this.dynamicPrice?.ruleCalculations?.calculations?.length > 0) {
            this.formGroup.get('price').setValue(this.dynamicPrice.rulePrice, {emitEvent: false});
        } else {
            this.formGroup.get('price').setValue(this.formGroup.get('product').value?.price, {emitEvent: false});
        }
    }

    useDynamicPriceChanged(useDynamicPrice: boolean): void {
        if (this.useDynamicPrice === useDynamicPrice) {
            return;
        }

        this.hasSeenDynPrice = false;
        this.useDynamicPrice = useDynamicPrice;

        if (this.useDynamicPrice) {
            this.formGroup.get('price').disable({emitEvent: false});
        } else {
            this.formGroup.get('price').enable({emitEvent: false});
        }

        this.checkHasNextTab();
    }

    selectTab(index: any): void {
        this.currentTabIndex = index;
        this.checkHasNextTab();
    }

    setVoucherUsage(usage: boolean): void {
        if (this.voucherUsage === usage) {
            return;
        }

        this.hasSeenVouchers = (this.currentTabIndex === 2);
        this.voucherUsage = usage;

        if (this.voucherUsage) {
            this.formGroup.get('price').setValue(0, {emitEvent: false});
        } else {
            if (this.dynamicPrice?.ruleIndex !== null && this.dynamicPrice?.ruleIndex >= 0 || this.dynamicPrice?.ruleCalculations?.calculations?.length > 0) {
                this.formGroup.get('price').setValue(this.dynamicPrice.rulePrice, {emitEvent: false});
            } else {
                this.formGroup.get('price').setValue(this.formGroup.get('product').value?.price, {emitEvent: false});
            }
        }
        this.checkHasNextTab();
    }

    checkHasNextTab(): void {
        if (this.currentTabIndex === 0) {
            this.hasNextTab = this.vouchersAvailable || this.hasDynamicPrice;
        } else if (this.currentTabIndex === 1) {
            this.hasSeenVouchers = true;
            this.hasNextTab = this.hasDynamicPrice;
        } else {
            this.hasNextTab = false;
            this.hasSeenDynPrice = true;
        }
    }

    nextTab(): void {
        if (this.currentTabIndex === 0) {
            if (this.vouchersAvailable) {
                this.currentTabIndex = 1;
            } else if (this.hasDynamicPrice) {
                this.currentTabIndex = 2;
            }
        } else if (this.currentTabIndex === 1) {
            if (this.hasDynamicPrice) {
                this.currentTabIndex = 2;
            }
        }
        this.checkHasNextTab();
    }

    setVouchersAvailable(vouchersAvailable: boolean): void {
        this.vouchersAvailable = vouchersAvailable;
    }

    openPanel(event: MouseEvent): void {
        event.stopPropagation();
        this.matAutocompleteTrigger.openPanel();
    }

    displayProductWith(product: Product): string {
        return product ? product.name : '';
    }

    public onSelectProduct(event: MatAutocompleteSelectedEvent): void {

        const product = event.option.value as Product;

        this.formGroup
            .patchValue({
                account: product.account,
                itemNumber: product.itemNumber,
                product,
                title: product.name,
                unit: product.unit,
                price: product.price,
                taxRate: product.taxRate,
            });
    }

    public emptyProduct(event: MouseEvent): void {
        stopEvent(event);

        this.hasDynamicPrice = false;
        this.vouchersAvailable = false;

        const {costCenter, costCenterFilterType} = this.formGroup.value;

        this.formGroup
            .reset({
                costCenter,
                costCenterFilterType,
                account: null,
                itemNumber: null,
                product: null,
                title: null,
                unit: null,
                quantity: 1,
                price: null,
                taxRate: null,
            });

        setTimeout(() => this.formGroup.get('title').setValue(null), 50);
    }

    compareUnits(unit1: IUnit, unit2: IUnit): boolean {
        return unit1 && unit2 && unit1.name === unit2.name && unit1.id === unit2.id;
    }

    compareTaxRates(taxRate1: ITaxRate, taxRate2: ITaxRate): boolean {
        return taxRate1 && taxRate2 && taxRate1.value === taxRate2.value && taxRate1.id === taxRate2.id;
    }

    saveAndContinue(): void {

        let invalid = false;

        for (const key in this.formGroup.controls) {
            if (key !== 'dynamicPriceFormGroup') {
                if (this.formGroup.controls[key].invalid) {
                    this.formGroup.controls[key].markAsTouched();
                    invalid = true;
                }
            }
        }

        if (invalid) {
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        const {
            product,
            title,
            quantity,
            account,
            itemNumber,
            unit,
            taxRate,
            price,
            vestingPeriodFrom,
            vestingPeriodUntil,
            discountPercentage,
            relatedBerthAssignment,
            costCenter,
        } = this.formGroup.value;

        const useDynamicPrice = this.data?.disableVoucherAndDynPrice
            ? null
            : this.formGroup.get('dynamicPriceFormGroup')?.get('useDynamicPrice')?.value as boolean | null;
        const dynamicPrice = this.data?.disableVoucherAndDynPrice
            ? null
            : ({...this.formGroup.get('dynamicPriceFormGroup')?.get('dynamicPrice')?.value} as BcmDynamicPrice | null);


        const berthAssignment = this.data?.berthAssignments?.find(assignment => assignment === relatedBerthAssignment);
        const berthAssignmentUuid = berthAssignment?.uuid;

        this.isSaving = true;

        if (!this.data?.disableVoucherAndDynPrice && this.voucherPositions?.length > 0) {
            this.dialogRef.close(this.voucherPositions.map((voucherPosition, index) => {
                if (voucherPosition.voucher) {
                    return new InvoicePosition({
                        id: index === 0 ? this.data?.position?.id : null,
                        fromTenantRelation: !!(this.data?.position?.tenantRelationAssignment?.id || this.data?.position?.fromTenantRelation),
                        tenantRelation: this.data?.position?.tenantRelation,
                        tenantRelationAssignment: this.data?.position?.tenantRelationAssignment,
                        product,
                        quantity: voucherPosition.quantity,
                        account,
                        itemNumber,
                        title,
                        unit,
                        price: 0,
                        taxRate,
                        vestingPeriodFrom,
                        vestingPeriodUntil,
                        dynamicPrice: null,
                        voucher: voucherPosition.voucher,
                        positionId: this.data?.position?.positionId,
                        isNew: index === 0 && !this.data?.position?.id,
                        voucherRemainingAmount: voucherPosition.voucher.quantityLeft - voucherPosition.quantity,
                        discountPercentage,
                        priceLock: true,
                        titleLock: (product?.name !== title),
                        accountLock: (product?.account !== account),
                        itemNumberLock: (product?.itemNumber !== itemNumber),
                        taxRateLock: (product?.taxRate?.id !== taxRate?.id),
                        unitLock: (product?.unit?.id !== unit?.id),
                        berthAssignmentUuid,
                        costCenter,
                    });
                } else {
                    return new InvoicePosition({
                        id: index === 0 ? this.data?.position?.id : null,
                        fromTenantRelation: !!(this.data?.position?.tenantRelationAssignment?.id || this.data?.position?.fromTenantRelation),
                        tenantRelation: this.data?.position?.tenantRelation,
                        tenantRelationAssignment: this.data?.position?.tenantRelationAssignment,
                        product,
                        quantity: voucherPosition.quantity,
                        account,
                        itemNumber,
                        title,
                        unit,
                        price: useDynamicPrice && dynamicPrice?.rulePrice != null
                            ? dynamicPrice.rulePrice
                            : ((price != null && price !== 0) ? price : product?.price),
                        taxRate,
                        vestingPeriodFrom,
                        vestingPeriodUntil,
                        dynamicPrice: useDynamicPrice ? dynamicPrice : null,
                        positionId: this.data?.position?.positionId,
                        isNew: !this.data?.position?.id,
                        discountPercentage,
                        priceLock: (price !== product?.price || (!!dynamicPrice?.id && price !== dynamicPrice?.rulePrice)),
                        titleLock: (title !== product?.name || (!!dynamicPrice?.id && title !== dynamicPrice?.ruleName)),
                        accountLock: (product?.account !== account),
                        itemNumberLock: (product?.itemNumber !== itemNumber),
                        taxRateLock: (product?.taxRate?.id !== taxRate?.id),
                        unitLock: (product?.unit?.id !== unit?.id),
                        berthAssignmentUuid,
                        costCenter,
                    });
                }
            }));
        } else {
            this.dialogRef.close([
                new InvoicePosition({
                    id: this.data?.position?.id,
                    fromTenantRelation: !!(this.data?.position?.tenantRelationAssignment?.id || this.data?.position?.fromTenantRelation),
                    tenantRelation: this.data?.position?.tenantRelation,
                    tenantRelationAssignment: this.data?.position?.tenantRelationAssignment,
                    product,
                    quantity,
                    account,
                    itemNumber,
                    title,
                    unit,
                    price: useDynamicPrice && dynamicPrice?.rulePrice != null
                        ? dynamicPrice.rulePrice
                        : (price != null ? price : product?.price),
                    taxRate,
                    vestingPeriodFrom,
                    vestingPeriodUntil,
                    dynamicPrice: useDynamicPrice ? dynamicPrice : null,
                    positionId: this.data?.position?.positionId,
                    isNew: !this.data?.position?.id,
                    discountPercentage,
                    priceLock: (price !== product?.price || (!!dynamicPrice?.id && price !== dynamicPrice?.rulePrice)),
                    titleLock: (title !== product?.name || (!!dynamicPrice?.id && title !== dynamicPrice?.ruleName)),
                    accountLock: (product?.account !== account),
                    itemNumberLock: (product?.itemNumber !== itemNumber),
                    taxRateLock: (product?.taxRate?.id !== taxRate?.id),
                    unitLock: (product?.unit?.id !== unit?.id),
                    berthAssignmentUuid,
                    costCenter,
                })
            ]);
        }
    }

    private async _createForm(): Promise<void> {
        const costCenterValue = this.data?.position?.costCenter
            || this.data?.costCenter
            || null;

        let relatedBerthAssignment: BerthBoatAssignment = null;

        if (this.data?.berthAssignments?.length) {
            if (this.data?.position?.bcm_berth_has_assignments_id > 0) {
                relatedBerthAssignment = this.data?.berthAssignments.find(assignment => assignment.id === this.data?.position.bcm_berth_has_assignments_id);
            } else if (this.data?.position?.berthAssignmentUuid) {
                relatedBerthAssignment = this.data?.berthAssignments.find(assignment => assignment.uuid === this.data?.position?.berthAssignmentUuid);
            } else if (!this.data?.position?.id && this.data?.berthAssignments?.length > 0) {
                relatedBerthAssignment = this.data?.berthAssignments[0];
            }
        }

        if (relatedBerthAssignment) {
            this.dynamicPriceContext = {
                ...this.data?.dynamicPriceContext,
                fromDate: relatedBerthAssignment.from,
                toDate: relatedBerthAssignment.to,
                preselection: {
                    ...this.data?.dynamicPriceContext?.preselection,
                    'berth': relatedBerthAssignment.berth,
                }
            };
        }

        this.formGroup = this._formBuilder.group({
            account: [this.data?.position?.account],
            itemNumber: [this.data?.position?.itemNumber],
            product: [this.data?.position?.product],
            title: [this.data?.position?.title || null, [U2bValidators.required('Bitte Positionstitel angeben')]],
            quantity: [this.data?.position?.quantity ?? 1, [U2bValidators.required('Bitte Menge angeben')]],
            unit: [this.data?.position?.unit || null, [U2bValidators.required('Bitte Einheit angeben')]],
            price: [this.data?.position?.price || null, [U2bValidators.required('Bitte Einzelpreis angeben')]],
            taxRate: [this.data?.position?.taxRate || null, [U2bValidators.required('Bitte MwSt. angeben')]],
            vestingPeriodFrom: [
                (this.data?.position?.vestingPeriodFrom ? tryParseDate(this.data?.position?.vestingPeriodFrom) : null) ||
                relatedBerthAssignment?.from ||
                this.data?.startDate
            ],
            vestingPeriodUntil: [
                (this.data?.position?.vestingPeriodUntil ? tryParseDate(this.data?.position?.vestingPeriodUntil) : null) ||
                relatedBerthAssignment?.to ||
                this.data?.endDate
            ],
            discountPercentage: [this.data?.position?.discountPercentage || null, [U2bNumericValidators.numberMin(0.1), U2bNumericValidators.numberMax(100)]],
            relatedBerthAssignment: [relatedBerthAssignment],
            costCenter: [costCenterValue],
            costCenterFilterType: [null],
        });

        const vestingPeriodFromField = this.formGroup.get('vestingPeriodFrom');
        const vestingPeriodUntilField = this.formGroup.get('vestingPeriodUntil');

        combineLatest([
            vestingPeriodFromField.valueChanges.pipe(startWith(vestingPeriodFromField.value)),
            vestingPeriodUntilField.valueChanges.pipe(startWith(vestingPeriodUntilField.value))
        ])
            .pipe(
                untilDestroyed(this),
                filter(([from, until]) => !!from && !!until),
                distinctUntilChanged((a, b) => {
                    return startOfDay(a[0]).getTime() === startOfDay(b[0]).getTime() &&
                        startOfDay(a[1]).getTime() === startOfDay(b[1]).getTime();
                }),
            )
            .subscribe(([from, until]) => {

                const {
                    dailyGuestFromHours,
                    dailyGuestFromMinutes,
                    dailyGuestToHours,
                    dailyGuestToMinutes,
                    overnightGuestFromHours,
                    overnightGuestFromMinutes,
                    overnightGuestToHours,
                    overnightGuestToMinutes,
                } = this.defaultBerthReservationTimeUnit;

                if (isSameDay(from, until)) {
                    from.setHours(dailyGuestFromHours, dailyGuestFromMinutes, 0, 0);
                    until.setHours(dailyGuestToHours, dailyGuestToMinutes, 0, 0);
                } else {
                    from.setHours(overnightGuestFromHours, overnightGuestFromMinutes, 0, 0);
                    until.setHours(overnightGuestToHours, overnightGuestToMinutes, 0, 0);
                }

                this.formGroup.patchValue({
                    vestingPeriodFrom: from,
                    vestingPeriodUntil: until,
                }, {
                    emitEvent: false
                });


                this.updateQuantity();
            });

        this.formGroup.get('unit').valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.updateQuantity();
            });

        this.formGroup.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {

                if (!value) {
                    return;
                }

                const dynamicPriceFormGroup = value.dynamicPriceFormGroup;
                this.useDynamicPrice = !!dynamicPriceFormGroup?.dynamicPrice;

                if (this.dynamicPriceContext?.fromDate !== value.vestingPeriodFrom || this.dynamicPriceContext?.toDate !== value.vestingPeriodUntil) {
                    this.dynamicPriceContext = {
                        ...this.dynamicPriceContext,
                        fromDate: value.vestingPeriodFrom,
                        toDate: value.vestingPeriodUntil,
                    };
                }

                if (this.useDynamicPrice) {
                    this.formGroupValid = this.formGroup.get('title').valid &&
                        this.formGroup.get('quantity').valid &&
                        this.formGroup.get('unit').valid &&
                        this.formGroup.get('taxRate').valid;
                } else {
                    this.formGroupValid = this.formGroup.get('title').valid &&
                        this.formGroup.get('quantity').valid &&
                        this.formGroup.get('unit').valid &&
                        this.formGroup.get('price').valid &&
                        this.formGroup.get('taxRate').valid;
                }

                const {
                    product,
                    vestingPeriodFrom,
                    vestingPeriodUntil,
                } = value;

                this.hasDynamicPrice = product?.hasDynamicPrice;

                if (vestingPeriodFrom && vestingPeriodUntil) {
                    if (vestingPeriodFrom > vestingPeriodUntil) {
                        vestingPeriodFromField.setErrors({
                            wrongDateRange: {
                                message: 'Das "Leistungszeitraum Start" Datum darf nicht hinter dem "Leistungszeitraum Ende" Datum liegen'
                            }
                        });
                        vestingPeriodUntilField.setErrors({
                            wrongDateRange: {
                                message: 'Das "Leistungszeitraum Ende" Datum darf nicht vor dem "Leistungszeitraum Start" Datum liegen'
                            }
                        });
                    } else {
                        removeErrorFromControls('wrongDateRange', [
                            vestingPeriodFromField,
                            vestingPeriodUntilField,
                        ]);
                    }
                }

                if (this.currentTabIndex === 0) {
                    this.hasNextTab = this.hasDynamicPrice || this.vouchersAvailable;
                }

            });

        forkJoin([
            this.formGroup.get('product').valueChanges,
            this.formGroup.get('unit').valueChanges
        ])
            .pipe(
                untilDestroyed(this),
                debounceTime(DEFAULT_DEBOUNCE_TIME)
            )
            .subscribe(([product, unit]) => {

                if (unit?.uniqueName === UnitUniqueName.SQM || product?.unit?.uniqueName === UnitUniqueName.SQM) {
                    this.showQuantityButtons = true;
                }

                this.voucherPositions = [];

                this._changeDetectorRef.detectChanges();
            });

        this.formGroup.get('product').valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(DEFAULT_DEBOUNCE_TIME)
            )
            .subscribe(product => {
                this._invoicePositionDialogService
                    .checkForCostCenterAvailability(product, this.formGroup.get('costCenter') as FormControl);

                if (product && product instanceof Product) {
                    this.showAllProductFields = false;
                }
            });

        merge(
            this.formGroup.get('quantity').valueChanges,
            this.formGroup.get('price').valueChanges,
            this.formGroup.get('discountPercentage').valueChanges,
        )
            .pipe(
                untilDestroyed(this),
                debounceTime(DEFAULT_DEBOUNCE_TIME)
            )
            .subscribe(() => {

                const [quantity, price, discountPercentage] = [
                    this.formGroup.get('quantity').value,
                    this.formGroup.get('price').value,
                    this.formGroup.get('discountPercentage').value,
                ];

                this._calculateTotalPrice(price, quantity, discountPercentage);

                this._changeDetectorRef.detectChanges();
            });

        this.filteredProducts$ = this.formGroup.get('title').valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                startWith(this.products),
                tap((value: string | Product) => {
                    const product = this.formGroup.get('product')?.value;
                    const hasProduct = product && product instanceof Product;

                    if (!hasProduct && value && isString(value)) {
                        this.showAllProductFields = true;
                    }
                }),
                switchMap(
                    (value: string | Product) => isString(value)
                        ? this._filterProducts(value as string)
                        : of(this.products)
                )
            );

        this.formGroup.get('relatedBerthAssignment').valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((berthBoatAssignment) => {
                if (berthBoatAssignment) {

                    this.formGroup.patchValue({
                        vestingPeriodFrom: berthBoatAssignment.from,
                        vestingPeriodUntil: berthBoatAssignment.to,
                    });

                    if (this.dynamicPriceContext) {
                        this.dynamicPriceContext = {
                            fromDate: berthBoatAssignment.from,
                            toDate: berthBoatAssignment.to,
                            preselection: {
                                ...this.dynamicPriceContext.preselection,
                                'berth': berthBoatAssignment.berth,
                            }
                        };
                    }

                } else {

                    this.formGroup.patchValue({
                        vestingPeriodFrom: this.data?.startDate,
                        vestingPeriodUntil: this.data?.endDate,
                    });

                }

                this.updateQuantity();
            });

        // if (this.data?.berthAssignments?.length > 0) {
        //     if (this.data?.position) {
        //         const berthAssignment = this.data?.berthAssignments[this.data?.position.berthAssignmentIndex] || this.data?.berthAssignments[0];
        //         if (this.data?.position.vestingPeriodFromDate?.getTime() === berthAssignment.from?.getTime() &&
        //             this.data?.position.vestingPeriodUntilDate?.getTime() === berthAssignment.to?.getTime()) {
        //
        //             this.formGroup.get('relatedBerthAssignment').setValue(berthAssignment);
        //         } else {
        //             this.formGroup.get('relatedBerthAssignment').setValue(null, {emitEvent: false});
        //         }
        //
        //     } else {
        //         this.formGroup.get('relatedBerthAssignment').setValue(this.data?.berthAssignments[0]);
        //     }
        // }

        combineLatest([
            this.formGroup.get('costCenter').valueChanges.pipe(startWith(null)),
            this.formGroup.get('costCenterFilterType').valueChanges.pipe(startWith(null)),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([costCenter, costCenterFilterType]) => {

                let httpParams: HttpParams;

                if (costCenter?.id) {
                    httpParams = new HttpParams()
                        .set('costCenterId', costCenter.id)
                        .set('costCenterFilterType', costCenterFilterType ?? '');
                }

                this._loadProducts(httpParams);

                this.formGroup
                    .get('title')
                    .updateValueAndValidity({onlySelf: false, emitEvent: true});
            });

        this._bcmUserSettingsFacade.settings$.subscribe(settings => {
            const invoicePositionFormSettings = settings[BcmUserSettingKey.InvoicePositionsForm];

            if (invoicePositionFormSettings?.vestingPeriodFromDefaultsToToday && !this.data?.startDate) {
                this.formGroup.get('vestingPeriodFrom')?.setValue(new Date());
            }

            if (invoicePositionFormSettings?.vestingPeriodUntilDefaultsToToday && !this.data?.endDate) {
                this.formGroup.get('vestingPeriodUntil')?.setValue(new Date());
            }
        });
    }

    private updateQuantity() {

        const {
            vestingPeriodFrom,
            vestingPeriodUntil,
            unit
        } = this.formGroup.getRawValue();

        if (!vestingPeriodFrom || !vestingPeriodUntil || !unit) {
            return;
        }

        let durationUnit: DurationUnit | null = null;

        if (unit?.uniqueName === UnitUniqueName.OVERNIGHT_STAY) {
            durationUnit = (unit?.uniqueName === UnitUniqueName.OVERNIGHT_STAY) ? DurationUnit.Overnights : DurationUnit.Days;
        } else if (unit?.uniqueName === UnitUniqueName.DAY) {
            durationUnit = DurationUnit.Days;
        }

        if (durationUnit) {
            const quantity = getDurationFromDates(vestingPeriodFrom, vestingPeriodUntil, durationUnit, this.defaultBerthReservationTimeUnit);
            this.formGroup.get('quantity').setValue(quantity);
        }

    }

    private _initRest() {
        if (this.data?.position?.voucher?.id >= 0) {
            this.voucherPositions.push({
                voucher: this.data?.position.voucher,
                quantity: this.data?.position.quantity,
                totalPrice: 0
            });
        }

        let httpParams: HttpParams;
        const costCenterId = this.data?.position?.costCenter?.id
            || this.data?.position?.bcm_cost_centers_id
            || this.data?.costCenter?.id;

        if (costCenterId) {
            httpParams = new HttpParams().set('costCenterId', costCenterId);
        }

        this._loadProducts(httpParams);
        this._loadProductTaxRates();
        this._loadProductUnits();
    }

    private _calculateTotalPrice(price = 0, quantity = 0, discountPercentage = 0): void {
        if (price == null || quantity == null) {
            return;
        }

        const totalPrice = price * quantity;
        this.totalDiscount = (totalPrice / 100 * (discountPercentage || 0));
        this.totalPrice = totalPrice - this.totalDiscount;
    }

    private _filterProducts(filterStr: string): Observable<Product[]> {
        return of(this.products)
            .pipe(
                untilDestroyed(this),
                map((products: Product[]) => Product.filterProducts(products, filterStr))
            );
    }

    private _loadProducts(httpParams?: HttpParams): void {
        this._productApiService
            .getAll(httpParams)
            .subscribe((products: Product[]) => {
                if (isFunction(this.data?.productsFilterFn)) {
                    // todo: move filter to backend
                    this.products = products.filter(this.data?.productsFilterFn);
                    return;
                }
                this.products = products;
            });
    }

    private _loadProductTaxRates(): void {
        this._productTaxRateApiService.getAll()
            .subscribe((taxRates: ITaxRate[]) => {
                this.taxRates = taxRates;
                this.showTaxRateColumn = this.taxRates?.length > 1 || (this.taxRates?.length === 0 && this.taxRates[0].value !== 0);
                if (!this.showTaxRateColumn) {
                    this.formGroup.get('taxRate').disable();
                }
            });
    }

    private _loadProductUnits(): void {
        this._productUnitApiService.getAll().subscribe((units: IUnit[]) => this.units = units);
    }
}
