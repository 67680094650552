import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { RealBaseStateService } from '@bcmServices/real-base-state.service';
import { Person } from '@shared/models/person';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BcmDocument } from '@shared/models/bcm-document';
import { PersonApiService } from '@modules/bcm/@shared/services';
import { PageRequest } from '@shared/models/pagination';

@Injectable({providedIn: 'root'})
export class BcmPersonsFacade extends PersonApiService {

    private readonly state = new RealBaseStateService<Person>(Person);

    get latestPageRequest(): PageRequest<Person> {
        return this.state.latestPageRequest;
    }

    set latestPageRequest(value: PageRequest<Person>) {
        this.state.latestPageRequest = value;
    }

    get persons$(): Observable<Person[]> {
        return this.state.list$;
    }

    get selectedPerson$(): Observable<Person> {
        return this.state.selected$;
    }

    get loadingAll$(): Observable<boolean> {
        return this.state.isUpdatingAll$;
    }

    get loading$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    constructor(_matDialog: MatDialog,
                _httpClient: HttpClient,
                _bcmService: BcmService) {
        super(_matDialog, _httpClient, _bcmService);
    }

    private _loadAll(httpParams?: HttpParams): Observable<Person[]> {
        this.state.startUpdatingAll();
        return this.getAll(httpParams);
    }

    loadAll(httpParams?: HttpParams): Subscription {
        this.state.startUpdatingAll();
        return this._loadAll(httpParams)
            .pipe(
                tap(persons => {
                    this.state.setList(persons);
                    this.state.stopUpdatingAll();
                }),
            ).subscribe();
    }

    loadOne(id: number): Observable<Person> {
        this.state.startUpdating();
        return this.getOne(id)
            .pipe(
                tap(person => {
                        this.state.setSelected(person);
                        this.state.stopUpdating();
                    }
                ),
                switchMap(() => this.selectedPerson$)
            );
    }

    addPerson(tmpPerson: Person): Observable<Person> {

        tmpPerson.id = -1;

        this.state.startUpdating();
        this.state.addListItem(tmpPerson);

        return this.add(tmpPerson)
            .pipe(
                map(id => {
                    return {
                        ...tmpPerson,
                        id: id
                    } as Person;
                }),
                tap((person) => {
                    this.state.replaceListItem(tmpPerson, person);
                    this.state.stopUpdating();
                }),
                catchError((error: HttpErrorResponse) => {
                    this.state.removeListItem(tmpPerson);
                    return throwError(error);
                })
            );

        // return throwError('Bitte überprüfe Deine Angaben.');
    }

    updatePerson(person: Person, personData: Partial<Person>): Observable<any> {
        if (person?.id) {

            this.state.startUpdating();

            const updatedPerson = {
                ...person,
                ...personData
            } as Person;

            this.state.replaceListItem(person, updatedPerson); // optimistic change

            return this.update(person.id, personData)
                .pipe(
                    tap(() => this.state.stopUpdating()),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(updatedPerson, person); // revert
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    removePerson(person: Person): Observable<any> {

        if (person?.id) {

            this.state.removeListItem(person);

            return this.remove(person.id)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.state.addListItem(person);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    addPersonDocument(person: Person, tmpDocument: BcmDocument): Observable<BcmDocument> {

        if (person?.id && tmpDocument?.title?.length) {

            tmpDocument.id = -1;

            this.state.startUpdating();

            const tmpPerson = {...person, documents: [...person.documents, tmpDocument]} as Person;

            this.state.replaceListItem(person, tmpPerson);

            return this.addDocument(person.id, tmpDocument)
                .pipe(
                    tap((document) => {
                        const newPerson = {...person, documents: [...person.documents, document]} as Person;
                        this.state.replaceListItem(tmpPerson, newPerson);
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(tmpPerson, person);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    updatePersonDocument(person: Person, tmpDocument: BcmDocument, documentData: Partial<BcmDocument>): Observable<any> {
        if (person?.id && tmpDocument?.title?.length) {

            this.state.startUpdating();

            const updatedDocument = {
                ...tmpDocument,
                ...documentData
            } as BcmDocument;

            this.state.startUpdating();

            const index = person.documents.findIndex(doc => doc.id === tmpDocument.id);

            if (index === -1) {
                return throwError('Bitte überprüfe Deine Angaben.');
            }

            const tmpDocuments = [...person.documents];
            tmpDocuments.splice(index, 1, updatedDocument);

            const tmpPerson = {...person, documents: tmpDocuments} as Person;

            this.state.replaceListItem(person, tmpPerson);

            return this.updateDocument(person.id, tmpDocument, documentData)
                .pipe(
                    tap((document) => {
                        person.documents.splice(index, 1, document);
                        this.state.replaceListItem(tmpPerson, person);
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(tmpPerson, person);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    removePersonDocument(person: Person, document: BcmDocument): Observable<any> {

        if (person?.id && document?.id) {

            const newPerson = {...person, documents: person.documents.filter(doc => doc.id !== document.id)} as Person;

            this.state.replaceListItem(person, newPerson);

            return this.deleteDocument(person.id, document.id)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(newPerson, person);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

}
