import {
    U2bColumnDefinitionExternalComponent,
    U2bColumnPipeName,
    U2bTableData
} from '@core/components/layout/table/table.types';
import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import { getIsActive } from '@core/functions/get-is-active';
import { Boat } from '@shared/models/boat';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';
import { FILTER_OPERATORS_DEFAULT_STRING_ALT } from '@core/datafilter/constants';

export const boatsTableData: U2bTableData = {
    tableId: BcmUserTableSettingKey.Boats,
    columnDefinitions:  [
        {
            property: 'avatar',
            name: 'Avatar',
            hideNameInHeader: true,
            isActive: getIsActive(),
            isAvatar: true,
            avatarAttribute: 'image',
            fixedWidth: '60',
        },
        {
            property: 'name',
            name: 'Bootsname',
            isActive: getIsActive(),
            translationKey: 'boatName',
            filter: {},
        },
        {
            property: 'licensePlate',
            name: 'Kennzeichen',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'manufacturer',
            name: 'Hersteller',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'type',
            name: 'Typ',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'length',
            name: 'Länge&nbsp;(m)',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Decimal,
            minWidth: '140',
            fixedWidth: '140',
            filter: {
                fieldType: FilterFieldType.Numeric
            },
        },
        {
            property: 'lengthOverall',
            name: 'LÜA&nbsp;(m)',
            infoTooltip: {
                body: 'Länge über Alles in Meter'
            },
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Decimal,
            minWidth: '140',
            fixedWidth: '140',
            filter: {
                fieldType: FilterFieldType.Numeric
            },
        },
        {
            property: 'width',
            name: 'Breite&nbsp;(m)',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Decimal,
            minWidth: '140',
            fixedWidth: '140',
            filter: {
                fieldType: FilterFieldType.Numeric
            },
        },
        {
            property: 'depth',
            name: 'Tiefe',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Decimal,
            filter: {
                fieldType: FilterFieldType.Numeric
            },
        },
        {
            property: 'berth',
            name: 'Liegeplatz',
            isActive: getIsActive(),
            externalComponent: U2bColumnDefinitionExternalComponent.BcmBerthLink,
            filter: {
                operators: FILTER_OPERATORS_DEFAULT_STRING_ALT
            },
        },
        {
            property: 'electricMeter',
            name: 'Stromzähler',
            isActive: getIsActive(false, false, false),
            externalComponent: U2bColumnDefinitionExternalComponent.ElectricMeterAssignments,
            filter: {
                property: 'electricMeterAssignments',
                compareAttribute: 'cabinet?.handle',
            },
        },
        {
            property: 'owner',
            name: 'Eigner',
            translationKey: 'boatOwner',
            isActive: getIsActive(),
            externalComponent: U2bColumnDefinitionExternalComponent.BcmOwnerLink,
            filter: {
                property: 'ownerName',
            },
        },
        {
            property: 'HIN',
            name: 'HIN',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'model',
            name: 'Modell',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'hasGasSystem',
            name: 'Gasanlage',
            isActive: getIsActive(false, false, false),
            parseItem: (boat: Boat) => boat.hasGasSystem ? '✔' : '',
            filter: {
                property: 'hasGasSystem',
                fieldType: FilterFieldType.Boolean
            },
        },
        // new display columns from ticket 3386
        {
            property: 'weight',
            name: 'Gewicht',
            isActive: getIsActive(false, false, false),
            parseItem: (boat: Boat) => boat.weight,
            filter: {},
        },
        {
            property: 'constructionYear',
            name: 'Baujahr',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'launchingYear',
            name: 'Erstwasserung',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'ownershipSince',
            name: 'Besitzer seit',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Date,
            parseItem: (boat: Boat) => (boat.owner || boat.ownerCompany)?.isOwnerSince,
            filter: {
                fieldType: FilterFieldType.Date
            },
        },
        {
            property: 'portOfRegistry',
            name: 'Heimathafen',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'insurer',
            name: 'Versicherer',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'nrz',
            name: 'NRZ',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Decimal,
            filter: {
                fieldType: FilterFieldType.Numeric
            },
            excludeIf: tenant => tenant.form !== 'Binnenhafen'
        },
        {
            property: 'brz',
            name: 'BRZ',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Decimal,
            filter: {
                fieldType: FilterFieldType.Numeric
            },
            excludeIf: tenant => tenant.form !== 'Binnenhafen'
        },
        {
            property: 'Rufzeichen',
            name: 'Rufzeichen',
            isActive: getIsActive(false, false, false),
            excludeIf: tenant => tenant.form !== 'Binnenhafen',
            parseItem: (boat: Boat) => boat.callSign,
            filter: {
                fieldType: FilterFieldType.Text,
                property: 'callSign',
            }
        },
        {
            property: 'tdw',
            name: 'DWT',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Decimal,
            filter: {
                fieldType: FilterFieldType.Numeric
            },
            excludeIf: tenant => tenant.form !== 'Binnenhafen'
        },
        {
            property: 'imoNr',
            name: 'IMO-Nr.',
            isActive: getIsActive(false, false, false),
            filter: {},
            excludeIf: tenant => tenant.form !== 'Binnenhafen'
        },
        {
            property: 'callSign',
            name: 'Rufzeichen',
            isActive: getIsActive(false, false, false),
            filter: {},
            excludeIf: tenant => tenant.form !== 'Binnenhafen'
        },
    ]
};
