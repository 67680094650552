import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { InvoiceApiService } from '../../@shared/services';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    invoice: IInvoice;

    constructor(
        private _invoiceApiService: InvoiceApiService
    ) {
    }

    saveInvoice(invoice: any/*Invoice*/): Observable<number> {
        return this._invoiceApiService.add(invoice);
    }

    getInvoiceTestPDF(invoice: any/*Invoice*/): Observable<number> {
        return this._invoiceApiService.getInvoiceTestPDF(invoice);
    }
}
