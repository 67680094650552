import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { forkJoin, Observable, Subject } from 'rxjs';
import { Berth } from '@shared/models/berth';
import { HttpParams } from '@angular/common/http';
import { BerthApiService } from '@modules/bcm/@shared/services';
import { debounceTime, filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { U2bBerthValidators } from '@shared/validators/berth/berth-validators';
import { isPlainObject } from '@shared/functions/is-plain-object';
import { isString } from '@shared/functions/is-string';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { toSqlDateTime } from '@core/functions/to-date-string';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { Pier } from '@shared/models/pier';

@Component({
    selector: 'form-widget-berth-only',
    templateUrl: './form-widget-berth-only.component.html',
})
export class FormWidgetBerthOnlyComponent implements OnInit, OnDestroy {

    private _unsubscribeAll = new Subject();

    @Input()
    readonly = false;

    @Input()
    headline = 'Liegeplatz wählen';

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    displayDimensions = false;

    @Input()
    set selectBerth(value: Berth) {
        if (value?.id || value?.id !== this._selectBerth?.id) {
            this._selectBerth = this.berths.find(berth => berth.id === value.id) || value;

            if (this.berthFormGroup?.get('berth')) {
                this.berthFormGroup.get('berth').setValue(this._selectBerth);
            }
        }
    }

    get selectBerth(): Berth {
        return this._selectBerth;
    }

    private _selectBerth: Berth;

    @Input()
    givenBerths: Berth[];

    @Input()
    personOrCompanyName: string;

    @Input()
    berthRequired = true;

    @Input()
    slimmedView = false;

    @Input()
    groupPiers = false;

    pierBerthGroups: Berth[][] = [];

    berthFormGroup: UntypedFormGroup;

    berths: Berth[] = [];
    berth: Berth;

    loadingBerths = false;

    topList: Berth[] = [];

    bottomList: Berth[] = [];

    filteredPierBerthGroups: Observable<Berth[][]>;

    filteredTopList$: Observable<Berth[]>;

    filteredBottomList$: Observable<Berth[]>;

    selectedBerthProductPriceTotal: number;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _productService: ProductService,
                private _berthApiService: BerthApiService) {
    }

    ngOnInit(): void {
        this.berth = this.selectBerth;

        this._createForm();

        setTimeout(() => {
            this.parentFormGroup.addControl('berthForm', this.berthFormGroup);

            if (this.parentFormGroup.get('dateRangeForm')?.value) {
                this.parentFormGroup
                    .get('dateRangeForm')
                    .valueChanges
                    .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME))
                    .subscribe(() => this._loadBerths(this.givenBerths));
            }

            this._loadBerths(this.givenBerths);
        });

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    public displayBerthWith(berth: any): string {

        if (isString(berth)) {
            return berth;
        }

        if (!isPlainObject(berth)) {
            return '';
        }

        const displayWith = [berth.handle];

        if (berth.pier?.handle) {
            displayWith.push(berth.pier?.handle);
        }

        if (berth.hasActiveAssignments()) {
            displayWith.push(berth.getActiveAssignments()?.boat?.name);
        }

        return displayWith.join(' - ');
    }

    private _createForm(): void {
        this.berthFormGroup = this._formBuilder.group({
            onlyFree: [!this.slimmedView],
            groupPier: [this.groupPiers],
            berth: [this.selectBerth, this.berthRequired ? [
                U2bValidators.required('Bitte Liegeplatz auswählen'),
                U2bBerthValidators.berthExists()
            ] : []],
            note: [null, [U2bValidators.maxLength(1024)]]
        });

        this.berthFormGroup
            .get('onlyFree')
            .valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME))
            .subscribe(() => this._loadBerths(this.givenBerths));

        this.berthFormGroup
            .get('groupPier')
            .valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME))
            .subscribe((groupPier) => {
                this.groupPiers = groupPier;
                this._loadBerths(this.givenBerths);
            });

        this.parentFormGroup
            .valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                map(value => ({
                    tenantRelationForm: value?.tenantRelationForm,
                    boatForm: value?.boatForm,
                    berthForm: value?.berthForm
                })),
                pairwise(),
                filter(([prev, current]) => {
                    return prev.tenantRelationForm?.tenantRelation?.id !== current.tenantRelationForm?.tenantRelation?.id ||
                        prev.boatForm?.boat?.id !== current.boatForm?.boat?.id ||
                        prev.berthForm?.berth?.id !== current.berthForm?.berth?.id;
                }),
                map(([prev, current]) => current.berthForm?.berth)
            )
            .subscribe((berth: Berth) => {
                this.berth = berth;
                this._updateDynPrices();
            });

    }

    private _updateDynPrices(): void {
        const boat = this.parentFormGroup.get('boatForm')?.value?.boat;
        const tenantRelation = this.parentFormGroup.get('tenantRelationForm')?.value?.tenantRelation;

        const parameters = {boat, berth: this.berth, tenantRelation};

        const dynPrices$ = [];

        for (const product of (this.berth?.products || []).filter(p => p.hasDynamicPrice)) {
            dynPrices$.push(
                this._productService.evaluatePriceRule(product, parameters, product.quantity)
                    .pipe(map(dynamicPrice => ({
                        ...product,
                        price: dynamicPrice.rulePrice,
                        name: dynamicPrice.ruleName,
                        dynamicPrice
                    })))
            );
        }


        forkJoin(dynPrices$)
            .subscribe(result => {
                this.berth.products = [
                    ...result,
                    ...(this.berth?.products || []).filter(p => !p.hasDynamicPrice)
                ];

                this.selectedBerthProductPriceTotal = (this.berth?.products || [])
                    .reduce((accumulator: number, product) => {
                        return accumulator + ((product?.price || 0) * (product?.quantity || 0));
                    }, 0);
            });
    }

    private _loadBerths(givenBerths: Berth[] = []): void {
        this.topList = [];
        this.bottomList = [];

        this.loadingBerths = true;

        const formValue = this.berthFormGroup.getRawValue();

        const {
            from,
            to
        } = this.parentFormGroup.get('dateRangeForm')?.getRawValue() || this.parentFormGroup.getRawValue() || {};

        const params = new HttpParams()
            .set('onlyFree', formValue.onlyFree || '')
            .set('from', toSqlDateTime(from) || '')
            .set('to', toSqlDateTime(to) || '');

        this.berths = [];

        this._berthApiService
            .getAll(params)
            .subscribe(berths => {

                this.berths = berths;

                if (this.groupPiers) {

                    berths.sort((a, b) => {
                        const compare = a.pier?.handle.localeCompare(b.pier?.handle);
                        if (compare === 0) {
                            return a.handle.localeCompare(b.handle);
                        } else if (compare === undefined) {
                            return 1;
                        }
                        return compare;
                    });

                    this.pierBerthGroups = [];

                    let currentPier: Pier = null;
                    let currentGroup: Berth[] = [];

                    for (const berth of berths) {
                        if (currentPier?.id !== berth.pier?.id) {
                            if (currentGroup.length) {
                                this.pierBerthGroups.push(currentGroup);
                            }
                            currentPier = berth.pier;
                            currentGroup = [];
                        }

                        currentGroup.push(berth);
                    }

                    const groupNoPier: Berth[] = berths
                        .filter(berth => !berth.pier)
                        .sort((a, b) => a.handle.localeCompare(b.handle));

                    if (groupNoPier.length) {
                        this.pierBerthGroups = [groupNoPier, ...this.pierBerthGroups];
                    }

                } else {

                    for (const berth of berths) {
                        if (givenBerths.find(givenBerth => givenBerth.id === berth?.id)) {
                            this.topList.push(berth);
                        } else {
                            this.bottomList.push(berth);
                        }
                    }

                }
            })
            .add(() => {
                this.loadingBerths = false;

                const searchValue = this.berthFormGroup.get('berth').value;
                this.berthFormGroup.get('berth').setValue(searchValue);
            });

        this.filteredPierBerthGroups = this.berthFormGroup.get('berth').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterPierBerthGroups(value, this.pierBerthGroups) : this.pierBerthGroups)
            );

        this.filteredTopList$ = this.berthFormGroup.get('berth').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterBerths(value, this.topList) : this.topList)
            );

        this.filteredBottomList$ = this.berthFormGroup.get('berth').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterBerths(value, this.bottomList) : this.bottomList)
            );
    }

    private _filterPierBerthGroups(search: string, pierBerthGroups: Berth[][]): Berth[][] {
        const filteredPierBerthGroups = [];

        for (const berths of pierBerthGroups) {
            const filteredBerths = this._filterBerths(search, berths);
            if (filteredBerths.length) {
                filteredPierBerthGroups.push(filteredBerths);
            }
        }

        return filteredPierBerthGroups;
    }

    private _filterBerths(search: string, berths: Berth[]): Berth[] {
        return berths.filter(berth => berth.handle.toLowerCase().includes(search.toLowerCase()));
    }

    onClickRemoveBerth(): void {
        this.berth = null;
        this.berthFormGroup.get('berth').setValue(null);
        this.berthFormGroup.markAsDirty();
    }
}
