import { Component } from '@angular/core';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { v4 as uuidv4 } from 'uuid';
import { AbstractControlOptions, FormBuilder } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BcmBookingTraveler, IBcmBookingTraveler } from '@shared/models/bcm-booking';
import { BaseArrayTabComponent } from '@sharedComponents/dialogs/booking-dialog/tabs/abstract/base-array-tab.component';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { U2bValidators } from '@shared/validators/validators';

@UntilDestroy()
@Component({
    selector: 'booking-travelers-tab',
    templateUrl: './travelers-tab.component.html',
    styleUrls: ['./travelers-tab.component.scss']
})
export class TravelersTabComponent extends BaseArrayTabComponent<IBcmBookingTraveler, BcmBookingTraveler> {

    constructor(
        bookingDialogService: BookingDialogService,
        formBuilder: FormBuilder,
    ) {
        super(
            bookingDialogService,
            formBuilder,
            BookingAttribute.TRAVELERS,
            BcmBookingTraveler
        );
    }

    formTemplate(value: BcmBookingTraveler): {
        controls: { [key: string]: any },
        options?: AbstractControlOptions | null
    } {
        return {
            controls: {
                id: [value?.id || null],
                firstName: [value?.firstName || null, U2bValidators.required('Der Vorname ist erforderlich')],
                lastName: [value?.lastName || null, U2bValidators.required('Der Nachname ist erforderlich')],
                email: [value?.email || null],
                phone: [value?.phone || null],
                birthDate: [value?.birthDate || null],
                person: [value?.person || null],
                uuid: [value?.uuid || uuidv4()],
            },
        };
    }

    removeTraveler(index: number): void {
        // we dont set the deleted flag here, because the travelers are completely removed before saving in the backend
        // therefore we can simply remove the traveler from the formArray
        this.formArray.removeAt(index);
    }

}
