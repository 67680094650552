import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { Invoice } from '@shared/models/invoice';

export class InvoiceCollection {
    id: number;
    subject: string;
    invoiceDate: Date;
    dueDate: Date;
    note: string;
    vestingPeriodFrom: Date;
    vestingPeriodUntil: Date;
    invoices: Invoice[];
    insertedOn: Date;
}

@Injectable({
    providedIn: 'root'
})
export class InvoiceCollectionsApiService extends BcmApiService {

    getAll(params: HttpParams = new HttpParams()): Observable<InvoiceCollection[]> {
        return super.get<any[]>('invoice-collections', params);
    }

    getPdf(id: number, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`invoice-collections/${id}/pdf`, params);
    }

    getZip(id: number, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`invoice-collections/${id}/zip`, params);
    }

    sendMails(id: number, body: {option: number}): Observable<any> {
        return super.post(`invoice-collections/${id}/send-mails`, body);
    }

    create(invoiceRecipients: any[], settings: any, wordTemplateId: number, params?: HttpParams): Observable<{
        success: number,
        failed: any[],
        invoiceCollection: any,
        duration: number,
    }> {
        return super.post('invoice-collections', {invoiceRecipients, settings, wordTemplateId}, params);
    }

    createTest(invoiceRecipients: any[], settings: any, fileFormat: any, params?: HttpParams): Observable<any> {
        return super.postWithBlobResponse(`invoice-collections/test-run/${fileFormat}`, {invoiceRecipients, settings}, params);
    }
}
