<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Platzhalter (Shortcodes)</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <mat-tab-group dynamicHeight="true"
                       [selectedIndex]="selectedTabIndex"
                       (selectedIndexChange)="selectedTabIndex = $event"
                       (selectedTabChange)="tabGroup2.realignInkBar()">

            <mat-tab label="Allgemein">
                <u2b-message customClass="mt-16">
                    <header>Hinweis</header>
                    Diese Platzhalter sind in <strong>allen</strong> Vertrags- und E-Mail-Vorlagen nutzbar.
                </u2b-message>

                <table class="simple table-sidebar">
                    <thead>
                    <tr>
                        <th>Platzhalter</th>
                        <th>Beschreibung</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr ngxClipboard [cbContent]="'[DATUM]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM]</td>
                        <td>Aktuelles Datum im Format <strong>TT.MM.JJJJ</strong>,
                            z.B. {{dateToday | dfnsFormat : 'dd.MM.yyyy' }}</td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[UHRZEIT]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[UHRZEIT] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[UHRZEIT]</td>
                        <td>Aktuelle Uhrzeit im Format <strong>HH:MM</strong>,
                            z.B. {{dateToday | dfnsFormat : 'HH:mm' }}</td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[Jahr]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[Jahr] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[JAHR]</td>
                        <td>Aktuelles Jahr im Format <strong>JJJJ</strong>, z.B. {{dateToday | dfnsFormat : 'yyyy' }}
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[MONAT]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[MONAT] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[MONAT]</td>
                        <td>Aktueller Monat im Format <strong>MM</strong>, z.B. {{dateToday | dfnsFormat : 'MM' }}</td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[MONAT_TEXT]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[MONAT_TEXT] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[MONAT_TEXT]</td>
                        <td>Aktueller Monat in <strong>Textform</strong>,
                            z.B. {{dateToday | dfnsFormat : 'MMMM' : {locale: locale} }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[FOLGEJAHR_DATUM]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[FOLGEJAHR_DATUM] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[FOLGEJAHR_DATUM]</td>
                        <td>Datum des Folgejahrs im Format <strong>TT.MM.JJJJ</strong>,
                            z.B. {{dateToday | dfnsAddYears : 1 | dfnsFormat : 'dd.MM.yyyy' }}</td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[FOLGEJAHR_JAHR]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[FOLGEJAHR_JAHR] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[FOLGEJAHR_JAHR]</td>
                        <td>Folgejahr im Format <strong>JJJJ</strong>,
                            z.B. {{dateToday | dfnsAddYears : 1 | dfnsFormat : 'yyyy' }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_VON]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_VON] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_VON]</td>
                        <td>
                            <strong>Wenn vorhanden</strong>, Datum "von" im Format <strong>TT.MM.JJJJ</strong>,
                            z.B. {{dateToday | dfnsFormat : 'dd.MM.yyyy' }}
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_VON_UHRZEIT]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_VON_UHRZEIT] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_VON_UHRZEIT]</td>
                        <td>
                            <strong>Wenn vorhanden</strong>, Uhrzeit im Format <strong>HH:MM</strong>,
                            z.B. {{dateToday | dfnsFormat : 'HH:mm' }}
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_VON_JAHR]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_VON_JAHR] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_VON_JAHR]</td>
                        <td>
                            <strong>Wenn vorhanden</strong>, Jahr im Format <strong>JJJJ</strong>,
                            z.B. {{dateToday | dfnsFormat : 'yyyy' }}
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_VON_MONAT]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_VON_MONAT] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_VON_MONAT]</td>
                        <td><strong>Wenn vorhanden</strong>, Monat im Format <strong>MM</strong>,
                            z.B. {{dateToday | dfnsFormat : 'MM' }}</td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_VON_MONAT_TEXT]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_VON_MONAT_TEXT] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_VON_MONAT_TEXT]</td>
                        <td><strong>Wenn vorhanden</strong>, Monat in <strong>Textform</strong>,
                            z.B. {{dateToday | dfnsFormat : 'MMMM' : {locale: locale} }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;</td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_BIS]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_BIS] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_BIS]</td>
                        <td>
                            <strong>Wenn vorhanden</strong>, Datum "bis" im Format <strong>TT.MM.JJJJ</strong>,
                            z.B. {{dateToday | dfnsFormat : 'dd.MM.yyyy' }}
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_BIS_UHRZEIT]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_BIS_UHRZEIT] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_BIS_UHRZEIT]</td>
                        <td>
                            <strong>Wenn vorhanden</strong>, Uhrzeit im Format <strong>HH:MM</strong>,
                            z.B. {{dateToday | dfnsFormat : 'HH:mm' }}
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_BIS_JAHR]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_BIS_JAHR] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_BIS_JAHR]</td>
                        <td>
                            <strong>Wenn vorhanden</strong>, Jahr im Format <strong>JJJJ</strong>,
                            z.B. {{dateToday | dfnsFormat : 'yyyy' }}
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_BIS_MONAT]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_BIS_MONAT] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_BIS_MONAT]</td>
                        <td><strong>Wenn vorhanden</strong>, Monat im Format <strong>MM</strong>,
                            z.B. {{dateToday | dfnsFormat : 'MM' }}</td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[DATUM_BIS_MONAT_TEXT]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[DATUM_BIS_MONAT_TEXT] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[DATUM_BIS_MONAT_TEXT]</td>
                        <td><strong>Wenn vorhanden</strong>, Monat in <strong>Textform</strong>,
                            z.B. {{dateToday | dfnsFormat : 'MMMM' : {locale: locale} }}</td>
                    </tr>
                    </tbody>
                </table>
            </mat-tab>

            <mat-tab label="E-Mail">
                <u2b-message customClass="mt-16">
                    <header>Hinweis</header>
                    Diese Platzhalter sind in <strong>allen</strong> Vertrags- und E-Mail-Vorlagen nutzbar.
                </u2b-message>

                <table class="simple table-sidebar">
                    <thead>
                    <tr>
                        <th>Platzhalter</th>
                        <th>Beschreibung</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr ngxClipboard [cbContent]="'[ANREDE]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[ANREDE] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[ANREDE]</td>
                        <td>
                            Feld <strong>Anrede E-Mail</strong> aus Stammdaten<br>
                            Wenn nicht hinterlegt: "Sehr geehrte Damen und Herren"
                        </td>
                    </tr>
                    </tbody>
                </table>
            </mat-tab>

            <mat-tab label="Belege">
                <u2b-message customClass="mt-16">
                    <header>Hinweis</header>
                    Diese Platzhalter sind nur in E-Mail-Templates gültig, die für die Mailings
                    <strong>{{'invoiceCollection' | translate}}</strong>, <strong>{{'invoice' | translate}}</strong> oder
                    <strong>Gutschrift</strong> verwendet werden.
                </u2b-message>

                <table class="simple table-sidebar">
                    <thead>
                    <tr>
                        <th>Platzhalter</th>
                        <th>Beschreibung</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr ngxClipboard [cbContent]="'[BELEG_NUMMER]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[BELEG_NUMMER] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[BELEG_NUMMER]</td>
                        <td>
                            Belegnummer des jeweils aktuellen Belegs, der versandt wird.
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[BELEG_DATUM]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[BELEG_DATUM] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[BELEG_DATUM]</td>
                        <td>
                            Datum des jeweils aktuellen Belegs, der versandt wird.
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[BELEG_DATUM_FAELLIG]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[BELEG_DATUM_FAELLIG] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[BELEG_DATUM_FAELLIG]</td>
                        <td>
                            Fälligkeitsdatum des jeweils aktuellen Belegs, der versandt wird.
                        </td>
                    </tr>
                    </tbody>
                </table>
            </mat-tab>

            <mat-tab label="Belegvorlagen">
                <u2b-message customClass="mt-16">
                    <header>Hinweis</header>
                    Diese Platzhalter sind nur für Belegvorlagen innerhalb des Feldes "Belegvorlagen (Word)"
                    gültig.
                </u2b-message>

                <mat-tab-group dynamicHeight="true"
                               #tabGroup2
                               [(selectedIndex)]="selectedContractsTabIndex"
                               (selectedIndexChange)="selectedContractsTabIndex = $event"
                               (selectedTabChange)="tabGroup3.realignInkBar(); tabGroup4.realignInkBar()">

                    <mat-tab label="Allgemein">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr ngxClipboard [cbContent]="'[BETREFF]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BETREFF] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BETREFF]</td>
                                <td>
                                    Gibt den Betreff an, der in der Rechnungsmaske definiert wird.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[IHR_ZEICHEN]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[IHR_ZEICHEN] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[IHR_ZEICHEN]</td>
                                <td>
                                    Gibt den Wert aus "Ihr Zeichen" an, das in der Rechnungsmaske definiert wird.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[ERSTELLER_NAME]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[ERSTELLER_NAME] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[ERSTELLER_NAME]</td>
                                <td>
                                    Gibt den Vor- und Nachname des Beleg-Erstellers an.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[ERSTELLER_EMAIL]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[ERSTELLER_EMAIL] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[ERSTELLER_EMAIL]</td>
                                <td>
                                    Gibt die Email Adresse des Beleg-Erstellers an.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[BELEG_ART]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BELEG_ART] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BELEG_ART]</td>
                                <td>
                                    Entspricht der Art des Belegs ({{'invoice' | translate}}, Barbeleg, etc.),
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[BELEG_NUMMER]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BELEG_NUMMER] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BELEG_NUMMER]</td>
                                <td>
                                    Entspricht dem Feld "Rechnungsnummer" bzw. "Gutschriftsnummer" auf dem manuellen
                                    Beleg.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[BELEG_DATUM]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BELEG_DATUM] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BELEG_DATUM]</td>
                                <td>
                                    Entspricht dem Feld "Rechnungsdatum" bzw. "Gutschriftsdatum" auf dem manuellen
                                    Beleg.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[BELEG_DATUM_FAELLIG]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BELEG_DATUM_FAELLIG] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BELEG_DATUM_FAELLIG]</td>
                                <td>
                                    Entspricht dem Feld "Fälligkeitsdatum" auf dem manuellen Beleg.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[SONSTIGE_ANGABEN]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[SONSTIGE_ANGABEN] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[SONSTIGE_ANGABEN]</td>
                                <td>
                                    Entspricht dem Feld "Sonstige Angaben (optional)" auf dem manuellen Beleg.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[ZAHLUNGSART]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[ZAHLUNGSART] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[ZAHLUNGSART]</td>
                                <td>
                                    Entspricht der Zahlungsart.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[BANKVERBINDUNG]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BANKVERBINDUNG] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BANKVERBINDUNG]</td>
                                <td>
                                    Entspricht dem Feld "Bankverbindung" auf dem manuellen Beleg.<br>
                                    => Name der Bank des Hafens
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[IBAN]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[IBAN] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[IBAN]</td>
                                <td>
                                    Entspricht dem Feld "IBAN" auf dem manuellen Beleg.<br>
                                    => IBAN der Kontoverbindung des Hafens
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[DEBITORENNUMMER]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[DEBITORENNUMMER] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[DEBITORENNUMMER]</td>
                                <td>
                                    Entspricht dem Feld "Debitorennummer" auf dem manuellen Beleg.
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[QR_CODE]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[QR_CODE] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[QR_CODE]</td>
                                <td>
                                    An dieser Stelle im Template wird ein von der TSE erzeugter QR Code hinterlegt (nur
                                    bei Barzahlung).
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </mat-tab>

                    <mat-tab label="Rechnungsempfänger (RE)">

                        <mat-tab-group dynamicHeight="true"
                                       #tabGroup3
                                       [(selectedIndex)]="selectedContractsTabIndex2"
                                       (selectedIndexChange)="selectedContractsTabIndex2 = $event">

                            <mat-tab label="Allgemein">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[RE_GEBURTSDATUM]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_GEBURTSDATUM] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_GEBURTSDATUM]</td>
                                        <td>
                                            Geburtsdatum des Rechnungsempfängers (Person) im Format TT.MM.JJJJ.<br>
                                            <em>01.01.1970</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_IDENTIFIKATIONSNUMMER]'"
                                        (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_IDENTIFIKATIONSNUMMER] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_IDENTIFIKATIONSNUMMER]</td>
                                        <td>
                                            Identifikationsnummer des Rechnungsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_STEUERNUMMER]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_STEUERNUMMER] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_STEUERNUMMER]</td>
                                        <td>
                                            Gibt die Steuernummer des Rechnungsempfängers (Person oder Organisation) an,
                                            die in der Rechnungsmaske definiert wird.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Adresse">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[RE_ADRESSE]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_ADRESSE] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[RE_ADRESSE]</td>
                                        <td>
                                            Gesamte Adresse mit Komma separiert (Person oder
                                            Organisation):<br>
                                            <em>
                                                Max Mustermann,
                                                Musterweg 12,
                                                12345 Musterhausen,
                                                Deutschland
                                            </em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_ADRESSE_UMBRUCH]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_ADRESSE_UMBRUCH] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_ADRESSE_UMBRUCH]</td>
                                        <td>
                                            Gesamte Adresse mit Zeilenumbrüchen (Person oder Organisation).<br>
                                            <em>
                                                Max Mustermann<br>
                                                Musterweg 12<br>
                                                12345 Musterhausen<br>
                                                Deutschland
                                            </em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_ANREDE]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_ANREDE] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_ANREDE]</td>
                                        <td>
                                            Anrede des Rechnungsempfängers ("Herr", "Frau" oder "Sehr geehrte Damen und
                                            Herren")
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_NAME]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_NAME] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_NAME]</td>
                                        <td>
                                            Voller Name des Rechnungsempfängers (Person [Vorname und
                                            Nachname] oder Organisation [Name und Rechtsform]).<br>
                                            <em>Max Mustermann</em><br>
                                            <em>Erika Mustermann</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_NAME_PLUS_TITEL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_NAME_PLUS_TITEL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_NAME_PLUS_TITEL]</td>
                                        <td>
                                            Voller Name des Rechnungsempfängers (Person [Titel, Vorname und
                                            Nachname] oder Organisation [Name und Rechtsform]).<br>
                                            <em>Dr. Max Mustermann</em><br>
                                            <em>Dr. Erika Mustermann</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_STR]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_STR] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_STR]</td>
                                        <td>
                                            Straße + Hausnummer des Rechnungsempfängers (Person oder Organisation).<br>
                                            <em>Musterweg 12</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_PLZ]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_PLZ] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_PLZ]</td>
                                        <td>
                                            Postleitzahl des Rechnungsempfängers (Person oder Organisation).<br>
                                            <em>12345</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_STADT]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_STADT] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_STADT]</td>
                                        <td>
                                            Wohnort des Rechnungsempfängers (Person oder Organisation).<br>
                                            <em>Musterhausen</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_LAND]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_LAND] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_LAND]</td>
                                        <td>
                                            Land des Rechnungsempfängers (Person oder Organisation).<br>
                                            <em>Deutschland</em>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Kontakt">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[RE_MAIL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_MAIL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_MAIL]</td>
                                        <td>
                                            E-Mail Adresse des Rechnungsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_MOBIL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_MOBIL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_MOBIL]</td>
                                        <td>
                                            Mobilnummer des Rechnungsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_TEL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_TEL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_TEL]</td>
                                        <td>
                                            Telefonnummer des Rechnungsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_FAX]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_FAX] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_FAX]</td>
                                        <td>
                                            Faxnummer des Rechnungsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <!--                                    <tr ngxClipboard [cbContent]="'[RE_WEB]'" (cbOnSuccess)="copied($event)"-->
                                    <!--                                        matTooltip="[RE_WEB] in Zwischenablage kopieren"-->
                                    <!--                                        style="cursor: pointer">-->
                                    <!--                                        <td>[RE_WEB]</td>-->
                                    <!--                                        <td>-->
                                    <!--                                            Webseite des Rechnungsempfängers (Person oder Organisation).-->
                                    <!--                                        </td>-->
                                    <!--                                    </tr>-->
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Bankdaten">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[RE_BANK_KONTOINHABER]'"
                                        (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_BANK_KONTOINHABER] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_BANK_KONTOINHABER]</td>
                                        <td>
                                            Kontoinhaber der Bank des Rechnungsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_BANK_NAME]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_BANK_NAME] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_BANK_NAME]</td>
                                        <td>
                                            Name der Bank des Rechnungsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_BANK_IBAN]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_BANK_IBAN] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_BANK_IBAN]</td>
                                        <td>
                                            IBAN des Rechnungsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RE_BANK_BIC]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RE_BANK_BIC] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RE_BANK_BIC]</td>
                                        <td>
                                            BIC des Rechnungsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                        </mat-tab-group>

                    </mat-tab>

                    <mat-tab label="Rechnungsaussteller (RA)">

                        <mat-tab-group dynamicHeight="true"
                                       #tabGroup4
                                       [(selectedIndex)]="selectedContractsTabIndex3"
                                       (selectedIndexChange)="selectedContractsTabIndex3 = $event">

                            <mat-tab label="Allgemein">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[RA_UST_ID]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_UST_ID] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_UST_ID]</td>
                                        <td>
                                            Gibt die Umsatzsteuer-Identifikationsnummer des Hafens an.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Adresse">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[RA_UST_ID]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_UST_ID] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_UST_ID]</td>
                                        <td>
                                            Gibt die Umsatzsteuer-Identifikationsnummer des Hafens an.
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_ADRESSE]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_ADRESSE] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[RA_ADRESSE]</td>
                                        <td>
                                            Gesamte Adresse mit Komma separiert:<br>
                                            <em>
                                                {{bcmService.tenant?.name}},
                                                {{bcmService.tenant?.street}},
                                                {{bcmService.tenant?.postCode}} {{bcmService.tenant?.city}},
                                                {{bcmService.tenant?.country}}
                                            </em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_ADRESSE_UMBRUCH]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_ADRESSE_UMBRUCH] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_ADRESSE_UMBRUCH]</td>
                                        <td>
                                            Gesamte Adresse mit Zeilenumbrüchen:<br>
                                            <em>
                                                {{bcmService.tenant?.name}}<br>
                                                {{bcmService.tenant?.street}}<br>
                                                {{bcmService.tenant?.postCode}} {{bcmService.tenant?.city}}<br>
                                                {{bcmService.tenant?.country}}
                                            </em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_NAME]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_NAME] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[RA_NAME]</td>
                                        <td>
                                            Name des Rechnungsausstellers ({{bcmService.tenant?.name}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_STR]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_STR] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_STR]</td>
                                        <td>
                                            Straße + Hausnummer des Rechnungsausstellers ({{bcmService.tenant?.street}}
                                            ).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_PLZ]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_PLZ] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_PLZ]</td>
                                        <td>
                                            Postleitzahl des Rechnungsausstellers ({{bcmService.tenant?.postCode}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_STADT]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_STADT] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_STADT]</td>
                                        <td>
                                            Wohnort des Rechnungsausstellers ({{bcmService.tenant?.city}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_LAND]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_LAND] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_LAND]</td>
                                        <td>
                                            Land des Rechnungsausstellers ({{bcmService.tenant?.country}}).
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Kontakt">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[RA_MAIL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_MAIL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_MAIL]</td>
                                        <td>
                                            E-Mail Adresse des Rechnungsausstellers ({{bcmService.tenant?.mail}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_MOBIL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_MOBIL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_MOBIL]</td>
                                        <td>
                                            Mobilnummer des Rechnungsausstellers ({{bcmService.tenant?.mobile}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_TEL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_TEL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_TEL]</td>
                                        <td>
                                            Telefonnummer des Rechnungsausstellers ({{bcmService.tenant?.phone}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_FAX]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_FAX] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_FAX]</td>
                                        <td>
                                            Faxnummer des Rechnungsausstellers ({{bcmService.tenant?.fax}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_WEB]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_WEB] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_WEB]</td>
                                        <td>
                                            Webseite des Rechnungsausstellers ({{bcmService.tenant?.website}}).
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Bankdaten">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[RA_BANK_KONTOINHABER]'"
                                        (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_BANK_KONTOINHABER] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[RA_BANK_KONTOINHABER]</td>
                                        <td>
                                            Konto des Rechnungsausstellers ({{bcmService.tenant?.name}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_BANK_NAME]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_BANK_NAME] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[RA_BANK_NAME]</td>
                                        <td>
                                            Bankname des Rechnungsausstellers
                                            ({{bcmService.tenant?.primaryBankAccount?.bankName}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_BANK_IBAN]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_BANK_IBAN] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[RA_BANK_IBAN]</td>
                                        <td>
                                            IBAN des Rechnungsausstellers
                                            ({{bcmService.tenant?.primaryBankAccount?.iban}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[RA_BANK_BIC]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[RA_BANK_BIC] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[RA_BANK_BIC]</td>
                                        <td>
                                            BIC des Rechnungsausstellers ({{bcmService.tenant?.primaryBankAccount?.bic}}
                                            ).
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                        </mat-tab-group>

                    </mat-tab>

                    <mat-tab label="Positionen">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[POSITIONEN_BETRAG_NETTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITIONEN_BETRAG_NETTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITIONEN_BETRAG_NETTO]</td>
                                <td>
                                    Gesamtbetrag Netto aller erfassten Positionen.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITIONEN_BETRAG_BRUTTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITIONEN_BETRAG_BRUTTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITIONEN_BETRAG_BRUTTO]</td>
                                <td>
                                    Gesamtbetrag Brutto aller erfassten Positionen.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITIONEN_BETRAG_MWST]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITIONEN_BETRAG_MWST] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITIONEN_BETRAG_MWST]</td>
                                <td>
                                    Gesamtbetrag MwSt aller erfassten Positionen.
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_NAME]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_NAME] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_NAME]</td>
                                <td>
                                    Name der N. Position, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_MENGE]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_MENGE] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_MENGE]</td>
                                <td>
                                    Menge der N. Position, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_TEXT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_TEXT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_TEXT]</td>
                                <td>
                                    Sub-Text (Leistungszeitraum, Dynamischer Preis, etc.) der N. Position, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_EINHEIT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_EINHEIT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_EINHEIT]</td>
                                <td>
                                    Einheit der N. Position, wobei N = Zahl von 1 bis N. Z.B. "Stück".
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_BETRAG_NETTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_BETRAG_NETTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_BETRAG_NETTO]</td>
                                <td>
                                    Betrag Netto der N. Position, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_BETRAG_NETTO_GESAMT]'"
                                (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_BETRAG_NETTO_GESAMT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_BETRAG_NETTO_GESAMT]</td>
                                <td>
                                    Betrag Netto der N. Position, wobei N = Zahl von 1 bis N multipliziert mit der Menge
                                    der N. Position.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_BETRAG_BRUTTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_BETRAG_BRUTTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_BETRAG_BRUTTO]</td>
                                <td>
                                    Betrag Brutto der N. Position, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_BETRAG_BRUTTO_GESAMT]'"
                                (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_BETRAG_BRUTTO_GESAMT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_BETRAG_BRUTTO_GESAMT]</td>
                                <td>
                                    Betrag Brutto der N. Position, wobei N = Zahl von 1 bis N multipliziert mit der
                                    Menge der N. Position.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_BETRAG_MWST]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_BETRAG_MWST] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_BETRAG_MWST]</td>
                                <td>
                                    Betrag MwSt der N. Position, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_BETRAG_MWST_GESAMT]'"
                                (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_BETRAG_MWST_GESAMT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_BETRAG_MWST_GESAMT]</td>
                                <td>
                                    Betrag MwSt der N. Position, wobei N = Zahl von 1 bis N multipliziert mit der Menge
                                    der N. Position.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_MWST]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_MWST] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_MWST]</td>
                                <td>
                                    MwSt der N. Position als ganze Zahl <strong>ohne</strong> %-Zeichen, wobei N = Zahl
                                    von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_RABATT_PROZENT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_RABATT_PROZENT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_RABATT_PROZENT]</td>
                                <td>
                                    Rabattwert der N. Position als ganze Zahl <strong>ohne</strong> %-Zeichen, wobei
                                    N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_KOSTENSTELLE_NAME]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_KOSTENSTELLE_NAME] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_KOSTENSTELLE_NAME]</td>
                                <td>
                                    Name der zugewiesenen Kostenstelle.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_KOSTENSTELLE_KUERZEL]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_KOSTENSTELLE_KUERZEL] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_KOSTENSTELLE_KUERZEL]</td>
                                <td>
                                    Kürzel der zugewiesenen Kostenstelle.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[POSITION_N_KOSTENSTELLE_NUMMER]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[POSITION_N_KOSTENSTELLE_NUMMER] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[POSITION_N_KOSTENSTELLE_NUMMER]</td>
                                <td>
                                    Nummer der zugewiesenen Kostenstelle.
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[#POSITIONEN] [/]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[#POSITIONEN] [/] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[#POSITIONEN] ... [/]</td>
                                <td>
                                    <u>! Nur für Word Templates !</u><br>
                                    Dieser Platzhalter gibt an, dass alle Positionen ausgegeben werden. <br>
                                    Die Bezeichnungen sind analog zu den der N. Position.<br>
                                    Allerdings muss auf den vorderen Teil verzichtet werden (z.B. aus [POSITION_N_NAME]
                                    wird nur [NAME]).<br>
                                    Abschließend muss mit [/] gekennzeichnet werden, dass die Ausgabe aller Positionen
                                    an dieser Stelle beendet wird.<br>
                                    Das bedeutet der Inhalt, der zwischen den Platzhaltern [#POSITIONEN] und [/] steht,
                                    wird für jedes Produkt wiederholt.<br><br>
                                    Ein Beispiel zum Auflisten aller Namen und Mengen der Positionen wäre:<br>
                                    [#POSITIONEN] [NAME] [MENGE] [/]<br><br>
                                    Die verschiedenen Positionen werden dann im Dialogfenster der Vertragsgenerierung
                                    abgefragt und können beliebig erweitert werden.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                    <mat-tab label="Mehrwertsteuer">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr ngxClipboard [cbContent]="'[BETRAG_MWST_N]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BETRAG_MWST_N] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BETRAG_MWST_N]</td>
                                <td>
                                    Betrag der Mehrwertsteuer für den Steuersatz N.<br>
                                    Beispiel: BETRAG_MWST_19 (hier erhält man die den Betrag der Summen mit 19 %
                                    Mehrwertsteuer).
                                </td>
                            </tr>

                            <tr ngxClipboard [cbContent]="'[BETRAG_MWST]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BETRAG_MWST] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BETRAG_MWST]</td>
                                <td>
                                    Gesamtbetrag der Mehrwertsteuer.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[#BETRAG_MWST_LISTE] [/BETRAG_MWST_LISTE]'"
                                (cbOnSuccess)="copied($event)"
                                matTooltip="[[#BETRAG_MWST_LISTE] [/BETRAG_MWST_LISTE] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[#BETRAG_MWST_LISTE]<br>
                                    [SATZ] % - [SUMME] €
                                    <br>[/BETRAG_MWST_LISTE]
                                </td>
                                <td>
                                    Hier werden alle Steuersätze (größer als 0 %) mit der Summe der Beträge ausgegeben.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                </mat-tab-group>

            </mat-tab>

            <mat-tab label="Vertragsvorlagen">
                <u2b-message customClass="mt-16">
                    <header>Hinweis</header>
                    Diese Platzhalter sind nur für Vertragsvorlagen innerhalb des Feldes "Vertragsinhalt" gültig.
                </u2b-message>

                <mat-tab-group dynamicHeight="true"
                               #tabGroup2
                               [(selectedIndex)]="selectedContractsTabIndex"
                               (selectedIndexChange)="selectedContractsTabIndex = $event"
                               (selectedTabChange)="tabGroup3.realignInkBar(); tabGroup4.realignInkBar()">

                    <mat-tab label="Vertragsempfänger (VE)">

                        <mat-tab-group dynamicHeight="true"
                                       #tabGroup3
                                       [(selectedIndex)]="selectedContractsTabIndex2"
                                       (selectedIndexChange)="selectedContractsTabIndex2 = $event">

                            <mat-tab label="Adresse">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[VE_ADRESSE]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_ADRESSE] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[VE_ADRESSE]</td>
                                        <td>
                                            Gesamte Adresse mit Komma separiert (Person oder
                                            Organisation):<br>
                                            <em>
                                                Max Mustermann,
                                                Musterweg 12,
                                                12345 Musterhausen,
                                                Deutschland
                                            </em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_ADRESSE_UMBRUCH]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_ADRESSE_UMBRUCH] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_ADRESSE_UMBRUCH]</td>
                                        <td>
                                            Gesamte Adresse mit Zeilenumbrüchen (Person oder Organisation).<br>
                                            <em>
                                                Max Mustermann<br>
                                                Musterweg 12<br>
                                                12345 Musterhausen<br>
                                                Deutschland
                                            </em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_ANREDE]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_ANREDE] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_ANREDE]</td>
                                        <td>
                                            Anrede des Vertragsempfängers ("Herr", "Frau" oder "Sehr geehrte Damen und
                                            Herren")
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_NAME]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_NAME] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_NAME]</td>
                                        <td>
                                            Voller Name des Vertragsempfängers (Person [Vorname und
                                            Nachname] oder Organisation [Name und Rechtsform]).<br>
                                            <em>Max Mustermann</em><br>
                                            <em>Erika Mustermann</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_NAME_PLUS_TITEL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_NAME_PLUS_TITEL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_NAME_PLUS_TITEL]</td>
                                        <td>
                                            Voller Name des Vertragsempfängers (Person [Titel, Vorname und
                                            Nachname] oder Organisation [Name und Rechtsform]).<br>
                                            <em>Dr. Max Mustermann</em><br>
                                            <em>Dr. Erika Mustermann</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_STR]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_STR] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_STR]</td>
                                        <td>
                                            Straße + Hausnummer des Vertragsempfängers (Person oder Organisation).<br>
                                            <em>Musterweg 12</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_PLZ]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_PLZ] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_PLZ]</td>
                                        <td>
                                            Postleitzahl des Vertragsempfängers (Person oder Organisation).<br>
                                            <em>12345</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_STADT]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_STADT] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_STADT]</td>
                                        <td>
                                            Wohnort des Vertragsempfängers (Person oder Organisation).<br>
                                            <em>Musterhausen</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_LAND]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_LAND] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_LAND]</td>
                                        <td>
                                            Land des Vertragsempfängers (Person oder Organisation).<br>
                                            <em>Deutschland</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_GEBURTSDATUM]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_GEBURTSDATUM] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_GEBURTSDATUM]</td>
                                        <td>
                                            Geburtsdatum des Vertragsempfängers (Person) im Format TT.MM.JJJJ.<br>
                                            <em>01.01.1970</em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_IDENTIFIKATIONSNUMMER]'"
                                        (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_IDENTIFIKATIONSNUMMER] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_IDENTIFIKATIONSNUMMER]</td>
                                        <td>
                                            Identifikationsnummer des Vertragsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Kontakt">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[VE_MAIL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_MAIL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_MAIL]</td>
                                        <td>
                                            E-Mail Adresse des Vertragsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_MOBIL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_MOBIL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_MOBIL]</td>
                                        <td>
                                            Mobilnummer des Vertragsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_TEL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_TEL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_TEL]</td>
                                        <td>
                                            Telefonnummer des Vertragsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_FAX]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_FAX] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_FAX]</td>
                                        <td>
                                            Faxnummer des Vertragsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <!--                                    <tr ngxClipboard [cbContent]="'[VE_WEB]'" (cbOnSuccess)="copied($event)"-->
                                    <!--                                        matTooltip="[VE_WEB] in Zwischenablage kopieren"-->
                                    <!--                                        style="cursor: pointer">-->
                                    <!--                                        <td>[VE_WEB]</td>-->
                                    <!--                                        <td>-->
                                    <!--                                            Webseite des Vertragsempfängers (Person oder Organisation).-->
                                    <!--                                        </td>-->
                                    <!--                                    </tr>-->
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Bankdaten">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[VE_BANK_KONTOINHABER]'"
                                        (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_BANK_KONTOINHABER] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_BANK_KONTOINHABER]</td>
                                        <td>
                                            Kontoinhaber der Bank des Vertragsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_BANK_NAME]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_BANK_NAME] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_BANK_NAME]</td>
                                        <td>
                                            Name der Bank des Vertragsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_BANK_IBAN]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_BANK_IBAN] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_BANK_IBAN]</td>
                                        <td>
                                            IBAN des Vertragsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_BANK_BIC]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_BANK_BIC] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_BANK_BIC]</td>
                                        <td>
                                            BIC des Vertragsempfängers (Person oder Organisation).
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                        </mat-tab-group>

                    </mat-tab>

                    <mat-tab label="Vertragsaussteller (VA)">

                        <mat-tab-group dynamicHeight="true"
                                       #tabGroup4
                                       [(selectedIndex)]="selectedContractsTabIndex3"
                                       (selectedIndexChange)="selectedContractsTabIndex3 = $event">

                            <mat-tab label="Adresse">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[VA_ADRESSE]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_ADRESSE] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[VA_ADRESSE]</td>
                                        <td>
                                            Gesamte Adresse mit Komma separiert:<br>
                                            <em>
                                                {{bcmService.tenant?.name}},
                                                {{bcmService.tenant?.street}},
                                                {{bcmService.tenant?.postCode}} {{bcmService.tenant?.city}},
                                                {{bcmService.tenant?.country}}
                                            </em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VA_ADRESSE_UMBRUCH]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_ADRESSE_UMBRUCH] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VA_ADRESSE_UMBRUCH]</td>
                                        <td>
                                            Gesamte Adresse mit Zeilenumbrüchen:<br>
                                            <em>
                                                {{bcmService.tenant?.name}}<br>
                                                {{bcmService.tenant?.street}}<br>
                                                {{bcmService.tenant?.postCode}} {{bcmService.tenant?.city}}<br>
                                                {{bcmService.tenant?.country}}
                                            </em>
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VA_NAME]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_NAME] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[VA_NAME]</td>
                                        <td>
                                            Name des Vertragsausstellers ({{bcmService.tenant?.name}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_STR]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_STR] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_STR]</td>
                                        <td>
                                            Straße + Hausnummer des Vertragsausstellers ({{bcmService.tenant?.street}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_PLZ]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_PLZ] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_PLZ]</td>
                                        <td>
                                            Postleitzahl des Vertragsausstellers ({{bcmService.tenant?.postCode}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_STADT]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_STADT] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_STADT]</td>
                                        <td>
                                            Wohnort des Vertragsausstellers ({{bcmService.tenant?.city}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VE_LAND]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VE_LAND] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VE_LAND]</td>
                                        <td>
                                            Land des Vertragsausstellers ({{bcmService.tenant?.country}}).
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Kontakt">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[VA_MAIL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_MAIL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VA_MAIL]</td>
                                        <td>
                                            E-Mail Adresse des Vertragsausstellers ({{bcmService.tenant?.mail}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VA_MOBIL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_MOBIL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VA_MOBIL]</td>
                                        <td>
                                            Mobilnummer des Vertragsausstellers ({{bcmService.tenant?.mobile}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VA_TEL]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_TEL] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VA_TEL]</td>
                                        <td>
                                            Telefonnummer des Vertragsausstellers ({{bcmService.tenant?.phone}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VA_FAX]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_FAX] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VA_FAX]</td>
                                        <td>
                                            Faxnummer des Vertragsausstellers ({{bcmService.tenant?.fax}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VA_WEB]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_WEB] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VA_WEB]</td>
                                        <td>
                                            Webseite des Vertragsausstellers ({{bcmService.tenant?.website}}).
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                            <mat-tab label="Bankdaten">
                                <table class="simple table-sidebar mt-24">
                                    <thead>
                                    <tr>
                                        <th>Platzhalter</th>
                                        <th>Beschreibung</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr ngxClipboard [cbContent]="'[VA_BANK_KONTOINHABER]'"
                                        (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_BANK_KONTOINHABER] in Zwischenablage kopieren"
                                        style="cursor: pointer">
                                        <td>[VA_BANK_KONTOINHABER]</td>
                                        <td>
                                            Konto des Vertragsausstellers ({{bcmService.tenant?.name}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VA_BANK_NAME]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_BANK_NAME] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[VA_BANK_NAME]</td>
                                        <td>
                                            Bankname des Vertragsausstellers
                                            ({{bcmService.tenant?.primaryBankAccount?.bankName}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VA_BANK_IBAN]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_BANK_IBAN] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[VA_BANK_IBAN]</td>
                                        <td>
                                            IBAN des Vertragsausstellers
                                            ({{bcmService.tenant?.primaryBankAccount?.iban}}).
                                        </td>
                                    </tr>
                                    <tr ngxClipboard [cbContent]="'[VA_BANK_BIC]'" (cbOnSuccess)="copied($event)"
                                        matTooltip="[VA_BANK_BIC] in Zwischenablage kopieren" style="cursor: pointer">
                                        <td>[VA_BANK_BIC]</td>
                                        <td>
                                            BIC des Vertragsausstellers ({{bcmService.tenant?.primaryBankAccount?.bic}}
                                            ).
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </mat-tab>

                        </mat-tab-group>

                    </mat-tab>

                    <mat-tab label="Vertragsmitinhaber (max. 4)">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[VMI_1_NAME_KOMPLETT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_1_NAME_KOMPLETT] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_1_NAME_KOMPLETT]</td>
                                <td>
                                    Kompletter Name des 1. Vertragsmitinhabers (Person: inkl. Anrede, Titel, Vor- und
                                    Nachname, Organisation: inkl. Gesellschaftsform und Name der Organisation)
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_1_STR]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_1_STR] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_1_STR]</td>
                                <td>
                                    Straße des 1. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_1_PLZ]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_1_PLZ] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_1_PLZ]</td>
                                <td>
                                    PLZ des 1. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_1_ORT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_1_ORT] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_1_ORT]</td>
                                <td>
                                    Ort des 1. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_2_NAME_KOMPLETT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_2_NAME_KOMPLETT] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_2_NAME_KOMPLETT]</td>
                                <td>
                                    Kompletter Name des 2. Vertragsmitinhabers (Person: inkl. Anrede, Titel, Vor- und
                                    Nachname, Organisation: inkl. Gesellschaftsform und Name der Organisation)
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_2_STR]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_2_STR] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_2_STR]</td>
                                <td>
                                    Straße des 2. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_2_PLZ]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_2_PLZ] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_2_PLZ]</td>
                                <td>
                                    PLZ des 2. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_2_ORT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_2_ORT] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_2_ORT]</td>
                                <td>
                                    Ort des 2. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_3_NAME_KOMPLETT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_3_NAME_KOMPLETT] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_3_NAME_KOMPLETT]</td>
                                <td>
                                    Kompletter Name des 3. Vertragsmitinhabers (Person: inkl. Anrede, Titel, Vor- und
                                    Nachname, Organisation: inkl. Gesellschaftsform und Name der Organisation)
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_3_STR]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_3_STR] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_3_STR]</td>
                                <td>
                                    Straße des 3. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_3_PLZ]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_3_PLZ] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_3_PLZ]</td>
                                <td>
                                    PLZ des 3. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_3_ORT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_3_ORT] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_3_ORT]</td>
                                <td>
                                    Ort des 3. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_4_NAME_KOMPLETT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_4_NAME_KOMPLETT] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_4_NAME_KOMPLETT]</td>
                                <td>
                                    Kompletter Name des 4. Vertragsmitinhabers (Person: inkl. Anrede, Titel, Vor- und
                                    Nachname, Organisation: inkl. Gesellschaftsform und Name der Organisation)
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_4_STR]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_4_STR] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_4_STR]</td>
                                <td>
                                    Straße des 4. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_4_PLZ]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_4_PLZ] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_4_PLZ]</td>
                                <td>
                                    PLZ des 4. Vertragsmitinhabers
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[VMI_4_ORT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[VMI_4_ORT] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[VMI_4_ORT]</td>
                                <td>
                                    Ort des 4. Vertragsmitinhabers
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                    <mat-tab [label]="'boat' | translate">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[BOOT_NAME]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_NAME] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BOOT_NAME]</td>
                                <td>
                                    Name des Bootes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BOOT_TYP]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_TYP] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BOOT_TYP]</td>
                                <td>
                                    Typ des Bootes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BOOT_MODELL]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_MODELL] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BOOT_MODELL]</td>
                                <td>
                                    Modell des Bootes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BOOT_HERSTELLER]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_HERSTELLER] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BOOT_HERSTELLER]</td>
                                <td>
                                    Hersteller des Bootes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BOOT_KENNZEICHEN]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_KENNZEICHEN] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BOOT_KENNZEICHEN]</td>
                                <td>
                                    Kennzeichen des Bootes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BOOT_HIN]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_HIN] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BOOT_HIN]</td>
                                <td>
                                    HIN / Rumpfnummer des Bootes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BOOT_LAENGE]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_LAENGE] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BOOT_LAENGE]</td>
                                <td>
                                    Länge ü. A. des Bootes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BOOT_BREITE]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_BREITE] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BOOT_BREITE]</td>
                                <td>
                                    Breite des Bootes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BOOT_GEWICHT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_GEWICHT] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BOOT_GEWICHT]</td>
                                <td>
                                    Gewicht des Bootes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BOOT_TIEFGANG]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BOOT_TIEFGANG] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BOOT_TIEFGANG]</td>
                                <td>
                                    Tiefgang des Bootes.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                    <mat-tab label="Liegeplatz">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[LP_NAME]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[LP_NAME] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[LP_NAME]</td>
                                <td>
                                    Name / Bezeichnung des Liegeplatzes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[LP_STEG_NAME]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[LP_STEG_NAME] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[LP_STEG_NAME]</td>
                                <td>
                                    Name / Bezeichnung des Steges.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[LP_BREITE]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[LP_BREITE] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[LP_BREITE]</td>
                                <td>
                                    Breite des Liegeplatzes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[LP_LAENGE]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[LP_LAENGE] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[LP_LAENGE]</td>
                                <td>
                                    Länge des Liegeplatzes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[LP_QM]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[LP_QM] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[LP_QM]</td>
                                <td>
                                    Fläche des Liegeplatzes (m²).
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                    <mat-tab label="Lagerplatz">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[LAPL_NAME]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[LAPL_NAME] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[LAPL_NAME]</td>
                                <td>
                                    Name / Bezeichnung des Lagerplatzes.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[LAPL_QM]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[LAPL_QM] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[LAPL_QM]</td>
                                <td>
                                    Fläche des Lagerplatzes (m²).
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                    <mat-tab label="Baukostenzuschuss">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[BKZ_BETRAG_NETTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_BETRAG_NETTO] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BKZ_BETRAG_NETTO]</td>
                                <td>
                                    Betrag in Netto wie angegeben.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BKZ_MWST]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_MWST] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BKZ_MWST]</td>
                                <td>
                                    MwSt wie angegeben.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BKZ_MWST_BETRAG]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_MWST_BETRAG] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BKZ_MWST_BETRAG]</td>
                                <td>
                                    Betrag der MwSt wie angegeben.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BKZ_BETRAG_GESAMT_NETTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_BETRAG_GESAMT_NETTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BKZ_BETRAG_GESAMT_NETTO]</td>
                                <td>
                                    Betrag in Netto wie angegeben.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BKZ_BETRAG_GESAMT_BRUTTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_BETRAG_GESAMT_BRUTTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BKZ_BETRAG_GESAMT_BRUTTO]</td>
                                <td>
                                    Betrag in Brutto wie angegeben.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BKZ_BETRAG_FAELLIG_ZUM]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_BETRAG_FAELLIG_ZUM] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BKZ_BETRAG_FAELLIG_ZUM]</td>
                                <td>
                                    Fälligkeitsdatum wie angegeben.
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">Teilzahlung</td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BKZ_TZ_1_BETRAG]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_TZ_1_BETRAG] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BKZ_TZ_1_BETRAG]</td>
                                <td>
                                    Teilzahlung 1 - Betrag wie angegeben.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BKZ_TZ_1_FAELLIG_ZUM]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_TZ_1_FAELLIG_ZUM] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BKZ_TZ_1_FAELLIG_ZUM]</td>
                                <td>
                                    Teilzahlung 1 - Fälligkeitsdatum wie angegeben.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BKZ_TZ_2_BETRAG]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_TZ_2_BETRAG] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BKZ_TZ_2_BETRAG]</td>
                                <td>
                                    Teilzahlung 2 - Betrag wie angegeben.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BKZ_TZ_2_FAELLIG_ZUM]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BKZ_TZ_2_FAELLIG_ZUM] in Zwischenablage kopieren" style="cursor: pointer">
                                <td>[BKZ_TZ_2_FAELLIG_ZUM]</td>
                                <td>
                                    Teilzahlung 2 - Fälligkeitsdatum wie angegeben.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                    <mat-tab label="Produkte">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[PRODUKTE_BETRAG_NETTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKTE_BETRAG_NETTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKTE_BETRAG_NETTO]</td>
                                <td>
                                    Gesamtbetrag Netto aller erfassten Produkte.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKTE_BETRAG_BRUTTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKTE_BETRAG_BRUTTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKTE_BETRAG_BRUTTO]</td>
                                <td>
                                    Gesamtbetrag Brutto aller erfassten Produkte.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKTE_BETRAG_MWST]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKTE_BETRAG_MWST] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKTE_BETRAG_MWST]</td>
                                <td>
                                    Gesamtbetrag MwSt aller erfassten Produkte.
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_NAME]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_NAME] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_NAME]</td>
                                <td>
                                    Name des N. Produkts, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_MENGE]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_MENGE] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_MENGE]</td>
                                <td>
                                    Menge des N. Produkts, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_EINHEIT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_EINHEIT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_EINHEIT]</td>
                                <td>
                                    Einheit des N. Produkts, wobei N = Zahl von 1 bis N. Z.B. "Stück".
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_BETRAG_NETTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_BETRAG_NETTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_BETRAG_NETTO]</td>
                                <td>
                                    Betrag Netto des N. Produkts, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_BETRAG_NETTO_GESAMT]'"
                                (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_BETRAG_NETTO_GESAMT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_BETRAG_NETTO_GESAMT]</td>
                                <td>
                                    Betrag Netto des N. Produkts, wobei N = Zahl von 1 bis N multipliziert mit der Menge
                                    des N. Produkts.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_BETRAG_BRUTTO]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_BETRAG_BRUTTO] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_BETRAG_BRUTTO]</td>
                                <td>
                                    Betrag Brutto des N. Produkts, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_BETRAG_BRUTTO_GESAMT]'"
                                (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_BETRAG_BRUTTO_GESAMT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_BETRAG_BRUTTO_GESAMT]</td>
                                <td>
                                    Betrag Brutto des N. Produkts, wobei N = Zahl von 1 bis N multipliziert mit der
                                    Menge des N. Produkts.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_BETRAG_MWST]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_BETRAG_MWST] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_BETRAG_MWST]</td>
                                <td>
                                    Betrag MwSt des N. Produkts, wobei N = Zahl von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_BETRAG_MWST_GESAMT]'"
                                (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_BETRAG_MWST_GESAMT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_BETRAG_MWST_GESAMT]</td>
                                <td>
                                    Betrag MwSt des N. Produkts, wobei N = Zahl von 1 bis N multipliziert mit der Menge
                                    des N. Produkts.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[PRODUKT_N_MWST]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[PRODUKT_N_MWST] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[PRODUKT_N_MWST]</td>
                                <td>
                                    MwSt des N. Produkts als ganze Zahl <strong>ohne</strong> %-Zeichen, wobei N = Zahl
                                    von 1 bis N.
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[#PRODUKTE] [/]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[[#PRODUKTE] [/] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[#PRODUKTE]<br>[/]</td>
                                <td>
                                    <u>! Nur für Word Templates !</u><br>
                                    Dieser Platzhalter gibt an, dass alle Produkte ausgegeben werden. <br>
                                    Die Bezeichnungen sind analog zu den des N. Produkts.<br>
                                    Allerdings muss auf den vorderen Teil verzichtet werden (z.B. aus [PRODUKT_N_NAME]
                                    wird nur [NAME]).<br>
                                    Abschließend muss mit [/] gekennzeichnet werden, dass die Ausgabe aller Produkte an
                                    dieser Stelle beendet wird.<br>
                                    Das bedeutet der Inhalt, der zwischen den Platzhaltern [#PRODUKTE] und [/] steht,
                                    wird für jedes Produkt wiederholt.<br><br>
                                    Ein Beispiel zum Auflisten aller Namen und Mengen der Produkte wäre:<br>
                                    [#PRODUKTE] [NAME] [MENGE] [/]<br><br>
                                    Die verschiedenen Produkte werden dann im Dialogfenster der Vertragsgenerierung
                                    abgefragt und können beliebig erweitert werden.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                    <mat-tab label="Schlüssel">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[SCHLUESSEL_NUMMER]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[SCHLUESSEL_NUMMER] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[SCHLUESSEL_NUMMER]</td>
                                <td>
                                    Schlüsselnummer des angegebenen Schlüssels.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                    <mat-tab label="Anhänge">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[ANHANG_TABELLE]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[ANHANG_TABELLE] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[ANHANG_TABELLE]</td>
                                <td>
                                    Tabellarische Aufstellung der Vertragsanhänge.
                                    <u2b-message type="warning" customClass="mt-12 mb-0">
                                        Wird der Platzhalter verwendet, ohne dass Anhänge hinzugefügt wurden,
                                        wird "Keine Angabe" an dieser Stelle ausgegeben.
                                    </u2b-message>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </mat-tab>

                    <mat-tab label="Sonstige">
                        <table class="simple table-sidebar mt-24">
                            <thead>
                            <tr>
                                <th>Platzhalter</th>
                                <th>Beschreibung</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ngxClipboard [cbContent]="'[%SIGNATUR][SIGNATUR_TEXT]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[%SIGNATUR][SIGNATUR_TEXT] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[%SIGNATUR][SIGNATUR_TEXT]</td>
                                <td>
                                    Signatur in Textform oder als Bild, je nach Auswahl bei der
                                    Vertragserstellung.<br><br>
                                    <b>Wichtig:</b> Bei Word Vorlagen müssen zwei Platzhalter in der Word-Datei
                                    hinterlegt werden:<br>
                                    [%SIGNATUR][SIGNATUR_TEXT]
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[SIGNATUR]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[SIGNATUR] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[SIGNATUR]</td>
                                <td>
                                    Signatur in Textform oder als Bild, je nach Auswahl bei der Vertragserstellung.<br>
                                    Gilt nicht für Word Vorlagen (siehe Eintrag oben).
                                </td>
                            </tr>
                            <tr ngxClipboard [cbContent]="'[BUCHUNGSNUMMER]'" (cbOnSuccess)="copied($event)"
                                matTooltip="[BUCHUNGSNUMMER] in Zwischenablage kopieren"
                                style="cursor: pointer">
                                <td>[BUCHUNGSNUMMER]</td>
                                <td>
                                    Buchungs- oder Reservierungsnummer, wenn im Vertragskontext verfügbar.
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <u2b-message customClass="mt-16">
                            <header>Hinweis</header>
                            <p>
                                Seit der Version 2022.1.7 ist es möglich, eigene Platzhalter in Verträgen zu definieren!
                                Verwende dazu am besten das Kürzel deines Hafens oder eine andere eindeutige
                                Schreibweise. Achte auch darauf, dass Du nicht den selben Namen wie bereits vorhandene
                                Platzhalter verwendest.
                            </p>
                            <p>
                                Ein Beispiel könnte sein: [MH_NAME_VERANSTALTUNG], wobei "MH" als Kürzel für "Mein
                                Hafen" verwendet wird. Eigene Platzhalter können einfach im Vertrag benutzt werden und
                                unser System bietet hierzu in der Vertragsmaske Freitext-Felder zum Befüllen an.
                            </p>
                        </u2b-message>
                    </mat-tab>

                </mat-tab-group>

            </mat-tab>

            <mat-tab label="Aufträge">
                <u2b-message customClass="mt-16">
                    <header>Hinweis</header>
                    Diese Platzhalter sind nur in E-Mail-Templates gültig, die für die Mailings
                    <strong>Arbeitsauftrag</strong> oder <strong>Arbeitsauftrag Änderung</strong> verwendet werden.
                </u2b-message>

                <table class="simple table-sidebar">
                    <thead>
                    <tr>
                        <th>Platzhalter</th>
                        <th>Beschreibung</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr ngxClipboard [cbContent]="'[AUTFRAG_NUMMER]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[AUTFRAG_NUMMER] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[AUTFRAG_NUMMER]</td>
                        <td>
                            Auftragsnummer des Auftrags. Z.B. "#0123"
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[AUTFRAG_TITEL]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[AUTFRAG_TITEL] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[AUTFRAG_TITEL]</td>
                        <td>
                            Titel des Auftrags. Z.B. "Kranen HD-1234, Max Mustermann"
                        </td>
                    </tr>
                    <tr ngxClipboard [cbContent]="'[AUFTRAG_BEGINN]'" (cbOnSuccess)="copied($event)"
                        matTooltip="[AUFTRAG_BEGINN] in Zwischenablage kopieren" style="cursor: pointer">
                        <td>[AUFTRAG_BEGINN]</td>
                        <td>
                            Zeitpunkt das Auftragsbeginns. Z.B. "{{dateToday | dfnsFormat : 'dd.MM.yyyy HH:mm' }}"
                        </td>
                    </tr>
                    </tbody>
                </table>
            </mat-tab>

            <mat-tab label="Mehr?">
                <u2b-message customClass="mt-16">
                    <header>Fehlen Dir Platzhalter?</header>
                    Sende uns eine E-Mail an <a href="mailto:support@up2boat.com">support@up2boat.com</a>.
                </u2b-message>
            </mat-tab>

        </mat-tab-group>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button data-test="B-Speichern" mat-flat-button
                color="accent"
                (click)="dialogRef.close(false)"
                class="save-button"
                aria-label="Speichern">
            Ausblenden
        </button>

    </div>
</div>
