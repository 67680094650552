import { MapBaseLayers } from '@modules/bcm/@shared/components/map/map.component';
import { U2bColumnDefinition } from '@core/components/layout/table/table.types';
import { Filter } from '@core/datafilter/filter';
import { BcmTenantApiClientView } from '@shared/models/bcm-tenant-api-client-view';

export enum BcmUserSettingKey {
    Navigation = 'navigation',
    DashboardSidebar = 'SIDEBAR',
    Dashboards = 'DASHBOARDS',
    TableDefaults = 'table_defaults',
    Map = 'map',
    InvoicePositionsForm = 'invoice_positions_form'
}

export enum BcmUserTableSettingKey {
    Persons = 'table_persons',
    Companies = 'table_companies',
    TenantRelations = 'table_tenant_relations',
    Products = 'table_products',
    // ProductCategories = 'table_product_categories',
    Boats = 'table_boats',
    Piers = 'table_piers',
    Berths = 'table_berths',
    BerthsScheduler = 'table_berths_scheduler',
    Storages = 'table_storages',
    ElectricMeters = 'table_electric_meters',
    // Keys = 'table_keys',
    // Seasons = 'table_seasons',
    Orders = 'table_order',
    Invoices = 'table_invoices',
    FinancialRecords = 'table_financial_records',
    FinancialRecordsWidget = 'table_financial_records_widget',
    // InvoicesCollections = 'table_invoice_collections',
    Mails = 'table_mails',
    // Mailings = 'table_mailings',
    // MailingTemplates = 'table_mailing_templates',
    // MailingRecipients = 'table_mailing_recipients',
    // Users = 'table_users',
    Documents = 'table_documents',
    Contracts = 'table_contract',
    // ContractTemplates = 'table_contract_templates',
    // ContractTemplatesWord = 'table_contract_templates_word',
    ContractAttachments = 'table_contract_attachments',
    Vouchers = 'table_vouchers',
    CostCenters = 'cost_centers',
    Absences = 'absences',
    BcmTenantApiClientView = 'table_bcm_tenant_api_client_view',
}

export interface BcmUserSettingsSection {
    [key: string]: any;
}

export interface BcmUserNavigationSettings extends BcmUserSettingsSection {
    state: any;
}

export interface BcmUserDashboardSidebarSettings extends BcmUserSettingsSection {
    SIDEBAR_OPEN: boolean;
}

export interface BcmUserDashboardsSettings extends BcmUserSettingsSection {
    dashboards: { name: string, id: string }[];
}

export interface BcmUserMapSettings extends BcmUserSettingsSection {
    selectedGoogleLayer: MapBaseLayers;
}

export interface BcmUserInvoicePositionsFormSettings extends BcmUserSettingsSection {
    vestingPeriodFromDefaultsToToday: boolean;
    vestingPeriodUntilDefaultsToToday: boolean;
}

export interface BcmUserTableDefaultSettings extends BcmUserSettingsSection {
    rowHeight?: 's' | 'm' | 'l';
    fontSize?: 's' | 'm' | 'l';
}

export interface BcmUserTablesSettings extends BcmUserSettingsSection {
    tableId: string;
    columnDefinitions: U2bColumnDefinition[];
}

export interface BcmUserTableFilterSettings extends BcmUserSettingsSection {
    filters?: Filter<unknown>[];
    lastActiveFilterName?: string;
}

export interface BcmUserSettings {
    [BcmUserSettingKey.Navigation]: BcmUserNavigationSettings;
    [BcmUserSettingKey.DashboardSidebar]: BcmUserDashboardSidebarSettings;
    [BcmUserSettingKey.Dashboards]: BcmUserDashboardsSettings;
    [BcmUserSettingKey.Map]: BcmUserMapSettings;
    [BcmUserSettingKey.TableDefaults]: BcmUserTableDefaultSettings;
    [BcmUserSettingKey.InvoicePositionsForm]: BcmUserInvoicePositionsFormSettings;
}
