<div class="with-loading-overlay">
    <form-widget-positions
            [readonly]="bookingDialogService.readonlyView"
            [positions]="positions"
            (positionsChanged)="bookingDialogService.positions.value = $event"
            (subscriptionsChanged)="bookingDialogService.subscriptions.value = $event"
            [subscriptions]="subscriptions"
            (invoiceCreated)="bookingDialogService.financialRecords.reload();bookingDialogService.positions.reload()"
            [person]="bookingDialogService.person.value"
            [company]="bookingDialogService.company.value"
            [tenantRelation]="bookingDialogService.tenantRelationAssignment.value?.tenantRelation"
            [berthAssignments]="bookingDialogService.berthAssignments.value"
            [boat]="bookingDialogService.boat.value"
            [travelers]="bookingDialogService.travelers.value"
            [showAddBerthPositionButton]="true">
    </form-widget-positions>

    <form-widget-subscription
            [readonly]="bookingDialogService.readonlyView"
            (subscriptionsChanged)="bookingDialogService.subscriptions.value = $event"
            [subscriptions]="subscriptions"
            [berthAssignments]="bookingDialogService.berthAssignments.value">
    </form-widget-subscription>

    <form-widget-financial-records
            (financialRecordsChanged)="bookingDialogService.financialRecords.reload();bookingDialogService.positions.reload()"
            [financialRecords]="bookingDialogService.financialRecords.value">
    </form-widget-financial-records>

    <ng-container *ngIf="bookingDialogService.positions?.loading">
        <div class="loading-overlay">
            <mat-spinner [diameter]="40"></mat-spinner>
        </div>
    </ng-container>
</div>
