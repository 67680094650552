import { TranslationService } from '@core/translation/translation.service';

export enum InvoiceTypes {
    Invoice = 'invoice', // Rechnung
    CancellationInvoice = 'cancellationInvoice', // Stornorechnung
    Crediting = 'crediting', // Gutschrift
    CancellationCrediting = 'cancellationCrediting', // Stornogutschrift
}

export enum ReceiptTypes {
    PayIn = 'payIn',
    Receipt = 'receipt',
    ReceiptError = 'error',
    CancellationReceipt = 'cancellationReceipt'
}

export enum InvoiceOrReceiptType {
    Invoice = 'invoice', // Rechnung
    InvoiceCollection = 'invoice_collection', // Sammelrechnung
    CancellationInvoice = 'cancellationInvoice', // Stornorechnung
    Crediting = 'crediting', // Gutschrift
    CancellationCrediting = 'cancellationCrediting', // Stornogutschrift
    Receipt = 'receipt',
    CancellationReceipt = 'cancellationReceipt',
    ReceiptError = 'error'
}

export const financialRecordTypeTranslationsDe: { [key in InvoiceTypes | ReceiptTypes]: string } = {
    [InvoiceTypes.Invoice]: TranslationService.translate('invoice'),
    [InvoiceTypes.CancellationInvoice]: TranslationService.translate('cancellationInvoice'),
    [InvoiceTypes.Crediting]: TranslationService.translate('crediting'),
    [InvoiceTypes.CancellationCrediting]: TranslationService.translate('cancellationCrediting'),

    [ReceiptTypes.PayIn]: TranslationService.translate('payIn'),
    [ReceiptTypes.Receipt]: TranslationService.translate('receipt'),
    [ReceiptTypes.ReceiptError]: TranslationService.translate('receiptError'),
    [ReceiptTypes.CancellationReceipt]: TranslationService.translate('cancellationReceipt'),
};
