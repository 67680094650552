<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Treffe eine Auswahl</span>
            <button data-test="B-Dialog schließen"  mat-icon-button (click)="dialogRef.close(false)" aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <div class="mb-24" fxLayout="column" fxLayoutAlign="start start">
                <mat-radio-group class="radio-group" formControlName="type">
                    <ng-container *ngFor="let entityType of selectableEntities">
                        <mat-radio-button class="radio-button" [value]="entityType">
                            {{entityTypeNames[entityType] | translate : null : entityTypeNames[entityType]}}
                        </mat-radio-button>
                    </ng-container>
                </mat-radio-group>
            </div>

            <ng-container *ngIf="formGroup.get('type').value === EntityType.Person">
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <app-input-select-person [control]="formGroup.get('person')"></app-input-select-person>
                </div>
            </ng-container>

            <ng-container *ngIf="formGroup.get('type').value === EntityType.Company">
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <app-input-select-company [control]="formGroup.get('company')"></app-input-select-company>
                </div>
            </ng-container>

            <ng-container *ngIf="formGroup.get('type').value === EntityType.Boat">
                <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                    <app-input-select-boat [control]="formGroup.get('boat')"></app-input-select-boat>
                </div>
            </ng-container>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button mat-flat-button
                (click)="save()"
                class="save-button mat-accent"
                [disabled]="isSaving || !formGroup.dirty"
                aria-label="Speichern">
            Übernehmen
        </button>

    </div>
</div>
