import { BehaviorSubject, EMPTY, merge, NEVER, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmBaseApiService } from './bcm-base-api.service';
import { BcmBaseState } from './bcm-base.state';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { FuseUtils } from '@fuse/utils';
import { DEFAULT_DEBOUNCE_TIME_SEARCH } from '@modules/bcm/@shared/constants';
import {
    parseHttpResponseItem,
    parseHttpResponseList,
    parseHttpResponsePage
} from '@shared/functions/parse-http-response';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { DataFilterService } from '@core/datafilter/data-filter.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { U2bColumnDefinition, U2bTableData } from '@core/components/layout/table/table.types';
import { FilesApiService } from '@modules/bcm/@shared/services';
import { IFile } from '@shared/interfaces/file';
import { isPlainObject } from '@shared/functions/is-plain-object';
import { FormArray, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Page } from '@shared/models/pagination';
import {
    TableHeaderAction,
    TablePredefinedFilter,
    TableRowAction
} from '@modules/bcm/@core/state-management/bcm-base.types';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';
import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import { FILTER_OPERATORS_DEFAULTS_BY_FIELD_TYPE } from '@core/datafilter/constants';
import { TranslationService } from '@core/translation/translation.service';
import { DataFilterOperationType } from '@core/datafilter/filter-operation-type';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { BcmBaseBulkChanges } from '@modules/bcm/@core/state-management/bcm-base-bulk-changes';

interface BaseEntity {
    id?: number;
}

const facadesMap = new Map<BcmUserTableSettingKey, BcmBaseFacade<BaseEntity, BaseEntity>>();

export abstract class BcmBaseFacade<Entity extends BaseEntity, RawEntity = unknown> extends BcmBaseApiService<Entity, RawEntity> {

    static readonly reloadById$: Subject<{ key: BcmUserTableSettingKey, item: BaseEntity }> = new Subject<{
        key: BcmUserTableSettingKey,
        item: BaseEntity
    }>();

    private readonly state: BcmBaseState<Entity>;

    public readonly searchTerm$ = new BehaviorSubject<string>('');

    protected readonly abstract resourcePath: string;

    public readonly abstract resourceNameSingular: string;

    public readonly abstract resourceNamePlural: string;

    // optional
    public readonly resourceNameSubHeadline: string;

    public readonly abstract resourceIconSingular: string;

    public readonly abstract resourceIconPlural: string;

    // optional
    public readonly resourceIconIsSVG?: boolean;

    public readonly abstract readPermission: BcmUserPermission;

    public readonly abstract writePermission: BcmUserPermission;

    public readonly abstract deletePermission: BcmUserPermission;

    // optional
    public readonly addNewButtonText: undefined | string;
    public readonly addNewCallback: undefined | (() => void);

    // optional
    public readonly headerActions: undefined | TableHeaderAction[];

    // optional
    public readonly rowActions: undefined | TableRowAction<Entity>[];

    // optional
    public readonly predefinedFilter: undefined | TablePredefinedFilter<Entity>[];

    // optional
    public readonly customSortAccessor: undefined | ((item: Entity, property: string) => string | number);

    // optional
    public readonly bulkChanges?: BcmBaseBulkChanges<Entity>;

    // ------------------------------------

    private _searchTerm$ = this.searchTerm$
        .pipe(
            debounceTime(DEFAULT_DEBOUNCE_TIME_SEARCH),
            distinctUntilChanged()
        );

    public get hasServerSideFiltering(): boolean {
        return this.tableDataDefaults.hasServerSideFiltering;
    }

    public rowClickHandler: undefined | ((item: Entity) => void);

    public get isLoading$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    public get selected$(): Observable<Entity> {
        return this.state.selected$;
    }

    public get list$(): Observable<Entity[]> {
        return this.state.list$;
    }

    public get page$(): Observable<Page<Entity>> {
        return this.state.page$;
    }

    public get filteredList$(): Observable<Entity[]> {
        const mergeSources: Observable<any>[] = [this._searchTerm$];

        if (this.dataFilterService) {
            mergeSources.push(this.dataFilterService.currentFilter$);
        }

        return merge(...mergeSources)
            .pipe(
                switchMap(() => {
                    const filterText = this.searchTerm$.getValue();
                    const currentFilter = this.dataFilterService?.currentFilter$.getValue();

                    return this.state.list$
                        .pipe(
                            switchMap(list => {

                                let filteredList = list;

                                if (currentFilter) {
                                    filteredList = currentFilter.dataFilter.match(list);
                                }

                                return of(FuseUtils.filterArrayByString(filteredList, filterText));
                            })
                        );
                }),
            );
    }

    private _formGroup: UntypedFormGroup;

    public get formGroup(): UntypedFormGroup {
        return this._formGroup;
    }

    public get hasRegisteredFormGroup(): boolean {
        return !!this._formGroup;
    }

    public get hasPristineFormGroup(): boolean {
        return this._formGroup?.pristine;
    }

    public dataFilterService!: DataFilterService<Entity>;

    public get tableId(): BcmUserTableSettingKey {
        return this.tableDataDefaults.tableId;
    }

    public get columnDefinitions(): U2bColumnDefinition[] {
        return this.tableDataDefaults.columnDefinitions || [];
    }

    public get uniqueIdentAttribute(): string {
        return this.tableDataDefaults.uniqueIdentAttribute || 'id';
    }

    private _lastListHttpParams: HttpParams;

    protected constructor(private modelClass: any,
                          private tableDataDefaults: U2bTableData,
                          protected bcmNavigationService: BcmNavigationService,
                          protected appNotificationService: AppNotificationService,
                          protected confirmDialogService: ConfirmDialogService,
                          private bcmUserSettingsFacade: BcmUserSettingsFacade, // todo: implement filter saving
                          protected httpClient: HttpClient,
                          protected bcmService: BcmService,
                          private filesApiService?: FilesApiService,
    ) {
        super(httpClient, bcmService);

        if (typeof modelClass !== 'function') {
            throw TypeError(`"${modelClass}" is not of type ctor.`);
        }

        facadesMap.set(tableDataDefaults.tableId, this);

        this.state = new BcmBaseState<Entity>(this.uniqueIdentAttribute);

        // needs to be done before DataFilterService is instantiated
        this.prepareColumnDefinitions();

        this.dataFilterService = new DataFilterService<Entity>(
            tableDataDefaults.tableId,
            this.columnDefinitions,
            this.hasServerSideFiltering
        );

        BcmBaseFacade.reloadById$.subscribe(data => {
            const facade = facadesMap.get(data.key) as BcmBaseFacade<Entity, RawEntity>;

            if (facade) {
                facade.updateStateFor(data.item as Entity);
            }
        });
    }

    // todo: maybe we need more than one? => updateStateFor(...item: Entity[])
    public updateStateFor(item: Entity, markFormAsDirty = false) {
        if (item) {
            this.state.updateStateFor(item);

            if (markFormAsDirty) {
                this.markFormAsDirty();
            }
        }
    }

    public saveLastUnsavedFormState() {
        if (!this.hasRegisteredFormGroup) {
            throw Error('<facade>.saveLastUnsavedFormState() can only be called if a formGroup was registered.');
        }
        if (this._formGroup.invalid) {
            this._formGroup.markAllAsTouched();
            this.appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }
        return this.addOrUpdate(this.state.selected, this._formGroup.value)
            .subscribe(() => this._formGroup.markAsPristine());
    }

    public registerCurrentFormGroup(formGroup: UntypedFormGroup) {
        this._formGroup = formGroup;
    }

    public markFormAsDirty() {
        this._formGroup.markAsDirty();
    }

    public createEmptyModelInstance(): Observable<Entity> {
        const selected = new this.modelClass();
        this.state.setSelected(selected);
        this.state.unsetUpdating();
        return of(selected);
    }

    // on setting a filter, all needs to get reset and new list/page needs to be loaded
    public setPredefinedFilter(predefinedFilter: TablePredefinedFilter<Entity>) {
        this.dataFilterService.resetActiveFilters();

        if (predefinedFilter.filters) {
            for (const filterRaw of Object.values(predefinedFilter.filters)) {
                const foundFormArray = this.dataFilterService.allFiltersFormGroup.get(filterRaw.property) as FormArray;

                if (foundFormArray) {
                    foundFormArray.push(new FormGroup({
                        operator: new FormControl(filterRaw.operator || filterRaw.columnDefinition.filter.operators[0]),
                        value: new FormControl(filterRaw.value),
                        operationType: new FormControl(filterRaw.operationType || DataFilterOperationType.And),
                        columnDefinition: new FormControl(filterRaw.columnDefinition)
                    }));
                }
            }
        }

        this.dataFilterService.forceNextFormValueChange();
        this.dataFilterService.allFiltersFormGroup.updateValueAndValidity();
    }

    public loadList(httpParams = new HttpParams()): Observable<Entity[]> {
        this.state.setUpdating();

        this._lastListHttpParams = httpParams;

        return this.getList(this.resourcePath, httpParams)
            .pipe(
                parseHttpResponseList<RawEntity, Entity>(this.modelClass),
                tap(list => this.state.setList(list)),
                finalize(() => this.state.unsetUpdating())
            );
    }

    public loadPage(httpParams: HttpParams = new HttpParams()): Observable<Page<Entity>> {
        this.state.setUpdating();

        this._lastListHttpParams = httpParams;

        return this.getPage(this.resourcePath, httpParams)
            .pipe(
                parseHttpResponsePage<RawEntity, Entity>(this.modelClass),
                tap(pageResult => this.state.setPage(pageResult)),
                finalize(() => this.state.unsetUpdating())
            );
    }

    public reloadCurrentListOrPage() {
        if (this.hasServerSideFiltering) {
            this.loadPage(this._lastListHttpParams).subscribe();
        } else {
            this.loadList(this._lastListHttpParams).subscribe();
        }
    }

    public reloadCurrentPage() {
        if (this.hasServerSideFiltering) {
            this.loadPage(this._lastListHttpParams).subscribe();
        }
    }

    public loadById(id: number, params = new HttpParams()): Observable<Entity> {
        this.state.setUpdating();

        if (!id) {
            return this.createEmptyModelInstance();
        }

        return this.get(`${this.resourcePath}/${id}`, params)
            .pipe(
                parseHttpResponseItem<RawEntity, Entity>(this.modelClass),
                tap(selected => this.state.setSelected(selected)),
                finalize(() => this.state.unsetUpdating())
            );
    }

    public reloadSelected(givenEntity?: Entity): Subscription {
        this.state.setUpdating();
        const selected = givenEntity || this.state.selected;
        return (
            selected && selected[this.uniqueIdentAttribute]
                ? this.get(`${this.resourcePath}/${selected[this.uniqueIdentAttribute]}`)
                : NEVER
        )
            .pipe(
                parseHttpResponseItem<RawEntity, Entity>(this.modelClass),
                tap(loadedItem => this.state.updateStateFor(loadedItem)),
                finalize(() => this.state.unsetUpdating())
            ).subscribe();
    }

    public add(item: Entity, params = new HttpParams(), navigateToAddedItem = true): Observable<Entity> {

        if (item) {

            this.state.setUpdating();

            return this.post(this.resourcePath, item, params)
                .pipe(
                    parseHttpResponseItem<RawEntity, Entity>(this.modelClass),
                    tap((addedItem) => {
                        this.state.addToList(addedItem);
                        this.appNotificationService.showSuccess('Datensatz gespeichert.');

                        if (navigateToAddedItem) {
                            this.bcmNavigationService.navigate([this.resourcePath, addedItem[this.uniqueIdentAttribute]]);
                        }
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return throwError(error);
                    }),
                    finalize(() => this.state.unsetUpdating())
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    public update(item: Entity, newItemData: Partial<Entity>, params = new HttpParams()): Observable<Entity> {

        if (item[this.uniqueIdentAttribute]) {

            this.state.setUpdating();

            const newItem = {
                ...item,
                ...newItemData
            };

            return this.put(`${this.resourcePath}/${item[this.uniqueIdentAttribute]}`, newItem, params)
                .pipe(
                    parseHttpResponseItem<RawEntity, Entity>(this.modelClass),
                    tap((updatedItem) => {
                        this.state.updateStateFor(updatedItem);
                        this.appNotificationService.showSuccess('Datensatz gespeichert.');
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return throwError(error);
                    }),
                    finalize(() => this.state.unsetUpdating())
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    public addOrUpdate(item: Entity, newItemData: Partial<Entity>, params?: HttpParams): Observable<Entity> {
        if (item[this.uniqueIdentAttribute]) {
            return this.update(item, newItemData, params);
        } else {
            return this.add({...item, ...newItemData}, params);
        }
    }

    public remove(item: Entity, name?: string, afterDeletionRedirectTo?: string): Observable<Entity> {

        name = name || this.getNameLike(item) || this.resourceNameSingular;

        // if it's null, it's by intention!
        if (afterDeletionRedirectTo === undefined) {
            afterDeletionRedirectTo = this.resourcePath;
        }

        return this.confirmDialogService
            .useWarnTheme()
            .setTitle(`${name} entfernen`)
            .setBody(`Bist Du sicher, dass Du ${name} unwiderruflich entfernen möchtest?`)
            .openAndReturnResult()
            .pipe(
                filter(result => result === true),
                switchMap(() => {
                    this.state.setUpdating();
                    return this.delete(`${this.resourcePath}/${item[this.uniqueIdentAttribute]}`);
                }),
                tap((response: any) => {
                    if (response) {
                        this.state.removeFromList(item);
                        this.reloadCurrentPage();
                        if (afterDeletionRedirectTo) {
                            this.bcmNavigationService.navigate(afterDeletionRedirectTo);
                        }
                        this.appNotificationService.showSuccess(`${this.resourceNameSingular} wurde gelöscht.`);
                    }
                }),
                catchError(() => {
                    this.appNotificationService.showError(`${this.resourceNameSingular} konnte nicht gelöscht werden.`);
                    return EMPTY;
                }),
                finalize(() => this.state.unsetUpdating())
            );
    }

    public removeMultiple(items: Entity[], afterDeletionRedirectTo = this.resourcePath): Observable<number[]> {

        const names = items.map(item => this.getNameLike(item));
        const uniqueIdentAttributeList = items.map(item => item[this.uniqueIdentAttribute]);

        return this.confirmDialogService
            .useWarnTheme()
            .setTitle(`${this.resourceNamePlural} entfernen`)
            .setBody(
                `<p>Bist Du sicher, dass Du alle hier aufgeführten ${this.resourceNamePlural} unwiderruflich entfernen möchtest?</p>` +
                `<ul>${names.map(name => `<li>${name}</li>`).join('')}</ul>`
            )
            .openAndReturnResult()
            .pipe(
                filter(result => result === true),
                switchMap(() => {
                    this.state.setUpdating();
                    return this.deleteMultiple(`${this.resourcePath}/${uniqueIdentAttributeList.join(',')}`);
                }),
                tap((deletedIdentifiers: number[]) => {
                    if (deletedIdentifiers) {
                        this.state.removeFromList(...items);
                        this.reloadCurrentPage();
                        this.appNotificationService.showSuccess(`${this.resourceNamePlural} wurden gelöscht.`);
                    }
                }),
                catchError(() => {
                    this.appNotificationService.showError(`${this.resourceNamePlural} konnten nicht gelöscht werden.`);
                    return EMPTY;
                }),
                finalize(() => this.state.unsetUpdating())
            );
    }

    public loadFile(fileId: number, params: HttpParams = new HttpParams()) {
        if (!this.filesApiService) {
            throw new ReferenceError(`Please provide FilesApiService in ${this.resourcePath} facade to load file.`);
        }
        return this.filesApiService.getById(fileId, params);
    }

    public addOrUpdateFile(file: IFile, params: HttpParams = new HttpParams()): Observable<IFile> {
        if (!this.filesApiService) {
            throw new ReferenceError(`Please provide FilesApiService in ${this.resourcePath} facade to add or update a file.`);
        }
        if (file?.id) {
            return this.filesApiService.updateAndFetch(file, params);
        } else {
            return this.filesApiService.addAndFetch(file, params);
        }
    }

    public deleteFile(file: IFile, params: HttpParams = new HttpParams()): Observable<any> {
        if (!this.filesApiService) {
            throw new ReferenceError(`Please provide FilesApiService in ${this.resourcePath} facade to delete file.`);
        }
        return this.filesApiService.deleteById(file?.id, params);
    }

    public getNameLike(item: Entity): string | null {
        if (!item || !isPlainObject(item)) {
            return null;
        }
        if (item.hasOwnProperty('fullNameBackward')) {
            return item['fullNameBackward'];
        }
        if (item.hasOwnProperty('fullName')) {
            return item['fullName'];
        }
        if (item.hasOwnProperty('name')) {
            return item['name'];
        }
        if (item.hasOwnProperty('title')) {
            return item['title'];
        }
        if (item.hasOwnProperty('handle')) {
            return item['handle'];
        }
        return item.toString();
    }

    private prepareColumnDefinitions() {
        this.tableDataDefaults.columnDefinitions = this.tableDataDefaults.columnDefinitions
            .filter(columnDefinition => !('excludeIf' in columnDefinition) || !columnDefinition.excludeIf(this.bcmService.tenant))
            .map(columnDefinition => {

                if (columnDefinition.translationKey) {
                    columnDefinition.name = TranslationService.translate(columnDefinition.translationKey);
                } else {
                    columnDefinition.name = TranslationService.translate(columnDefinition.name);
                }

                if (columnDefinition.filter) {
                    if (!columnDefinition.filter.property) {
                        columnDefinition.filter.property = columnDefinition.property;
                    }

                    if (!columnDefinition.filter.fieldType) {
                        columnDefinition.filter.fieldType = FilterFieldType.Text;
                    }

                    if (!columnDefinition.filter.operators?.length) {
                        columnDefinition.filter.operators = FILTER_OPERATORS_DEFAULTS_BY_FIELD_TYPE[columnDefinition.filter.fieldType];
                    }

                    if (columnDefinition.filter.fieldType === FilterFieldType.Select
                        && !columnDefinition.filter.selectOptionsRelation
                        && !columnDefinition.filter.selectOptions) {
                        throw Error(`missing data for selectOptions in column definitions of column ${columnDefinition.property}`);
                    }
                }

                return columnDefinition;
            });
    }
}
