import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bAddressValidators } from '@shared/validators/address/address-validators';
import { U2bValidators } from '@shared/validators/validators';
import { AppNotificationService } from '@core/services/app-notification.service';

@Component({
    selector: 'get-person-dialog',
    templateUrl: './get-person-dialog.component.html',
    styles: ['.dialog-title { font-size: 14px }']
})
export class GetPersonDialogComponent {
    dialogTitle: string;
    formGroup: UntypedFormGroup;
    isSaving: boolean;

    constructor(
        public dialogRef: MatDialogRef<GetPersonDialogComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogRef.addPanelClass('default-dialog');
        this.dialogTitle = 'Person wählen';
        this.formGroup = this._createForm();
    }

    save(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;
        const person = this.formGroup.get('person').value;
        this.dialogRef.close(person);
    }

    private _createForm(): UntypedFormGroup {
        return this._formBuilder.group({
            person: [
                '',
                [
                    U2bValidators.required('Bitte Person auswählen'),
                    U2bAddressValidators.addressExists()
                ]
            ],
        });
    }
}
