import { Component, Inject, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bAddressValidators } from '@shared/validators/address/address-validators';
import { U2bValidators } from '@shared/validators/validators';
import { U2bCompanyValidators } from '@shared/validators/company/company-validators';
import { AppNotificationService } from '@core/services/app-notification.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { U2bBoatValidators } from '@shared/validators/boat/boat-validators';

enum EntityType {
    Person,
    Company,
    Boat
}

const entityTypeNames: { [key in EntityType]: string } = {
    [EntityType.Person]: 'Person',
    [EntityType.Company]: 'company', // translation key
    [EntityType.Boat]: 'boat', // translation key
};

@Component({
    selector: 'get-entity-dialog',
    templateUrl: './get-entity-dialog.component.html',
    styles: ['.dialog-title { font-size: 14px }']
})
export class GetEntityDialogComponent {

    formGroup: UntypedFormGroup;

    EntityType = EntityType;

    entityTypeNames = entityTypeNames;

    isSaving: boolean;

    selectableEntities: EntityType[];

    constructor(
        public dialogRef: MatDialogRef<GetEntityDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            selectableEntities?: EntityType[],
        },
        private _formBuilder: UntypedFormBuilder,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogRef.addPanelClass('default-dialog');

        if (data?.selectableEntities?.length) {
            this.selectableEntities = data.selectableEntities;
        } else {
            this.selectableEntities = Object.values(EntityType)
                .filter((value) => typeof value !== 'string') as EntityType[];
        }

        this._createForm();
    }

    save(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;

        const formValue = this.formGroup.value;

        switch (formValue.type) {
            case EntityType.Person:
                this.dialogRef.close({person: formValue.person});
                break;
            case EntityType.Company:
                this.dialogRef.close({company: formValue.company});
                break;
            case EntityType.Boat:
                this.dialogRef.close({boat: formValue.boat});
                break;
        }
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            type: [null],
            person: [
                {value: null, disabled: true},
                [
                    U2bValidators.required('Bitte Auswahl treffen'),
                    U2bAddressValidators.addressExists()
                ]
            ],
            company: [
                {value: null, disabled: true},
                [
                    U2bValidators.required('Bitte Auswahl treffen'),
                    U2bCompanyValidators.companyExists()
                ]
            ],
            boat: [
                {value: null, disabled: true},
                [
                    U2bValidators.required('Bitte Auswahl treffen'),
                    U2bBoatValidators.boatExists()
                ]
            ]
        });

        this.formGroup.get('type').valueChanges.subscribe((value) => {
            switch (value) {
                case EntityType.Person:
                    this.formGroup.get('boat').disable();
                    this.formGroup.get('person').enable();
                    this.formGroup.get('company').disable();
                    break;
                case EntityType.Company:
                    this.formGroup.get('boat').disable();
                    this.formGroup.get('person').disable();
                    this.formGroup.get('company').enable();
                    break;
                case EntityType.Boat:
                    this.formGroup.get('boat').enable();
                    this.formGroup.get('person').disable();
                    this.formGroup.get('company').disable();
                    break;
            }
        });

        this.formGroup.get('type').setValue(this.selectableEntities[0]);
    }
}
