import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BcmApiService } from './bcm-api.service';
import { downloadFile } from '@shared/functions/download-file';
import {
    FromToRangeButton,
    GetFromToRangeDialogComponent
} from '@sharedComponents/dialogs/get-from-to-range-dialog/get-from-to-range-dialog.component';
import { BcmService } from '../../../bcm.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { format } from '@core/date.facade';
import { TranslationService } from '@core/translation/translation.service';
import { lastValueFrom } from 'rxjs';
import { startOfYear } from '@core/date.facade';

@Injectable({
    providedIn: 'root'
})
export class BcmExportExcelApiService extends BcmApiService {

    constructor(private _matDialog: MatDialog,
                _http: HttpClient,
                _bcmService: BcmService) {
        super(_http, _bcmService);
    }

    exportPersons(): void {
        this.export('persons', 'Personen');
    }

    exportPersonTenantRelations(): void {
        this.export('persons/tenant-relations', 'Personen Beziehungen');
    }

    exportPersonsAndCompanies(): void {
        this.export('persons-companies', 'Personen & Organisationen');
    }

    exportCompanies(): void {
        this.export('companies', 'Organisationen');
    }

    exportCompanyTenantRelations(): void {
        this.export('companies/tenant-relations', 'Organisationen Beziehungen');
    }

    exportProducts(): void {
        this.export('products', 'Produkte');
    }

    exportProductsWithCostCenter(): void {
        this.export('products-with-cost-center', 'Produkte mit Kostenstelle');
    }

    exportBoats(): void {
        this.export('boats', TranslationService.translate('boats', undefined, 'Boote'));
    }

    exportBerths(): void {
        this.export('berths', 'Liegeplan');
    }

    async exportBerthsAssignments(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const range = await dialogRef
            .afterClosed()
            .toPromise();

        if (range === false) {
            return;
        }

        const params = new HttpParams()
            .set('from', range?.from ? format(range?.from, 'yyyy-MM-dd') : '')
            .set('to', range?.to ? format(range.to, 'yyyy-MM-dd') : '');

        this.export(
            'berthsAssignments',
            this.getFileNameWithDates('Liegeplatzbelegung', range?.from, range?.to),
            params
        );
    }

    exportElectricMeters(): void {
        this.export('electric-meters', 'Stromzähler');
    }

    exportKeys(persons = false): void {
        this.export('keys' + (persons ? '/persons' : ''), 'Schlüssel');
    }

    exportSeasons(): void {
        this.export('seasons', 'Saison');
    }

    exportVouchers(): void {
        this.export('vouchers', TranslationService.translate('vouchers'));
    }

    async exportElectricMeterReadings(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const range = await dialogRef
            .afterClosed()
            .toPromise();

        if (range === false) {
            return;
        }

        const params = new HttpParams()
            .set('from', range?.from ? format(range?.from, 'yyyy-MM-dd') : '')
            .set('to', range?.to ? format(range.to, 'yyyy-MM-dd') : '');

        this.export(
            'electric-meter-readings',
            this.getFileNameWithDates('Stromzählerablesungen', range?.from, range?.to),
            params
        );
    }

    async exportOpenInvoicePostions(): Promise<void> {
        this.export('invoicePositions', 'Bierdeckel-Positionen');
    }

    async exportAllInvoicePostions(includeOpen: boolean): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const now = new Date();
        dialogRef.componentInstance.fromDateDefault = startOfYear(now);
        dialogRef.componentInstance.fromDateMax = now;
        dialogRef.componentInstance.toDateDefault = now;
        dialogRef.componentInstance.toDateMax = now;
        dialogRef.componentInstance.types = [
            FromToRangeButton.All,
            FromToRangeButton.LastYear,
            FromToRangeButton.CurrentYear,
            FromToRangeButton.LastMonth,
            FromToRangeButton.CurrentMonth,
            FromToRangeButton.LastWeek,
            FromToRangeButton.CurrentWeek,
            FromToRangeButton.Yesterday,
            FromToRangeButton.Today,
        ];

        const range = await dialogRef
            .afterClosed()
            .toPromise();

        if (range === false) {
            return;
        }

        const params = new HttpParams()
            .set('from', range?.from ? format(range?.from, 'yyyy-MM-dd') : '')
            .set('to', range?.to ? format(range.to, 'yyyy-MM-dd') : '')
            .set('includeOpen', includeOpen ? 'true' : '');

        const fileName = includeOpen
            ? 'Beleg_Positionen_mit_Bierdeckel'
            : 'Beleg_Positionen_ohne_Bierdeckel';

        this.export(
            'invoicePositions/all',
            this.getFileNameWithDates(fileName, range?.from, range?.to),
            params
        );
    }

    async exportAllSubscriptions(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        dialogRef.componentInstance.optionalInfoText =
            'Zum Eingrenzen des Zeitraums wird die Gesamtlaufzeit des Abos berücksichtigt, ' +
            'also das Start- und Enddatum des Abos. Abos mit offenem Ende werden auch in ' +
            'den Export aufgenommen, wenn das Startdatum vor dem "von"-Datum des ausgewählten Zeitraums liegt, ' +
            'weil diese ebenfalls aktiv sind.';
        dialogRef.componentInstance.types = [
            FromToRangeButton.All,
            FromToRangeButton.LastYear,
            FromToRangeButton.CurrentYear,
            FromToRangeButton.LastMonth,
            FromToRangeButton.CurrentMonth,
            FromToRangeButton.LastWeek,
            FromToRangeButton.CurrentWeek,
            FromToRangeButton.Yesterday,
            FromToRangeButton.Today,
        ];

        const range = await dialogRef
            .afterClosed()
            .toPromise();

        if (range === false) {
            return;
        }

        const params = new HttpParams()
            .set('from', range?.from ? format(range?.from, 'yyyy-MM-dd') : '')
            .set('to', range?.to ? format(range.to, 'yyyy-MM-dd') : '');

        this.export(
            'subscriptions',
            this.getFileNameWithDates('Abos', range?.from, range?.to),
            params
        );
    }

    async exportFinancialRecords(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const now = new Date();
        dialogRef.componentInstance.allowTypeAll = false;
        dialogRef.componentInstance.dateFieldsOptional = false;
        dialogRef.componentInstance.fromDateDefault = startOfYear(now);
        dialogRef.componentInstance.fromDateMax = now;
        dialogRef.componentInstance.toDateDefault = now;
        dialogRef.componentInstance.toDateMax = now;
        dialogRef.componentInstance.types = [
            FromToRangeButton.All,
            FromToRangeButton.LastYear,
            FromToRangeButton.CurrentYear,
            FromToRangeButton.LastMonth,
            FromToRangeButton.CurrentMonth,
            FromToRangeButton.LastWeek,
            FromToRangeButton.CurrentWeek,
            FromToRangeButton.Yesterday,
            FromToRangeButton.Today,
        ];
        dialogRef.componentInstance.orderByFilters = [
            {orderBy: 'date', label: 'Belegdatum'},
            {orderBy: 'number', label: 'Belegnummer'},
        ];
        dialogRef.componentInstance.orderByDefault = 'number';

        const formData = await dialogRef
            .afterClosed()
            .toPromise();

        if (formData === false) {
            return;
        }

        const params = new HttpParams()
            .set('from', formData?.from ? format(formData?.from, 'yyyy-MM-dd') : '')
            .set('to', formData?.to ? format(formData.to, 'yyyy-MM-dd') : '')
            .set('orderBy', formData?.orderBy || '');

        this.export(
            'financial-records',
            this.getFileNameWithDates('Alle_Belege', formData?.from, formData?.to),
            params
        );
    }

    async exportInvoices(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const now = new Date();
        dialogRef.componentInstance.allowTypeAll = false;
        dialogRef.componentInstance.dateFieldsOptional = false;
        dialogRef.componentInstance.fromDateDefault = startOfYear(now);
        dialogRef.componentInstance.fromDateMax = now;
        dialogRef.componentInstance.toDateDefault = now;
        dialogRef.componentInstance.toDateMax = now;
        dialogRef.componentInstance.types = [
            FromToRangeButton.All,
            FromToRangeButton.LastYear,
            FromToRangeButton.CurrentYear,
            FromToRangeButton.LastMonth,
            FromToRangeButton.CurrentMonth,
            FromToRangeButton.LastWeek,
            FromToRangeButton.CurrentWeek,
            FromToRangeButton.Yesterday,
            FromToRangeButton.Today,
        ];
        dialogRef.componentInstance.orderByFilters = [
            {orderBy: 'date', label: 'Rechnungsdatum'},
            {orderBy: 'invoiceNumber', label: 'Rechnungsnummer'},
        ];
        dialogRef.componentInstance.orderByDefault = 'invoiceNumber';

        const formData = await dialogRef
            .afterClosed()
            .toPromise();

        if (formData === false) {
            return;
        }

        const params = new HttpParams()
            .set('from', formData?.from ? format(formData?.from, 'yyyy-MM-dd') : '')
            .set('to', formData?.to ? format(formData.to, 'yyyy-MM-dd') : '')
            .set('orderBy', formData?.orderBy || '');

        this.export(
            'invoices',
            this.getFileNameWithDates(TranslationService.translate('invoices'), formData?.from, formData?.to),
            params
        );
    }

    async exportOrders(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const range = await lastValueFrom(dialogRef.afterClosed(), {defaultValue: false});

        if (range === false) {
            return;
        }

        const from = range?.from ? format(range?.from, 'yyyy-MM-dd') : '';
        const to = range?.to ? format(range.to, 'yyyy-MM-dd') : '';

        const params = new HttpParams()
            .set('from', from)
            .set('to', to);

        this.exportNew(
            'orders',
            this.getFileNameWithDates('Arbeitsaufträge', range?.from, range?.to),
            params
        );
    }

    exportCostCenters(): void {
        this.export('cost-centers', 'Kostenstellen');
    }

    async exportPositionsByCostCenters(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const range = await lastValueFrom(dialogRef.afterClosed(), {defaultValue: false});

        if (range === false) {
            return;
        }

        const from = range?.from ? format(range?.from, 'yyyy-MM-dd') : '';
        const to = range?.to ? format(range.to, 'yyyy-MM-dd') : '';

        const params = new HttpParams()
            .set('from', from)
            .set('to', to);

        this.export(
            'cost-center-positions',
            this.getFileNameWithDates('Positionen_mit_Kostenstelle', range?.from, range?.to),
            params
        );
    }

    private export(endpoint: string, name: string, params = new HttpParams()): void {
        super.getBlob(`export/excel/${endpoint}`, params.set('workSheetName', name))
            .subscribe((file) => downloadFile(file, name, 'xlsx'));
    }

    private exportNew(endpoint: string, name: string, params = new HttpParams()): void {
        super.getBlob(`export/${endpoint}/excel`, params.set('workSheetName', name))
            .subscribe((file) => downloadFile(file, name, 'xlsx'));
    }

    private getFileNameWithDates(fileName: string, fromDate?: Date, toDate?: Date) {
        const from = fromDate ? format(fromDate, 'yyyyMMdd') : '';
        const to = toDate ? format(toDate, 'yyyyMMdd') : '';

        return [
            fileName,
            ...(
                (from || to)
                    ? [
                        '_',
                        from,
                        '-',
                        to ?? from
                    ]
                    : []
            )
        ].filter(_ => !!_).join('');
    }
}
