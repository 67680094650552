import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BcmDocumentCategory } from '@shared/models/bcm-document-category';
import { RealBaseStateService } from '@bcmServices/real-base-state.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmDocumentCategoriesApiService } from '@bcmApiServices/bcm-document-categories.api-service';
import { Page, PageRequest } from '@shared/models/pagination';

@Injectable({providedIn: 'root'})
export class BcmDocumentCategoriesFacade extends BcmDocumentCategoriesApiService {

    private readonly state = new RealBaseStateService<BcmDocumentCategory>(BcmDocumentCategory);

    get latestPageRequest(): PageRequest<BcmDocumentCategory> {
        return this.state.latestPageRequest;
    }

    set latestPageRequest(value: PageRequest<BcmDocumentCategory>) {
        this.state.latestPageRequest = value;
    }

    get page$(): Observable<Page<BcmDocumentCategory>> {
        return this.state.page$;
    }

    get documentCategories$(): Observable<BcmDocumentCategory[]> {
        return this.state.list$;
    }

    get selectedDocumentCategory$(): Observable<BcmDocumentCategory> {
        return this.state.selected$;
    }

    get loadingAll$(): Observable<boolean> {
        return this.state.isUpdatingAll$;
    }

    get loading$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    constructor(httpClient: HttpClient,
                bcmService: BcmService) {
        super(httpClient, bcmService);
    }

    loadAll(paginationRequest?: PageRequest<BcmDocumentCategory>): Subscription {
        return this.loadAllAsObservable(paginationRequest).subscribe();
    }

    loadAllAsObservable(paginationRequest?: PageRequest<BcmDocumentCategory>, skipPageRequest = false): Observable<BcmDocumentCategory[]> {
        this.state.latestPageRequest = paginationRequest || this.state.latestPageRequest;
        this.state.startUpdatingAll();
        return this.getAll(skipPageRequest ? undefined : this.state.latestPageRequest)
            .pipe(
                tap(page => {
                    this.state.page = page;
                    this.state.stopUpdatingAll();
                }),
                map(page => page.results)
            );
    }

    loadOne(id: number): Observable<BcmDocumentCategory> {
        this.state.startUpdating();
        return this.getOne(id)
            .pipe(
                tap(documentCategory => {
                        this.state.setSelected(documentCategory);
                        this.state.stopUpdating();
                    }
                ),
                switchMap(() => this.selectedDocumentCategory$)
            );
    }

    addCategory(tmpDocumentCategory: BcmDocumentCategory): Observable<BcmDocumentCategory> {

        if (tmpDocumentCategory?.name?.length) {

            tmpDocumentCategory.id = -1;

            this.state.startUpdating();
            // this.state.addListItem(tmpDocumentCategory);

            return this.add(tmpDocumentCategory)
                .pipe(
                    tap((documentCategory) => {
                        // this.state.replaceListItem(tmpDocumentCategory, documentCategory);
                        this.loadAll();
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        // this.state.removeListItem(tmpDocumentCategory);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    updateCategory(documentCategory: BcmDocumentCategory, documentCategoryData: Partial<BcmDocumentCategory>): Observable<any> {
        if (documentCategory?.id) {

            this.state.startUpdating();

            const updatedDocumentCategory = {
                ...documentCategory,
                ...documentCategoryData
            } as BcmDocumentCategory;

            this.state.replacePageItem(documentCategory, updatedDocumentCategory, ''); // optimistic change
            this.state.replaceListItem(documentCategory, updatedDocumentCategory); // optimistic change

            return this.update(documentCategory, documentCategoryData)
                .pipe(
                    tap(() => this.state.stopUpdating()),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replacePageItem(updatedDocumentCategory, documentCategory, ''); // revert
                        this.state.replaceListItem(updatedDocumentCategory, documentCategory); // revert
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    removeCategory(documentCategory: BcmDocumentCategory): Observable<any> {

        if (documentCategory?.id) {

            // this.state.removeListItem(documentCategory);

            return this.remove(documentCategory)
                .pipe(
                    tap(() => this.loadAll()),
                    catchError((error: HttpErrorResponse) => {
                        this.state.addListItem(documentCategory);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

}
