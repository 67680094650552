import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { Boat, IBoat } from '@shared/models/boat';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BcmExportExcelApiService } from '@bcmApiServices/bcm-export-excel.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { boatsTableData } from '@modules/bcm/@core/state-management/boats/boats-table-data';
import { BcmBaseFacade } from '@modules/bcm/@core/state-management/bcm-base.facade';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { TranslationService } from '@core/translation/translation.service';
import { ThemePalette } from '@angular/material/core';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmBaseBulkChanges } from '@modules/bcm/@core/state-management/bcm-base-bulk-changes';

@Injectable({providedIn: 'root'})
export class BoatsFacade extends BcmBaseFacade<Boat, IBoat> {

    readonly resourcePath = 'boats';

    readonly resourceNameSingular = TranslationService.translate('boat', undefined, 'Boot');

    readonly resourceNamePlural = TranslationService.translate('boats', undefined, 'Boote');

    readonly resourceIconPlural = 'directions_boat';

    readonly resourceIconSingular = 'directions_boat';

    readonly deletePermission = BcmUserPermission.BOATS_DELETE;

    readonly readPermission = BcmUserPermission.BOATS_READ;

    readonly writePermission = BcmUserPermission.BOATS_WRITE;

    readonly headerActions = [
        {
            title: 'Excel Export',
            onClick: () => this._bcmExportExcelApiService.exportBoats(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        }
    ];

    readonly rowActions = [
        {
            title: 'Datensatz anzeigen',
            icon: 'assignment',
            iconColor: 'accent' as ThemePalette,
            onClick: (boat: Boat) => this._bcmNavigationService.navigate('boats/' + boat.id),
        },
        {
            title: 'Datensatz löschen',
            icon: 'delete',
            iconColor: 'warn' as ThemePalette,
            onClick: (boat: Boat) => super.remove(boat, boat.licensePlate || boat.name).subscribe(),
        }
    ];

    readonly bulkChanges = new BcmBaseBulkChanges<Boat>()
        .setButtons(
            {
                title: 'Löschen',
                icon: 'delete',
                iconColor: 'warn' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (boats: Boat[]) => super.removeMultiple(boats),
            },
        );

    constructor(appNotificationService: AppNotificationService,
                confirmDialogService: ConfirmDialogService,
                bcmUserSettingsFacade: BcmUserSettingsFacade,
                httpClient: HttpClient,
                bcmService: BcmService,
                bcmNavigationService: BcmNavigationService,
                private _bcmNavigationService: BcmNavigationService,
                private _bcmExportExcelApiService: BcmExportExcelApiService,
    ) {
        super(
            Boat,
            boatsTableData,
            bcmNavigationService,
            appNotificationService,
            confirmDialogService,
            bcmUserSettingsFacade,
            httpClient,
            bcmService
        );
    }
}
