import { TenantRelationAssignment, TenantRelationAssignmentDto } from '@shared/models/tenant-relation-assignment';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BcmBooking } from '@shared/models/bcm-booking';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { getVestingPeriodUntil } from '@shared/functions/vesting-period-helper';
import { ProductSubscriptionType } from '@shared/models/product-subscription';

export class BookingDialogTenantRelationAssignment extends BookingDialogBaseEntity<TenantRelationAssignment, TenantRelationAssignment> {

    constructor(bookingDialogService: BookingDialogService) {
        super(bookingDialogService, BookingAttribute.TENANT_RELATION_ASSIGNMENT);

        this.value = new TenantRelationAssignment({
            id: 0,
            fromDate: new Date(),
            toDate: new Date(),
            periodFromDate: new Date(),
            periodToDate: new Date(),
            vestingPeriodFrom: new Date(),
            vestingPeriodUntil: new Date(),
            captureTenantRelation: false,
        } as unknown as TenantRelationAssignmentDto);

    }

    protected afterValueSet(): void {

        const tenantRelationChanged = this.bookingDialogService.booking?.tenantRelationAssignment?.tenantRelation?.id !== this.value.tenantRelation?.id;

        if (!tenantRelationChanged) {
            this.bookingDialogService.subscriptions?.value
                .filter(subscription => subscription.type === ProductSubscriptionType.TenantRelation)
                .map(subscription => subscription.deleted = false);
        }

        if (tenantRelationChanged && this.value.captureTenantRelation && this.value.tenantRelation?.id && this.value.tenantRelation.products?.length) {
            this.value.payableOption = this.value.tenantRelation.payableOption;

            if (this.value.toDate) {
                this.bookingDialogService.positions.removeFromTenantRelation();
                this.bookingDialogService.positions.addFromTenantRelation(this.value);
            } else {
                this.bookingDialogService.subscriptions.removeFromTenantRelation();
                this.bookingDialogService.subscriptions.addFromTenantRelation(this.value);
            }

        } else if ((!tenantRelationChanged || this.value.captureTenantRelation === false) && this.bookingDialogService.initialized) {
            this.bookingDialogService.positions.removeUnsavedFromTenantRelation();
            this.bookingDialogService.subscriptions.removeUnsavedFromTenantRelation();
        }

    }

    async setBooking(booking: BcmBooking) {
        if (booking.id && booking.tenantRelationAssignment?.id) {
            super.setBooking(booking);
        }
    }

    updateDates() {

        const assignmentWithUpdatedDates = this.getTenantRelationAssignmentWithUpdatedDates();

        this.value.fromDate = assignmentWithUpdatedDates.fromDate;
        this.value.toDate = assignmentWithUpdatedDates.toDate;
        this.value.periodFromDate = assignmentWithUpdatedDates.periodFromDate;
        this.value.periodToDate = assignmentWithUpdatedDates.periodToDate;
        this.value.vestingPeriodFrom = assignmentWithUpdatedDates.vestingPeriodFrom;
        this.value.vestingPeriodUntil = assignmentWithUpdatedDates.vestingPeriodUntil;

        if (this.value.toDate) {
            const sameFromDate = this.bookingDialogService.booking.tenantRelationAssignment?.fromDate?.getTime() === this.value.fromDate.getTime();
            const sameToDate = this.bookingDialogService.booking.tenantRelationAssignment?.toDate?.getTime() === this.value.toDate.getTime();

            if (!sameFromDate || !sameToDate) {
                this.bookingDialogService.positions.removeFromTenantRelation();
                this.bookingDialogService.positions.addFromTenantRelation(this.value);
            }

        } else {
            this.bookingDialogService.subscriptions.removeFromTenantRelation();
            this.bookingDialogService.subscriptions.addFromTenantRelation(this.value);
        }

        if (!this.value.captureTenantRelation) {
            this.sendUpdate(true);
            return;
        }

        this.bookingDialogService.loading = true;
        this.value.captureTenantRelation = false;
        this.sendUpdate(true);

        setTimeout(() => {
            this.value.captureTenantRelation = true;
            this.sendUpdate(true);
            this.bookingDialogService.loading = false;
        }, 1000);
    }

    getTenantRelationAssignmentWithUpdatedDates(): TenantRelationAssignment {

        const fromDate = this.bookingDialogService.booking.from;
        const toDate = this.bookingDialogService.booking.to;

        return new TenantRelationAssignment({
            ...this.value,
            fromDate: fromDate,
            toDate: toDate,
            periodFromDate: fromDate,
            periodToDate: toDate,
            vestingPeriodFrom: fromDate,
            vestingPeriodUntil: toDate || getVestingPeriodUntil(fromDate, this.value.payableOption),
        } as unknown as TenantRelationAssignmentDto);

    }

}
