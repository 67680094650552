<div class="container">

    <ng-container *ngIf="headline">
        <div>
            <h2 [innerHTML]="headline"></h2>
        </div>
    </ng-container>

    <div>

        <table class="simple sm">
            <thead>
            <tr>
                <th></th>
                <th>Belegart</th>
                <th>Nummer</th>
                <th>Datum</th>
                <th>Fällig zum</th>
                <th>Status</th>
                <th>Storno-Nr.</th>
                <th class="text-right">Betrag (Brutto)</th>
                <th>Zahlart</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngIf="!financialRecords?.length">
                <tr>
                    <td [attr.colspan]="colspan" class="text-center">Noch keine {{ headline }} erfasst.</td>
                </tr>
            </ng-container>
            <ng-container *ngFor="let record of financialRecords; let i = index">
                <tr>
                    <td></td>
                    <td>{{ record.type | translate }}</td>
                    <td><a style="cursor: pointer" [matTooltip]="(record.type | translate) + ' herunterladen'"
                           (click)="onClickDownload($event, record)">{{ record.number }}</a></td>
                    <td>{{ record.date | dateFormat: '' }}</td>
                    <td>{{ record.dueDate | dateFormat: '' }}</td>
                    <td>{{ record.statusText }}</td>
                    <td>{{ record.cancellationNumber }}</td>
                    <td class="text-right">
                        {{ record.total | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                    <td>{{ record.paymentTypeDe }}</td>
                    <td>

                        <ng-template #buttons>
                            <ng-container *hasTenantPermission="bcmTenantPermissions.INVOICE_MAILING">
                                <ng-container *ngIf="record.invoice || record.receipt">
                                    <button data-test="B-Per E-Mail versenden" mat-menu-item
                                            fxHide.lt-md
                                            (click)="onClickSendMail($event, record)">
                                        <mat-icon>send</mat-icon>
                                        Per E-Mail versenden
                                    </button>
                                </ng-container>
                            </ng-container>

                            <button data-test="B-Als PDF herunterladen" mat-menu-item
                                    fxHide.lt-md
                                    (click)="onClickDownload($event, record)">
                                <mat-icon>picture_as_pdf</mat-icon>
                                Als PDF herunterladen
                            </button>

                            <button mat-menu-item
                                    fxHide.lt-md
                                    (click)="onClickPrint($event, record)">
                                <mat-icon>print</mat-icon>
                                Drucken, wenn möglich
                            </button>
                        </ng-template>

                        <ng-container *ngIf="record.invoice; else byCashButtons">
                            <button data-test="B-Menu" mat-icon-button
                                    [matMenuTriggerFor]="moreMenu" (click)="$event.stopPropagation()">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu" style="display: none;">

                                <ng-container *ngTemplateOutlet="buttons"></ng-container>

                                <ng-container *hasTenantPermission="bcmTenantPermissions.INVOICES_MARK_AS_PAID">

                                    <mat-divider></mat-divider>
                                    <div [matTooltip]="record.invoice?.invoiceStatus === FinancialRecordStatus.Payed ? 'Bereits bezahlt' : (record.cancellationNumber ? 'Bereits storniert' : '')"
                                         matTooltipPosition="left">
                                        <button mat-menu-item data-test="B-Bezahlt am"
                                                (click)="onChangeStatus($event, record, FinancialRecordStatus.Payed)"
                                                [disabled]="record.invoice?.invoiceStatus === FinancialRecordStatus.Payed || record.invoice?.invoiceStatus === FinancialRecordStatus.Done || record.invoice?.invoiceStatus === FinancialRecordStatus.BookedOut || !!record.cancellationNumber">
                                            <mat-icon>euro</mat-icon>
                                            Zahlung erfassen
                                        </button>
                                    </div>

                                    <ng-container *ngIf="record.invoice?.payments?.length">
                                        <button mat-menu-item (click)="openPaymentsDialog($event, record.invoice)">
                                            <mat-icon>playlist_add_check</mat-icon>
                                            Zahlungen bearbeiten
                                        </button>
                                    </ng-container>

                                </ng-container>

                                <ng-container
                                        *ngIf="record.invoice?.invoiceType === InvoiceTypes.Invoice || record.invoice?.invoiceType === InvoiceTypes.Crediting">
                                    <mat-divider></mat-divider>
                                    <div [matTooltip]="!!record.cancellationNumber ? 'Bereits storniert' : ''"
                                         matTooltipPosition="left">
                                        <button mat-menu-item data-test="B-Stornieren"
                                                (click)="onChangeStatus($event, record, FinancialRecordStatus.Canceled)"
                                                [disabled]="!!record.cancellationNumber">
                                            <mat-icon>cancel</mat-icon>
                                            Stornieren
                                        </button>
                                    </div>
                                </ng-container>

                            </mat-menu>
                        </ng-container>

                        <ng-template #byCashButtons>
                            <button data-test="B-Menu" mat-icon-button
                                    [matMenuTriggerFor]="moreMenu" (click)="$event.stopPropagation()">
                                <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #moreMenu="matMenu" style="display: none;">

                                <ng-container *ngTemplateOutlet="buttons"></ng-container>

                                <ng-container *ngIf="record.receipt?.receiptType === ReceiptTypes.Receipt">
                                    <div [matTooltip]="!!(record.cancellationRecord?.number) ? 'Bereits storniert' : ''"
                                         matTooltipPosition="left">
                                        <button mat-menu-item data-test="B-Stornieren"
                                                (click)="onChangeStatus($event, record, FinancialRecordStatus.Canceled)"
                                                [disabled]="!!(record.cancellationRecord?.number)">
                                            <mat-icon>cancel</mat-icon>
                                            Stornieren
                                        </button>
                                    </div>
                                </ng-container>
                            </mat-menu>
                        </ng-template>
                    </td>
                </tr>
            </ng-container>

            <div *cdkDragPlaceholder></div>
            </tbody>
        </table>

    </div>

</div>
