import { Component } from '@angular/core';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { ProductSubscription } from '@shared/models/product-subscription';
import { InvoicePosition } from '@shared/models/invoice-position';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'booking-positions-tab',
    templateUrl: './positions-tab.component.html',
    styleUrls: ['./positions-tab.component.scss']
})
export class PositionsTabComponent {

    positions: InvoicePosition[] = [];
    subscriptions: ProductSubscription[] = [];

    constructor(
        public bookingDialogService: BookingDialogService,
    ) {

        bookingDialogService.positions.value$
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.positions = value.slice();
            });

        bookingDialogService.subscriptions.value$
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.subscriptions = value.slice();
            });

    }

}
