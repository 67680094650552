import { DynamicPriceRuleSet } from '@modules/bcm/products/product/dynamic-price-rules-builder/models/dynamic-price-rules.interfaces';
import { DynamicPriceCalculationSet } from '@modules/bcm/products/product/dynamic-price-calculations-builder/models/dynamic-price-calculations.interfaces';

export interface BcmProductDynamicPriceMapDto {
    id?: number;
    ruleSets?: DynamicPriceRuleSet[];
    calculationSets: DynamicPriceCalculationSet[];
}

export class BcmProductDynamicPriceMap {

    id: number;
    ruleSets: DynamicPriceRuleSet[];
    calculationSets: DynamicPriceCalculationSet[];

    constructor(dto = {} as BcmProductDynamicPriceMapDto) {
        this.id = dto?.id;
        this.ruleSets = dto?.ruleSets ? dto?.ruleSets : [];
        this.calculationSets = dto?.calculationSets ? dto?.calculationSets : [];
    }

    getRequirements(): string[] {
        const ruleRequirements = (this.ruleSets || [])
            .flatMap(ruleSet => ruleSet.rules.flatMap(rule => rule.entityId))
            .filter((v, i, a) => a.indexOf(v) === i);

        const calculationRequirements = (this.calculationSets || [])
            .flatMap(calculationSet => (calculationSet.calculations || []).flatMap(calculation => calculation.entityId))
            .filter((v, i, a) => a.indexOf(v) === i);

        return [...new Set([...ruleRequirements, ...calculationRequirements])];
    }

}
