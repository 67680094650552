import { differenceInCalendarDays, isSameDay } from 'date-fns';
import { DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';

export enum DurationUnit {
    Days = 'Days',
    Overnights = 'Overnights',
}

export function getDurationFromDates(fromDate: Date, toDate: Date, durationUnit: DurationUnit, defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit): number {

    if (!fromDate || !toDate) {
        return null;
    }

    if (durationUnit === DurationUnit.Overnights) {

        let fromHours: number;
        let fromMinutes: number;
        let toHours: number;
        let toMinutes: number;

        let isAfterPeriodStarts: boolean;
        let isAfterPeriodEnds: boolean;

        let durationOfStay: number;

        if (isSameDay(fromDate, toDate)) {
            fromHours = defaultBerthReservationTimeUnit.dailyGuestFromHours || 0;
            fromMinutes = defaultBerthReservationTimeUnit.dailyGuestFromMinutes || 0;
            toHours = defaultBerthReservationTimeUnit.dailyGuestToHours || 0;
            toMinutes = defaultBerthReservationTimeUnit.dailyGuestToMinutes || 0;
        } else {
            fromHours = defaultBerthReservationTimeUnit.overnightGuestFromHours || 0;
            fromMinutes = defaultBerthReservationTimeUnit.overnightGuestFromMinutes || 0;
            toHours = defaultBerthReservationTimeUnit.overnightGuestToHours || 0;
            toMinutes = defaultBerthReservationTimeUnit.overnightGuestToMinutes || 0;
        }

        isAfterPeriodStarts = fromHours < fromDate?.getHours() ||
            (fromHours === fromDate?.getHours() && fromMinutes <= fromDate?.getMinutes());

        isAfterPeriodEnds = toHours < toDate.getHours() ||
            (toHours === toDate.getHours() && toMinutes < toDate.getMinutes());

        durationOfStay = Math.max(differenceInCalendarDays(toDate, fromDate), 1);

        if (!isAfterPeriodStarts) {
            durationOfStay++;
        }

        if (isAfterPeriodEnds) {
            durationOfStay++;
        }

        return durationOfStay;

    } else if (durationUnit === DurationUnit.Days) {

        return differenceInCalendarDays(toDate, fromDate) + 1;

    }

}
