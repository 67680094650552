import { Berth } from '@shared/models/berth';
import { tryParseDate } from '@shared/functions/try-parse-date';

export interface BcmLockDto {
    id: number;
    berth: Berth;
    lockedFrom: Date;
    lockedUntil: Date;
    future?: boolean;
    active?: boolean;
    note: string;
}

export class BcmLock {
    id: number;
    berth: Berth;
    lockedFrom: Date;
    lockedUntil: Date;
    future?: boolean;
    active?: boolean;
    note: string;

    constructor(dto: BcmLockDto = {} as BcmLockDto) {
        this.id = dto.id;
        this.berth = dto.berth ;
        this.lockedFrom = tryParseDate(dto.lockedFrom);
        this.lockedUntil = tryParseDate(dto.lockedUntil);
        this.future = this.lockedFrom > new Date();
        this.active = this.active = this.lockedFrom <= new Date() && (
            this.lockedUntil == null || this.lockedUntil >= new Date()
        );

        this.note = dto.note;
    }
}
