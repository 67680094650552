<div fxLayout="column" fxLayoutGap="8px">

    <ng-container *ngIf="bookingDialogService.booking.id != null; else bookingNotInDBYet">

        <bcm-documents-table
                [readonly]="bookingDialogService.readonlyView"
                [showSearch]="false"
                [documents]="bookingDialogService.booking.documents"
                (downloadDocument)="onClickFileDownload($event)"
                (editDocument)="editDocument($event)"
                (deleteDocument)="onClickDocumentDelete($event)"></bcm-documents-table>

        <ng-container *ngIf="!bookingDialogService.readonlyView">
            <div fxFlex="100" class="text-center">
                <button mat-stroked-button
                        type="button"
                        color="accent"
                        data-test="B-Neues Dokument hinzufügen"
                        style="width: 100%"
                        (click)="addDocument()">
                    <mat-icon> add</mat-icon>
                    Neues Dokument hinzufügen
                </button>
            </div>

            <div fxFlex="100" class="text-center">
                <button mat-stroked-button
                        type="button"
                        color="accent"
                        data-test="B-Vertrag erstellen"
                        style="width: 100%"
                        (click)="openContractCreator()">
                    <mat-icon>add</mat-icon>
                    Neuen Vertrag erstellen
                </button>
            </div>
        </ng-container>

    </ng-container>

    <ng-template #bookingNotInDBYet>
        <u2b-message type="warning" class="mt-16">
            Dokumente / Verträge können hinzugefügt werden, sobald die Buchung gespeichert wurde.
        </u2b-message>
    </ng-template>

</div>
