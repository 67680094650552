import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, Optional } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ConfirmDialogService } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.service';
import { U2bValidators } from '@shared/validators/validators';
import { BcmWorkflowApiService } from '@bcmApiServices/bcm-workflow-api.service';
import { forkJoin, of, Subject, switchMap } from 'rxjs';
import { debounceTime, filter, map, pairwise, take, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { Berth } from '@shared/models/berth';
import { BerthsMapFilterService, LastFilter } from '@bcmServices/berths/berths-map-filter.service';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';
import { Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { BcmSettingsSectionName, DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { endOfToday } from '@core/date.facade';
import { addDays, isSameDay, startOfToday } from '@core/date.facade';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';
import { BerthBoatAssignment, NewBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { ContractFileType } from '@shared/models/contract';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { GetProductSubscriptionDatesDialogComponent } from '@sharedComponents/dialogs/get-product-subscription-dates-dialog/get-product-subscription-dates-dialog.component';
import { Product } from '@shared/models/product';
import { DEFAULT_BERTH_RESERVATION_UNIT } from '@modules/bcm/settings/default-units/default-units.component';
import { UnitUniqueName } from '@shared/models/unit';
import { concatDateAndTime } from '@shared/functions/concat-date-and-time';
import { deepEqual } from '@shared/functions/deep-equal';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { BcmTenantService } from '@modules/bcm/bcm-tenant.service';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { GetCostCenterForInvoicePositionsDialogComponent } from '@modules/bcm/@shared/components/dialogs/get-cost-center-for-invoice-positions-dialog/get-cost-center-for-invoice-positions-dialog.component';
import { BcmService } from '@modules/bcm/bcm.service';
import { BerthsFacade } from '@modules/bcm/@core/state-management/berths/berths.facade';
import { TimeSchedulerAsset } from '@sharedComponents/time-scheduler/shared/models/classes/time-scheduler-asset';
import { TimeSchedulerItem } from '@sharedComponents/time-scheduler/shared/models/classes/time-scheduler-item';
import { DurationUnit, getDurationFromDates } from '@shared/functions/get-duration-from-dates';

@Component({
    selector: 'berth-reservation',
    templateUrl: './berth-reservation.component.html',
    styleUrls: ['./berth-reservation.component.scss'],
    providers: [InvoicesService]
})
export class BerthReservationComponent implements AfterViewInit, OnDestroy {

    DEFAULT_BERTH_RESERVATION_UNIT = DEFAULT_BERTH_RESERVATION_UNIT;

    private _unsubscribeAll = new Subject<any>();

    isLoading = true;

    BerthAssignmentType = BookingType;

    berthAssignmentTitle: string;

    formGroupPrepare: UntypedFormGroup;

    formGroup: UntypedFormGroup;

    captureBoat = true;

    captureTenantRelation = false;

    captureContract = false;

    captureReservedUntil = false;

    sendMail = false;

    isSaving: boolean;

    givenBerth?: Berth;

    berthProducts: Product[] = [];

    givenBerthAssignmentType?: BookingType;

    hasGivenFromDate?: boolean;

    hasGivenToDate?: boolean;

    givenFromDate?: Date;

    givenToDate?: Date;

    prefilledTenantRelationId: number;

    activeTenantRelations: TenantRelationAssignment[] = [];

    givenInvoicePositions: InvoicePosition[] = [];

    boatToLong: number;

    boatToWide: number;

    boatToHeavy: number;
    boatWeight: number;
    berthMaxWeight: number;

    canSave = true;

    durationOfStay: number;
    totalDays: number;
    sameDay: boolean;
    isAfterPeriodStarts: boolean;
    isAfterPeriodEnds: boolean;

    showAssignmentTypeSelect: boolean;

    defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit;

    get showIsOwnerCheckBox() {
        return this.formGroupPrepare.get('showIsOwnerCheckBox').value;
    }

    get boatOwnerName() {
        return this.formGroup.get('name').value;
    }

    get boatName() {
        const boatForm = this.formGroup.get('boatForm')?.value;
        return boatForm?.boat?.name
            || boatForm?.boat?.licensePlate
            || boatForm?.name
            || boatForm?.licensePlate;
    }

    constructor(
        private _dialogRef: MatDialogRef<BerthReservationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            asset?: TimeSchedulerAsset<any, any>,
            item?: TimeSchedulerItem<any, any>,
            preselect?: { [key: string]: any },
            berth?: Berth,
            berthAssignmentType?: BookingType,
            assignment?: BerthBoatAssignment,
        },
        private _appNotificationService: AppNotificationService,
        private _confirmDialogService: ConfirmDialogService,
        private _bcmWorkflowApiService: BcmWorkflowApiService,
        private _berthsFilterService: BerthsMapFilterService,
        private _matDialog: MatDialog,
        private bcmSettingsFacade: BcmSettingsFacade,
        private _formBuilder: UntypedFormBuilder,
        private _productService: ProductService,
        private bcmNavigationService: BcmNavigationService,
        private bcmTenantService: BcmTenantService,
        private berthsFacade: BerthsFacade,
        private bcmService: BcmService,
        private changeDetectorRef: ChangeDetectorRef) {

        this._dialogRef.addPanelClass('default-dialog');
        this._dialogRef.disableClose = true;

        this.givenBerth = this.data.berth || this.data.asset?.metaData || null;

        this.berthProducts = this.givenBerth?.products || [];

        this.showAssignmentTypeSelect = !!this.data.item?.id;

        this.givenBerthAssignmentType = this.data.berthAssignmentType || BookingType.Reservation;
        this.berthAssignmentTitle = this.givenBerthAssignmentType === BookingType.Reservation
            ? 'Reservierung'
            : 'Buchung';

        const lastFilterData = this._berthsFilterService.lastBerthFilters[0];
        this.hasGivenFromDate = !!this.data.item?.startDate || !!lastFilterData?.start;
        this.hasGivenToDate = !!this.data.item?.endDate || !!lastFilterData?.end;
        this.givenFromDate = this.data.item?.startDate || lastFilterData?.start || startOfToday();
        this.givenToDate = this.data.item?.endDate || lastFilterData?.end || endOfToday();

        if (!lastFilterData?.boat?.id) {
            this.isLoading = false;
        }

        this.prepareForms(lastFilterData);
    }

    ngAfterViewInit() {
        if (this.data?.assignment) {
            this.waitForSubForms().then(() => {
                const {from, to, person, company, boat} = this.data.assignment;

                this.formGroup.patchValue({
                    dateRangeForm: {from, to},
                    personForm: {person},
                    companyForm: {company},
                    boatForm: {boat},
                });

                this.berthsFacade.loadById(this.givenBerth.id).subscribe(berth => {
                    this.givenBerth = berth;
                    this.berthProducts = berth?.products || [];
                    this.updatePositions();
                });
            });
        }
    }

    private waitForSubForms(): Promise<void> {
        return new Promise(resolve => {
            const interval = setInterval(() => {
                if (this.allSubFormsAdded()) {
                    clearInterval(interval);
                    resolve();
                }
                this.changeDetectorRef.detectChanges();
            }, 100);
        });
    }

    private allSubFormsAdded(): boolean {

        const assignment = this.data.assignment;

        const hasDateRangeForm = this.formGroup.get('dateRangeForm') !== null;
        const hasPersonForm = !assignment.person?.id || this.formGroup.get('personForm') !== null;
        const hasCompanyForm = !assignment.company?.id || this.formGroup.get('companyForm') !== null;
        const hasBoatForm = !assignment.boat?.id || this.formGroup.get('boatForm') !== null;
        const hasInvoiceForm = !this.berthProducts?.length || this.formGroup.get('invoiceForm') !== null;
        // should we make berth editable too?
        // const hasBerthForm = !this.givenBerth?.id || this.formGroup.get('berthForm') !== null;

        // tenant relations are not connected to the assignment...

        return hasDateRangeForm
            && hasPersonForm
            && hasCompanyForm
            && hasBoatForm
            && hasInvoiceForm;
        // && hasBerthForm;
    }

    private prepareForms(lastFilterData: LastFilter) {
        let givenType = null;

        if (this.data?.assignment?.person?.id) {
            givenType = 'person';
        } else if (this.data?.assignment?.company?.id) {
            givenType = 'organisation';
        }

        this.formGroupPrepare = this._formBuilder.group({
            type: [givenType, [U2bValidators.required('Bitte wähle eine Option aus.')]],
            showIsOwnerCheckBox: [null]
        });

        this.formGroup = this._formBuilder.group({
            assignmentType: [this.givenBerthAssignmentType],
            note: [this.data?.assignment?.note, [U2bValidators.maxLength(1024)]],
            reservedUntil: [this.data?.assignment?.reservedUntil],
            name: [null],
            mail: [null],
            isOwner: [null],
        });

        this.formGroup.get('assignmentType').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value: BookingType) => {
                this.givenBerthAssignmentType = value;
                this.berthAssignmentTitle = this.givenBerthAssignmentType === BookingType.Reservation
                    ? 'Reservierung'
                    : 'Buchung';
            });

        this.formGroup.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME * 2),
                map(value => ({
                    tenantRelationForm: value?.tenantRelationForm,
                    berthForm: value?.berthForm
                })),
                pairwise(),
                filter(([prev, current]) => {
                    return prev.tenantRelationForm?.tenantRelation?.id !== current.tenantRelationForm?.tenantRelation?.id
                        || prev.berthForm?.berth?.id !== current.berthForm?.berth?.id;
                }),
                map(([, current]) => current),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe((value) => {
                const berth = value.berthForm?.berth || this.givenBerth;

                if (berth?.id) {
                    this.berthProducts = (berth?.products || []).map(p => {
                        p.fromBerth = true;
                        return p;
                    });
                } else {
                    this.berthProducts = [];
                }

                this.updatePositions();
            });

        this.formGroup.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME * 2),
                map(value => value?.boatForm),
                pairwise(),
                filter(([prev, current]) => !deepEqual(prev, current)),
                map(([, current]) => current),
                takeUntil(this._unsubscribeAll),
            )
            .subscribe(boatForm => {
                this.canSave = !this.captureBoat || (this.captureBoat && this.formGroup.get('boatForm')?.value?.boat?.id);

                this.checkForBoatOwnerUpdate(boatForm);
            });

        this.formGroup.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                takeUntil(this._unsubscribeAll),
                pairwise(),
                filter(([prev, current]) => !deepEqual(prev, current)),
                map(([, current]) => current),
            )
            .subscribe(value => {
                const personForm = value?.personForm;
                const companyForm = value?.companyForm;
                const person: Person = personForm?.person;
                const company: Company = companyForm?.company;

                if (person?.id) {
                    this.formGroup.patchValue({
                        mail: person.mail || person.mail,
                        name: person.fullName,
                    });
                } else if (company?.id) {
                    this.formGroup.patchValue({
                        mail: company.mail || company.mail,
                        name: company.fullName,
                    });
                } else if (personForm || companyForm) {
                    this.formGroup.patchValue({
                        mail: (personForm || companyForm).mail,
                        name: companyForm?.name || ((personForm?.firstName || '') + ' ' + (personForm?.lastName || '')).trim(),
                    });
                }

                this.activeTenantRelations =
                    person?.activeTenantRelationAssignments || company?.activeTenantRelationAssignments || [];

                const boatForm = value?.boatForm;
                const boat = boatForm?.boat;
                const boatOwnerId = (boat?.owner || boat?.ownerCompany)?.id;
                const boatLength = boat?.length || boatForm?.length;
                const boatWidth = boat?.width || boatForm?.width;
                const boatWeight = boat?.weight || boatForm?.weight;

                this.boatToLong = undefined;
                this.boatToWide = undefined;
                this.boatToHeavy = undefined;
                this.berthMaxWeight = undefined;
                this.boatWeight = undefined;

                if (this.givenBerth && (boatLength || boatWidth)) {
                    if (this.givenBerth.length < boatLength) {
                        this.boatToLong = boatLength - this.givenBerth.length;
                    }
                    if (this.givenBerth.width < boatWidth) {
                        this.boatToWide = boatWidth - this.givenBerth.width;
                    }
                    if (this.givenBerth.isBuoy && this.givenBerth.maxCarryingCapacity) {
                        this.berthMaxWeight = this.givenBerth.maxCarryingCapacity * 1000; // t > kg

                        if (this.berthMaxWeight < boatWeight) {
                            this.boatToHeavy = boatWeight - this.berthMaxWeight;
                            this.boatWeight = boatWeight;
                        }
                    }
                }

                const personIsOwner = boatOwnerId && person?.id && boatOwnerId === person?.id;
                const companyIsOwner = boatOwnerId && company?.id && boatOwnerId === company?.id;
                const isOwner = personIsOwner || companyIsOwner;

                this.formGroupPrepare.get('showIsOwnerCheckBox').setValue(!isOwner);

                if (this.formGroup.get('isOwner').pristine) {
                    this.formGroup.get('isOwner').setValue(
                        isOwner == null
                            ? isOwner
                            : false
                    );
                }
            });

        this.formGroup.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map(value => ({
                    dateRangeForm: value?.dateRangeForm
                })),
                pairwise(),
                filter(([prev, current]) => {
                    return prev.dateRangeForm?.from?.getTime() !== current.dateRangeForm?.from?.getTime() ||
                        prev.dateRangeForm?.to?.getTime() !== current.dateRangeForm?.to?.getTime();
                }),
                map(([prev, current]) => current)
            )
            .subscribe((value) => {
                const from: Date = value?.dateRangeForm?.from;
                const to: Date = value?.dateRangeForm?.to;

                this.calculateDurationOfStay(from, to);
            });

        if (!this.data?.assignment) {
            setTimeout(() => {
                const boat = this.data?.preselect?.boat || lastFilterData?.boat;
                this.formGroup.get('boatForm')?.patchValue({boat});
                this.updatePositions();
                if (lastFilterData?.boat?.owner === null && lastFilterData?.boat?.ownerCompany === null) {
                    this.isLoading = false;
                }
            }, 1000);

            this.bcmSettingsFacade.loadSettings().subscribe((settings) => {
                this.prefilledTenantRelationId = settings[BcmSettingsSectionName.DefaultUnits]?.dayGuestBoater;

                this.defaultBerthReservationTimeUnit = settings[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];
                this.calculateDurationOfStay(this.givenFromDate, this.givenToDate);

                const {type, fromHours, fromMinutes, toHours, toMinutes} = this.defaultBerthReservationTimeUnit;

                if (type != null && !this.hasGivenFromDate && !this.hasGivenToDate) {
                    this.givenFromDate = concatDateAndTime(this.givenFromDate, fromHours, fromMinutes);
                    this.givenToDate = concatDateAndTime(this.givenToDate, toHours, toMinutes);

                    if (type === DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY) {
                        this.givenToDate = addDays(this.givenToDate, 1);
                    }
                }
            });
        }
    }

    private async checkForBoatOwnerUpdate(boatForm: any) {
        const boat = boatForm?.boat;
        const boatOwner = boat?.owner;
        const boatOwnerCompany = boat?.ownerCompany;
        const hasPersonOrCompany = this.formGroup.get('personForm')?.value || this.formGroup.get('companyForm')?.value;

        const showDialog = hasPersonOrCompany && (boatOwner || boatOwnerCompany)?.id;

        if (showDialog) {
            const dialogResult = await this._confirmDialogService
                .useWarnTheme()
                .setBody('Es wurde ein neues Boot ausgewählt. Soll die unten ausgewählte Person/Organisation mit dem Eigner überschrieben werden?')
                .openAndReturnResult()
                .toPromise();

            if (!dialogResult) {
                return;
            }
        }

        if (boatOwner?.id) {
            this.formGroupPrepare.get('type').setValue('person');
            setTimeout(() => {
                this.formGroup.get('personForm').get('person').setValue(boatOwner);
                this.isLoading = false;
            }, 500);
        } else if (boatOwnerCompany?.id) {
            this.formGroupPrepare.get('type').setValue('organisation');
            setTimeout(() => {
                this.formGroup.get('companyForm').get('company').setValue(boatOwnerCompany);
                this.isLoading = false;
            }, 500);
        }
    }

    private calculateDurationOfStay(from: Date, to: Date) {
        if (from !== null && to !== null && !!this.defaultBerthReservationTimeUnit) {

            if (this.defaultBerthReservationTimeUnit?.fromHours !== null && this.defaultBerthReservationTimeUnit?.fromMinutes !== null) {
                this.isAfterPeriodStarts = this.defaultBerthReservationTimeUnit.fromHours < from.getHours() ||
                    (this.defaultBerthReservationTimeUnit.fromHours === from.getHours() && this.defaultBerthReservationTimeUnit.fromMinutes <= from.getMinutes());

            }
            if (this.defaultBerthReservationTimeUnit?.toHours !== null && this.defaultBerthReservationTimeUnit?.toMinutes !== null) {
                this.isAfterPeriodEnds = this.defaultBerthReservationTimeUnit.toHours < to.getHours() ||
                    (this.defaultBerthReservationTimeUnit.toHours === to.getHours() && this.defaultBerthReservationTimeUnit.toMinutes < from.getMinutes());

            }

            this.sameDay = isSameDay(from, to);

            const fromWithoutTime = new Date(from.getFullYear(), from.getMonth(), from.getDate());
            const toWithoutTime = new Date(to.getFullYear(), to.getMonth(), to.getDate());
            this.totalDays = Math.max(1, Math.floor(Math.abs((toWithoutTime.getTime() - fromWithoutTime.getTime()) / (1000 * 60 * 60 * 24))));

            if (this.sameDay && this.defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY) {
                this.durationOfStay = 1;
            } else if (!this.sameDay && this.defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY) {
                this.durationOfStay = this.totalDays + 1;
            } else if (this.defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY) {
                this.durationOfStay = (this.totalDays) + (this.isAfterPeriodStarts ? 0 : 1) + ((!this.sameDay && this.isAfterPeriodEnds) ? 1 : 0);
            }

            this.updatePositions();

        } else {
            this.durationOfStay = -1;
        }
    }

    updatePositions(): void {
        this.givenInvoicePositions = [];

        const tenantRelation = this.formGroup.get('tenantRelationForm')?.value?.tenantRelation;
        const boatForm = this.formGroup.get('boatForm')?.value;
        const boat = boatForm?.boat;
        let boatDimensions: { length: number, width: number };

        if (boatForm && !boatForm?.boat?.id) {
            boatDimensions = {
                width: boatForm.width,
                length: boatForm.length,
            };
        }

        if (tenantRelation?.products?.length || this.berthProducts?.length) {

            const berth = this.givenBerth;

            const parameters = {
                boat,
                berth,
                tenantRelation,
                days: getDurationFromDates(this.givenFromDate, this.givenToDate, DurationUnit.Days, this.defaultBerthReservationTimeUnit),
                overnights: getDurationFromDates(this.givenFromDate, this.givenToDate, DurationUnit.Overnights, this.defaultBerthReservationTimeUnit),
            };

            const tenantRelationProducts = (tenantRelation?.products || [])
                .filter((p: Product) => !!p)
                .map((p: Product) => ({
                    ...p,
                    fromTenantRelation: true,
                    fromBerth: false,
                    costCenter: (p.costCenters?.length === 1 ? p.costCenters[0] : undefined)
                }));

            const berthProducts = (this.berthProducts || [])
                .filter((p: Product) => !!p)
                .map((p: Product) => ({
                    ...p,
                    fromTenantRelation: false,
                    fromBerth: true,
                    costCenter: berth.costCenter
                        || berth.pier?.costCenter
                        || (p.costCenters?.length === 1 ? p.costCenters[0] : undefined)
                }));

            const positionsWithDynamicPrice$ = [
                ...tenantRelationProducts,
                ...berthProducts
            ].map(product => {

                // special for Helgoland and only if it's a reservation
                if (this.bcmService.tenant?.form === 'Binnenhafen'
                    && this.givenBerthAssignmentType === BookingType.Reservation) {
                    product.quantity = 0;
                } else if (this.durationOfStay > 0
                    && [UnitUniqueName.OVERNIGHT_STAY, UnitUniqueName.DAY].includes(product.unit?.uniqueName)) {
                    product.quantity = this.durationOfStay;
                }

                if (product.hasDynamicPrice) {
                    return this._productService.evaluatePriceRule(
                        product,
                        parameters,
                        product.quantity,
                        boatDimensions)
                        .pipe(
                            take(1),
                            map(dynamicPrice => {
                                dynamicPrice = {
                                    ...dynamicPrice,
                                    boat: boat,
                                    berth: berth,
                                    tenantRelation: tenantRelation
                                };

                                return {
                                    product,
                                    quantity: product.quantity,
                                    account: product.account,
                                    title: dynamicPrice.ruleName,
                                    unit: product.unit,
                                    price: dynamicPrice.rulePrice,
                                    taxRate: product.taxRate,
                                    dynamicPrice,
                                    fromTenantRelation: product.fromTenantRelation,
                                    fromBerth: product.fromBerth,
                                    vestingPeriodFromDate: this.givenFromDate,
                                    vestingPeriodUntilDate: this.givenToDate,
                                    costCenter: product.costCenter,
                                } as InvoicePositionDto;
                            })
                        );
                } else {
                    return of({
                        product,
                        quantity: product.quantity,
                        account: product.account,
                        title: product.name,
                        unit: product.unit,
                        price: product.price,
                        taxRate: product.taxRate,
                        fromTenantRelation: product.fromTenantRelation,
                        fromBerth: product.fromBerth,
                        vestingPeriodFromDate: this.givenFromDate,
                        vestingPeriodUntilDate: this.givenToDate,
                        costCenter: product.costCenter,
                    } as InvoicePositionDto);
                }
            });

            forkJoin(positionsWithDynamicPrice$)
                .pipe(take(1))
                .subscribe(positions => {
                    // Nach Absprache mit Sandra werden Positionen erst nach dem Absenden des Formulars gefiltert.
                    this.givenInvoicePositions = positions
                        .map(position => new InvoicePosition(position));

                    if (tenantRelationProducts?.length && this.bcmTenantService.checkPermission(BcmTenantPermission.COST_CENTERS)) {
                        this._matDialog.open(
                            GetCostCenterForInvoicePositionsDialogComponent,
                            {
                                data: {
                                    invoicePositions: this.givenInvoicePositions,
                                }
                            }
                        );
                    }
                });

        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickClose(): void {
        if (this.formGroupPrepare.dirty || this.formGroup.dirty) {
            return this._confirmDialogService
                .useWarnTheme()
                .setTitle('Achtung')
                .setBody('Alle bislang eingegebenen Daten werden nicht gespeichert. Trotzdem beenden?')
                .setYesButton({
                    text: 'Ja, beenden'
                })
                .setNoButton({
                    text: 'Abbrechen'
                })
                .openWithCallback(() => this._dialogRef.close());
        }

        this._dialogRef.close();
    }

    doesOverlapBooking(): boolean {
        if (!this.data.berth.activeAssignments) {
            return false;
        }

        const startDate = this.formGroup.value?.dateRangeForm?.from || null;
        const endDate = this.formGroup.value?.dateRangeForm?.to || null;
        const allActiveBerthAssignments = this.data.berth?.getActiveAssignments();

        if (allActiveBerthAssignments) {
            for (const berthAssignment of allActiveBerthAssignments) {
                if (berthAssignment.from && this.data.berth.isCurrentStartDateBetweenDates(berthAssignment.from, startDate, endDate)) {
                    return true;
                }

                if (berthAssignment?.to && this.data.berth.isCurrentEndDateBetweenDates(berthAssignment.to, startDate, endDate)) {
                    return true;
                }
            }
        }
    }

    async onClickSaveAndClose(): Promise<void> {
        if (this.formGroup.invalid || this.formGroupPrepare.invalid) {
            this.formGroup.markAllAsTouched();
            this.formGroupPrepare.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
        } else if (this.doesOverlapBooking()) {
            this._confirmDialogService
                .setTheme('accent')
                .setTitle('Doppelbuchung erkannt')
                .setBody(`
                        Die von Ihnen angegebenen Daten führen zu einer Doppelbelegung auf <b> Liegeplatz ${this.data.berth.handle}</b>. <br>
                        Möchten Sie die Doppelbuchung dennoch wie angegeben eingetragen?
                    `)
                .openWithCallback(
                    () => this.saveFormData(),
                    () => false
                );
        } else {
            this.saveFormData();
        }
    }

    async saveFormData(): Promise<void> {

        this.isSaving = true;

        const {
            dateRangeForm,
            berthForm,
            personForm,
            companyForm,
            tenantRelationForm,
            boatForm,
            contractForm,
            contractCoOwnerForm,
            invoiceForm,
            note,
            reservedUntil,
            mail,
            isOwner
            // reservationText,
        } = this.formGroup.value;

        const body: any = {
            note,
            // reservationText,
            reservedUntil,
            isReservation: this.givenBerthAssignmentType === BookingType.Reservation,
            fromDate: dateRangeForm.from,
            toDate: dateRangeForm.to,
            sendMail: this.sendMail,
            mail
        };

        const hasTenantRelationProducts = tenantRelationForm?.tenantRelation?.products?.length;
        const hasInvoicePositions = invoiceForm?.positions?.length;

        if (!dateRangeForm.to && (hasTenantRelationProducts || hasInvoicePositions)) {
            const result = await this._confirmDialogService
                .setTitle('Abo hinzufügen')
                .setBody('Es wurde kein Enddatum erfasst. Möchtest Du für die ausgewählten Produkte ein Abo erzeugen?')
                .useWarnTheme()
                .openAndReturnResult()
                .toPromise();

            if (result) {
                const subscriptionForm = await new Promise(resolve => {
                    const aboDialogRef = this._matDialog.open(
                        GetProductSubscriptionDatesDialogComponent,
                        {
                            data: {
                                initialFromDate: dateRangeForm.from,
                                initialPayableOption: tenantRelationForm?.tenantRelation?.payableOption,
                            }
                        }
                    );

                    aboDialogRef.afterClosed().subscribe(data => {
                        resolve(data);
                    });
                });

                if (subscriptionForm) {
                    body['subscription'] = subscriptionForm;
                }
            }
        }

        if (berthForm) {
            body['berth'] = {
                id: berthForm.berth.id,
                note: berthForm.note
            };
        } else if (this.givenBerth) {
            body['berth'] = {
                id: this.givenBerth.id,
            };
        }

        if (personForm) {
            let person: any = {};

            if (personForm.person && personForm.person.id) {
                person.id = personForm.person.id;
            } else {
                delete personForm.person;
                person = {...personForm};
            }

            body['person'] = person;
        }

        if (companyForm) {
            let company: any = {};

            if (companyForm.company && companyForm.company.id) {
                company.id = companyForm.company.id;
            } else {
                delete companyForm.company;
                company = {...companyForm};
            }

            body['company'] = company;
        }

        if (tenantRelationForm?.tenantRelation?.id) {
            body['tenantRelation'] = tenantRelationForm?.tenantRelation;

            if (tenantRelationForm?.tenantRelation?.products) {
                body['products'] = tenantRelationForm.tenantRelation.products;
            }
        }

        if (boatForm) {
            let boat: any = {};

            if (boatForm.boat && boatForm.boat.id) {
                boat.id = boatForm.boat.id;
                boat.isOwner = isOwner;
            } else {
                delete boatForm.boat;
                boat = {...boatForm};
            }

            body['boat'] = boat;
        }

        if (contractForm) {
            body['contract'] = contractForm;
            body['contractFileType'] = ContractFileType[contractForm.contractFileType];

            if (contractForm.contract?.isWordTemplate && !contractForm.contract?.templateMailFile && !contractForm.contract?.templatePrintFile) {
                this._appNotificationService.showError('Der Vertrag kann nicht erstellt werden, da kein Word Dokument hinterlegt wurde.');
                this.isSaving = false;
                return;
            }
        }

        if (contractCoOwnerForm) {
            body['contractCoOwner'] = contractCoOwnerForm;
        }

        if (invoiceForm) {
            body['invoice'] = invoiceForm;
        }

        // todo find reason for this bug
        body?.invoice?.positions?.map(position => {
            if (position.dynamicPrice?.boat?.owner?.boats?.length > 0) {
                position.dynamicPrice.boat.owner.boats = position.dynamicPrice.boat.owner.boats.map(boat => {
                    return {...boat, owner: null, ownerCompany: null};
                });
            }
            return position;
        });

        // Nach Absprache mit Sandra, filtern wir erst hier die Positionen, die über den Liegeplatz kommen.
        if (body?.invoice?.positions?.length) {
            body.invoice.positions = body.invoice.positions
                .filter(position => {
                    return !(position.fromBerth
                        && !!position.product?.ignoreFreeOfChargePosition
                        && ((position.price ?? 0) * (position.quantity ?? 0)) === 0);
                });
        }

        let observable = of(null);

        if (this.data?.assignment) {
            observable = this._bcmWorkflowApiService
                .berthReservationCancellation({assignment: this.data.assignment});
        }

        observable
            .pipe(
                takeUntil(this._unsubscribeAll),
                switchMap(() => {
                    return this._bcmWorkflowApiService
                        .berthReservation(body)
                        .pipe(takeUntil(this._unsubscribeAll));
                })
            )
            .subscribe(async (result: NewBerthBoatAssignment) => {
                this._appNotificationService.showSuccess('Die Daten wurden erfolgreich gespeichert.');
                this._dialogRef.close(result);

                if (invoiceForm?.createInvoice) {
                    await this.bcmNavigationService.navigate(['accounting', 'invoices', 'new'], {
                        queryParams: {
                            person: result.person?.id,
                            company: result.company?.id,
                            bookingId: result.booking?.id,
                        }
                    });
                }
            })
            .add(() => this.isSaving = false);
    }

    captureTenantRelationChange(event: any): void {
        this.captureTenantRelation = event.checked;
        if (!this.captureTenantRelation) {
            this.updatePositions();
            this.formGroup.get('tenantRelationForm')?.reset();
        }
    }

    captureBoatChange(event: any): void {
        this.captureBoat = event.checked;
        if (!this.captureBoat) {
            this.formGroup.get('boatForm')?.reset();
        }
    }

}
