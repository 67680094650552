<mat-expansion-panel class="container" [class.mat-panel-padding]="!expanded" [(expanded)]="expanded">

    <mat-expansion-panel-header [class.expanded]="expanded">
        <ng-container *ngIf="!expanded; else expand">
            <mat-panel-description>
                <div>
                    #{{ index + 1 }} | {{ berthAssignmentFormGroup.get('berth')?.value }}
                </div>

                <div class="mat-panel-description-time">
                    {{ berthAssignmentFormGroup.get('from')?.value | date: 'dd.MM.yyyy HH:mm' }} Uhr
                    <ng-container *ngIf="berthAssignmentFormGroup.get('to')?.value">
                        <br>{{ berthAssignmentFormGroup.get('to')?.value | date: 'dd.MM.yyyy HH:mm' }} Uhr
                    </ng-container>
                </div>
            </mat-panel-description>
        </ng-container>
        <ng-template #expand>
            <mat-panel-description># {{ index + 1 }}</mat-panel-description>
        </ng-template>
    </mat-expansion-panel-header>

    <form [formGroup]="berthAssignmentFormGroup">

        <app-input-date-time [readonly]="bookingDialogService.readonlyView" formControlName="from">
            <label>von</label>
        </app-input-date-time>

        <app-input-date-time [readonly]="bookingDialogService.readonlyView" formControlName="to">
            <label>bis</label>
        </app-input-date-time>

        <ng-container *ngIf="defaultBerthReservationTimeUnit?.type !== undefined && durationOfStay > 0">
            <u2b-message type="info" class="mt-16" [collapsable]="true"
                         [header]="'Dauer der Belegung beträgt ' + durationOfStay + ' ' + (defaultBerthReservationTimeUnit?.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY ? 'Tag(e).' : 'Übernachtung(en).')">
                In den Hafeneinstellungen wurde festgelegt, dass die Dauer anhand der Anzahl der
                <b>{{ defaultBerthReservationTimeUnit?.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY ? 'Tage' : 'Übernachtungen' }}</b>
                berechnet wird.<br><br>
                <ng-container
                        *ngIf="defaultBerthReservationTimeUnit?.type === DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY">
                    Der Zeitraum einer Übernachtung beginnt
                    um {{ defaultBerthReservationTimeUnit.overnightGuestFromHours + ':' + defaultBerthReservationTimeUnit.overnightGuestFromMinutes }}
                    Uhr und endet am darauf folgenden Tag um
                    {{ defaultBerthReservationTimeUnit.overnightGuestToHours + ':' + defaultBerthReservationTimeUnit.overnightGuestToMinutes }}
                    Uhr.<br><br>
                </ng-container>
                Die Dauer dieser Belegung beträgt
                <b>{{ durationOfStay }} {{ defaultBerthReservationTimeUnit?.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY ? 'Tag(e).' : 'Übernachtung(en).' }}</b>
                <br><br>
                Anreise: {{ berthAssignmentFormGroup.get('from')?.value | dateTimeFormat: 'dd.MM. hh:mm' }}
                <br>
                Abreise: {{ berthAssignmentFormGroup.get('to')?.value | dateTimeFormat: 'dd.MM. hh:mm' }}<br>
                <ng-container
                        *ngIf="defaultBerthReservationTimeUnit?.type === DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY && (!isAfterPeriodStarts || isAfterPeriodEnds)">
                    <br>
                    - Entspricht {{ totalDays }} Übernachtung(en).
                    <ng-container *ngIf="!isAfterPeriodStarts">
                        <br>
                        - Zusätzlich 1 Übernachtung, da Anreise
                        vor {{ fromHours + ':' + fromMinutes }}
                        Uhr.
                    </ng-container>
                    <ng-container *ngIf="!sameDay && isAfterPeriodEnds">
                        <br>
                        - Zusätzlich 1 Übernachtung, da Abreise
                        nach {{ toHours + ':' + toMinutes }}
                        Uhr.
                    </ng-container>
                </ng-container>
            </u2b-message>
        </ng-container>

        <mat-divider class="special-divider"></mat-divider>

        <form-widget-berth-only appearance="outline"
                                [readonly]="bookingDialogService.readonlyView"
                                [parentFormGroup]="berthAssignmentFormGroup"
                                [selectBerth]="berthAssignmentFormGroup.get('berth')?.value"
                                [displayDimensions]="true"
                                [groupPiers]="true">
        </form-widget-berth-only>

        <ng-container *ngIf="boatToHeavy">
            <u2b-message type="warning" [customClass]="'mt-16'">
                <header>{{ 'boatToHeavyForBuoy' | translate }}</header>
                <p [innerHtml]="'boatToHeavyForBuoyText' | translate : {
                                    'berthMaxWeight': (berthMaxWeight | number: '1.0-2'),
                                    'boatWeight': (boatWeight | number: '1.0-2'),
                                    'boatToHeavy': (boatToHeavy | number: '1.0-2')
                                }">
                </p>
            </u2b-message>
        </ng-container>

        <ng-container *ngIf="boatToLong || boatToWide">
            <u2b-message type="warning" [customClass]="'mt-16'">
                <header>{{ 'boatDoesNotFitBerth' | translate }}</header>
                <ng-container *ngIf="boatToLong">
                    <p [innerHtml]="'boatLengthDoesNotFitBerth' | translate : {
                                        'boatToLong': (boatToLong | number: '1.0-2'),
                                        'berthLength': (berth.length | number: '1.0-2')
                                    }">
                    </p>
                </ng-container>
                <ng-container *ngIf="boatToWide">
                    <p [innerHtml]="'boatWidthDoesNotFitBerth' | translate : {
                                        'boatToWide': (boatToWide | number: '1.0-2'),
                                        'berthLength': (berth.width | number: '1.0-2')
                                    }">
                    </p>
                </ng-container>
            </u2b-message>
        </ng-container>

    </form>

    <ng-container *ngIf="!bookingDialogService.readonlyView">
        <mat-action-row>
            <button mat-flat-button color="warn" class="remove-btn"
                    (click)="removeAssignment.emit()">
                Belegung entfernen
            </button>
        </mat-action-row>
    </ng-container>

</mat-expansion-panel>
