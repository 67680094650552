<mat-form-field [appearance]="appearance"
                class="auto-complete-field"
                floatLabel="always">
    <mat-label>
        <ng-content select="label"></ng-content>
    </mat-label>
    <input matInput type="text"
           [formControl]="searchControl"
           [matAutocomplete]="selectAutocomplete">
    <mat-autocomplete autoActiveFirstOption
                      #selectAutocomplete="matAutocomplete"
                      [displayWith]="displayItemWith()"
                      (optionSelected)="setValue($event)">
        <mat-option disabled class="loading" *ngIf="isLoadingList">
            <mat-spinner diameter="35"></mat-spinner>
        </mat-option>

        <!-- Falls Gruppen existieren -->
        <ng-container *ngIf="groupBy && (filteredList$ | async) as grouped">
            <ng-container *ngFor="let group of grouped">
                <mat-optgroup [label]="group.name">
                    <mat-option *ngFor="let item of group.items" [value]="item">
                        <ng-container *ngIf="listTranslations?.length">
                            {{ listTranslations[item] | translate }}
                        </ng-container>
                        <ng-container *ngIf="!listTranslations?.length">
                            {{ item[displayWith] | translate }}
                        </ng-container>
                    </mat-option>
                </mat-optgroup>
            </ng-container>
        </ng-container>

        <!-- Falls keine Gruppen existieren -->
        <ng-container *ngIf="!groupBy && (filteredList$ | async) as list">
            <ng-container *ngFor="let item of list">
                <mat-option [value]="item">
                    <ng-container *ngIf="listTranslations?.length">
                        {{ listTranslations[item] | translate }}
                    </ng-container>
                    <ng-container *ngIf="!listTranslations?.length">
                        {{ item[displayWith] | translate }}
                    </ng-container>
                </mat-option>
            </ng-container>
        </ng-container>
    </mat-autocomplete>
    <mat-hint>
        <ng-content select=".hint"></ng-content>
    </mat-hint>
    <mat-error>{{ ngControl?.errors | firstErrorMessage }}</mat-error>
</mat-form-field>
