import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { BerthsMapFilterService, DateRange } from '@bcmServices/berths/berths-map-filter.service';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { debounceTime, filter, map, skip, take } from 'rxjs/operators';
import { BcmMapEditorSettings, BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { fromEvent, Observable } from 'rxjs';
import { MapFavourite } from '@shared/models/map-interfaces';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { U2bValidators } from '@shared/validators/validators';
import { SharedLayerService } from '@modules/bcm/berths/berths-map/_shared/shared-layer.service';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';
import { v4 as uuid } from 'uuid';
import { Options } from 'ngx-slider-v2';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { isSameDay } from 'date-fns';
import { Berth, BerthStatus } from '@shared/models/berth';
import { BerthReservationsDialog } from '@modules/bcm/berths/berths-map/berth-reservation-dialog/berth-reservation-dialog';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { BerthsFacadeOld } from '@bcmServices/berths/berths-facade-old.service';
import { BerthMapFabOptions } from '@modules/bcm/berths/berths-map/berth-map-action-bar/berth-map-fab/berth-map-fab.component';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';

export enum DateRangeType {
    Today = 'today',
    Tomorrow = 'tomorrow',
    RestOfWeek = 'restWeek',
    RestOfMonth = 'restMonth',
    Custom = 'custom',
}

@UntilDestroy()
@Component({
    selector: 'berth-map-action-bar',
    templateUrl: './berth-map-action-bar.component.html',
    styleUrls: ['./berth-map-action-bar.component.scss'],
})
export class BerthMapActionBarComponent implements AfterViewInit, OnInit {

    @ViewChild('dateRangeMenuTrigger') dateRangeMenuTrigger!: MatMenuTrigger;
    @ViewChild('moreMenuFavouritesTrigger') moreMenuFavouritesTrigger!: MatMenuTrigger;

    @ViewChild('picker') picker: MatDateRangePicker<Date>;

    dateRangeForm = this.formBuilder.group({
        start: new FormControl(null, [U2bValidators.required('Bitte Anreisedatum angeben')]),
        end: new FormControl(null),
    });

    dateForm = this.formBuilder.group({
        start: new FormControl(null, [U2bValidators.required('Bitte Anreisedatum angeben')]),
        end: new FormControl(null),
    });

    timeRange = new FormControl([(new Date().getHours() * 60) + new Date().getMinutes(), 1440]); // 24 * 60 = 1440

    DateRangeType = DateRangeType;

    FabOptions: BerthMapFabOptions = {
        buttons: []
    };

    sliderDisabled: boolean;

    sliderVisible = false;

    reservedAssignments: any[];

    berths: Berth[];

    sliderOptions: Options = {
        floor: 0,
        ceil: 1440, // 24h * 60m
        step: 5,
        noSwitching: true,
        translate: (value: number): string => {

            const timeString = this.berthsFilterService.getTimeStringFromSliderValue(value);

            return `${timeString} Uhr`;
        },
        animate: false,
        hideLimitLabels: true,
    };

    $favourites: Observable<MapFavourite[]> = this.settingsFacade
        .settings$
        .pipe(map(settings => settings[BcmSettingsSectionName.MapEditor]?.favourites || []));

    constructor(
        public berthsFilterService: BerthsMapFilterService,
        private _matDialog: MatLegacyDialog,
        private bcmNavigationService: BcmNavigationService,
        private settingsFacade: BcmSettingsFacade,
        private confirmDialogService: ConfirmDialogService,
        private informationDialogService: InformationDialogService,
        private sharedLayerService: SharedLayerService,
        private berthsFacade: BerthsFacadeOld,
        private formBuilder: FormBuilder,
    ) {

    }

    ngOnInit() {

        this.berthsFilterService.centralDateRange
            .pipe(untilDestroyed(this))
            .subscribe((dateRange: DateRange) => {

                if (this.picker.opened) {
                    return;
                }

                this.dateRangeForm.setValue(dateRange, {emitEvent: false});
                this.dateForm.setValue(dateRange, {emitEvent: true});

                if (isSameDay(dateRange.start, dateRange.end)) {
                    this.timeRange.enable({emitEvent: false});
                    this.sliderDisabled = false;

                    const timeSliderStart = (dateRange.start.getHours() * 60) + dateRange.start.getMinutes();
                    const timeSliderEnd = (dateRange.end.getHours() * 60) + dateRange.end.getMinutes();

                    this.timeRange.setValue([timeSliderStart, timeSliderEnd], {emitEvent: false});
                } else {
                    this.timeRange.disable({emitEvent: false});
                    this.sliderDisabled = true;
                }

                this.berthsFilterService.updateBerthView();

            });


        this.dateForm.valueChanges
            .pipe(
                skip(1),
                untilDestroyed(this),
                map((value: any) => {
                    const {start, end} = value;

                    return {
                        start: new Date(start) || null,
                        end: new Date(end) || null,
                    } as DateRange;

                }),
            )
            .subscribe((value) => {

                if (value.end && value.end?.getTime() < value.start?.getTime()) {
                    value.end = new Date(value.start);
                    this.dateForm.patchValue({end: value.end});
                    return;
                }

                this.berthsFilterService.setDateRange(value, false);
            });


        this.dateForm.valueChanges
            .pipe(
                take(1),
                untilDestroyed(this),
                map((value: any) => {
                    const {start, end} = value;

                    return {
                        start: new Date(start) || null,
                        end: new Date(end) || null,
                    } as DateRange;

                }),
            )
            .subscribe((value) => {

                if (value.end && value.end?.getTime() < value.start?.getTime()) {
                    value.end = new Date(value.start);
                    this.dateForm.patchValue({end: value.end});
                    return;
                }

                this.berthsFilterService.setDateRange(value);
            });


        this.dateRangeForm.valueChanges
            .pipe(
                untilDestroyed(this),
                map((value: any) => {
                    const {start, end} = value;

                    return {
                        start: new Date(start) || null,
                        end: new Date(end) || null,
                    } as DateRange;

                }),
            )
            .subscribe((value) => {
                this.berthsFilterService.setDateRange(value);
            });


        this.timeRange.valueChanges
            .pipe(
                untilDestroyed(this),
                filter(() => !this.sliderDisabled),
                distinctUntilChanged(),
                debounceTime(DEFAULT_DEBOUNCE_TIME)
            )
            .subscribe(([start, end]) => {

                const startDate = new Date(this.dateForm.get('start').value);
                const endDate = new Date(this.dateForm.get('end').value);

                const startHours = startDate.getHours();
                const startMinutes = startDate.getMinutes();

                const endHours = endDate.getHours();
                const endMinutes = endDate.getMinutes();

                const startVal = (startHours * 60) + startMinutes;
                let endVal = (endHours * 60) + endMinutes;

                if (endVal >= 1438) {
                    endVal = 1440;
                }

                if (startVal !== start || endVal !== end) {

                    const startHour = this.berthsFilterService.getHourStringFromSliderValue(start);
                    let startMinute = this.berthsFilterService.getMinuteStringFromSliderValue(start);

                    if (startMinute === 60) {
                        startMinute = 59;
                    }

                    const endHour = this.berthsFilterService.getHourStringFromSliderValue(end);
                    let endMinute = this.berthsFilterService.getMinuteStringFromSliderValue(end);

                    if (endMinute === 60) {
                        endMinute -= 1;
                    }

                    startDate.setHours(startHour, startMinute, 0);
                    endDate.setHours(endHour, endMinute, 59);

                    this.berthsFilterService.setDateRange({start: startDate, end: endDate}, false);
                }

            });

        this.berthsFacade.mapList$
            .subscribe((berths) => {
                this.reservedAssignments = berths
                    .filter(item => item.status === BerthStatus.Reserved);
            });

    }

    openReservations() {
        this._matDialog.open(BerthReservationsDialog, {
            data: {
                reservedAssignments: this.reservedAssignments
            }
        });
    }

    ngAfterViewInit(): void {
        this.FabOptions.buttons = [
            {
                icon: 'refresh',
                text: 'Neu laden',
                method: () => this.berthsFilterService.updateBerthView()
            },
            {
                icon: 'compare_arrows',
                text: 'Tabellarischer Liegeplan',
                method: () => this.openTable()
            },
            {
                icon: 'star',
                text: 'Fav. Ansicht',
                method: () => this.moreMenuFavouritesTrigger?.openMenu()
            },

            {
                icon: 'more_vert',
                text: 'Datum Schnellfilter',
                method: () => this.dateRangeMenuTrigger?.openMenu()
            },
            {
                icon: 'tune',
                text: 'Erweiterte Filter',
                method: () => this.berthsFilterService.toggleAdvancedFilters()
            }
        ];
    }

    openEditor(): void {
        this.bcmNavigationService.navigate(['berths', 'map', 'editor']);
    }

    openTable(): void {
        this.bcmNavigationService.navigate(['berths', 'list']);
    }

    newFavourite(): void {
        this.confirmDialogService
            .setTitle('Neue Ansicht speichern')
            .setBody(
                'Es wird die aktuelle Ansicht der Karte gespeichert. Diese kannst Du jetzt noch anpassen. ' +
                'Schiebe dafür dieses Fenster einfach beiseite und verändere die Kartenansicht.'
            )
            .setYesButton({text: 'Speichern'})
            .setNoButton({text: 'Abbrechen'})
            .appendInputToBody({
                label: 'Name der Ansicht',
                name: 'name',
                defaultValue: '',
                validators: [U2bValidators.required('Bitte Name der Ansicht angeben')],
                type: 'text'
            })
            .setDialogConfig({hasBackdrop: false})
            .openAndReturnResult<{ name: string }>()
            .subscribe(result => {
                if (result == null) {
                    return;
                }

                const mapCenter = this.sharedLayerService.map.getCenter();
                const mapZoom = this.sharedLayerService.mapZoom;

                this.$favourites
                    .pipe(take(1))
                    .subscribe(favourites => {
                        this.settingsFacade
                            .updateSectionItem<BcmMapEditorSettings, MapFavourite[]>(
                                BcmSettingsSectionName.MapEditor,
                                'favourites',
                                [
                                    ...favourites,
                                    {
                                        id: uuid(),
                                        name: result.name,
                                        center: mapCenter,
                                        zoom: mapZoom,
                                    } as MapFavourite
                                ]
                            );
                    });
            });
    }

    changeMapView(favourite: MapFavourite): void {
        fromEvent(this.sharedLayerService.map, 'moveend')
            .pipe(take(1))
            .subscribe(() => {
                this.sharedLayerService.map.setZoom(favourite.zoom);
            });

        this.sharedLayerService.map.panTo(favourite.center);
    }

    deleteFavourite(favouriteToRemove: MapFavourite, event: MouseEvent): void {
        event.stopPropagation();
        this.confirmDialogService
            .useWarnTheme()
            .setBody('Möchtest Du den Favoriten wirklich löschen?')
            .openAndReturnResult()
            .subscribe(result => {
                if (result) {
                    this.$favourites
                        .pipe(take(1))
                        .subscribe(favourites => {
                            this.settingsFacade
                                .updateSectionItem<BcmMapEditorSettings, MapFavourite[]>(
                                    BcmSettingsSectionName.MapEditor,
                                    'favourites',
                                    favourites.filter(favourite => favourite.id !== favouriteToRemove.id)
                                );
                        });
                }
            });
    }

    showFavouritesHelp(): void {
        this.informationDialogService
            .setTitle('Favorisierte Ansichten')
            .setBody(
                '<p>Mit fav. Ansichten hast Du die Möglichkeit, den aktuellen Kartenausschnitt zu speichern. ' +
                'Das System merkt sich den Ausschnitt und speichert diesen ab. Berücksichtigt wird hierbei das ' +
                'Zoom-Level und der Mittelpunkt der Karte.</p>' +
                '<p>Gespeicherte fav. Ansichten werden dann oben rechts unter "Fav. Ansichten" angezeigt und können mit ' +
                'einem Klick geladen werden. Hierbei springt die KArte auf die zuvor festgelegte Ansicht.</p>' +
                '<p>Eine zuvor gespeicherte Ansicht kann über das rotee Mülleiner-Symbol wieder gelöscht werden.</p>' +
                '<p>Deine Ansichten werden auch den anderen Benutzern angezeigt, die auf den frafischen Liegeplan ' +
                'zugreifen dürfen.<p>'
            )
            .open();
    }

}
