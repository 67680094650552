import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { RealBaseStateService } from '@bcmServices/real-base-state.service';
import { PageRequest } from '@shared/models/pagination';
import { BcmInvoiceWdTemplatesApiService } from '@bcmApiServices/bcm-invoice-wd-templates-api.service';
import { BcmInvoiceWdTemplate } from '@shared/models/bcm-invoice-wd-template';

@Injectable({providedIn: 'root'})
export class BcmInvoiceWdTemplatesFacade extends BcmInvoiceWdTemplatesApiService {

    private readonly state = new RealBaseStateService<BcmInvoiceWdTemplate>(BcmInvoiceWdTemplate);

    get latestPageRequest(): PageRequest<BcmInvoiceWdTemplate> {
        return this.state.latestPageRequest;
    }

    set latestPageRequest(value: PageRequest<BcmInvoiceWdTemplate>) {
        this.state.latestPageRequest = value;
    }

    get templates$(): Observable<BcmInvoiceWdTemplate[]> {
        return this.state.list$;
    }

    get templates(): BcmInvoiceWdTemplate[] {
        return this.state.list;
    }

    get selectedTemplate$(): Observable<BcmInvoiceWdTemplate> {
        return this.state.selected$;
    }


    get selectedTemplate(): BcmInvoiceWdTemplate {
        return this.state.selected;
    }

    get loadingAll$(): Observable<boolean> {
        return this.state.isUpdatingAll$;
    }

    get loading$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    constructor(_httpClient: HttpClient,
                _bcmService: BcmService) {
        super(_httpClient, _bcmService);
    }

    private _loadAll(httpParams?: HttpParams): Observable<BcmInvoiceWdTemplate[]> {
        this.state.startUpdatingAll();
        return this.getAll(httpParams);
    }

    loadAll(httpParams?: HttpParams): Subscription {
        this.state.startUpdatingAll();
        return this._loadAll(httpParams)
            .pipe(
                tap(templates => {
                    this.state.setList(templates);
                    this.state.stopUpdatingAll();
                }),
            ).subscribe();
    }

    loadOne(id: number): Observable<BcmInvoiceWdTemplate> {
        this.state.startUpdating();
        return this.getOne(id)
            .pipe(tap(template => {
                this.state.setSelected(template);
                this.state.stopUpdating();
            }));
    }

    addTemplate(tmpTemplate: BcmInvoiceWdTemplate): Observable<BcmInvoiceWdTemplate> {

        if (tmpTemplate?.title?.length) {

            tmpTemplate.id = -1;

            this.state.startUpdating();
            this.state.addListItem(tmpTemplate);

            return this.create(tmpTemplate)
                .pipe(
                    tap((template) => {
                        this.state.replaceListItem(tmpTemplate, template);
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.state.removeListItem(tmpTemplate);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    updateTemplate(template: BcmInvoiceWdTemplate, templateData: Partial<BcmInvoiceWdTemplate>): Observable<any> {
        if (template?.id) {

            this.state.startUpdating();

            const updatedTemplate = {
                ...template,
                ...templateData
            } as BcmInvoiceWdTemplate;

            this.state.replaceListItem(template, updatedTemplate); // optimistic change

            return this.update(updatedTemplate)
                .pipe(
                    tap(() => this.state.stopUpdating()),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(updatedTemplate, template); // revert
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    removeTemplate(template: BcmInvoiceWdTemplate): Observable<any> {

        if (template?.id) {

            this.state.removeListItem(template);

            return this.remove(template)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.state.addListItem(template);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

}
