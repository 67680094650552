import { Boat, IBoat } from '@shared/models/boat';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { IWinterStorage, WinterStorage } from '@shared/models/winter-storage';
import { startOfToday } from '@core/date.facade';
import { Company, ICompany } from '@shared/models/company';
import { IPerson, Person } from '@shared/models/person';
import { BcmCostCenter, BcmCostCenterDto } from '@shared/models/bcm-cost-center';

export interface IWinterStorageBoatAssignment {
    id?: number;
    assignedWidth: number;
    assignedLength: number;
    assignedArea: number;
    from: string;
    to: string;
    position?: string;
    helperTools?: string;
    note?: string;
    boat: IBoat;
    person?: IPerson;
    company?: ICompany;
    winterStorage: IWinterStorage;
    customAsset: string;
    assetType: string;
    costCenter?: BcmCostCenterDto;
}

export class WinterStorageBoatAssignment {
    id: number;
    assignedWidth: number;
    assignedLength: number;
    assignedArea: number;
    from: Date;
    to: Date;
    position?: string;
    helperTools?: string;
    note?: string;
    boat: Boat;
    person?: Person;
    company?: Company;
    winterStorage: WinterStorage;
    winterStorages?: WinterStorage[]; // only used on create new
    foretime: boolean;
    active: boolean;
    future: boolean;
    customAsset: string;
    assetType: string;
    assetOwnerType: 'person' | 'company' | 'none';
    costCenter?: BcmCostCenter;

    constructor(assignment: IWinterStorageBoatAssignment) {
        const today = startOfToday();

        if (assignment) {
            this.id = assignment.id;
            this.assignedWidth = assignment.assignedWidth;
            this.assignedLength = assignment.assignedLength;
            this.assignedArea = assignment.assignedArea || (this.assignedLength * this.assignedWidth);
            this.from = tryParseDate(assignment.from);
            this.to = tryParseDate(assignment.to);
            this.position = assignment.position || '';
            this.helperTools = assignment.helperTools || '';
            this.note = assignment.note;
            this.customAsset = assignment.customAsset;
            this.assetType = assignment.assetType;
            if (assignment.person) {
                this.assetOwnerType = 'person';
            } else if (assignment.company) {
                this.assetOwnerType = 'company';
            } else {
                this.assetOwnerType = 'none';
            }
            this.foretime = !!this.to && (this.to < today);
            this.active = this.from <= today && (
                this.to == null || this.to >= today
            );
            this.future = this.from > today && (
                this.to == null || this.to > today
            );
            this.costCenter = assignment?.costCenter ? new BcmCostCenter(assignment.costCenter) : null;

            this.boat = assignment.boat ? new Boat(assignment.boat) : null;
            this.person = assignment.person ? new Person(assignment.person) : null;
            this.company = assignment.company ? new Company(assignment.company) : null;
            this.winterStorage = assignment.winterStorage ? new WinterStorage(assignment.winterStorage) : null;
        }
    }

    toString(): string {
        return this.winterStorage.toString() +
            (this.boat ? ` - ${this.boat.toString()}` : (this.customAsset ? ` - ${this.customAsset}` : ''));
    }
}
