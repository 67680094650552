export function deepEqual(obj1: unknown, obj2: unknown, seen = new WeakSet<object>()): boolean {
    if (obj1 === obj2) {
        return true; // Primitive Werte und identische Referenzen sind gleich
    }

    if (obj1 instanceof Date && obj2 instanceof Date) {
        return obj1.getTime() === obj2.getTime(); // Direkter Vergleich der Zeitstempel
    }

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false; // Primitive Werte oder wenn einer null ist, sind ungleich
    }

    if (seen.has(obj1) || seen.has(obj2)) {
        return true; // Zirkuläre Referenz erkannt, als gleich behandeln
    }

    seen.add(obj1);
    seen.add(obj2);

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false; // Unterschiedliche Anzahl von Schlüsseln => ungleich
    }

    for (const key of keys1) {
        if (!deepEqual((obj1 as Record<string, unknown>)[key], (obj2 as Record<string, unknown>)[key], seen)) {
            return false; // Wenn irgendein Schlüssel ungleich ist, => ungleich
        }
    }

    return true; // Alle Schlüssel und Werte sind gleich
}
