<div class="container"
     fxLayout="row wrap"
     fxLayoutGap="grid">

    <ng-container *ngIf="headline">
        <div fxFlex="100">
            <h2 [innerHTML]="headline"></h2>
        </div>
    </ng-container>

    <div fxFlex="100" class="mb-16 ml-16">
        <table class="simple sm">
            <thead>
            <tr>
                <th></th>
                <th></th>
                <th>Beschreibung</th>
                <th style="width: 80px;" class="text-center">Anzahl</th>
                <th class="text-right">Einheit</th>
                <th class="text-right">Einzelpreis</th>
                <th class="text-right">Summe</th>
                <th class="text-right">Beginn</th>
                <th class="text-right">Intervall</th>
                <th class="pr-0"></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngIf="!subscriptions?.length">
                <tr>
                    <td [attr.colspan]="colspan" class="text-center">Keine Abonnements vorhanden.</td>
                </tr>
            </ng-container>
            <ng-container *ngFor="let subscription of subscriptions; let i = index">
                <tr cdkDrag [class.deleted]="subscription.deleted">
                    <td></td>
                    <td>
                        <div class="position-icon">
                            <ng-container
                                    *ngIf="subscription.berthAssignment">
                                <mat-icon
                                        [matTooltip]="'Belegung #' + subscription.berthAssignmentIndex"
                                        matTooltipPosition="right">
                                    directions_boat
                                </mat-icon>
                            </ng-container>
                            <ng-container *ngIf="subscription.tenantRelationName?.length">
                                <mat-icon [matTooltip]="'Beziehung - ' + subscription.tenantRelationName"
                                          matTooltipPosition="right">
                                    timeline
                                </mat-icon>
                            </ng-container>
                        </div>
                    </td>
                    <td>
                        {{ subscription.customTitle || subscription.product.name }}
                    </td>
                    <td style="width: 80px;" class="text-center">
                        {{ subscription.quantity }}
                    </td>
                    <td class="text-right">
                        {{ subscription.product?.unit?.name }}
                    </td>
                    <td class="text-right">
                        {{ subscription.customPrice || subscription.product.price | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                    <td class="text-right">
                        {{ subscription.totalPrice | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                    <td class="text-right">
                        {{ subscription.fromDate | date: 'dd.MM.yyyy' }}
                    </td>
                    <td class="text-right">
                        {{ subscription.payableOption?.name }}
                    </td>
                    <td class="text-right pr-0" width="80px">
                        <div fxLayout="row" fxLayoutAlign="flex-end center">
                            <ng-container *ngIf="!readonly">
                                <button type="button" mat-icon-button color="warn"
                                        [disabled]="subscription.id === -1"
                                        [matTooltipDisabled]="subscription.id !== -1 || subscription.deleted"
                                        matTooltip="Abonnement kann nicht gelöscht werden, da es mit der Beziehung verknüpft ist"
                                        (click)="removeSubscription(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </ng-container>

            <div *cdkDragPlaceholder></div>
            </tbody>
            <tfoot>
            </tfoot>
        </table>
    </div>

</div>
