<mat-chip-list #chipList>

    <mat-chip *ngFor="let chip of selectedItems"
              [removable]="true"
              [disableRipple]="true"
              [selectable]="false"
              (removed)="removeChip(chip, trigger)">
        {{ chip[dataLabelAttribute] || chip[dataIdentAttribute] }}
        <ng-container *ngIf="hasRemove">
            <mat-icon matChipRemove>cancel</mat-icon>
        </ng-container>
    </mat-chip>

    <div fxFlex=100>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <input #input
                   #trigger="matAutocompleteTrigger"
                   matInput
                   [formControl]="itemCtrl"
                   [matChipInputFor]="chipList"
                   [matChipInputAddOnBlur]="true"
                   [placeholder]="placeholder"
                   [errorStateMatcher]="errorStateMatcher"
                   [matAutocomplete]="matAutocomplete ? matAutocomplete : null"
                   [matAutocompleteDisabled]="!filteredItems"
                   (matChipInputTokenEnd)="addChip($event, input)"
                   data-test="FI-Chips"/>

            <button mat-icon-button matSuffix matTooltip="Eingabefeld zurücksetzen" *ngIf="!!itemCtrl.value"
                    (mousedown)="clearInput($event)">
                <mat-icon>backspace</mat-icon>
            </button>
        </div>
    </div>

    <mat-autocomplete [hidden]="!filteredItems"
                      #chipListAutocomplete="matAutocomplete"
                      fxFlex="100"
                      [displayWith]="displayWith"
                      (optionSelected)="selected($event)">
        <ng-container *ngIf="currentFilteredItems?.length">
            <mat-option class="sticky-option" disabled>
                <ng-container *ngIf="currentFilteredItems.length !== _allItems.length">
                    <div (click)="addAllFromFilteredList()">
                        <mat-icon>playlist_add</mat-icon>
                        Auswahl hinzufügen
                    </div>
                </ng-container>
                <ng-container *ngIf="currentFilteredItems.length === _allItems.length">
                    <div class="flex-placeholder"></div>
                </ng-container>
                <mat-icon (click)="trigger.closePanel()">close</mat-icon>
            </mat-option>
        </ng-container>
        <ng-container *ngFor="let item of filteredItems | async">
            <mat-option [value]="item"
                        [disabled]="item.disabled"
                        [matTooltip]="item.disabledReason"
                        [matTooltipDisabled]="!item.disabled || !item.disabledReason"
                        (click)="trigger.openPanel()">
                {{ item[dataLabelAttribute] || item[dataIdentAttribute] }}
            </mat-option>
        </ng-container>

        <mat-option *ngIf="(filteredItems | async)?.length === 0" disabled>
            Keine Einträge gefunden
        </mat-option>
    </mat-autocomplete>

</mat-chip-list>
