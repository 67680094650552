import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    MatLegacyFormFieldAppearance as MatFormFieldAppearance,
    MatLegacyFormFieldControl as MatFormFieldControl
} from '@angular/material/legacy-form-field';
import { BaseInputComponent } from '@sharedComponents/form/base-input.component';
import { DefaultInputErrorStateMatcher } from '@sharedComponents/form/base-input.error-state-matcher';
import { BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isString } from '@shared/functions/is-string';
import { MatLegacyAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';

@Component({
    selector: 'app-input-auto-complete',
    templateUrl: './auto-complete-input.component.html',
    styleUrls: ['./auto-complete-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutoCompleteInputComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: AutoCompleteInputComponent
        }
    ],
})
export class AutoCompleteInputComponent extends BaseInputComponent<any> implements OnInit, OnChanges {

    errorStateMatcher: DefaultInputErrorStateMatcher;

    placeholder: string;

    @Input() fc: AbstractControl;

    @Input() appearance: MatFormFieldAppearance = 'outline';

    @Input() list: any[];

    @Input() listTranslations: { [key: string | number | symbol]: string };

    @Input() filterBy: string[];

    @Input() displayWith: string;

    @Input() orderBy: string;

    @Input() groupBy: string;

    @Input() groupByOrder: string[] = [];

    @Input() isLoadingList: boolean;

    protected filteredList$ = new BehaviorSubject<any[]>([]);

    protected searchControl = new FormControl();

    ngOnInit(): void {
        super.ngOnInit();

        if (this.ngControl) {
            this.errorStateMatcher = new DefaultInputErrorStateMatcher(this.ngControl);
        }

        this.searchControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this.mapFilterList(value)),
            )
            .subscribe(list => {
                this.filteredList$.next(list);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'list': {
                        this.filteredList$.next(this.mapFilterList(''));
                    }
                }
            }
        }
    }

    public writeValue(inputValue: Date): void {
        super.writeValue(inputValue);
        const {nativeElement} = this.el;
        this._renderer.setProperty(nativeElement, 'value', inputValue);
        this.searchControl.setValue(inputValue);
    }

    public setDisabledState(isDisabled: boolean): void {
        super.setDisabledState(isDisabled);
        const {nativeElement} = this.el;
        this._renderer.setProperty(nativeElement, 'disabled', isDisabled);
    }

    public setValue(event: MatLegacyAutocompleteSelectedEvent) {
        this.value = event.option.value;
        this.valueChange.emit(this.value);
    }

    public displayItemWith = () => (item: any): string => {
        return item && this.displayWith && item[this.displayWith] ? item[this.displayWith] : '';
    }

    private mapFilterList(value: string): any[] {
        let list: any[];

        if (isString(value) && value !== '') {
            list = this._filterItems(value);
        } else {
            list = (this.list || []).slice();
        }

        // Falls `groupBy` definiert ist, gruppierte Liste zurückgeben
        if (this.groupBy) {
            return Object.values(this._groupItems(list));
        }

        return list;
    }

    private _groupItems(items: any[]): Record<string, any> {
        const grouped = items.reduce((acc, item) => {
            const groupName = item[this.groupBy];
            if (!acc[groupName]) {
                acc[groupName] = { name: groupName, items: [] };
            }
            acc[groupName].items.push(item);
            return acc;
        }, {});

        const sortedGroups = Object.keys(grouped).sort((a, b) => {
            const indexA = this.groupByOrder.indexOf(a);
            const indexB = this.groupByOrder.indexOf(b);

            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
            }

            if (indexA !== -1) {
                return -1;
            }

            if (indexB !== -1) {
                return 1;
            }

            return a.localeCompare(b);
        });

        return sortedGroups.reduce((acc, key) => {
            acc[key] = grouped[key];
            return acc;
        }, {});
    }

    private _filterItems(filter = ''): any[] {
        return (this.list || [])
            .slice()
            .filter(item => {
                for (const key of (this.filterBy || [])) {
                    if (key && item && item[key] && item[key].toLowerCase().includes(filter.toLowerCase())) {
                        return true;
                    }
                }
            });
    }
}
