import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { UserDataResult } from 'angular-auth-oidc-client/lib/user-data/userdata-result';
import { catchError, map, take } from 'rxjs/operators';
import { ElectronService } from '@core/services/electron.service';
import { UserService } from '@core/services/user.service';
import { U2bUserApiService } from '@core/services/api/u2b-user.api-service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

let windowLoginPopup: Window;

@Injectable({providedIn: 'root'})
export class AuthService {

    private isLoggingIn = false;

    constructor(@Inject(DOCUMENT) private document: Document,
                private oidcSecurityService: OidcSecurityService,
                private electronService: ElectronService,
                private userApiService: U2bUserApiService,
                private userService: UserService,
                private router: Router) {
    }

    get isLoggedIn$(): Observable<boolean> {
        return this.oidcSecurityService
            .isAuthenticated$
            .pipe(map(result => result.isAuthenticated));
    }

    get token(): Observable<string> {
        return this.oidcSecurityService.getAccessToken();
    }

    get userDataResult$(): Observable<UserDataResult> {
        return this.oidcSecurityService.userData$;
    }

    get userData$(): Observable<any> {
        return this.userDataResult$.pipe(map(result => result.userData));
    }

    checkAuth(url?: string): Observable<boolean> {
        this.isLoggingIn = false;

        if (this.electronService.isElectronApp()) {
            this._closeLoginPopup();
        }

        if (url) {
            this.oidcSecurityService.checkAuth(url).subscribe();
            return EMPTY;
        }

        const alreadyLoggedOut = localStorage.getItem('alreadyLoggedOut');

        if (!alreadyLoggedOut) {
            return this.oidcSecurityService.getIdToken().pipe(
                take(1),
                switchMap(idToken => {
                    const customParams = idToken ? {id_token_hint: idToken} : {};
                    return this.oidcSecurityService.logoffAndRevokeTokens(undefined, {customParams});
                }),
                map(() => {
                    localStorage.setItem('alreadyLoggedOut', 'true');
                    this.doLogin();
                    return false;
                })
            );
        }

        return this.oidcSecurityService
            .checkAuth()
            .pipe(
                take(1),
                map(({isAuthenticated}) => {
                    if (isAuthenticated) {
                        this.loadCurrentUser();
                    } else if (url === undefined) {
                        this.doLogin();
                    }
                    return isAuthenticated;
                }),
                catchError((error) => {
                    console.error(error);
                    return of(false);
                })
            );
    }

    doLogin(): void {
        if (this.isLoggingIn) {
            return;
        }

        this.isLoggingIn = true;

        if (this.electronService.isElectronApp()) {
            const urlHandler = (authUrl: string) => {
                windowLoginPopup = this.document.defaultView.open(authUrl, '_blank', 'nodeIntegration=no,popup=true,prompt=login');
            };
            this.oidcSecurityService.authorize(undefined, {urlHandler});
        } else {
            this.oidcSecurityService.authorize(undefined, {customParams: {prompt: 'login'}});
        }
    }

    signOut(): void {
        this.oidcSecurityService.getIdToken().subscribe(idToken => {
            const customParams = idToken ? {id_token_hint: idToken} : {};
            this.oidcSecurityService
                .logoffAndRevokeTokens(undefined, {customParams})
                .subscribe(() => {
                    localStorage.removeItem('alreadyLoggedOut');
                });
        });
    }

    loadCurrentUser(): void {
        this.userApiService
            .getCurrent()
            .pipe(catchError((error) => {
                console.error(error);
                return EMPTY;
            }))
            .subscribe(appUser => {
                if (appUser) {
                    this.userService.user = appUser;
                } else {
                    this.router.navigate(['errors', '500']);
                }
            });
    }

    private _closeLoginPopup(): void {
        if (windowLoginPopup) {
            (windowLoginPopup.window || windowLoginPopup).close();
        }
    }
}
