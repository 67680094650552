import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormWidgetTenantRelationComponent } from './form-widget-tenant-relation.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';
import { ChipListInputModule } from '@sharedComponents/form/chip-list-input/chip-list-input.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateTimeInputModule } from '@sharedComponents/form/date-time-input/date-time-input.module';

@NgModule({
    declarations: [FormWidgetTenantRelationComponent],
    imports: [

        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        BcmPipesModule,
        ChipListInputModule,
        MatButtonToggleModule,
        DateTimeInputModule
    ],
    exports: [FormWidgetTenantRelationComponent]
})
export class FormWidgetTenantRelationModule {
    
}
