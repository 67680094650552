import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from 'hmr';
import { Logger } from '@core/services/logger.service';
import { installAllPatches } from '@core/patches/installer';

if ( environment.production )
{
    enableProdMode();
    Logger.enableProductionMode();
}

// todo:
//  Because of keycloak url changes, localstorage needs to be cleared.
//  Delete this in one of the next releases
const currentVersion = 'keycloak-url-fix';
const storedVersion = localStorage.getItem(currentVersion);

if (storedVersion !== currentVersion) {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem(currentVersion, currentVersion);
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)
    .then(ref => {
        if (window['ngRef']) {
            window['ngRef'].destroy();
        }
        window['ngRef'] = ref;
        return ref;
    });

if ( environment.hmr )
{
    if ( module['hot'] )
    {
        hmrBootstrap(module, bootstrap);
    }
    else
    {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else
{
    bootstrap().catch(err => console.error(err));
}

installAllPatches();
