<div [formGroup]="formGroup"
     class="with-loading-overlay">

    <div>
        <mat-checkbox class="ml-8 mt-16" formControlName="captureTenantRelation">
            Beziehung auswählen
        </mat-checkbox>
    </div>

    <ng-container
            *ngIf="bookingDialogService.tenantRelationAssignment.value?.captureTenantRelation || bookingDialogService.readonlyView && bookingDialogService.tenantRelationAssignment.value?.id">
        <mat-divider class="special-divider"></mat-divider>
        <booking-tenant-relation-assignment [readonly]="bookingDialogService.readonlyView"></booking-tenant-relation-assignment>
    </ng-container>

    <ng-container *ngIf="bookingDialogService.loading">
        <div class="loading-overlay">
            <mat-spinner [diameter]="40"></mat-spinner>
        </div>
    </ng-container>
</div>

