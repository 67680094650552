import {
    DATE_DE,
    DATE_ISO,
    DATE_TIME_DE,
    DATE_TIME_ISO,
    DATE_TIME_ISO_INCLUDING_SECONDS
} from '@shared/constants/date';
import { de } from 'date-fns/locale';
import { format } from '@core/date.facade';
import { isString } from '@shared/functions/is-string';
import { parseISO } from '@core/date.facade';
import { isValidDate } from '@core/functions/is-valid-date';

function toDateString(date: Date | string, dateStringFormat = DATE_DE, locale = de): string | null {
    if (!date) {
        return null;
    }

    if (isValidDate(date)) {
        return format(date as Date, dateStringFormat, {locale});
    }

    if (isString(date)) {
        try {
            return format(parseISO(String(date)), dateStringFormat, {locale});
        } catch (e) {
            // console.error(e);
        }
    }

    return null;
}

export function toDateStringByFormat(date: Date | string, dateFormat: string): string {
    const parsedDate: Date = isValidDate(date) ? date as Date : parseISO(String(date));
    return toDateString(parsedDate, dateFormat);
}

export function toDateStringDE(date: Date | string): string {
    return toDateString(date, DATE_DE);
}

export function toSqlDate(date: Date | string): string {
    try {
        const parsedDate: Date = isValidDate(date) ? date as Date : parseISO(String(date));
        return format(parsedDate, DATE_ISO);
    } catch (e) {
        return null;
    }
}

export function toSqlDateTime(date: Date | string, includeSeconds = true): string {
    try {
        const parsedDate: Date = isValidDate(date) ? date as Date : parseISO(String(date));
        return format(parsedDate, DATE_TIME_ISO + (includeSeconds ? ':ss' : '')).replace(' ', 'T');
    } catch (e) {
        return null;
    }
}

export function toDateTimeStringDE(date: Date | string, includeSeconds = false): string {
    const parsedDate: Date = isValidDate(date) ? date as Date : parseISO(String(date));
    return toDateString(parsedDate, DATE_TIME_DE + (includeSeconds ? ':ss' : ''));
}

export function toDateTimeStringISO(date: Date): string {
    // ToDo: get date format from tenant
    return toDateString(date, DATE_TIME_ISO_INCLUDING_SECONDS).replace(' ', 'T');
}

