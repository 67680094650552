import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { Berth } from '@shared/models/berth';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';

export class BookingDialogBerthAssignments extends BookingDialogBaseEntity<BerthBoatAssignment, BerthBoatAssignment[]> {

    constructor(bookingDialogService: BookingDialogService) {
        super(bookingDialogService, BookingAttribute.BERTH_ASSIGNMENTS);
    }

    add(value: BerthBoatAssignment): void {
        super.add(value);

        this.bookingDialogService.positions.addFromBerthAssignment(value);
    }

    remove(value: BerthBoatAssignment) {
        super.remove(value);

        this.bookingDialogService.positions.removeFromBerthAssignment(value);
    }

    cut(assignmentA: { id: number, berth: Berth, from: Date, to: Date }, assignmentB: {
        berth: Berth,
        from: Date,
        to: Date
    }) {

        const boat = this.bookingDialogService.boat.value;

        const index = this.value.findIndex(a => a.id === assignmentA.id);
        if (index !== -1) {
            this.value[index].berth = assignmentA.berth;
            this.value[index].from = assignmentA.from;
            this.value[index].to = assignmentA.to;
        }

        this.add(new BerthBoatAssignment({
            boat: boat,
            electricMeterAssignments: [],
            berth: assignmentB.berth,
            from: assignmentB.from,
            to: assignmentB.to
        } as unknown as IBerthBoatAssignment));

    }

    getFromDate(): Date {
        return new Date(Math.min(...this.value.map(a => a.from?.getTime())));
    }

    getToDate(): Date {
        if (this.value.some(a => !a.to)) {
            return null;
        }

        return new Date(Math.max(...this.value.map(a => a.to?.getTime())));
    }

}
