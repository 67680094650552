<div [formGroup]="boatFormGroup"
     fxLayout="row wrap">

    <div fxFlex="100">

        <div fxLayout="row" fxLayoutAlign="space-between center">
            <ng-container *ngIf="!slimmedView">
                <h2 [innerHTML]="headline"></h2>
            </ng-container>
        </div>

        <ng-container *ngIf="loadingBoats; else formContent">
            <div style="display: flex; justify-content: center; align-items: center;">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
        </ng-container>

        <ng-template #formContent>

            <ng-container *ngIf="!topList?.length && !bottomList?.length">
                <u2b-message>
                    <header>{{ 'missingBoats' | translate }}</header>
                    <p>{{ 'missingBoatsInSystem' | translate }}</p>
                </u2b-message>
            </ng-container>

            <ng-container *ngIf="topList?.length || bottomList?.length">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex [appearance]="appearance" floatLabel="always">
                        <mat-icon matPrefix>search</mat-icon>
                        <mat-label>{{ 'searchBoat' | translate : null : 'Nach Boot suchen...' }}</mat-label>
                        <input matInput
                               [readonly]="readonly"
                               type="text"
                               formControlName="boat"
                               [matAutocomplete]="boatSelector">

                        <mat-autocomplete
                                #boatSelector="matAutocomplete"
                                [displayWith]="displayBoatWith">
                            <ng-container *ngIf="displayBoatOwnerName?.length > 0 || boatOwnerName?.length > 0">
                                <ng-container *ngIf="filteredTopList$ | async as topList">
                                    <ng-container *ngIf="topList?.length">
                                        <mat-optgroup
                                                [label]="'boatsFromOwner' | translate : {boatOwnerName: (boatOwnerName || displayBoatOwnerName)}">
                                            <ng-container *ngFor="let boat of topList">
                                                <mat-option [value]="boat">
                                                    <bcm-boat-link [disableLink]="true"
                                                                   [boat]="boat"></bcm-boat-link>
                                                    <ng-container
                                                            *ngIf="(boat.owner || boat.ownerCompany)?.id; else noOwner">
                                                        <br>
                                                        <small>
                                                            <bcm-boat-owner-link [disableLink]="true"
                                                                                 [boat]="boat"></bcm-boat-owner-link>
                                                        </small>
                                                    </ng-container>
                                                    <ng-template #noOwner>
                                                        <br>
                                                        <small class="color-warn">Kein Eigner</small>
                                                    </ng-template>
                                                </mat-option>
                                                <ng-container *ngIf="!topList?.length">
                                                    {{ ('ownerHasNoBoat' | translate : {boatOwnerName: (boatOwnerName || displayBoatOwnerName)}) }}
                                                </ng-container>
                                            </ng-container>
                                        </mat-optgroup>
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="filteredBottomList$ | async as bottomList">
                                <ng-container *ngIf="bottomList?.length">
                                    <mat-optgroup
                                            [label]="(displayBoatOwnerName?.length > 0 || boatOwnerName?.length > 0) ?('moreBoats' | translate) :('allBoats' | translate)">
                                        <ng-container *ngFor="let boat of bottomList">
                                            <mat-option [value]="boat">
                                                <bcm-boat-link [disableLink]="true" [boat]="boat"></bcm-boat-link>
                                                <ng-container
                                                        *ngIf="(boat.owner || boat.ownerCompany)?.id; else noOwner">
                                                    <br>
                                                    <small>
                                                        <bcm-boat-owner-link [disableLink]="true"
                                                                             [boat]="boat"></bcm-boat-owner-link>
                                                    </small>
                                                </ng-container>
                                                <ng-template #noOwner>
                                                    <br>
                                                    <small class="color-warn">Kein Eigner</small>
                                                </ng-template>
                                            </mat-option>
                                            <ng-container *ngIf="!bottomList?.length">
                                                {{ 'missingBoats' | translate }}
                                            </ng-container>
                                        </ng-container>
                                    </mat-optgroup>
                                </ng-container>
                            </ng-container>
                        </mat-autocomplete>

                        <div matSuffix>
                            <ng-container *ngIf="editable && boat?.id && !readonly">
                                <button
                                        mat-icon-button
                                        type="button"
                                        color="primary"
                                        [matTooltip]="'editBoat' | translate"
                                        (click)="openEditDialog($event)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!boat?.id && canCreate && !readonly">
                                <button
                                        mat-icon-button
                                        type="button"
                                        color="primary"
                                        [matTooltip]="'createBoat' | translate"
                                        (click)="openEditDialog($event)">
                                    <mat-icon>add_circle_outline</mat-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!readonly">
                                <button
                                        mat-icon-button
                                        type="button"
                                        color="warn"
                                        [matTooltip]="'deleteBoatTooltip' | translate"
                                        [disabled]="!boat?.id || boatFormGroup.disabled"
                                        (click)="onClickRemoveBoat()">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </ng-container>
                        </div>
                        <mat-error
                                [hidden]="slimmedView">{{ boatFormGroup.get('boat').errors | firstErrorMessage }}
                        </mat-error>
                    </mat-form-field>

                </div>
            </ng-container>

        </ng-template>
    </div>

    <ng-container *ngIf="boat?.id && showMetaData">
        <div fxFlex="100">
            <table class="simple sm">
                <tbody>
                <tr>
                    <td>Länge&nbsp;(m):</td>
                    <td fxLayoutAlign="end center">{{ boat.length || 'Keine Angabe' }}</td>
                </tr>
                <tr>
                    <td>Breite&nbsp;(m):</td>
                    <td fxLayoutAlign="end center">{{ boat.width || 'Keine Angabe' }}</td>
                </tr>
                <tr>
                    <td>Tiefgang&nbsp;(m):</td>
                    <td fxLayoutAlign="end center">{{ boat.depth || 'Keine Angabe' }}</td>
                </tr>
                <tr>
                    <td>Länge über Alles&nbsp;(m):</td>
                    <td fxLayoutAlign="end center">{{ boat.lengthOverall || 'Keine Angabe' }}</td>
                </tr>
                <tr>
                    <td>Fläche (m²):</td>
                    <td fxLayoutAlign="end center">{{ boat.sqm || 'Keine Angabe' }}</td>
                </tr>
                <ng-container *ngIf="givenBerth?.isBuoy">
                    <tr>
                        <td>Gewicht (kg):</td>
                        <td fxLayoutAlign="end center">{{ boat.weight || 'Keine Angabe' }}</td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </ng-container>

</div>
