import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bAddressValidators } from '@shared/validators/address/address-validators';
import { U2bValidators } from '@shared/validators/validators';
import { U2bCompanyValidators } from '@shared/validators/company/company-validators';
import { AppNotificationService } from '@core/services/app-notification.service';

enum InvoiceAddressType {
    Person,
    Company
}

@Component({
    selector: 'get-invoice-address-dialog',
    templateUrl: './get-invoice-address-dialog.component.html',
    styleUrls: ['./get-invoice-address-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GetInvoiceAddressDialogComponent {
    dialogTitle: string;
    formGroup: UntypedFormGroup;
    invoiceAddressType = InvoiceAddressType;
    isSaving: boolean;

    constructor(
        public dialogRef: MatDialogRef<GetInvoiceAddressDialogComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogTitle = 'Adresse aus Stammdaten wählen';
        this.formGroup = this._createForm();

        this.formGroup.get('type').valueChanges.subscribe((value) => {
            switch (value) {
                case InvoiceAddressType.Person:
                    this.formGroup.get('person').enable();
                    this.formGroup.get('company').disable();
                    break;
                case InvoiceAddressType.Company:
                    this.formGroup.get('company').enable();
                    this.formGroup.get('person').disable();
                    break;
            }
        });
    }

    save(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;
        switch (this.formGroup.get('type').value) {
            case InvoiceAddressType.Person:
                const person = this.formGroup.get('person').value;
                this.dialogRef.close({person});
                break;
            case InvoiceAddressType.Company:
                const company = this.formGroup.get('company').value;
                this.dialogRef.close({company});
                break;
        }
    }

    private _createForm(): UntypedFormGroup {
        return this._formBuilder.group({
            type: [InvoiceAddressType.Person],
            person: [
                '',
                [
                    U2bValidators.required('Bitte Person auswählen'),
                    U2bAddressValidators.addressExists()
                ]
            ],
            company: [
                {value: '', disabled: true},
                [
                    U2bValidators.required('Bitte Organisation auswählen'),
                    U2bCompanyValidators.companyExists()
                ]
            ]
        });
    }
}
