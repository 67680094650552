import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { BaseInputComponent } from '@sharedComponents/form/base-input.component';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { DefaultInputErrorStateMatcher } from '@sharedComponents/form/base-input.error-state-matcher';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { MatLegacyAutocomplete, MatLegacyAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { isString } from '@shared/functions/is-string';

export interface ChipListInputItem {
    [key: string]: any;
    disabled?: boolean;
    disabledReason?: string;
}

@Component({
    selector: 'u2b-chip-list-input',
    templateUrl: './chip-list-input.component.html',
    styleUrls: ['./chip-list-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipListInputComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: ChipListInputComponent
        }
    ],
})
export class ChipListInputComponent extends BaseInputComponent<ChipListInputItem[]> implements OnInit, AfterViewInit {

    itemCtrl = new FormControl();

    errorStateMatcher: DefaultInputErrorStateMatcher;

    controlType = 'chip-list';

    selectedItems: ChipListInputItem[] = [];

    _allItems: ChipListInputItem[];

    get allItems() {
        if (this.disableConditionCallback) {
            this.disableConditionCallback(this.selectedItems, this._allItems);
        }
        return this._allItems;
    }

    filteredItems: Observable<ChipListInputItem[]>;

    currentFilteredItems: ChipListInputItem[] = [];

    @ViewChild('chipListAutocomplete', { static: false }) matAutocomplete?: MatLegacyAutocomplete;

    @ViewChild('trigger', {static: true}) autocompleteTrigger!: MatLegacyAutocompleteTrigger;

    @ViewChild('input') chipInput!: ElementRef<HTMLInputElement>;

    @Input() placeholder = 'Hier tippen...';

    @Input() hasRemove: boolean;

    @Input() autoFocus: boolean;

    @Input() dataObservable: Observable<ChipListInputItem[]>;

    @Input() items: ChipListInputItem[];

    @Input() set selectedFilterOption(value: 'Volltext' | 'Beginnt mit') {
        this._selectedFilterOption.next(value || 'Volltext');
    }

    _selectedFilterOption = new BehaviorSubject<'Volltext' | 'Beginnt mit'>('Volltext');

    @Input() dataIdentAttribute = 'name';

    @Input() dataLabelAttribute = 'name';

    @Input() disableConditionCallback?: (list: ChipListInputItem[], currentItem: ChipListInputItem) => void;

    ngOnInit(): void {
        super.ngOnInit();

        if (this.ngControl) {
            this.errorStateMatcher = new DefaultInputErrorStateMatcher(this.ngControl);
        }

        if (this.dataObservable) {
            this.dataObservable.subscribe(data => {
                this.initItems(data);
            });
        } else {
            this.initItems(this.items);
        }
    }

    ngAfterViewInit(): void {
        if (this.autoFocus && this.filteredItems) {
            this.chipInput?.nativeElement?.focus();
            setTimeout(() => {
                if (this.matAutocomplete && this.autocompleteTrigger) {
                    this.autocompleteTrigger?.openPanel();
                }
            }, 0);
        }
    }

    initItems(givenItems: ChipListInputItem[]) {
        this._allItems = givenItems;

        this.filteredItems = combineLatest([
            this._selectedFilterOption,
            this.itemCtrl.valueChanges.pipe(startWith(null))
        ]).pipe(
            map(
                ([selectedFilterOption, item]: ['Volltext' | 'Beginnt mit', string]) =>
                    (isString(item) ? this._filter(item, selectedFilterOption) : this.allItems.slice())
            ),
            tap(items => this.currentFilteredItems = items)
        );
    }

    displayWith(item: ChipListInputItem): string {
        return item?.[this.dataLabelAttribute] || item?.[this.dataIdentAttribute] || '';
    }

    selected(event: any): void {
        const selectedItem = event.option.value;

        if (!selectedItem) {
            return;
        }

        if (!this.selectedItems.includes(selectedItem)) {
            this.selectedItems.push(selectedItem);
        }

        if (!isString(this.itemCtrl.value)) {
            this.clearInput();
        }

        this.value = [...this.selectedItems];
    }

    clearInput(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.itemCtrl.setValue(null);
        this.itemCtrl.reset();
    }

    addChip($event: MatChipInputEvent, input: HTMLInputElement): void {
        if (this.dataObservable || !$event.value) {
            return;
        }
        input.value = null;
        this.selectedItems.push({[this.dataIdentAttribute]: $event.value});
        this.value = [...this.selectedItems];
        this.clearInput();
    }

    addAllFromFilteredList() {
        for (const item of this.currentFilteredItems) {
            if (!item) {
                return;
            }

            if (!this.selectedItems.includes(item)) {
                this.selectedItems.push(item);
            }
        }

        this.clearInput();

        this.value = [...this.selectedItems];
    }

    removeChip(chip: ChipListInputItem, trigger: MatLegacyAutocompleteTrigger): void {
        this.selectedItems.splice(this.selectedItems.findIndex(currChip => currChip[this.dataIdentAttribute] === chip[this.dataIdentAttribute]), 1);
        this.value = [...this.selectedItems];
        this.itemCtrl.updateValueAndValidity();
        this.clearInput();
        trigger.updatePosition();
    }

    writeValue(obj: any): void {
        super.writeValue(obj);
        this.selectedItems = super.value || [];
    }

    private _filter(value: string, selectedFilterOption: 'Volltext' | 'Beginnt mit'): any[] {
        const filterValue = value.toLowerCase();
        switch (selectedFilterOption) {
            case 'Volltext':
                return this.allItems.filter(item => item[this.dataIdentAttribute].toLowerCase().includes(filterValue));
            case 'Beginnt mit':
                return this.allItems.filter(item => item[this.dataIdentAttribute].toLowerCase().startsWith(filterValue));
        }

    }
}
