import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { Person } from '@shared/models/person';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GetPersonDialogComponent } from '@sharedComponents/dialogs/get-person-dialog/get-person-dialog.component';

@UntilDestroy()
@Component({
    selector: 'booking-traveler',
    templateUrl: './traveler.component.html',
    styleUrls: ['./traveler.component.scss'],
})
export class TravelerComponent implements OnInit {

    constructor(
        private _matDialog: MatDialog,
        public bookingDialogService: BookingDialogService
    ) {
    }

    @Input()
    travelerFormGroup: UntypedFormGroup;

    @Output()
    removeTraveler: EventEmitter<void> = new EventEmitter<void>();

    expanded: boolean;

    ngOnInit(): void {
        this.expanded = !this.travelerFormGroup.get('id')?.value && !this.travelerFormGroup.get('person')?.value;
    }

    showSelectPersonDialog(): void {
        this._matDialog.open(GetPersonDialogComponent, {
            disableClose: true,
        }).afterClosed().subscribe((person: Person) => {
            this.updateFormData(person);
        });
    }

    updateFormData(person: Person): void {
        if (person) {
            this.travelerFormGroup.patchValue({
                person: person,
                firstName: person.firstName,
                lastName: person.lastName,
                email: person.mail,
                phone: person.phone,
                birthDate: person.birthDate,
            });
        }
    }

}
