import { Boat } from '@shared/models/boat';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { ParticipantType } from '@shared/models/bcm-booking';

export interface PredefinedBoatValues {
    width: number | null;
    length: number | null;
    depth: number | null;
}

export class BookingDialogBoat extends BookingDialogBaseEntity<Boat, Boat> {

    constructor(bookingDialogService: BookingDialogService) {
        super(bookingDialogService, BookingAttribute.BOAT);
    }

    get predefinedValues(): PredefinedBoatValues {
        return this._predefinedValues;
    }

    private _predefinedValues: PredefinedBoatValues = {
        width: null,
        length: null,
        depth: null
    };

    setPredefinedValues(width: number | null, length: number | null, depth: number | null): void {
        this._predefinedValues = {width, length, depth};
    }

    override afterValueSet(): void {

        if (!this.value && this.bookingDialogService.general.value.isBoatOwner) {
            this.bookingDialogService.general.value.isBoatOwner = false;
            this.bookingDialogService.general.sendUpdate();
        }

        if (this.bookingDialogService.person.value || this.bookingDialogService.company.value) {
            return;
        }

        if (this.value?.owner && !this.bookingDialogService.person.value?.id) {
            this.bookingDialogService.general.value.personOrCompany = ParticipantType.Person;
            this.bookingDialogService.general.sendUpdate();

            this.bookingDialogService.person.value = this.value.owner;
            this.bookingDialogService.person.sendUpdate();

        } else if (this.value?.ownerCompany && !this.bookingDialogService.company.value?.id) {
            this.bookingDialogService.general.value.personOrCompany = ParticipantType.Company;
            this.bookingDialogService.general.sendUpdate();

            this.bookingDialogService.company.value = this.value.ownerCompany;
            this.bookingDialogService.company.sendUpdate();
        }
        // if (this.bookingDialogService.person.value === null && this.bookingDialogService.company.value === null) {
        //     if (this.value?.owner) {
        //         this.bookingDialogService.general.value.personOrCompany = 'person';
        //         this.bookingDialogService.person.value = this.value.owner;
        //         this.bookingDialogService.general.sendUpdate();
        //         this.bookingDialogService.person.sendUpdate();
        //     } else if (this.value?.ownerCompany) {
        //         this.bookingDialogService.general.value.personOrCompany = 'company';
        //         this.bookingDialogService.company.value = this.value.ownerCompany;
        //         this.bookingDialogService.general.sendUpdate();
        //         this.bookingDialogService.company.sendUpdate();
        //     }
        // }
    }

}
