<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title"> {{ (data?.boat?.id ? 'editBoat' : 'createBoat') | translate }}</span>
            <button data-test="B-Dialog schließen" [disabled]="isSaving" mat-icon-button
                    (click)="close()"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="m-0" fusePerfectScrollbar fxFlex="100">

        <div [formGroup]="data?.boatFormGroup"
             fxLayout="row wrap"
             fxLayoutGap="12px grid">

            <mat-form-field [appearance]="data?.appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
                <mat-label>Kennzeichen</mat-label>
                <input matInput data-test="FI-Bootsname" type="text" formControlName="licensePlate"
                       autocomplete="new-password" [required]="!data?.boatFormGroup.get('name').value">
                <mat-error>{{ data?.boatFormGroup.get('licensePlate').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <mat-form-field [appearance]="data?.appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
                <mat-label>{{ 'boatName' | translate }}</mat-label>
                <input matInput data-test="FI-Bootsname" type="text" formControlName="name"
                       autocomplete="new-password" [required]="!data?.boatFormGroup.get('licensePlate').value">
                <mat-error>{{ data?.boatFormGroup.get('name').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>

            <ng-container *ngIf="!data?.optionalFields || showOptionalFields">
                <mat-form-field [appearance]="data?.appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
                    <mat-label>Länge&nbsp;(m)</mat-label>
                    <app-input-number
                            [required]="!data?.optionalFields"
                            data-test="FI-Länge (m)"
                            formControlName="length"
                            [step]="0.1"></app-input-number>
                    <mat-error>{{ data?.boatFormGroup.get('length').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <mat-form-field [appearance]="data?.appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
                    <mat-label>Breite&nbsp;(m)</mat-label>
                    <app-input-number
                            [required]="!data?.optionalFields"
                            data-test="FI-Breite (m)"
                            formControlName="width"
                            [step]="0.1"></app-input-number>
                    <mat-error>{{ data?.boatFormGroup.get('width').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

            </ng-container>

            <ng-container *ngIf="showOptionalFields">

                <mat-form-field [appearance]="data?.appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
                    <mat-label>Länge&nbsp;über&nbsp;Alles&nbsp;(LüA)&nbsp;(m)</mat-label>
                    <app-input-number
                            [required]="!data?.optionalFields"
                            data-test="FI-Länge über Alles (LüA) (m)"
                            formControlName="lengthOverall"
                            [step]="0.1"></app-input-number>
                    <mat-error>{{ data?.boatFormGroup.get('lengthOverall').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <mat-form-field [appearance]="data?.appearance" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
                    <mat-label>Tiefgang&nbsp;(m)</mat-label>
                    <app-input-number
                            data-test="FI-Tiefgang (m)"
                            formControlName="depth"
                            [step]="0.1"></app-input-number>
                    <mat-error>{{ data?.boatFormGroup.get('depth').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>

                <mat-form-field [appearance]="data?.appearance" fxFlex="100" floatLabel="always">
                    <mat-label>Bemerkungen / Notizen</mat-label>
                    <textarea matInput data-test="FT-Bemerkungen / Notizen" formControlName="note" rows="3"
                              autocomplete="new-password"></textarea>
                    <mat-error>{{ data?.boatFormGroup.get('note').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="!showOptionalFields">
                <button fxFlex="100" class="p-0" mat-stroked-button color="accent"
                        [disabled]="isSaving"
                        (click)="showOptionalFields = true">
                    <mat-icon>visibility</mat-icon>
                    <span>Optionale Felder anzeigen</span>
                </button>
            </ng-container>

        </div>

    </div>

    <div mat-dialog-actions class="m-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button mat-button
                [disabled]="isSaving"
                (click)="close()">
            <mat-icon>cancel</mat-icon>
            <span>Abbrechen</span>
        </button>

        <button mat-flat-button color="accent"
                [disabled]="isSaving"
                (click)="save()">
            <ng-container *ngIf="isSaving; else notSaving">
                <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
            </ng-container>
            <ng-template #notSaving>
                <mat-icon>save</mat-icon>
                <span>Speichern</span>
            </ng-template>
        </button>


    </div>

</div>
