import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { BookingDialogBaseEntity } from '@sharedComponents/dialogs/booking-dialog/services/classes/abstract/booking-dialog-base-entity';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { ElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { ElectricMeterPaymentType } from '@modules/bcm/@shared/enums/electric-meter-payment-type';
import { TranslationService } from '@core/translation/translation.service';
import { DisconnectElectricMeterEvent } from '@sharedComponents/dialogs/booking-dialog/tabs/electric-meter-tab/electric-meter/assignment-electric-meters.component';
import { toDateStringDE } from '@core/functions/to-date-string';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { Product } from '@shared/models/product';
import { UnitUniqueName } from '@shared/models/unit';
import { concatMap, from, Observable, of, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { BcmBookingsApiService } from '@bcmApiServices/bcm-bookings.api-service';
import { BcmSettingsSectionName, DefaultBerthReservationTimeUnit } from '@shared/models/bcm-settings';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { calculateProportionalTime } from '@shared/functions/calculate-proportional-time';
import { DurationUnit, getDurationFromDates } from '@shared/functions/get-duration-from-dates';

export class BookingDialogPositions extends BookingDialogBaseEntity<InvoicePosition, InvoicePosition[]> {

    constructor(
        bookingDialogService: BookingDialogService,
        private _bookingsApiService: BcmBookingsApiService,
        private _productService: ProductService,
        private _bcmSettingsFacade: BcmSettingsFacade,
    ) {
        super(bookingDialogService, BookingAttribute.POSITIONS);

        this._defaultBerthReservationTimeUnit = this._bcmSettingsFacade.settings()[BcmSettingsSectionName.DefaultBerthReservationTimeUnit];
    }

    private readonly _defaultBerthReservationTimeUnit: DefaultBerthReservationTimeUnit;

    protected isDuplicate(value: InvoicePosition): boolean {
        return this.bookingDialogService.positions.value.findIndex(sub => {
            return sub.product.id === value.product.id &&
                sub.quantity === value.quantity &&
                sub.fromTenantRelation &&
                !sub.id;
        }) !== -1;
    }

    reload(): void {
        this._bookingsApiService.getPositions(this.bookingDialogService.booking.id)
            .subscribe(positions => {

                positions.forEach(p => {
                    if (p.bcm_berth_has_assignments_id) {
                        p.berthAssignmentUuid = this.bookingDialogService.berthAssignments.value.find(a => a.id === p.bcm_berth_has_assignments_id)?.uuid;
                    }
                    if (p.bcm_electric_meter_has_readings_id) {
                        p.electricMeterAssignmentUuid = this.bookingDialogService.electricMeterAssignments.value.find(a => a.id === p.bcm_electric_meter_has_boats_id)?.uuid;
                    }
                    return p;
                });

                this.bookingDialogService.booking.positions = positions;
                this.value = positions;
            });
    }

    addFromTenantRelation(assignment: TenantRelationAssignment): void {

        if (this.bookingDialogService.readonlyView) {
            return;
        }

        if (!assignment?.tenantRelation?.products) {
            return;
        }

        const positions = assignment.tenantRelation.products.map(product => {

            const proportionalTime = calculateProportionalTime(assignment.fromDate, assignment.toDate, assignment.tenantRelation.payableOption);

            const positionDto = {
                product,
                quantity: product.quantity * proportionalTime,
                account: product.account,
                unit: product.unit,
                price: product.price,
                taxRate: product.taxRate,
                fromTenantRelation: true,
                tenantRelationAssignment: assignment,
                tenantRelation: assignment.tenantRelation,
                title: product.name,
            } as InvoicePositionDto;

            return new InvoicePosition(positionDto);
        });

        from(positions).pipe(
            concatMap(position => this.getPositionWithDynamicPrice(position))
        ).subscribe({
            next: dynamicPricePosition => {
                this.add(dynamicPricePosition);
            }
        });

    }

    removeUnsavedFromTenantRelation(): void {
        if (this.bookingDialogService.readonlyView) {
            return;
        }

        this.value = this.value.filter(position => position.id || !position.fromTenantRelation);
    }

    removeFromTenantRelation(): void {
        if (this.bookingDialogService.readonlyView) {
            return;
        }

        this.value
            .filter(position => position.tenantRelationAssignment?.id)
            .forEach(position => {
                position.deleted = true;
            });
    }

    addFromBerthAssignment(assignment: BerthBoatAssignment): void {

        if (this.bookingDialogService.readonlyView) {
            return;
        }

        const positions = assignment.berth.products.map(product => {
            const positionDto = {
                product,
                quantity: product.quantity,
                account: product.account,
                unit: product.unit,
                price: product.price,
                taxRate: product.taxRate,
                fromBerth: true,
                bcm_berth_has_assignments_id: assignment.id,
                berthAssignmentUuid: assignment.uuid,
                title: product.name,
            } as InvoicePositionDto;

            return new InvoicePosition(positionDto);
        });

        from(positions).pipe(
            concatMap(position => this.getPositionWithDynamicPrice(position))
        ).subscribe({
            next: dynamicPricePosition => {
                this.add(dynamicPricePosition);
            }
        });

    }

    removeFromBerthAssignment(assignment: BerthBoatAssignment): void {
        if (this.bookingDialogService.readonlyView) {
            return;
        }

        this.value = this.value.filter(position => position.berthAssignmentUuid !== assignment.uuid);
    }

    addFromElectricMeterAssignment(assignment: ElectricMeterAssignment): void {

        if (this.bookingDialogService.readonlyView) {
            return;
        }

        if (assignment.paymentType === ElectricMeterPaymentType.Flatrate && assignment.product) {

            const title = TranslationService.translate('electricityForBoatAccordingToFlatrate',
                    {'boatName': (this.bookingDialogService.boat.value?.licensePlate || this.bookingDialogService.boat.value?.name)}) +
                (assignment.electricMeter.cabinet?.handle ? ` Schrank: ${assignment.electricMeter.cabinet.handle} ` : '') +
                (assignment.electricMeter.handle ? `Zähler: ${assignment.electricMeter.handle} ` : '') +
                (assignment.electricMeter.manufacturerHandle ? `Nr.: ${assignment.electricMeter.manufacturerHandle} ` : '');


            const position = {
                product: assignment.product,
                quantity: assignment.quantity ?? 1,
                account: assignment.product.account,
                unit: assignment.product.unit,
                price: assignment.product.price,
                taxRate: assignment.product.taxRate,
                berthAssignmentUuid: assignment.berthAssignment?.uuid,
                electricMeterAssignmentUuid: assignment.uuid,
                title
            } as InvoicePositionDto;

            this.bookingDialogService.positions.add(new InvoicePosition(position));

        } else if (assignment.paymentType === ElectricMeterPaymentType.BasicCharge && assignment.product) {

            const boat = this.bookingDialogService.boat.value;
            const title = TranslationService.translate('electricityForBoatAccordingToBasicChargeAdditionalFee', {'boatName': (boat.licensePlate || boat.name)}) +
                (assignment.electricMeter.cabinet?.handle ? ` Schrank: ${assignment.electricMeter.cabinet.handle} ` : '') +
                (assignment.electricMeter.handle ? `Zähler: ${assignment.electricMeter.handle} ` : '') +
                (assignment.electricMeter.manufacturerHandle ? `Nr.: ${assignment.electricMeter.manufacturerHandle} ` : '');

            const positionDto = {
                product: assignment.product,
                quantity: assignment.quantity ?? 1,
                account: assignment.product.account,
                unit: assignment.product.unit,
                price: assignment.product.price,
                taxRate: assignment.product.taxRate,
                berthAssignmentUuid: assignment.berthAssignment?.uuid,
                electricMeterAssignmentUuid: assignment.uuid,
                title
            } as InvoicePositionDto;

            this.add(new InvoicePosition(positionDto));
        }

    }

    addFromElectricMeterAssignmentDisconnect(event: DisconnectElectricMeterEvent): void {

        if (this.bookingDialogService.readonlyView) {
            return;
        }

        if (event.product) {

            const boat = this.bookingDialogService.boat.value;

            const title = TranslationService.translate('electricityForBoatAccordingToReadingMeter', {'boatName': (boat.licensePlate || boat.name)}) +
                (event.electricMeterAssignment.electricMeter.cabinet?.handle ? ` Schrank: ${event.electricMeterAssignment.electricMeter.cabinet.handle} ` : '') +
                (event.electricMeterAssignment.electricMeter.handle ? `Zähler: ${event.electricMeterAssignment.electricMeter.handle} ` : '') +
                (event.electricMeterAssignment.electricMeter.manufacturerHandle ? `Nr.: ${event.electricMeterAssignment.electricMeter.manufacturerHandle} ` : '') +
                `Startablesung: ${toDateStringDE(event.from)} ` +
                `Zählerstand: ${event.meterReadingStart} ` +
                `Schlussablesung: ${toDateStringDE(event.to)} ` +
                `Zählerstand: ${event.meterReadingEnd}`;

            const positionDto = {
                product: event.product,
                quantity: event.quantity ?? 1,
                account: event.product.account,
                unit: event.product.unit,
                price: event.product.price,
                taxRate: event.product.taxRate,
                berthAssignmentUuid: event.electricMeterAssignment.berthAssignment?.uuid,
                electricMeterAssignmentUuid: event.electricMeterAssignment.uuid,
                electricMeterReadingUuid: event.electricMeterAssignment.reading?.uuid,
                title
            } as InvoicePositionDto;

            this.add(new InvoicePosition(positionDto));
        }

    }

    removeFromElectricMeterAssignment(assignment: ElectricMeterAssignment): void {

        if (this.bookingDialogService.readonlyView) {
            return;
        }

        this.value = this.value.filter(position => position.electricMeterAssignmentUuid !== assignment.uuid);
    }

    updateDatesFromBerthAssignments(): void {

        if (this.bookingDialogService.readonlyView) {
            return;
        }

        this.value.forEach(position => {
            if (position.berthAssignmentUuid) {
                const berthAssignment = this.bookingDialogService.berthAssignments.value.find(assignment => assignment.uuid === position.berthAssignmentUuid);
                if (berthAssignment) {
                    position.vestingPeriodFromDate = berthAssignment.from;
                    position.vestingPeriodFrom = berthAssignment.from.toISOString();
                    position.vestingPeriodUntilDate = berthAssignment.to;
                    position.vestingPeriodUntil = berthAssignment.to?.toISOString();
                }
            } else if (position.fromTenantRelation || position.tenantRelationAssignment) {
                const tenantRelationAssignment = this.bookingDialogService.tenantRelationAssignment.value;
                if (tenantRelationAssignment) {
                    position.vestingPeriodFromDate = tenantRelationAssignment.vestingPeriodFrom;
                    position.vestingPeriodFrom = tenantRelationAssignment.vestingPeriodFrom.toISOString();
                    position.vestingPeriodUntilDate = tenantRelationAssignment.vestingPeriodUntil;
                    position.vestingPeriodUntil = tenantRelationAssignment.vestingPeriodUntil?.toISOString();
                }
            } else {
                position.vestingPeriodFromDate = this.bookingDialogService.booking.from;
                position.vestingPeriodFrom = this.bookingDialogService.booking.from.toISOString();
                position.vestingPeriodUntilDate = this.bookingDialogService.booking.to;
                position.vestingPeriodUntil = this.bookingDialogService.booking.to?.toISOString();
            }

            this.getPositionWithDynamicPrice(position)
                .pipe(take(1))
                .subscribe(p => position = p);
        });
    }

    getPositionWithDynamicPrice(position: InvoicePosition): Observable<InvoicePosition> {

        if (position.product.hasDynamicPrice) {

            const boat = this.bookingDialogService.boat.value;
            const berth = this.bookingDialogService.berthAssignments.value.find(assignment => assignment.uuid === position.berthAssignmentUuid)?.berth;
            const tenantRelation = this.bookingDialogService.tenantRelationAssignment.value?.tenantRelation;

            let fromDate: Date | null = null;
            let toDate: Date | null = null;

            if (position.berthAssignmentUuid) {
                const berthAssignment = this.bookingDialogService.berthAssignments.value.find(assignment => assignment.uuid === position.berthAssignmentUuid);
                if (berthAssignment) {
                    fromDate = berthAssignment.from;
                    toDate = berthAssignment.to;
                }
            } else if (position.fromTenantRelation) {
                const tenantRelationAssignment = this.bookingDialogService.tenantRelationAssignment.value;
                if (tenantRelationAssignment) {
                    fromDate = tenantRelationAssignment.fromDate;
                    toDate = tenantRelationAssignment.toDate;
                }
            }

            const days = getDurationFromDates(fromDate, toDate, DurationUnit.Days, this._defaultBerthReservationTimeUnit);
            const overnights = getDurationFromDates(fromDate, toDate, DurationUnit.Overnights, this._defaultBerthReservationTimeUnit);
            const travelers = this.bookingDialogService.travelers.value.length;

            return this._productService.evaluatePriceRule(
                position.product as Product,
                {
                    boat,
                    berth,
                    tenantRelation,
                    days,
                    overnights,
                    travelers
                },
                position.quantity,
                null,
            )
                .pipe(
                    take(1),
                    map(dynamicPrice => {
                        dynamicPrice = {
                            ...dynamicPrice,
                            boat,
                            berth,
                            tenantRelation
                        };

                        position.dynamicPrice = dynamicPrice;
                        position.price = dynamicPrice.rulePrice;
                        position.title = (position.unit.uniqueName === UnitUniqueName.KWH && !!position.berthAssignmentUuid) ? position.title : dynamicPrice.ruleName;

                        return position;
                    })
                );

        } else {
            return of(position);
        }
    }

}
