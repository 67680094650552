<ng-container *ngIf="!loadingPersons && (filteredPersons$ | async)?.length === 0">
    <div fxFlex="100">
        <u2b-message customClass="mb-0">
            Es wurden keine Personen gefunden, die den angegebenen Anforderungen entsprechen.
        </u2b-message>
    </div>
</ng-container>

<mat-form-field fxFlex="100" style="width: 100%" [appearance]="appearance" floatLabel="always">
    <mat-label>
        <ng-container *ngIf="multiSelect">Personen</ng-container>
        <ng-container *ngIf="!multiSelect">Person</ng-container>
    </mat-label>

    <ng-container *ngIf="multiSelect; else singleSelect">
        <mat-select data-test="" [formControl]="control" multiple
                    [compareWith]="compareObjectsById">
            <mat-option *ngFor="let person of (filteredPersons$ | async) | orderBy : 'fullNameBackward'"
                        [value]="person">
                <bcm-person-link [disableLink]="true" [person]="person"></bcm-person-link>
            </mat-option>
        </mat-select>
    </ng-container>

    <ng-template #singleSelect>
        <input type="text" matInput [formControl]="control" [matAutocomplete]="personSelector">

        <mat-autocomplete #personSelector="matAutocomplete" [displayWith]="displayWith">
            <ng-container
                    *ngFor="let person of (filteredPersons$ | async) | orderBy : 'fullNameBackward'">
                <mat-option [value]="person">
                    <bcm-person-link [disableLink]="true" [person]="person"></bcm-person-link>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </ng-template>

    <button type="button" matSuffix mat-icon-button
            matTooltip="Auswahl entfernen"
            [matTooltipDisabled]="!control.value || control.disabled"
            [disabled]="!control.value || control.disabled"
            (click)="control.setValue(null)">
        <mat-icon>clear</mat-icon>
    </button>

    <mat-error>{{control.errors | firstErrorMessage}}</mat-error>
</mat-form-field>
