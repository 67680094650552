import { Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    AbstractControl,
    FormGroupDirective,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors
} from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { BoatApiService } from '@modules/bcm/@shared/services';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { Observable, of, Subject, withLatestFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Boat } from '@shared/models/boat';
import { disableControlsByName } from '@shared/functions/form/disable-controls';
import { enableControlsByName } from '@shared/functions/form/enable-controls';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { Berth } from '@shared/models/berth';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { TranslationService } from '@core/translation/translation.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BoatEditorDialogComponent } from '@bcm-work-flows/@form-widgets/boat/boat-editor-dialog/boat-editor-dialog.component';
import { isFunction } from '@shared/functions/is-function';
import { Company } from '@shared/models/company';
import { Person } from '@shared/models/person';
import { BcmBookingGeneralData } from '@shared/models/bcm-booking';
import { PredefinedBoatValues } from '@sharedComponents/dialogs/booking-dialog/services/classes/booking-dialog-boat';

@Component({
    selector: 'form-widget-boat',
    templateUrl: './form-widget-boat.component.html'
})
export class FormWidgetBoatComponent implements OnInit, OnDestroy, OnChanges {

    private _unsubscribeAll = new Subject<any>();

    @Input()
    readonly = false;

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    headline = TranslationService.translate('boat') + ' wählen <small>oder</small> neu erfassen';

    @Input()
    givenBerth: Berth;

    @Input()
    givenBoats: Boat[];

    @Input()
    showMetaData: boolean;

    @Input()
    boat$: Observable<Boat>;

    @Input()
    set selectBoat(value: Boat) {
        if (value?.id || value?.id !== this._selectBoat?.id) {
            this._selectBoat = value;
        }
    }

    get selectBoat(): Boat {
        return this._selectBoat;
    }

    private _selectBoat: Boat;

    @Input()
    boatRequired: boolean;

    @Input()
    optionalFields: boolean;

    @Input()
    canCreate = true;

    @Input()
    slimmedView = false;

    @Input()
    boatOwnerName: string;

    @Input()
    canSave: boolean;

    @Input()
    boatId: number;

    @Input()
    predefinedValues: PredefinedBoatValues;

    @Input()
    editable = false;

    @Input()
    person$: Observable<Person>;

    @Input()
    company$: Observable<Company>;

    @Input()
    general$: Observable<BcmBookingGeneralData>;

    @Output()
    boatChanged = new Subject<Boat>();

    personOrCompany: 'person' | 'company';

    person: Person;

    company: Company;

    boats: Boat[] = [];

    displayBoatOwnerName: string;

    isSaving: boolean;

    optionalString: string;

    boatFormGroup: UntypedFormGroup;

    boat: Boat;

    loadingBoats: boolean;

    topList: Boat[] = [];

    bottomList: Boat[] = [];

    filteredTopList$: Observable<Boat[]>;

    filteredBottomList$: Observable<Boat[]>;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _boatApiService: BoatApiService,
                private formGroupDirective: FormGroupDirective,
                private _dialog: MatDialog) {
    }

    ngOnInit(): void {

        this.boat = this.selectBoat;

        if (!this.canCreate) {
            this.headline = TranslationService.translate('selectBoat');
        }

        this._createForm();
        this._loadBoats(this.givenBoats);

        if (this.formGroupDirective?.form) {
            this.formGroupDirective.form.addControl('boatForm', this.boatFormGroup);
        }

        if (isFunction(this.boat$?.subscribe)) {
            this.boat$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(boat => {
                    this.boatFormGroup.patchValue({boat, ...boat}, {emitEvent: false});

                    this.boatFormGroup.markAsDirty();
                });
        }

        if (isFunction(this.general$?.subscribe)) {
            this.general$
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    distinctUntilChanged((previous, current) => previous?.personOrCompany === current?.personOrCompany),
                    debounceTime(DEFAULT_DEBOUNCE_TIME)
                )
                .subscribe(general => {
                    this.personOrCompany = general?.personOrCompany;
                });
        }

        if (isFunction(this.person$?.subscribe)) {
            this.person$
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    distinctUntilChanged((previous, current) => previous?.id === current?.id),
                    debounceTime(DEFAULT_DEBOUNCE_TIME)
                )
                .subscribe(person => {

                    if (person) {
                        this.company = null;
                    }

                    this.person = person;
                    this.onPersonOrCompanyChange();
                });
        }

        if (isFunction(this.company$?.subscribe)) {
            this.company$
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    distinctUntilChanged((previous, current) => previous?.id === current?.id),
                    debounceTime(DEFAULT_DEBOUNCE_TIME)
                )
                .subscribe(company => {
                    if (company) {
                        this.person = null;
                    }
                    this.company = company;

                    this.onPersonOrCompanyChange();
                });
        }

    }

    onPersonOrCompanyChange(): void {

        const boatOwnerChanged = this.boat?.owner?.id !== this.person?.id || this.boat?.ownerCompany?.id !== this.company?.id;

        if (!boatOwnerChanged || (!this.person && !this.company)) {
            return;
        }

        if (this.personOrCompany === 'person' && this.person) {
            this.displayBoatOwnerName = this.person.fullName;
            this.boatOwnerName = this.person.fullName;
            this.givenBoats = this.person.boats;
        } else if (this.personOrCompany === 'company' && this.company) {
            this.displayBoatOwnerName = this.company.name;
            this.boatOwnerName = this.company.name;
            this.givenBoats = this.company.boats;
        } else {
            this.displayBoatOwnerName = null;
            this.boatOwnerName = null;
            this.givenBoats = [];
        }

        this._loadBoats(this.givenBoats);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!this.boatFormGroup) {
            return;
        }

        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'givenBoats': {
                        this._loadBoats(this.givenBoats);
                    }
                }
            }
        }

        if (this.selectBoat) {
            const boat = (this.boats || [this.selectBoat]).find(b => b.id === this.selectBoat.id);
            this.boatFormGroup.patchValue({boat});
        }


    }

    ngOnDestroy(): void {
        if (this.formGroupDirective?.form) {
            this.formGroupDirective.form.removeControl('boatForm');
        }
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    onClickRemoveBoat(): void {
        this.boat = null;
        this.boatFormGroup.get('boat').setValue(null);
        this.boatFormGroup.markAsDirty();
    }

    openEditDialog(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this._dialog.open(BoatEditorDialogComponent, {
            data: {
                parentFormGroup: this.formGroupDirective.form,
                boatFormGroup: this.boatFormGroup,
                boat: this.boat,
                optionalFields: this.optionalFields,
                appearance: this.appearance,
                predefinedValues: this.predefinedValues,
            },
            disableClose: true,
        }).afterClosed().subscribe((boat: Boat) => {
            if (boat) {
                if (this.boat.id === boat.id) {
                    this.boatFormGroup.markAsPristine();
                    this.boatFormGroup.markAsUntouched();
                }
                this.boat = boat;
            }
        });
    }

    public displayBoatWith(boat: Boat): string {
        return boat ? [boat.licensePlate, boat.name, boat.manufacturer || boat.type].filter(_ => !!_).join(', ') : '';
    }

    private _createForm(): void {

        this.boatFormGroup = this._formBuilder.group(
            {
                boat: [this.selectBoat, this.boatRequired ? [U2bValidators.required(TranslationService.translate('pleaseSelectBoat'))] : []],
                name: [{value: null, disabled: !!this.boat}],
                length: [
                    {value: null, disabled: !!this.boat},
                    this.optionalFields ? [] : [
                        U2bValidators.required('Bitte Länge angeben'),
                        U2bNumericValidators.numberFormat(),
                        U2bNumericValidators.numberMin(0.5),
                        U2bNumericValidators.numberMax(500)
                    ].filter(_ => _ != null)
                ],
                lengthOverall: [
                    {value: null, disabled: !!this.boat},
                    this.optionalFields ? [] : [
                        U2bValidators.required('Bitte Länge angeben'),
                        U2bNumericValidators.numberFormat(),
                        U2bNumericValidators.numberMin(0.5),
                        U2bNumericValidators.numberMax(500)
                    ].filter(_ => _ != null)
                ],
                width: [
                    {value: null, disabled: !!this.boat},
                    this.optionalFields ? [] : [
                        U2bValidators.required('Bitte Breite angeben'),
                        U2bNumericValidators.numberFormat(),
                        U2bNumericValidators.numberMin(0.5),
                        U2bNumericValidators.numberMax(100)
                    ].filter(_ => _ != null)
                ],
                depth: [
                    {value: null, disabled: !!this.boat},
                    [
                        U2bNumericValidators.numberFormat(),
                        U2bNumericValidators.numberMin(0),
                        U2bNumericValidators.numberMax(20)
                    ]
                ],
                licensePlate: [{value: null, disabled: !!this.boat}],
                note: [
                    {value: null, disabled: !!this.boat},
                    [U2bValidators.maxLength(1024)]
                ],
            },
            {
                validators: [
                    form => {
                        if (this.optionalFields || !this.canCreate) {
                            return null;
                        }

                        if (!form || this.boat?.id) {
                            return null;
                        }

                        const nameControl: AbstractControl = form.get('name');
                        const licensePlateControl: AbstractControl = form.get('licensePlate');

                        if (nameControl.disabled || licensePlateControl.disabled) {
                            return null;
                        }

                        if (!nameControl.value && !licensePlateControl.value) {
                            const error: ValidationErrors = {
                                nameLicensePlate: {
                                    message: TranslationService.translate('missingBoatNameOrLicensePlate')
                                }
                            };
                            nameControl.setErrors(error);
                            licensePlateControl.setErrors(error);
                            return error;
                        }

                        form.get('boat').setErrors(null);
                        nameControl.setErrors(null);
                        licensePlateControl.setErrors(null);
                        return null;
                    }
                ]
            }
        );

        if (!this.canCreate && !this.editable) {
            disableControlsByName(
                this.boatFormGroup,
                ['name', 'length', 'width', 'depth', 'note', 'licensePlate', 'lengthOverall']
            );
        }

        this.boatFormGroup
            .get('boat')
            .valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((boat) => {

                if (boat && boat.id) {
                    this.boatChanged.next(boat);

                    this.boat = boat;

                    disableControlsByName(
                        this.boatFormGroup,
                        ['name', 'length', 'width', 'depth', 'note', 'licensePlate', 'lengthOverall']
                    );
                } else {
                    enableControlsByName(
                        this.boatFormGroup,
                        ['name', 'length', 'width', 'depth', 'note', 'licensePlate', 'lengthOverall'],
                        true
                    );
                }
            });

        this.formGroupDirective.form.valueChanges.subscribe(value => {

            const personForm = value?.personForm;
            const person = personForm?.person;
            const companyForm = value?.companyForm;
            const company = companyForm?.company;

            if (company || companyForm?.name) {
                this.displayBoatOwnerName = company?.toString() || companyForm?.name;
            } else if (person || (personForm?.firstName && personForm?.lastName)) {
                this.displayBoatOwnerName = person?.toString() || (personForm?.firstName + ' ' + personForm?.lastName).trim();
            } else {
                this.displayBoatOwnerName = null;
            }
        });
    }

    private _loadBoats(givenBoats: Boat[] = []): void {

        this.loadingBoats = true;

        this._boatApiService
            .getAll()
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap(() => this.loadingBoats = false),
                withLatestFrom(this.boat$ || of(null))
            )
            .subscribe(([boats, boat]: [Boat[], Boat]) => {

                this.topList = [];
                this.bottomList = [];

                this.boats = boats;

                if (this.boatId || this.selectBoat || boat) {
                    this.boat = boats.find(b => b.id === this.boatId || b.id === this.selectBoat?.id || b.id === boat?.id);
                    this.boatFormGroup.patchValue({boat: this.boat});
                }

                for (const _boat of boats) {
                    if (givenBoats.find(givenBoat => givenBoat.id === _boat.id)) {
                        this.topList.push(_boat);
                    } else {
                        this.bottomList.push(_boat);
                    }
                }
            });

        this.filteredTopList$ = this.boatFormGroup.get('boat').valueChanges
            .pipe(
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterBoats(value, this.topList) : this.topList),
                takeUntil(this._unsubscribeAll),
            );

        this.filteredBottomList$ = this.boatFormGroup.get('boat').valueChanges
            .pipe(
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterBoats(value, this.bottomList) : this.bottomList),
                takeUntil(this._unsubscribeAll),
            );
    }

    private _filterBoats(filter: string, boats: Boat[]): Boat[] {
        filter = filter.toLowerCase();
        return boats.filter(boat => {
            return (boat.name || '').toLowerCase().includes(filter)
                || (boat.licensePlate || '').toLowerCase().includes(filter);
        });
    }

}
