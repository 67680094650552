<div [formGroup]="berthFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <ng-container *ngIf="headline && !slimmedView">
        <div fxFlex=100>
            <h2>{{ headline }}</h2>
        </div>
    </ng-container>

    <ng-container *ngIf="!slimmedView && !readonly">
        <div fxFlex="100" class="pb-16">
            <mat-checkbox data-test="FCB-Nur freie Liegeplätze anzeigen" formControlName="onlyFree">
                Nur freie Liegeplätze anzeigen
            </mat-checkbox>
        </div>
        <div fxFlex="100" class="pb-16">
            <mat-checkbox data-test="FCB-Nach Steg gruppieren" formControlName="groupPier">
                Nach Steg gruppieren
            </mat-checkbox>
        </div>
    </ng-container>

    <ng-container *ngIf="!loadingBerths && !topList?.length && !bottomList?.length && !pierBerthGroups?.length">
        <div fxFlex="100">
            <u2b-message>
                Es wurden keine Liegeplätze gefunden, die den angegebenen Anforderungen entsprechen. Neue
                Liegeplätze können unter <strong>Hafenverwaltung</strong> > <strong>Liegeplan</strong> erfasst
                werden.
            </u2b-message>
        </div>
    </ng-container>

    <ng-container *ngIf="berthFormGroup?.errors">
        <div fxFlex="100">
            <u2b-message type="error">
                {{ berthFormGroup.errors | firstErrorMessage }}
            </u2b-message>
        </div>
    </ng-container>

    <ng-container *ngIf="loadingBerths; else showSelect">
        <mat-spinner class="mb-16" diameter="35"></mat-spinner>
    </ng-container>

    <ng-template #showSelect>
        <ng-container *ngIf="topList?.length || bottomList?.length || pierBerthGroups?.length">
            <div fxFlex="100">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field fxFlex [appearance]="appearance" floatLabel="always">
                        <mat-label>Liegeplatz</mat-label>
                        <input type="text" matInput data-test="FI-Nach Liegeplatz suchen..."
                               [readonly]="readonly"
                               formControlName="berth"
                               [matAutocomplete]="berthSelector">
                            <button matSuffix
                                    mat-icon-button
                                    type="button"
                                    color="warn"
                                    matTooltip="Ausgewählten Liegeplatz entfernen"
                                    [disabled]="!berth?.id || berthFormGroup.disabled || readonly"
                                    (click)="onClickRemoveBerth()">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        <mat-error [hidden]="slimmedView">
                            {{ berthFormGroup.get('berth').errors | firstErrorMessage }}
                        </mat-error>
                    </mat-form-field>

                    <mat-autocomplete #berthSelector="matAutocomplete" [displayWith]="displayBerthWith">
                        <ng-container *ngIf="groupPiers; else personOrCompany">
                            <ng-container *ngFor="let pierBerthGroup of (filteredPierBerthGroups | async)">
                                <mat-optgroup [label]="pierBerthGroup[0]?.pier?.handle || 'Kein Steg'">
                                    <ng-container *ngFor="let berth of pierBerthGroup">
                                        <mat-option [value]="berth">
                                            <span>{{ berth.handle }}</span>
                                            <ng-container *ngIf="displayDimensions && berth.length && berth.width">
                                                <br><small>Länge (m): {{ berth.length | number: '1.0-2' }}</small>
                                                <br><small>Breite (m): {{ berth.width | number: '1.0-2' }}</small>
                                            </ng-container>
                                            <small class="secondary-text">
                                                <ng-container
                                                        *ngIf="berth.hasActiveAssignments() && !berthFormGroup.get('onlyFree').value">
                                                    <br>Belegt von
                                                    <ng-container
                                                            *ngFor="let assignment of berth.getActiveAssignments(); let isLast = last">
                                                        {{ assignment?.boat?.name }}
                                                        <ng-container *ngIf="!isLast">,</ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                        </mat-option>
                                    </ng-container>
                                </mat-optgroup>
                            </ng-container>
                        </ng-container>
                        <ng-template #personOrCompany>
                            <ng-container *ngIf="personOrCompanyName">
                                <ng-container *ngIf="filteredTopList$ | async as topList">
                                    <mat-optgroup [label]="'Liegeplatz-Belegungen von ' + personOrCompanyName">
                                        <ng-container *ngFor="let berth of topList">
                                            <mat-option [value]="berth">
                                                <span>{{ berth.handle }}</span>
                                                <small class="secondary-text">
                                                    <ng-container *ngIf="berth.pier?.handle">
                                                        <br>Steg: {{ berth.pier?.handle || '-' }}
                                                    </ng-container>
                                                    <ng-container
                                                            *ngIf="berth.hasActiveAssignments() && !berthFormGroup.get('onlyFree').value">
                                                        <br>Belegt von
                                                        <ng-container
                                                                *ngFor="let assignment of berth.getActiveAssignments(); let isLast = last">
                                                            {{ assignment?.boat?.name }}
                                                            <ng-container *ngIf="!isLast">,</ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </small>
                                            </mat-option>
                                            <ng-container *ngIf="!topList?.length">
                                                {{ personOrCompanyName }} belegt keinen Liegeplatz
                                            </ng-container>
                                        </ng-container>
                                    </mat-optgroup>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="filteredBottomList$ | async as bottomList">
                                <mat-optgroup
                                        [label]="personOrCompanyName ? 'Weitere Liegeplätze' : 'Alle Liegeplätze'">
                                    <ng-container *ngFor="let berth of bottomList">
                                        <mat-option [value]="berth">
                                            <span>{{ berth.handle }}</span>
                                            <small class="secondary-text">
                                                <ng-container *ngIf="berth.pier?.handle">
                                                    <br>Steg: {{ berth.pier?.handle || '-' }}
                                                </ng-container>
                                                <ng-container
                                                        *ngIf="berth.hasActiveAssignments() && !berthFormGroup.get('onlyFree').value">
                                                    <br>Belegt von
                                                    <ng-container
                                                            *ngFor="let assignment of berth.getActiveAssignments(); let isLast = last">
                                                        {{ assignment?.boat?.name }}
                                                        <ng-container *ngIf="!isLast">,</ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                        </mat-option>
                                        <ng-container *ngIf="!bottomList?.length">
                                            Keine Liegeplätze gefunden
                                        </ng-container>
                                    </ng-container>
                                </mat-optgroup>
                            </ng-container>
                        </ng-template>
                    </mat-autocomplete>
                </div>
            </div>
        </ng-container>
    </ng-template>

    <ng-container *ngIf="!slimmedView">
        <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
            <mat-label>Bemerkungen / Notizen (optional)</mat-label>
            <textarea matInput data-test="FT-Bemerkungen / Notizen" formControlName="note" rows="3"
                      [readonly]="readonly" autocomplete="new-password"></textarea>
            <mat-error>{{ berthFormGroup?.get('note')?.errors | firstErrorMessage }}</mat-error>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="berth?.products?.length && !slimmedView">
        <div fxFlex="100" class="mb-16">
            <table class="simple sm">
                <thead>
                <tr>
                    <th>Produkt</th>
                    <th style="width: 80px;" class="text-center">Anzahl</th>
                    <th class="text-right">
                        Einzelpreis<br>
                    </th>
                    <th class="text-right">Summe</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let product of (berth?.products || []); let i = index">
                    <tr>
                        <td>{{ product.name }}</td>
                        <td style="width: 80px;" class="text-center">
                            {{ product.quantity }}
                        </td>
                        <td class="text-right">
                            {{ product.price | bcmDynamicCurrency: '1.2-2' }}
                        </td>
                        <td class="text-right">
                            {{ (product.quantity || 0) * (product.price || 0) | bcmDynamicCurrency: '1.2-2' }}
                        </td>
                    </tr>
                </ng-container>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="3" class="no-border"></td>
                    <td class="text-right padding sum">
                        {{ selectedBerthProductPriceTotal | bcmDynamicCurrency: '1.2-2' }}
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </ng-container>

</div>
