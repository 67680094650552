import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { TenantRelationsApiService } from '@modules/bcm/@shared/services';
import { TenantRelation, TenantRelationWithDisable } from '@shared/models/tenant-relation';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { Berth } from '@shared/models/berth';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { Boat } from '@shared/models/boat';
import { TenantRelationFilter } from '@shared/models/mail-filter';

@Component({
    selector: 'form-widget-tenant-relation',
    templateUrl: './form-widget-tenant-relation.component.html',
    styleUrls: ['./form-widget-tenant-relation.component.scss']
})
export class FormWidgetTenantRelationComponent implements OnInit, OnDestroy {

    @Input()
    readonly = false;

    @Input()
    headline = 'Beziehung';

    @Input()
    label = 'Beziehung';

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    set prefilledTenantRelationId(value: number) {
        this._prefilledTenantRelationId = value;
    }

    get prefilledTenantRelationId(): number {
        return this._prefilledTenantRelationId;
    }

    private _prefilledTenantRelationId: number;

    @Input()
    disableConditionCallback: (selectedItems: TenantRelationWithDisable[], allItems: TenantRelationWithDisable[]) => void;

    private _selectTenantRelation: TenantRelation;

    @Input()
    set selectTenantRelation(value: TenantRelation) {
        if (value?.id || value?.id !== this._selectTenantRelation?.id) {
            this._selectTenantRelation = value;
        }
    }

    get selectTenantRelation(): TenantRelation {
        return this._selectTenantRelation;
    }

    @Input()
    selectedTenantRelations?: TenantRelationFilter | TenantRelation[];

    @Input()
    givenTenantRelations: TenantRelation[];

    @Input()
    personOrCompanyName: string;

    @Input()
    tenantRelationRequired = true;

    @Input()
    slimmedView = false;

    @Input()
    givenBerth: Berth;

    @Input()
    givenBoat: Boat;

    @Input()
    disableOtherRelations = false;

    @Input()
    multipleSelectable = false;

    tenantRelationFormGroup: UntypedFormGroup;

    selectedTenantRelations$ = new Subject<TenantRelation[]>();

    selectedTenantRelationPayableOption$ = this.selectedTenantRelations$
        .pipe(map(tenantRelations => {
            return tenantRelations.find(relation => !!relation.payableOption?.id)?.payableOption;
        }));

    selectedTenantRelationProductPriceTotal$ = this.selectedTenantRelations$
        .pipe(map(tenantRelations => {
            return (tenantRelations || [])
                .flatMap(tenantRelation => tenantRelation.products)
                .reduce((accumulator: number, product) => {
                    return accumulator + ((product?.price || 0) * (product?.quantity || 0));
                }, 0);
        }));

    loading: boolean;

    topList: TenantRelation[] = [];

    bottomList: TenantRelation[] = [];

    filteredTopList$: Observable<TenantRelation[]>;

    filteredBottomList$: Observable<TenantRelation[]>;

    selectedFilterOption: 'Volltext' | 'Beginnt mit' =
        (localStorage.getItem('tr_selectedFilterOption') as 'Volltext' | 'Beginnt mit') || 'Volltext';

    filterOptions = ['Volltext', 'Beginnt mit'];

    private _unsubscribeAll = new Subject();

    constructor(private _formBuilder: UntypedFormBuilder,
                private _formGroupDirective: FormGroupDirective,
                public tenantRelationsApiService: TenantRelationsApiService,
                private _productService: ProductService) {
    }

    ngOnInit(): void {
        this._createForm();

        if (this._formGroupDirective?.form && !this.parentFormGroup) {
            this.parentFormGroup = this._formGroupDirective.form;
        }

        this._loadTenantRelations(this.givenTenantRelations);

        setTimeout(() => {
            this.parentFormGroup.addControl('tenantRelationForm', this.tenantRelationFormGroup);
        });

        if (this.multipleSelectable && this.headline === 'Beziehung') {
            this.headline = 'Beziehung(en)';
        }

        if (this.multipleSelectable && this.label === 'Beziehung') {
            this.label = 'Beziehung(en)';
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
        this.parentFormGroup.removeControl('tenantRelationForm');
    }

    saveSelectedFilterOption(selectedFilterOption: 'Volltext' | 'Beginnt mit') {
        localStorage.setItem('tr_selectedFilterOption', selectedFilterOption);
    }

    removeTenantRelation(): void {
        this.tenantRelationFormGroup.get('tenantRelation').setValue(null);
        this.tenantRelationFormGroup.markAsDirty();
    }

    private _createForm(): void {
        this.tenantRelationFormGroup = this._formBuilder.group({
            tenantRelations: [
                {
                    value: this.selectTenantRelation
                        ? [this.selectTenantRelation]
                        : this.selectedTenantRelations ?? [],
                    disabled: !this.multipleSelectable,
                },
                this.tenantRelationRequired ? [U2bValidators.required('Bitte Beziehung(en) angeben')] : []
            ],
            tenantRelation: [
                {
                    value: this.selectTenantRelation,
                    disabled: this.multipleSelectable,
                },
                this.tenantRelationRequired ? [U2bValidators.required('Bitte Beziehung angeben')] : []
            ],
        });

        this.tenantRelationFormGroup.valueChanges
            .subscribe(({tenantRelation, tenantRelations}: {
                tenantRelation: TenantRelation,
                tenantRelations: TenantRelation[]
            }) => {

                const selectedTenantRelations = (tenantRelation ? [tenantRelation] : (tenantRelations || []));

                if (tenantRelation?.id) {
                    this.selectTenantRelation = tenantRelation;
                }

                const boat = this.givenBoat || this.parentFormGroup.get('boatForm')?.value?.boat;

                const dynamicPricesRequests$: Observable<void>[] = [];

                selectedTenantRelations.forEach(selectedTenantRelation => {
                    const parameters = {boat, berth: this.givenBerth, tenantRelation: selectedTenantRelation};

                    for (const product of (selectedTenantRelation?.products || []).filter(p => p.hasDynamicPrice)) {
                        dynamicPricesRequests$.push(
                            this._productService.evaluatePriceRule(product, parameters, product.quantity)
                                .pipe(map(dynamicPrice => {
                                    product.dynamicPrice = dynamicPrice;
                                }))
                        );
                    }
                });

                if (dynamicPricesRequests$.length === 0) {
                    dynamicPricesRequests$.push(of(null));
                }

                forkJoin(dynamicPricesRequests$)
                    .subscribe(() => {
                        this.selectedTenantRelations$.next(selectedTenantRelations);
                    });
            });
    }

    private _loadTenantRelations(givenTenantRelations: TenantRelation[] = []): void {
        this.topList = [];
        this.bottomList = [];

        this.loading = true;

        this.tenantRelationsApiService
            .getAll()
            .subscribe((tenantRelations) => {
                for (const tenantRelation of tenantRelations) {
                    if (this.personOrCompanyName && givenTenantRelations.find(givenTenantRelation => givenTenantRelation.id === tenantRelation.id)) {
                        this.topList.push(tenantRelation);
                    } else {
                        this.bottomList.push(tenantRelation);
                    }
                }

                if (this.prefilledTenantRelationId && tenantRelations?.length) {
                    this.tenantRelationFormGroup.patchValue({
                        tenantRelation: tenantRelations.find(tr => tr.id === this.prefilledTenantRelationId) || null
                    });
                }
            })
            .add(() => this.loading = false);

        this.filteredTopList$ = this.tenantRelationFormGroup.get('tenantRelation').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterTenantRelations(value, this.topList) : this.topList)
            );

        this.filteredBottomList$ = this.tenantRelationFormGroup.get('tenantRelation').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterTenantRelations(value, this.bottomList) : this.bottomList)
            );
    }

    private _filterTenantRelations(filter: string, tenantRelations: TenantRelation[]): TenantRelation[] {
        return tenantRelations.filter(tenantRelation => tenantRelation.name.toLowerCase().includes(filter.toLowerCase()));
    }

    public displayTenantRelationWith(tenantRelation: any): string {
        return tenantRelation?.name || '';
    }
}
