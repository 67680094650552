import { Component, Input } from '@angular/core';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';
import { BaseTabComponent } from '@sharedComponents/dialogs/booking-dialog/tabs/abstract/base-tab.component';
import { BcmBookingGeneralData, BcmBookingGeneralDataDto, ParticipantType } from '@shared/models/bcm-booking';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { AbstractControlOptions, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { BookingDialogFirstChoice } from '@modules/bcm/settings/ui-settings/ui-settings.component';

@UntilDestroy()
@Component({
    selector: 'booking-general-tab',
    templateUrl: './general-tab.component.html',
    styleUrls: ['./general-tab.component.scss']
})
export class GeneralTabComponent extends BaseTabComponent<BcmBookingGeneralDataDto, BcmBookingGeneralDataDto> {

    readonly BookingType = BookingType;
    readonly ParticipantType = ParticipantType;

    readonly BookingDialogFirstChoice = BookingDialogFirstChoice;

    @Input()
    bookingDialogFirstChoice: BookingDialogFirstChoice;

    boatFormGroup: UntypedFormGroup = this.formBuilder.group({});

    constructor(
        bookingDialogService: BookingDialogService,
        formBuilder: FormBuilder
    ) {
        super(
            bookingDialogService,
            formBuilder,
            BookingAttribute.GENERAL,
            BcmBookingGeneralData
        );

        if (bookingDialogService.readonlyView) {
            this.formGroup.get('bookingType').disable();
        }
    }

    formTemplate(value: BcmBookingGeneralDataDto | undefined): {
        controls: { [key: string]: any },
        options?: AbstractControlOptions | null
    } {
        return {
            controls: {
                bookingType: [value?.bookingType || BookingType.Reservation],
                reservedUntil: [value?.reservedUntil || null],
                reservationText: [value?.reservationText || null],
                note: [value?.note || null],
                status: [value?.status || null],
                isBoatOwner: [value?.isBoatOwner || false],
                personOrCompany: [value?.personOrCompany || null]
            }
        };
    }

}
