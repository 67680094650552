<ng-template #actions let-showNoteButton>
    <div fxLayout="row">
        <mat-form-field style="width: 100%" appearance="outline">
            <mat-label>Vorlage auswählen</mat-label>
            <mat-select [(value)]="wordTemplate">
                <mat-option *ngFor="let template of invoiceTemplates"
                            [value]="template">
                    <fa-icon style="margin-right: 5px;" [icon]="faFileWord" fxHide.lt-sm></fa-icon>
                    {{ template.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center">

        <div class="button-group">
            <ng-container *ngIf="newInvoice">
                <ng-template #saving>
                    <mat-icon>save</mat-icon>
                    speichert...
                    <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                </ng-template>

                <ng-template #notSaving>
                    <mat-icon>save</mat-icon>
                    <strong fxHide.lt-sm>{{ (formGroup.get('invoiceType').value + 'Save') | translate : null : 'Rechnung speichern' }}</strong>
                </ng-template>

                <ng-template #savingTest>
                    <mat-icon>picture_as_pdf</mat-icon>
                    Erstelle Test PDF...
                    <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                </ng-template>

                <ng-template #notSavingTest>
                    <mat-icon>picture_as_pdf</mat-icon>
                    Test PDF
                </ng-template>

                <button data-test="B-Speichern" mat-flat-button
                        color="accent"
                        [disabled]="isSaving || !formGroup?.dirty"
                        (click)="saveInvoice()">
                    <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
                </button>

                <button data-test="B-Test PDF" mat-stroked-button
                        [disabled]="isLoadingTestPdf || !formGroup?.dirty"
                        (click)="saveInvoice(true)">
                    <ng-container *ngIf="isLoadingTestPdf; then savingTest else notSavingTest"></ng-container>
                </button>

                <button data-test="B-Template bearbeiten" mat-stroked-button
                        (click)="onEditTemplateClick()">
                    <mat-icon>edit</mat-icon>
                    <span fxHide.lt-sm>Template bearbeiten</span>
                </button>

            </ng-container>
        </div>

        <ng-container *ngIf="showNoteButton">
            <mat-slide-toggle [(ngModel)]="showNote" labelPosition="before" class="mt-16 mt-md-0"
                              data-test="FTS-Interne Notizen anzeigen">
                Interne Notizen anzeigen
            </mat-slide-toggle>
        </ng-container>


        <ng-container *ngIf="!!data">
            <div>
                <button data-test="B-Speichern" mat-flat-button
                        color="warn"
                        (click)="dialogRef.close(false)">
                    <mat-icon>close</mat-icon>
                    <span fxHide.lt-sm>Abbrechen</span>
                </button>
            </div>
        </ng-container>

    </div>
</ng-template>

<ng-container *ngIf="!!data">
    <mat-toolbar matDialogTitle>
        <mat-toolbar-row fxLayout="row">
            <button style="margin: 0 8px 0 auto;" data-test="B-Dialog schließen" mat-icon-button
                    (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
</ng-container>

<div id="invoice" [style.overflow-x]="!!data ? 'hidden' : 'auto'" class="compact page-layout blank" fxLayout="row">

    <ng-container *ngIf="newInvoice; else embeddedPdf">

        <ng-template #missingInvoiceData>
            <div class="invoice-container">
                <u2b-message type="error">
                    <p>
                        Um eine {{ 'invoice' | translate }} zu stellen müssen folgende Angaben angegeben werden:
                    </p>

                    <ul>
                        <li>Rechnungskopf (Adresse des Rechnungsausstellers)</li>
                        <li>Fußnote / das Kleingedruckte</li>
                    </ul>

                    <a data-test="L-Jetzt konfigurieren" mat-flat-button class="fuse-white"
                       [routerLink]="bcmService.baseUrl + '/accounting/invoice-settings'">
                        Jetzt konfigurieren
                    </a>
                </u2b-message>
            </div>
        </ng-template>

        <ng-container
                *ngIf="invoiceSettings.header && (invoiceSettings.footer || invoiceSettings.footers?.length); else missingInvoiceData">

            <div class="invoice-container">

                <div class="mb-16 clearfix">
                    <ng-template [ngTemplateOutlet]="actions" [ngTemplateOutletContext]="{ $implicit: true }">
                    </ng-template>
                </div>

                <ng-container *ngIf="showNote">
                    <div class="fuse-card auto-width mb-16" [formGroup]="formGroup">
                        <div class="p-16">
                            <mat-form-field class="invoice-note" appearance="outline"
                                            hintLabel="Maximal 1024 Zeichen">
                                <mat-label>Interne Notizen</mat-label>
                                <textarea #note
                                          matInput
                                          formControlName="internalNote"
                                          autocomplete="new-password"
                                          [cdkTextareaAutosize]="true"
                                          [cdkAutosizeMinRows]="3"
                                          [cdkAutosizeMaxRows]="5"
                                          [maxLength]="1024"></textarea>
                                <mat-hint align="end">{{ note.value?.length || 0 }}/1024</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>

                <div class="card" [formGroup]="formGroup">

                    <div class="header">

                        <div class="logo-wrapper">
                            <div class="logo" [matTooltip]="'Neues Logo hochladen'" matTooltipPosition="above">
                                <div>
                                    <a [routerLink]="bcmService.baseUrl + '/accounting/invoice-settings'">
                                        <u2b-secured-image-bcm [fileId]="invoiceSettings.logoId"
                                                               [placeholder]="'assets/images/logos/placeholder.png'"></u2b-secured-image-bcm>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div fxLayout="column"
                             fxLayout.gt-sm="row"
                             fxLayoutAlign="space-between stretch">

                            <div class="client">
                                <div class="insurer-address" data-test="C-Absenderadresse">
                                    {{ invoiceSettings.header }}
                                </div>

                                <mat-form-field style="width: 100%;" appearance="outline">
                                    <mat-label>
                                        <strong>{{ (formGroup.get('invoiceType').value + 'Address') | translate : null : 'Rechnungsadresse' }}</strong>
                                    </mat-label>
                                    <textarea matInput
                                              data-test="FI-Rechnungsadresse"
                                              formControlName="address"
                                              autocomplete="new-password"
                                              [placeholder]="'Fam. Mustermann\nMusterstraße 17\n12345 Musterstadt'"
                                              [cdkTextareaAutosize]="true"
                                              [cdkAutosizeMinRows]="5"
                                              [readonly]="invoice.person?.id || invoice.company?.id"></textarea>
                                    <mat-error>{{ formGroup?.get('address').errors | firstErrorMessage }}</mat-error>

                                    <ng-container *ngIf="invoice.person?.id || invoice.company?.id">
                                        <button data-test="B-Adresse bearbeiten"
                                                mat-stroked-button
                                                color="accent"
                                                matTooltip="Adresse ändern"
                                                (click)="onClickEditAddress()">
                                            <mat-icon>edit</mat-icon>
                                            Adresse bearbeiten
                                        </button>
                                    </ng-container>
                                </mat-form-field>

                                <button data-test="B-Adresse aus Stammdaten" mat-stroked-button color="accent"
                                        (click)="onClickAddAddress()">
                                    <mat-icon>playlist_add</mat-icon>
                                    Adresse aus Stammdaten
                                </button>

                            </div>

                            <div class="issuer">
                                <table class="invoice-meta-table">
                                    <tbody>
                                    <ng-container *ngIf="invoiceSettings.showAdditionalDataUnderLogo">
                                        <tr>
                                            <td colspan="2"
                                                class="pb-20 font-size-12"
                                                [style.text-align]="invoiceSettings.additionalDataUnderLogoAlign"
                                                [innerHTML]="additionalDataUnderLogo"></td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td class="align-right"></td>
                                        <td>
                                            <label>
                                                <select data-test="FS-Typ"
                                                        id="invoice_type"
                                                        formControlName="invoiceType">
                                                    <option [value]="InvoiceTypes.Invoice">{{ InvoiceTypes.Invoice | translate }}</option>
                                                    <option [value]="InvoiceTypes.Crediting">{{ InvoiceTypes.Crediting | translate }}</option>
                                                    <option [value]="ReceiptTypes.Receipt">{{ ReceiptTypes.Receipt | translate }}</option>
                                                </select>
                                                <br>
                                                <br>
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-right" style="word-wrap: break-word">
                                            <ng-container
                                                    *ngIf="formGroup.get('invoiceType').value === InvoiceTypes.Invoice">
                                                <strong>{{ 'invoiceNumber' | translate }}:</strong>
                                            </ng-container>
                                            <ng-container
                                                    *ngIf="formGroup.get('invoiceType').value === InvoiceTypes.Crediting">
                                                <strong>{{ 'creditingNumber' | translate }}:</strong>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <strong>
                                                <ng-container data-test="C-Rechnungsnummer"
                                                              *ngIf="invoice.invoiceNumber; else noInvoiceNumberYet">
                                                    {{ invoice.invoiceNumber }}
                                                </ng-container>
                                                <ng-template #noInvoiceNumberYet>
                                                    <ng-container
                                                            *ngIf="formGroup.get('invoiceType').value === InvoiceTypes.Invoice">
                                                        <abbr
                                                                [matTooltip]="'Eine neue '+ ('invoiceNumber' | translate) +' wird beim Speichern erzeugt!'"
                                                                matTooltipPosition="right">
                                                            automatisch
                                                        </abbr>
                                                    </ng-container>
                                                    <ng-container
                                                            *ngIf="formGroup.get('invoiceType').value === InvoiceTypes.Crediting">
                                                        <abbr
                                                                [matTooltip]="'Eine neue '+ ('creditingNumber' | translate) +' wird beim Speichern erzeugt!'"
                                                                matTooltipPosition="right">
                                                            automatisch
                                                        </abbr>
                                                    </ng-container>
                                                </ng-template>
                                            </strong>
                                        </td>
                                    </tr>
                                    <ng-container
                                            *ngIf="formGroup?.get('invoiceType').value === ReceiptTypes.Receipt">
                                        <tr>
                                            <td style="word-wrap: break-word">{{ 'invoiceNumber' | translate }}:</td>
                                            <td>
                                                <abbr [matTooltip]="'Eine neue '+ ('invoiceNumber' | translate) +' wird beim Speichern erzeugt!'"
                                                      matTooltipPosition="right">
                                                    automatisch
                                                </abbr>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td style="word-wrap: break-word">
                                            <label for="invoice_date">
                                                <ng-container
                                                        *ngIf="formGroup.get('invoiceType').value === InvoiceTypes.Invoice">
                                                    {{ 'invoiceDate' | translate }}:
                                                </ng-container>
                                                <ng-container
                                                        *ngIf="formGroup.get('invoiceType').value === InvoiceTypes.Crediting">
                                                    {{ 'creditingDate' | translate }}:
                                                </ng-container>
                                                <ng-container
                                                        *ngIf="formGroup.get('invoiceType').value === ReceiptTypes.Receipt">
                                                    {{ 'invoiceDate' | translate }}:
                                                </ng-container>
                                            </label>
                                        </td>
                                        <td>
                                            <div>
                                                <input matInput data-test="FI-Rechnungsdatum"
                                                       id="invoice_date"
                                                       type="text"
                                                       formControlName="date"
                                                       autocomplete="new-password"
                                                       [matDatepicker]="datePicker" appMaskDate
                                                       placeholder="TT.MM.JJJJ">
                                                <mat-datepicker-toggle matSuffix
                                                                       data-test="FIT-Rechnungsdatum"
                                                                       [disableRipple]="true"
                                                                       [for]="datePicker"></mat-datepicker-toggle>
                                                <mat-datepicker touchUi #datePicker></mat-datepicker>
                                            </div>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="formGroup?.get('date').errors">
                                        <tr>
                                            <td colspan="2" class="text-right">
                                                <mat-error>{{ formGroup?.get('date').errors | firstErrorMessage }}</mat-error>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="formGroup.get('invoiceType').value === InvoiceTypes.Invoice">
                                        <tr>
                                            <td><label for="invoice_dueDate">Fälligkeitsdatum:</label></td>
                                            <td>
                                                <div>
                                                    <input matInput data-test="FI-Fälligkeitsdatum"
                                                           appearance="outline"
                                                           id="invoice_dueDate"
                                                           type="text"
                                                           formControlName="dueDate"
                                                           autocomplete="new-password"
                                                           [matDatepicker]="dueDatePicker"
                                                           appMaskDate placeholder="TT.MM.JJJJ">
                                                    <mat-datepicker-toggle matSuffix
                                                                           data-test="FIT-Fälligkeitsdatum"
                                                                           [disableRipple]="true"
                                                                           [for]="dueDatePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker touchUi #dueDatePicker></mat-datepicker>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="formGroup?.get('dueDate').errors && (formGroup?.get('dueDate').touched || formSubmitted)">
                                        <tr>
                                            <td colspan="2" class="text-right">
                                                <mat-error>{{ formGroup?.get('dueDate').errors | firstErrorMessage }}</mat-error>
                                            </td>
                                        </tr>
                                    </ng-container>

                                    <tr>
                                        <td><label for="invoice_subject">Betreff:</label></td>
                                        <td>
                                            <div>
                                                <input matInput data-test="FI-Betreff"
                                                       appearance="outline"
                                                       id="invoice_subject"
                                                       type="text"
                                                       formControlName="subject">
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label for="invoice_taxNumber">Steuer-Nr.:</label></td>
                                        <td>
                                            <div>
                                                <input matInput data-test="FI-Steuer-Nr."
                                                       appearance="outline"
                                                       id="invoice_taxNumber"
                                                       type="text"
                                                       formControlName="taxNumber">
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label for="invoice_yourSign">Ihr Zeichen:</label></td>
                                        <td>
                                            <div>
                                                <input matInput data-test="FI-Ihr Zeichen"
                                                       appearance="outline"
                                                       id="invoice_yourSign"
                                                       type="text"
                                                       formControlName="yourSign">
                                            </div>
                                        </td>
                                    </tr>

                                    <ng-container
                                            *ngIf="invoiceSettings.showBankDataInHeader && formGroup?.get('invoiceType').value !== ReceiptTypes.Receipt">
                                        <tr>
                                            <td colspan="2" class="p-0 font-size-12">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 font-size-12">Bankverbindung:</td>
                                            <td class="p-0 font-size-12">
                                                <ng-container
                                                        *ngIf="invoice.tenant?.primaryBankAccount?.bankName; else noIBAN">
                                                    {{ invoice.tenant.primaryBankAccount?.bankName }}
                                                </ng-container>
                                                <ng-template #noIBAN>
                                                    <span class="warn">Keine Bankverbindung</span>
                                                </ng-template>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 font-size-12">IBAN:</td>
                                            <td class="p-0 font-size-12">
                                                <ng-container
                                                        *ngIf="invoice.tenant?.primaryBankAccount?.iban; else noIBAN">
                                                    {{ invoice.tenant.primaryBankAccount?.iban }}
                                                </ng-container>
                                                <ng-template #noIBAN>
                                                    <span class="warn">Keine IBAN</span>
                                                </ng-template>
                                            </td>
                                        </tr>
                                        <ng-container *ngIf="invoice.tenant?.primaryBankAccount?.bic">
                                            <tr>
                                                <td class="p-0 font-size-12">BIC:</td>
                                                <td class="p-0 font-size-12">
                                                    {{ invoice.tenant.primaryBankAccount?.bic }}
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="(invoice.person || invoice.company)?.identNumber && formGroup.get('invoiceType').value !== ReceiptTypes.Receipt">
                                        <tr>
                                            <td colspan="2" class="p-0 font-size-12">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 font-size-12">Debitorennummer:</td>
                                            <td class="p-0 font-size-12">
                                                {{ (invoice.person || invoice.company)?.identNumber }}
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container
                                            *ngIf="invoiceSettings.showTenantRelationInHeader != null && invoiceSettings.showTenantRelationInHeader && (invoice.person?.firstActiveTenantRelationAssignment?.tenantRelation.name || invoice.company?.firstActiveTenantRelationAssignment?.tenantRelation.name)">
                                        <tr>
                                            <td colspan="2" class="p-0 font-size-12">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 font-size-12">Beziehung:</td>
                                            <td class="p-0 font-size-12">
                                                {{ invoice.person?.firstActiveTenantRelationAssignment?.tenantRelation.name || invoice.company?.firstActiveTenantRelationAssignment?.tenantRelation.name }}
                                            </td>
                                        </tr>
                                    </ng-container>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                    <p fxHide.gt-xs>
                        <b>Hinweis:</b>
                        <br>Sie können die untere Tabelle scrollen um die Menge und den Preis der Position zu
                        bestimmen.
                    </p>

                    <div class="content"
                         cdkDropList
                         cdkDropListOrientation="vertical"
                         cdkDropListLockAxis="y"
                         [cdkDropListData]="invoice?.positions"
                         (cdkDropListDropped)="drop($event)">

                        <table class="simple invoice-table">
                            <thead>
                            <tr>
                                <th width="32px"></th>
                                <th width="40px"></th>
                                <th>Pos</th>
                                <ng-container *ngIf="invoiceSettings.showItemNumber">
                                    <th class="text-right">Artikel-Nr.</th>
                                </ng-container>
                                <ng-container *ngIf="invoiceSettings.showAccount">
                                    <th class="text-right">Konto</th>
                                </ng-container>
                                <th class="min-width">Bezeichnung</th>
                                <th class="text-right">Menge</th>
                                <th>Einheit</th>
                                <th class="text-right">
                                    <bcm-product-price-label [lineBrake]="true"
                                                             [switchToNet]="true"></bcm-product-price-label>
                                </th>
                                <ng-container *ngIf="showTaxRateColumn">
                                    <th class="text-right">
                                        MwSt.
                                    </th>
                                </ng-container>
                                <th class="text-right">
                                    Rabatt
                                </th>
                                <th class="text-right">
                                    <bcm-product-price-label [lineBrake]="true"
                                                             text="Summe"></bcm-product-price-label>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngIf="showEmptyPositionsError">
                                <tr>
                                    <td [attr.colspan]="invoicePositionsColspan">
                                        <u2b-message type="error">
                                            Es muss mindestens eine Position angegeben und ausgefüllt werden.
                                        </u2b-message>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let position of invoice?.positions; let i = index">
                                <tr cdkDrag
                                    [cdkDragDisabled]="!dragEnabled"
                                    #tr1
                                    (mouseenter)="tr1.classList.add('highlighted');tr2.classList.add('highlighted');"
                                    (mouseleave)="tr1.classList.remove('highlighted');tr2.classList.remove('highlighted');"
                                    (click)="editPosition(position)"
                                    style="cursor: pointer">
                                    <td width="32px">
                                        <ng-container *ngIf="!data">
                                            <div style="width: 16px">
                                                <mat-checkbox (change)="changePositionSelection(i)"
                                                              (click)="$event.stopPropagation()"></mat-checkbox>
                                            </div>
                                        </ng-container>
                                    </td>

                                    <td width="40px">
                                        <span class="drag-and-drop-handle-wrapper">
                                            <i class="material-icons drag-indicator"
                                               cdkDragHandle
                                               (mouseenter)="dragEnabled = true"
                                               (mouseleave)="dragEnabled = false"
                                               (click)="$event.stopPropagation()">drag_indicator</i>
                                        </span>
                                    </td>

                                    <td>{{ i + 1 }}</td>

                                    <ng-container *ngIf="invoiceSettings.showItemNumber">
                                        <td class="text-right" style="max-width: 90px; min-width: 90px;">
                                            {{ position.itemNumber }}
                                        </td>
                                    </ng-container>

                                    <ng-container *ngIf="invoiceSettings.showAccount">
                                        <td class="text-right" style="max-width: 90px; min-width: 90px;">
                                            {{ position.account }}
                                        </td>
                                    </ng-container>
                                    <td>
                                        <span>{{ position.title }}</span>

                                        <ng-container *ngIf="position.bcm_bookings_id">
                                            <small>
                                                (Buchung #{{ position.bcm_bookings_id }})
                                            </small>
                                        </ng-container>

                                        <ng-container *ngIf="position.vestingPeriodText">
                                            <br>
                                            <small>
                                                <!--                                                === Leistungszeitraum ===<br>-->
                                                {{ position.vestingPeriodText }}
                                            </small>
                                        </ng-container>

                                        <ng-container *ngIf="position.voucher">
                                            <br>
                                            <small>
                                                {{ position.voucher.title }}<br>
                                                (Verbleibend: {{ position.voucherRemainingAmount || 0 }})
                                            </small>
                                        </ng-container>

                                        <ng-container *ngIf="position.subTitle">
                                            <div>
                                                <small>
                                                    {{ position.subTitle }}
                                                </small>
                                            </div>
                                        </ng-container>
                                    </td>
                                    <td class="text-right" style="max-width: 95px; min-width: 95px;">
                                        {{ position.quantity }}
                                    </td>
                                    <td style="max-width: 110px; min-width: 110px;">
                                        {{ position.unit?.name }}
                                    </td>
                                    <td class="text-right" style="max-width: 110px; min-width: 110px;">
                                        <!-- CH special -->
                                        <ng-container *ngIf="invoiceSettings.showSinglePriceInNet">
                                            {{ position.price | bcmGrossToNetPrice : position | bcmDynamicPriceRounded }}
                                        </ng-container>

                                        <ng-container *ngIf="!invoiceSettings.showSinglePriceInNet">
                                            {{ position.price | bcmDynamicCurrency }}
                                        </ng-container>
                                    </td>

                                    <ng-container *ngIf="showTaxRateColumn">
                                        <td class="text-right" style="max-width: 90px;">
                                            {{ position.taxRateValue }}&nbsp;%
                                        </td>
                                    </ng-container>

                                    <td class="text-right" style="max-width: 90px;">
                                        <ng-container *ngIf="position.discountPercentage">
                                            {{ position.discountPercentage }}&nbsp;%
                                        </ng-container>
                                    </td>

                                    <td class="text-right position-price">
                                        {{ position?.totalPrice | bcmDynamicPriceRounded }}
                                    </td>
                                </tr>

                                <tr #tr2
                                    (mouseenter)="tr1.classList.add('highlighted');tr2.classList.add('highlighted');"
                                    (mouseleave)="tr1.classList.remove('highlighted');tr2.classList.remove('highlighted');">
                                    <ng-container *ngIf="!data">
                                        <td class="py-0 text-right" [attr.colspan]="invoicePositionsColspan">
                                            <div class="button-group invoice-row-actions">
                                                <button data-test="B-Position bearbeiten"
                                                        mat-stroked-button
                                                        color="primary"
                                                        [disabled]="!!position.voucher"
                                                        [matTooltip]="('voucher' | translate) + ' Positionen können aus technischen Gründen nur gelöscht werden.'"
                                                        [matTooltipDisabled]="!position.voucher"
                                                        (click)="editPosition(position); $event.preventDefault()">
                                                    <mat-icon>edit</mat-icon>
                                                    Bearbeiten
                                                </button>
                                                <button data-test="B-Position entfernen"
                                                        mat-stroked-button
                                                        color="warn"
                                                        (click)="onClickRemovePosition(position, i); $event.preventDefault()">
                                                    <mat-icon>delete</mat-icon>
                                                    Entfernen
                                                </button>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>

                            </ng-container>

                            <tr class="no-bg">
                                <td [attr.colspan]="invoicePositionsColspan">
                                    <div class="button-group">
                                        <ng-container *ngIf="positionsSelected">
                                            <button data-test="B-Ausgewählte Positionen entfernen"
                                                    mat-stroked-button
                                                    color="warn"
                                                    (click)="onClickDeleteMultiplePositions()">
                                                <mat-icon>delete</mat-icon>
                                                Ausgewählte Positionen entfernen
                                            </button>
                                        </ng-container>

                                        <ng-container *ngIf="!data">
                                            <button data-test="B-Position hinzufügen"
                                                    mat-stroked-button
                                                    color="accent"
                                                    (click)="onClickAddPosition()">
                                                <mat-icon>add</mat-icon>
                                                Position hinzufügen
                                            </button>
                                        </ng-container>

                                    </div>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                    <table class="simple invoice-table-footer">
                        <tbody>
                        <tr class="subtotal">
                            <td>Nettogesamt</td>
                            <td data-test="TS-Netto">{{ invoice?.totalNetPrice | bcmDynamicCurrency }}</td>
                        </tr>
                        <ng-container *ngIf="invoice.totalTaxFreeProductsPrice !== 0">
                            <tr class="tax first-tax"
                                [class.last-tax]="invoice?.totalTaxesListFiltered?.length === 0">
                                <td>Nettobeträge mit 0% MwSt.</td>
                                <td data-test="TS-Nettobeträge mit 0% MwSt.">
                                    {{ invoice?.totalTaxFreeProductsPrice | bcmDynamicCurrency }}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container
                                *ngFor="let taxItem of invoice?.totalTaxesListFiltered; let i = index; let first = first; let last = last">
                            <ng-container *ngIf="taxItem.taxRate !== 0">
                                <tr class="tax" [class.first-tax]="first && invoice.totalTaxFreeProductsPrice === 0"
                                    [class.last-tax]="last">
                                    <td>Zzgl. MwSt. {{ taxItem.taxRate?.toString()?.replace('.', ',') }}%</td>
                                    <td data-test="TS-MwSt">{{ taxItem.value | bcmDynamicCurrency }}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <tr class="total">
                            <td><strong>Zahlbetrag (Brutto)</strong></td>
                            <td data-test="TS-Zahlbetrag">
                                <strong>{{ invoice?.totalGrossPrice | bcmDynamicCurrency }}</strong>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <div class="footer">

                        <ng-container *ngIf="formGroup.get('invoiceType').value === InvoiceTypes.Invoice">

                            <p>
                                Zahlungsart: <select class="input-small inline"
                                                     data-test="FS-Zahlungsart"
                                                     formControlName="paymentType">
                                <option [value]="invoicePaymentType.Invoice">Überweisung</option>
                                <ng-container *ngIf="invoice.isSepa">
                                    <option [value]="invoicePaymentType.DirectDebit">SEPA-Lastschrift</option>
                                </ng-container>
                            </select>
                            </p>

                            <ng-container
                                    *ngIf="dueDateNoteInvoice && formGroup.get('paymentType').value === invoicePaymentType.Invoice">
                                <p>{{ dueDateNoteInvoice }}</p>
                            </ng-container>

                            <ng-container
                                    *ngIf="dueDateNoteDirectDebit && formGroup.get('paymentType').value === invoicePaymentType.DirectDebit">
                                <p>{{ dueDateNoteDirectDebit }}</p>
                            </ng-container>

                            <ng-container
                                    *ngIf="certificateOfAchievementNote && formGroup.get('invoiceType').value === InvoiceTypes.Invoice">
                                <p>{{ certificateOfAchievementNote }}</p>
                            </ng-container>

                        </ng-container>

                        <mat-form-field style="width: 100%;" appearance="outline">
                            <mat-label>Sonstige Angaben (optional)</mat-label>
                            <textarea matInput
                                      data-test="FI-Sonstige Angaben"
                                      formControlName="note"
                                      autocomplete="new-password"
                                      [cdkTextareaAutosize]="true"
                                      [cdkAutosizeMinRows]="5"></textarea>
                            <mat-error>{{ formGroup?.get('note').errors | firstErrorMessage }}</mat-error>
                        </mat-form-field>

                        <ng-container *ngIf="invoiceSettings.footerLayout === FooterLayout.Row">
                            <div class="small-note" fxHide fxShow.gt-sm>
                                <div [innerHTML]="invoiceSettings.footer"
                                     [style.text-align]="invoiceSettings.footerAlign"></div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="invoiceSettings.footerLayout === FooterLayout.Columns">
                            <div class="small-note" fxHide fxShow.gt-sm>
                                <table>
                                    <tbody>
                                    <tr>
                                        <ng-container *ngFor="let footerPreview of footersPreview">
                                            <td style="vertical-align: top"
                                                [style.text-align]="footerPreview.align"
                                                [style.width.%]="100 / footersPreview.length"
                                                [innerHTML]="footerPreview.text">
                                            </td>
                                        </ng-container>
                                    </tr>
                                    </tbody>
                                </table>
                                <div style="text-align: center">
                                    <small>Seite 1 von 1</small>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                </div>

                <div class="mt-16 mb-16">
                    <ng-template [ngTemplateOutlet]="actions"></ng-template>
                </div>

            </div>

        </ng-container>
    </ng-container>

    <div>
        <ng-template #embeddedPdf>
            <ng-container *ngIf="isIEOrNonWebkitEdge">
                {{ 'can_not_open_invoice_in_ie' | translate }}
            </ng-container>
            <ng-container *ngIf="!isIEOrNonWebkitEdge && pdfDataUrl">
                <embed [src]="pdfDataUrl" width="100%" height="100%" id="invoicePdf" #invoicePdf>
            </ng-container>
        </ng-template>
    </div>
</div>
