<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Gebühr / Produkt {{ product?.id ? 'bearbeiten' : 'hinzufügen' }}</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="productForm" fxLayout="column">

            <ng-container *ngIf="products?.length; else noProducts">

                <div fxLayout="row" fxLayoutAlign="start start" class="mb-24">
                    <mat-form-field fxFlex>
                        <input type="text" matInput data-test="FI-Produkte durchsuchen..." autofocus
                               formControlName="product" placeholder="Produkte durchsuchen..."
                               [matAutocomplete]="productSelector">
                        <mat-hint *ngIf="productForm.get('product').value">
                            <bcm-product-price-label textAlign="left"></bcm-product-price-label>
                            : {{ productForm.get('product').value?.price | bcmDynamicCurrency: '1.2-2' }}
                        </mat-hint>
                        <mat-error>{{ productForm.get('product').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>

                    <mat-autocomplete #productSelector="matAutocomplete" [displayWith]="displayProductWith">
                        <mat-option *ngFor="let product of (filteredProducts$ | async)" [value]="product">
                            <span>{{ product?.name }}</span>
                            <small> | {{ product?.price | bcmDynamicCurrency :'1.2-4' }}</small>
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <ng-container *ngIf="showQuantityField">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <mat-form-field fxFlex>
                            <app-input-number
                                    matInput
                                    data-test="FI-Anzahl / Einheiten" placeholder="Anzahl / Einheiten"
                                    formControlName="quantity"></app-input-number>
                            <mat-error>{{ productForm.get('quantity').errors | firstErrorMessage }}</mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>

                <ng-container *ngIf="showIgnoreFreeOfChargePositionCheckbox">
                    <mat-divider class="my-24"></mat-divider>
                    <div fxFlex="100">
                        <mat-checkbox data-test="FCB-Position erstellen wenn Preis"
                                      formControlName="ignoreFreeOfChargePosition">
                            <b>Keine</b> Position erstellen, wenn der tatsächliche Preis {{ 0 | bcmDynamicCurrency }}
                            beträgt.
                        </mat-checkbox>
                    </div>
                    <u2b-message type="info" class="mt-16" [collapsable]="true">
                        <header>Was bedeutet das?</header>
                        <p>
                            Wenn die Checkbox aktiviert ist, dann wird für dieses Produkt keine Position erstellt,
                            sofern der Preis {{ 0 | bcmDynamicCurrency }} beträgt.
                        </p>
                        <p>
                            Das kann dann sinnvoll sein, wenn der Grundpreis des Produkts {{ 0 | bcmDynamicCurrency }}
                            beträgt und sich der tatsächliche Preis über dynamische Preisregeln errechnet.
                        </p>
                        <p>
                            Z.B. könnten nur Kosten anfallen, wenn ein {{ 'boat' | translate }} eine definierte Größe
                            überschreitet. Andernfalls bliebe der Preis bei {{ 0 | bcmDynamicCurrency }}.
                        </p>
                    </u2b-message>
                </ng-container>

                <ng-container *ngIf="data.hasTenantRelationSelection">
                    <mat-divider class="my-24"></mat-divider>
                    <u2b-message type="info" class="mt-16" [collapsable]="true">
                        <header>Warum Beziehung(en) angeben?</header>
                        <p>
                            Das oben gewählte Produkt wird nur für Buchungen berücksichtigt, bei denen eine der hier
                            ausgewählten Beziehungen gewählt wurde. Andernfalls wird es nicht angezeigt.
                        </p>
                    </u2b-message>
                    <form-widget-tenant-relation [selectedTenantRelations]="product?.forTenantRelations"
                                                 [disableConditionCallback]="tenantRelationDisableConditionCallback"
                                                 [slimmedView]="true"
                                                 [multipleSelectable]="true"></form-widget-tenant-relation>
                </ng-container>
            </ng-container>

            <ng-template #noProducts>
                Es konnten keine Produkte gefunden werden.
            </ng-template>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                aria-label="Dialog schließen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>

        <button data-test="B-Speichern" mat-flat-button
                color="accent"
                (click)="saveAndContinue()"
                class="save-button"
                [disabled]="isSaving || !productForm.dirty"
                aria-label="Speichern">
            Speichern
        </button>
    </div>
</div>
