import {
    U2bColumnDefinitionExternalComponent,
    U2bColumnPipeName,
    U2bTableData
} from '@core/components/layout/table/table.types';
import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import { getIsActive } from '@core/functions/get-is-active';
import { Berth, BerthStatus, berthStatusTranslationsDe } from '@shared/models/berth';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';

export const berthsTableData: U2bTableData = {
    tableId: BcmUserTableSettingKey.Berths,
    columnDefinitions: [
        {
            property: 'statusIndex',
            name: 'Status',
            hideNameInHeader: true,
            isActive: getIsActive(),
            externalComponent: U2bColumnDefinitionExternalComponent.BerthsStatusIndicator,
            filter: {
                property: 'status',
                fieldType: FilterFieldType.Select,
                selectOptions: Object.values(BerthStatus),
                selectOptionsTranslations: berthStatusTranslationsDe
            },
        },
        {
            property: 'pierHandle',
            name: 'Steg',
            isActive: getIsActive(),
            parseItem: (item: Berth) => item.pier?.id ? item.pier.handle : '',
            filter: {
                property: 'pier',
            },
        },
        {
            property: 'handle',
            name: 'Liegeplatz',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'type',
            name: 'Typ',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'length',
            name: 'Länge',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Decimal,
            filter: {
                fieldType: FilterFieldType.Numeric
            },
        },
        {
            property: 'width',
            name: 'Breite',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Decimal,
            filter: {
                fieldType: FilterFieldType.Numeric
            },
        },
        {
            property: 'm²',
            name: 'm²',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Decimal,
            parseItem: (item: Berth) => item.squareMeters,
            filter: {
                property: 'squareMeters',
                fieldType: FilterFieldType.Numeric
            },
        },
        {
            property: 'maxDraft',
            name: 'Tiefe',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Decimal,
            filter: {
                fieldType: FilterFieldType.Numeric
            },
        },
        {
            property: 'boatName',
            name: 'Bootsname',
            isActive: getIsActive(),
            translationKey: 'boatName',
            externalComponent: U2bColumnDefinitionExternalComponent.BerthsAssignments,
            externalComponentMetaData: {
                showName: true
            },
            filter: {},
        },
        {
            property: 'boatLicensePlate',
            name: 'Kennzeichen',
            isActive: getIsActive(),
            externalComponent: U2bColumnDefinitionExternalComponent.BerthsAssignments,
            externalComponentMetaData: {
                showLicensePlate: true
            },
            filter: {},
        },
        {
            property: 'boatManufacturer',
            name: 'Boots&shy;hersteller',
            isActive: getIsActive(),
            translationKey: 'boatManufacturer',
            externalComponent: U2bColumnDefinitionExternalComponent.BerthsAssignments,
            externalComponentMetaData: {
                showManufacturer: true
            },
            filter: {},
        },
        {
            property: 'boatOwner',
            name: 'Eigner&nbsp;/ Kontakt',
            isActive: getIsActive(),
            translationKey: 'ownerName',
            externalComponent: U2bColumnDefinitionExternalComponent.BerthsAssignments,
            externalComponentMetaData: {
                showOwner: true
            },
            filter: {},
        },
        // new display columns from ticket 3386
        {
            property: 'isDoubleBox',
            name: 'Doppelbox',
            isActive: getIsActive(false, false, false),
            parseItem: (item: Berth) => item.isDoubleBox ? '✔' : '',
            filter: {
                fieldType: FilterFieldType.Boolean
            },
        },
        {
            property: 'isBuoy',
            name: 'Boje',
            isActive: getIsActive(false, false, false),
            parseItem: (item: Berth) => item.isBuoy ? '✔' : '',
            filter: {
                fieldType: FilterFieldType.Boolean
            },
        },
        {
            // just needed in case it's a buoy
            property: 'maxCarryingCapacity',
            name: 'Max. Tragkraft',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Number,
            filter: {
                fieldType: FilterFieldType.Numeric
            },
        },
        {
            property: 'isJetSki',
            name: 'Jetski',
            isActive: getIsActive(false, false, false),
            parseItem: (item: Berth) => item.isJetSki ? '✔' : '',
            filter: {
                fieldType: FilterFieldType.Boolean
            },
        },
        {
            property: 'isLiftStation',
            name: 'Hebeanlage',
            isActive: getIsActive(false, false, false),
            parseItem: (item: Berth) => item.isLiftStation ? '✔' : '',
            filter: {
                fieldType: FilterFieldType.Boolean
            },
        },
        {
            property: 'forGuest',
            name: 'für Gäste',
            isActive: getIsActive(false, false, false),
            parseItem: (item: Berth) => item.forGuest ? '✔' : '',
            filter: {
                fieldType: FilterFieldType.Boolean
            },
        },
        {
            property: 'forLongTermResidents',
            name: 'für Dauergastlieger',
            isActive: getIsActive(false, false, false),
            parseItem: (item: Berth) => item.forLongTermResidents ? '✔' : '',
            filter: {
                fieldType: FilterFieldType.Boolean
            },
        },
        {
            property: 'statusText',
            name: 'Status Text',
            isActive: getIsActive(false, false, false),
            externalComponent: U2bColumnDefinitionExternalComponent.BerthsReservationInfo,
            filter: {}
        },
        {
            property: 'note',
            name: 'Notiz',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
    ],
};
