import { Component, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { BcmService } from '../../../bcm.service';
import { Tenant } from '@shared/models/tenant';

@Component({
    selector: 'mark-as-payed-dialog',
    templateUrl: './start-direct-debit-dialog.component.html',
    styleUrls: ['./start-direct-debit-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StartDirectDebitDialogComponent {
    formGroup: UntypedFormGroup;

    minDate = new Date();

    tenant: Tenant;

    typeText = 'Lastschrift';

    @ViewChild('input', {read: ViewContainerRef}) public input;

    constructor(
        public dialogRef: MatDialogRef<StartDirectDebitDialogComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _bcmService: BcmService,
    ) {
        this._bcmService.selectedTenant$.subscribe(tenant => this.tenant = tenant);
        this.formGroup = this._createForm();
    }

    private _createForm(): UntypedFormGroup {
        const formGroup = this._formBuilder.group({
            type: ['SEPA'],
            date: [U2bValidators.required('Bitte gib ein korrektes Datumsformat ein. TT.MM.JJJJ')],
            dueDate: [],
        });

        formGroup.get('type').valueChanges
            .subscribe((value) => {
                this.typeText = value === 'SEPA' ? 'Lastschrift' : 'Gutschrift';
            });

        return formGroup;
    }
}
