import { Component } from '@angular/core';
import { BaseTabComponent } from '@sharedComponents/dialogs/booking-dialog/tabs/abstract/base-tab.component';
import { TenantRelationAssignment, TenantRelationAssignmentDto } from '@shared/models/tenant-relation-assignment';
import { U2bValidators } from '@shared/validators/validators';
import { U2bDateValidators } from '@shared/validators/date/date-validators';
import { BookingDialogService } from '@sharedComponents/dialogs/booking-dialog/services/booking-dialog.service';
import { AbstractControlOptions, FormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BookingAttribute } from '@sharedComponents/dialogs/booking-dialog/enums/booking-attribute.enum';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { TenantRelation } from '@shared/models/tenant-relation';

@UntilDestroy()
@Component({
    selector: 'booking-tenant-relation-tab',
    templateUrl: './tenant-relation-tab.component.html',
    styleUrls: ['./tenant-relation-tab.component.scss']
})
export class TenantRelationTabComponent extends BaseTabComponent<TenantRelationAssignmentDto, TenantRelationAssignment> {

    constructor(
        bookingDialogService: BookingDialogService,
        formBuilder: FormBuilder
    ) {
        super(
            bookingDialogService,
            formBuilder,
            BookingAttribute.TENANT_RELATION_ASSIGNMENT,
            TenantRelationAssignment
        );

        if (bookingDialogService.readonlyView) {
            this.formGroup.get('captureTenantRelation').disable({emitEvent: false});
        }

        // bookingDialogService.tenantRelationAssignment.value$
        //     .pipe(
        //         untilDestroyed(this),
        //         map((value: TenantRelationAssignment) => value?.tenantRelation),
        //         filter((tenantRelation: TenantRelation) => !!tenantRelation?.id),
        //         debounceTime(1000),
        //     )
        //     .subscribe((tenantRelation) => {
        //         console.log(this.formGroup.get('tenantRelationForm').value);
        //         this.formGroup.get('tenantRelation').setValue(tenantRelation);
        //     });
    }

    override distinctUntilChangedComparator(a: TenantRelationAssignment, b: TenantRelationAssignment): boolean {
        return a.tenantRelation?.id === b.tenantRelation?.id &&
            a.fromDate === b.fromDate &&
            a.toDate === b.toDate &&
            a.periodFromDate === b.periodFromDate &&
            a.periodToDate === b.periodToDate &&
            a.vestingPeriodFrom === b.vestingPeriodFrom &&
            a.vestingPeriodUntil === b.vestingPeriodUntil &&
            a.lastDayOfMonth === b.lastDayOfMonth &&
            a.captureTenantRelation === b.captureTenantRelation;
    }

    formTemplate(value: TenantRelationAssignment | undefined): {
        controls: { [key: string]: any },
        options?: AbstractControlOptions
    } {
        return {
            controls: {
                id: [value?.id || null],
                fromDate: [
                    value?.fromDate,
                    [U2bValidators.required('Bitte angeben, zu welchem Datum das Abo starten soll.')],
                ],
                toDate: [value?.toDate || null],
                periodFromDate: [
                    value?.periodFromDate,
                    value?.periodFromDate ? [] : [
                        U2bValidators.required('Bitte angeben, zu welchem Datum das Abo für die Beziehung starten soll.'),
                    ],
                ],
                periodToDate: [value?.periodToDate || null],
                vestingPeriodFrom: [
                    value?.vestingPeriodFrom,
                    value?.vestingPeriodFrom ? [] : [
                        U2bValidators.required('Bitte gib den Leistungszeitraum an.'),
                    ],
                ],
                vestingPeriodUntil: [
                    {value: value?.vestingPeriodUntil || null, disabled: value?.lastDayOfMonth},
                    [U2bValidators.required('Bitte gib den Leistungszeitraum an.')],
                ],
                lastDayOfMonth: [value?.lastDayOfMonth || false],
                captureTenantRelation: [value?.captureTenantRelation || false],
                tenantRelation: [value?.tenantRelation || null, [U2bValidators.required('Bitte wähle eine Beziehung aus.')]],
            },
            options: {
                validators: [
                    U2bDateValidators.dateBeforeOtherDate('fromDate', 'toDate', 'Start', 'Ende'),
                    U2bDateValidators.dateBeforeOtherDate('periodFromDate', 'periodToDate', 'Abo Start', 'Abo Ende'),
                    U2bDateValidators.dateBeforeOtherDate('vestingPeriodFrom', 'vestingPeriodUntil', 'Leistungszeitraum Start', 'Leistungszeitraum Ende'),
                ]
            }
        };
    }

}
