<div fxFlex="100" fxLayout="column" class="travelers-tab">

    <div [class.mb-16]="(electricMeterAssignments$ | async)?.length > 0" class="mt-16">
        <mat-accordion [multi]="false">
            <ng-container
                    *ngFor="let assignment of (electricMeterAssignments$ | async);">
                <app-booking-assignment-electric-meters [electricMeterAssignment]="assignment">
                </app-booking-assignment-electric-meters>
            </ng-container>
        </mat-accordion>
    </div>

    <div class="button-list button-list-vertical mb-16">
        <ng-container *ngIf="!bookingDialogService.boat.value?.id">
            <u2b-message type="warning">
                {{ ('assignElectricMeterNeedsBoat' | translate) }}
            </u2b-message>
        </ng-container>

        <ng-container *ngIf="!bookingDialogService.readonlyView">
            <button class="add-btn" mat-stroked-button color="accent"
                    [disabled]="!bookingDialogService.boat.value?.id"
                    (click)="connectElectricMeter()">Stromzähler hinzufügen
            </button>
        </ng-container>
    </div>

</div>
