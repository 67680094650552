import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { BerthApiService } from '@modules/bcm/@shared/services';
import { Berth } from '@shared/models/berth';
import { RealBaseStateService } from '@bcmServices/real-base-state.service';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BerthAssignmentApiService } from '@bcmApiServices/berth-assignment.api-service';
import { deepCopyArray } from '@core/functions/deep-copy';
import { PageRequest } from '@shared/models/pagination';
import { AppNotificationService } from '@core/services/app-notification.service';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';

class BerthStateService extends RealBaseStateService<Berth> {

    private readonly forGuestListState$ = new BehaviorSubject<Berth[]>([]);

    get forGuestList$(): Observable<Berth[]> {
        return this.forGuestListState$
            .asObservable()
            .pipe(distinctUntilChanged());
    }

    private readonly mapListState$ = new BehaviorSubject<Berth[]>([]);

    get mapList$(): Observable<Berth[]> {
        return this.mapListState$
            .asObservable()
            .pipe(distinctUntilChanged());
    }

    get mapList(): Berth[] {
        return this.mapListState$.getValue();
    }

    constructor() {
        super(Berth);
    }

    public setForGuestList(list: Berth[]): void {
        this.forGuestListState$.next(list);
    }

    public setMapList(list: Berth[]): void {
        this.mapListState$.next(list);
    }

}

// todo (thilo): replace all usages with new BerthFacade from state-management folder
@Injectable({providedIn: 'root'})
export class BerthsFacadeOld extends BerthApiService {

    private readonly state = new BerthStateService();

    get latestPageRequest(): PageRequest<Berth> {
        return this.state.latestPageRequest;
    }

    set latestPageRequest(value: PageRequest<Berth>) {
        this.state.latestPageRequest = value;
    }

    get berths$(): Observable<Berth[]> {
        return this.state.list$;
    }

    get mapList$(): Observable<Berth[]> {
        return this.state.mapList$;
    }

    get forGuestList$(): Observable<Berth[]> {
        return this.state.forGuestList$;
    }

    get selectedBerth$(): Observable<Berth> {
        return this.state.selected$;
    }

    get selectedBerth(): Berth {
        return this.state.selected;
    }

    get loadingAll$(): Observable<boolean> {
        return this.state.isUpdatingAll$;
    }

    get loading$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    constructor(private assignmentApi: BerthAssignmentApiService,
                private informationDialogService: InformationDialogService,
                private appNotificationService: AppNotificationService,
                httpClient: HttpClient,
                bcmService: BcmService) {
        super(httpClient, bcmService);
    }

    private _loadAll(httpParams?: HttpParams): Observable<Berth[]> {
        this.state.startUpdatingAll();
        return this.getAll(httpParams);
    }

    loadAll(httpParams?: HttpParams): Subscription {
        this.state.startUpdatingAll();
        return this._loadAll(httpParams)
            .pipe(
                tap(berths => {
                    this.state.setList(berths);
                    this.state.stopUpdatingAll();
                }),
            ).subscribe();
    }

    loadBerthMapList(httpParams?: HttpParams): Subscription {
        if (!httpParams) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.set('type', 'berth-map');

        this.state.startUpdatingAll();
        return this._loadAll(httpParams)
            .pipe(
                tap(berths => {
                    this.state.setMapList(berths);
                    this.state.stopUpdatingAll();
                }),
            ).subscribe();
    }

    loadForBerthsAssignmentMatrix(httpParams: HttpParams = new HttpParams()): Observable<Berth[]> {
        return this.matrix(httpParams);
    }

    loadOne(id: number): Observable<Berth> {
        this.state.startUpdating();

        return this.getOne(id)
            .pipe(
                tap(berth => {
                        this.state.setSelected(berth);
                        this.state.stopUpdating();
                    }
                ),
                switchMap(() => this.selectedBerth$)
            );
    }

    addBerth(tmpBerth: Berth): Observable<Berth> {

        if (tmpBerth?.handle?.length) {

            tmpBerth.id = -1;

            this.state.startUpdating();
            this.state.addListItem(tmpBerth);

            return this.add(tmpBerth)
                .pipe(
                    tap((berth) => {
                        this.state.replaceListItem(tmpBerth, berth);
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.state.removeListItem(tmpBerth);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    updateBerth(berth: Berth, berthData: Partial<Berth>): Observable<any> {
        if (berth?.id) {

            this.state.startUpdating();

            const updatedBerth = {
                ...berth,
                ...berthData
            } as Berth;

            this.state.replaceListItem(berth, updatedBerth); // optimistic change

            return this.update(berth, berthData)
                .pipe(
                    tap(() => this.state.stopUpdating()),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(updatedBerth, berth); // revert
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    removeBerth(berth: Berth): Observable<any> {

        if (berth?.id) {

            this.state.removeListItem(berth);

            return this.remove(berth)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.state.addListItem(berth);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    /* berth assignments */
    addAssignment(berth: Berth, tmpAssignment: BerthBoatAssignment): Observable<BerthBoatAssignment> {
        if (tmpAssignment?.from) {

            this.state.startUpdating();

            return this.assignmentApi
                .add(tmpAssignment)
                .pipe(
                    tap((assignment) => {
                        berth.assignments.push(assignment);
                        berth.assignments = deepCopyArray(berth.assignments);
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    updateAssignment(assignment: BerthBoatAssignment, newAssignmentData: Partial<BerthBoatAssignment>): Observable<BerthBoatAssignment> {

        if (assignment?.id) {

            this.state.startUpdating();

            return this.assignmentApi
                .update(assignment, newAssignmentData)
                .pipe(
                    tap((updatedAssignment) => {
                        if (updatedAssignment.berth?.id) {
                            const foundItem = this.state.list.find(b => b.id === updatedAssignment.berth.id);
                            if (foundItem) {
                                const index = foundItem.assignments.findIndex(item => item.id === assignment.id);
                                if (index > -1) {
                                    foundItem.assignments[index] = updatedAssignment;
                                    foundItem.assignments = deepCopyArray(foundItem.assignments);
                                }
                            }
                        }
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    removeAssignment(assignment: BerthBoatAssignment, {
        cancelInvoice = false,
        removeInvoicePositions = false
    }): Observable<any> {
        if (assignment?.id) {

            this.state.startUpdating(); // todo: reload only single section (or 3 if switched berth)

            return this.assignmentApi
                .remove(assignment, {cancelInvoice, removeInvoicePositions})
                .pipe(
                    tap((response: { result: unknown, errors: Error[] }) => {
                        const foundItem = this.state.list.find(b => b.id === assignment.berth.id);
                        if (foundItem) {
                            foundItem.assignments = foundItem.assignments.filter(a => a.id !== assignment.id);
                            foundItem.assignments = deepCopyArray(foundItem.assignments);
                        }
                        const foundItem2 = this.state.mapList.find(b => b.id === assignment.berth.id);
                        if (foundItem2) {
                            foundItem2.assignments = foundItem2.assignments.filter(a => a.id !== assignment.id);
                            foundItem2.assignments = deepCopyArray(foundItem2.assignments);
                        }
                        this.state.stopUpdating();

                        if (response.errors?.length) {
                            this.informationDialogService
                                .setBody(
                                    '<p>Das Löaschen war teilweise erfolgreich.</p>' +
                                    '<p>Folgende Probleme sind aufgetreten:</p>' +
                                    response.errors.map(error => `<p>${error.message}</p>`)
                                );
                        }
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

}
